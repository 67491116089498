<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>  
        <h4>Registo de candidaturas por Sexo</h4>
      </div>
  </div>
  <div class="row">    
    <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
      <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-12 mt-3">
        <h4 class="text-center">De las {{total}} (100%) candidaturas registradas, {{cSexo1}} ({{ cPorcentajeS1 }}%) son Hombres, {{cSexo2}} ({{ cPorcentajeS2 }}%) son Mujeres, {{cSexo3}} ({{ cPorcentajeS3 }}%) es No Binario y {{cSexo4}} ({{ cPorcentajeS4 }}%) a otro</h4>
    </div>
  </div>

  </div>

  


</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaSexo',
  components: { Pie },
  data() {
    return {
      listaSRPMR:[],
      listaCRPMR:[],
      listaPS:[],
      listaS:[],
     

      loaded:false,
      chartData: {       

        labels: [ 'Hombre', 'Mujer','No binario','Otro'],
        datasets: [ 
            {
            label: 'Sexo',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#E75395','#6461A9','#70257F','#2A7BA9']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        },

        legend:{
          display:true,
          position:'bottom',
          text:"De los candidados.."
        },
        title:{
          display:true,
          text: "Distribucion de datos"
        },
        
      },
      //Variables para creacion de los datos
      lista:[],
      listaC:[],
      


      cSexo1:0,
      cSexo2:0,
      cSexo3:0,
      cSexo4:0,
      cTotalSexo:0,
      cPorcentajeS1:0,
      cPorcentajeS2:0,
      cPorcentajeS3:0,
      cPorcentajeS4:0,
      total:0,
    
    } 

  },
  methods:{
    mergeListaSuplentes(listaP, listaS, listaS2){
            return listaP.map( element1 =>{
                if(element1.cve_distrito > 0){
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else if(element1.cve_distrito === "0" && element1.cve_mpio === "0"){
                    const element2 = listaS2.find(element => (element.posicion === element1.posicion+1)&&(element.id_postulacion===element1.id_postulacion))
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else{
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:""                  
                     } )
                    }
                }   
            })
        }

      
    },

  async mounted(){
    //let listatmp=[]
        //Se inicializan las listas que conforman la tabla se realiza con create para no utilizar promesas
        const res2 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/candidatos`)
        this.listaC=res2.data;
        const res3 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/diputacioness`)
        this.listaS = res3.data;

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/partidos'
        const res = await axios.get(url)
        this.listaP = res.data
        let count1=0
        let count2=0
        let count3=0
        let count4=0
        
        this.listaP.forEach(element=>{
         if((element.posicion%2) !== 0){
                this.listaSRPMR.push(element) // Lista que guarda puras DRP propietarias
            }
         })
        //console.log("LISTA FILTRADA SIN SUPLENCIAS",this.listaSRPMR)
        this.listaP.forEach(element=>{
            if((element.posicion%2)===0){
                this.listaCRPMR.push(element) //Lista que guarda puras DRP suplentes
            }

        })
        this.listaPS = this.mergeListaSuplentes(this.listaSRPMR, this.listaS,this.listaCRPMR) //SE QUITA this.listaP 
        //se combinan las listas de acuerdo a una condicion en el metodo
        
        //console.log("LISTA CON IMAGEN",listatmp)
        this.listaPS.forEach(element =>{
            this.lista.push(element)
            
        })
        this.total=this.lista.length

    
       //console.log(this.lista)
        this.lista.forEach(element =>{
          
        {
            if(element.genero === "H")
                {
                  count1=count1+1

                }
              else if(element.genero === "M"){
                count2=count2+1

              }
              else if(element.genero === "NB") {
                count3= count3+1
              }   
              else{
                count4= count4+1
                //console.log(element.id)

              }   
        }      
     })

     this.chartData.datasets[0].data[0] = count1
     this.chartData.datasets[0].data[1] = count2
     this.chartData.datasets[0].data[2] = count3
     this.chartData.datasets[0].data[3] = count4 
 
   
     this.cTotalSexo = count1 + count2 + count3+ count4
     this.cSexo1 = count1
     this.cSexo2 = count2
     this.cSexo3 = count3
     this.cSexo4 = count4

     this.cTotalSexo = this.cSexo1 + this.cSexo2 + this.cSexo3
     this.cPorcentajeS1 = ((this.cSexo1 * 100) / this.cTotalSexo).toFixed(2)
     this.cPorcentajeS2  = ((this.cSexo2 * 100) / this.cTotalSexo).toFixed(2)
     this.cPorcentajeS3  = ((this.cSexo3 * 100) / this.cTotalSexo).toFixed(2)
     this.cPorcentajeS4  = ((this.cSexo4* 100) / this.cTotalSexo).toFixed(2)
     

     this.loaded = true

    }
}
</script>


<style>
/*rescribimos los estilos heredados*/
h3{
  font-weight:bolder;  
  color: #686868;
}
p{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
/*rescribimos los estilos heredados*/
h4{
  font-weight:bolder;  
  color: #686868;
}
h5{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
.sizeChart{
  width: 600px;
  height: 600px;

}

</style>