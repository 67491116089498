<template>
<div class="container">
    <div style="border-color:#970064;">
            <div class="row" style="margin-bottom: -4%; ">
            <b-navbar toggleable="lg">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>             
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="navbar heading-top" style="margin-left: auto; color:#970064">
                        <b-nav-item href="https://directorio.ine.mx/filtroConsultaEmpleado" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-users" class="btn-toggle" />&emsp14;Directorio INE</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/transmisiones-en-vivo/"  target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-video"  class="btn-toggle" />&emsp14;Transmisiones en vivo</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/estructura-ine/consejo-general/gaceta-electoral/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-book" class="btn-toggle" />&emsp14;Gaceta Electoral</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/agenda-consejeros-electorales/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-calendar-days"  class="btn-toggle" />&emsp14;Agenda Consejeros (as)</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/compendio-normativo/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-scale-balanced"  class="btn-toggle" />&emsp14;NormaINE</p></b-nav-item>
                        <b-nav-item href="https://www.facebook.com/INEMexico/" target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-facebook" class="brand-icon btn-toggle"  style="color:gray"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://twitter.com/INEMexico"  target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://www.youtube.com/user/IFETV" target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-youtube" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/feed" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-square-rss" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>             
                    </b-navbar-nav>&nbsp;&emsp;                               
                </b-collapse>  
            </b-navbar>
            </div>           
            <div class="col-xs-12 col-sm-12 col-lg-12 heading-bg">                         
                    <b-navbar toggleable="lg">
                    <b-navbar-brand href="#" style="margin-right: 13%;"><img src="../assets/ine_logo.png" alt="logo INE" width="90px"/></b-navbar-brand>                    
                        <b-collapse id="nav-collapse" is-nav>
                            <b-navbar-nav >
                                <b-nav-item href="https://portal.ine.mx/sobre-el-ine/" target="_blank" class="">&nbsp;&emsp; SOBRE EL INE</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/credencial/"  target="_blank">&nbsp;&emsp;CREDENCIAL PARA VOTAR</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/voto-y-elecciones/" target="_blank" >&nbsp;&emsp;VOTO Y ELECCIONES</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/cultura-civica/" target="_blank" >&nbsp;&emsp;CULTURA CÍVICA</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/servicios-ine/" target="_blank">&nbsp;&emsp;SERVICIOS INE</b-nav-item>
                                <b-nav-item href="https://centralelectoral.ine.mx/" target="_blank">&nbsp;&emsp;CENTRAL ELECTORAL</b-nav-item>
                                
                            </b-navbar-nav>
                                   
                        </b-collapse>
                    </b-navbar>                    
            </div>

<div  class="d-flex flex-column" id="bottom-bar-breadcrumb">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            
            <li class="breadcrumb-item"><a href="/">REGRESAR A CANDIDATAS Y CANDIDATOS CONÓCELES</a></li>
            <li class="breadcrumb-item active fw-bold" style="color:#970064" aria-current="page">INFORMACIÓN CURRICULAR</a>&nbsp; </li>
        </ol>
            </nav>
        </div>
</div>
</div>
  
  <!-- class="fixed-top" -->
  <!--
        <div >
        <b-navbar type="light" variant="light">
    <b-navbar-brand href="#"></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item style="height: 100px;">&emsp;</b-nav-item>
      </b-navbar-nav>

    
  -->

      <!-- Right aligned nav items -->
      <!-- <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
           Using 'button-content' slot -->
          <!-- <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown> -->
      <!-- </b-navbar-nav> -->
      <!--
            </b-collapse>
  </b-navbar>
</div>

      -->


</template>
<script>
 export default {
  // data(){
  //   return{
  //     tipos:null
  //   }
  // },
  methods:{
    partido(){
    this.thispath('/partido')
  },
    partidocomite(){
    this.thispath('/partidocomite')
  },
    thispath(path){
      if (this.$route.path !== path) this.$router.push(path)
  }
}
    }
</script>
<style scoped>
/* .navbar.navbar{
    background-color: #AABB55!important;
 } */


.btn-toggle-f {
    font-weight: 16;
    color: #686868;
    background-color: #fff;  
    border-radius: 5px;
    font-size:25px ; 
    text-align: left;  

}
.btn-toggle-f:hover,
.btn-toggle-f:focus {
    color: #fff;
    background-color: #1877f2;
    font-weight: 16;
    border-radius: 5px;
    font-size: 25px;
    text-align: left;
}
.btn-toggle-x {
    font-weight: 16;
    color: #686868;
    background-color: #fff;  
    border-radius: 5px;
    font-size:25px ; 
    text-align: left;  

}
.btn-toggle-x:hover,
.btn-toggle-x:focus {
    color: #fff;
    background-color: #000;
    font-weight: 16;
    border-radius: 5px;
    font-size: 25px;
    text-align: left;
}
.btn-toggle-y {
    font-weight: 16;
    color: #686868;
    background-color: #fff;  
    border-radius: 5px;
    font-size:25px ; 
    text-align: left;  

}
.btn-toggle-y:hover,
.btn-toggle-y:focus {
    color: #fff;
    background-color: #FF0000;
    font-weight: 16;
    border-radius: 5px;
    font-size: 25px;
    text-align: left;
}

.btn-toggle-rss {
    font-weight: 16;
    color: #686868;
    background-color: #fff;  
    border-radius: 5px;
    font-size:25px ; 
    text-align: left;  

}
.btn-toggle-rss:hover,
.btn-toggle-rss:focus {
    color: #fff;
    background-color: #F29738;
    font-weight: 16;
    border-radius: 5px;
    font-size: 25px;
    text-align: left;
}
strong {
    font-family: GalanoBold;
}
.heading {
    padding: 10px 5% 10px 5%;
    font-family: GalanoRegular;
    background: rgb(223,223,223);
    background: linear-gradient(180deg, rgba(223,223,223,1) 0%, rgba(255,255,255,1) 46%, rgba(255,255,255,1) 100%);
    color: #B20066;
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -webkit-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -moz-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
   
    
    
}
.heading-bg{
   
   font-family: GalanoRegular;  
   width: 100%;
   border-radius: 12px;
   font-size: 14px;
   
   margin-bottom:2%;
   
}
.heading-top{
   color:#970064 ;
}


</style>