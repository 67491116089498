<template>
    <div><label></label></div>
    
</template>
<script>
export default {
    mounted() {
        
        // Cargar el script de Google Tag Manager
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YM7ZLSHPGR';
            document.head.appendChild(script);
    
            // Configurar el dataLayer
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YM7ZLSHPGR');
            },             
        }    

</script>
