<template>
  <div id="home">
    <div class="wrapper fadeInDown">
      <div id="formContent" class="d-flex flex-column">
        <!-- Tabs Titles -->

        <!-- Icon -->
        <div class="fadeIn first">
          <h2>Acceso a los Cuestionarios de Identidad, Profesional y Laboral</h2>
          <img src="../assets/boton_conoceles.png" style="margin-top: 20px;" id="icon" alt="User Icon" />
        </div>
        <!-- Login Form -->
        
          <form v-on:submit.prevent="login">
            <div class="mb-3">
              <p>Nombre de Usuario</p>
              <input type="text" id="login" class="fadeIn second" name="login" placeholder="correo@dominio.com" v-model="username">
              <p>Contraseña</p><br>
              <input type="password" id="password" class="fadeIn third" name="login" placeholder="Contraseña" v-model="password">
            </div>
            <div class="d-flex flex-column mb-3">              
              <button type="submit" class="btn btn-custom fadeIn fourth">Ingresar</button>
            </div>
          </form>
        <!-- Remind Passowrd -->
        <div id="formFooter">
          <!-- <a class="underlineHover" href="#">He olvidado la contraseña</a> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios';


export default {
  name: 'Login',
  components: {
   // HelloWorld
  },
  data(){
    return{
      username:"",
      password:"",
      inicioSesion:1
     
      
      
    }
  },

  methods:{
    async login(){
      const url = 'https://informatica.iem.org.mx/api/conoceles/api/__login__'
      try {
        const result = await axios.post(url, { username:this.username, password:this.password })
        const { idCandidato, token, username, usuarioActivo } = result.data
        //console.log({idCandidato, token, username})
        localStorage.setItem('loggedAppUser', JSON.stringify({idCandidato, token, username,usuarioActivo}))
        if(usuarioActivo!== false){         
          //this.$router.push('/contacto')
          this.$router.push({name:'LandingPage', params:{ loggedIn:1}}) // se envia clave de inicio de sesion 
         
        }
       
      } catch (error) {
        //const {response} = error.request
        this.$alert('','EL PERIODO DE CAPTURA PARA LOS CUESTIONARIOS CURRICULAR Y DE IDENTIDAD A FINALIZADO','error');
        this.$router.push('/index')
      }
      //this.$router.push('/contacto');
    },

  },
  async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
    const loggedAppUser = localStorage.getItem('loggedAppUser')

    //console.log(loggedAppUser)
    if(loggedAppUser){
      this.$router.push('/LandingPage')
    }
  },
}

</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* BASIC */
html {
  background-color: #56baed;
}

body {
  font-family: GalanoRegular;
  height: 100vh;
}

a {
  font-family: GalanoRegular;
  color: #6a6ea8;
  display:inline-block;
  text-decoration: none;
  font-size: 14px;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #6a6ea8;
  font-family: GalanoRegular;
  padding-left: 2%;
  padding-right: 2%;
}

/* STRUCTURE */
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 10%;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */
h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/
.btn-custom {
    font-family: GalanoRegular;
    margin: 0px 7.5%;
    padding: 15px 0px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #B20066;
    --bs-btn-hover-border-color: #B20066;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #B20066;
}

input[type=text]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */
/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #B20066;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #B20066;
}

.underlineHover:hover:after{
  width: 100%;
}

/* OTHERS */
*:focus {
    outline: none;
} 

#icon {
  width:60%;
} 
input {
  font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
p{
  font-family: GalanoRegular;
  text-align:left;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;  
  color:#6c757d;
}
</style>