<template>
    <div>
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container">
            <div id="first">
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=18>
                    <span>18 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div>
            <div class="row questionary-row">
                <div class="questionary-container">
                <p class="question">Personas mexicanas migrantes</p>
                <div style="margin-top: 5%; margin-bottom: 5%;">
                    <label class="multiple-choice">Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <div class="radio-tile-group">
                            <div class="input-container">
                                <input class="radio-button" type="radio" value="Sí" v-model="identidad.situacion_asegurada"/>
                                <div class="radio-tile">
                                    Sí
                                </div>
                            </div>

                            <div class="input-container">
                                <input class="radio-button" type="radio" value="No" v-model="identidad.situacion_asegurada"/>
                                <div class="radio-tile">
                                    No
                                </div>
                            </div>

                            <div class="input-container">
                                <input class="radio-button" type="radio" value="SR" v-model="identidad.situacion_asegurada"/>
                                <div class="radio-tile">
                                    Prefiero no contestar
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="d-flex flex-row">
                    <button style="margin-right: 1%;" type="button" :disabled="disable" class="btn btn-secondary" v-on:click="atras()">Atras&emsp;<fa icon="arrow-left"/>&emsp;</button>
                    <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button>
                </div>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'

export default{
    name:"Identidad15",
    data(){
        return{
            routes:routes,
            isLoading: true,
            disable:false,
            idCandidato:null,
            config:{},
            username:"",
            dirname:this.$assetsDirname, max:24,
            identidad:{situacion_asegurada:""},
            pagina:"",
            imagen:"",
            estadoRoC :"",
            validacionCaptura:false,
            logsAcciones:[],
            dataLogs : logs,
            loggedIn: this.$route.params.loggedIn
        }
    },
    components:{
        Header,
        Footer,
        Loading
    },
    methods:{
        async atras(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-1)
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        async save(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            if(this.paginat > 0){
                this.estadoRoC ="CAPTURADO"
                this.validacionCaptura=true

                if(this.identidad.comunidad_indigena !== ""){
                const res = await axios.patch(url, { identidad:this.identidad , idCandidato:this.idCandidato, pagina:this.pagina+(25-this.paginat),paginat:0,estadoRoC:this.estadoRoC,validacionCaptura:this.validacionCaptura}, this.config)
                    if(res.data.result = 1){
                        this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                        this.logsCandidato(37)
                        this.disable=false
                        this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                        return
                    }
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                    this.disable=false
                    }
                    else{
                    this.$alert('','<label class="alertlabel">Campo de seleccion obligatorio</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.disable=false
                    }
            }else{
                if(this.identidad.comunidad_indigena !== ""){
                const res = await axios.patch(url, { identidad:this.identidad , idCandidato:this.idCandidato, pagina:this.pagina+1 }, this.config)
                    if(res.data.result = 1){
                        this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                        this.logsCandidato(36)
                        this.disable=false
                        this.path(this.pagina+1) // siguiente pagina
                        return
                    }
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+1) // siguiente pagina
                    this.disable=false
                    }
                    else{
                        this.$alert('','<label class="alertlabel">Campo de seleccion obligatorio</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                        this.disable=false
                    }
             }
        },
        async logsCandidato(clave){
            let now= new Date();
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            try{
                const log  = this.dataLogs[clave];
                console.log(log)
                this.logsAcciones.push(log+" : "+now)            
                await axios.patch(url, { idCandidato:this.idCandidato,logsAcciones:this.logsAcciones }, this.config)
                

            }catch(error){
                console.error(error)
            }
    }

    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato
            this.config = {
                headers:{
                    Authorization: `Bearer ${token}` 
                }
            }
            const result = await axios.get(url, this.config)
            this.pagina = result.data[0].pagina
            // Varible bandera para control de modificacion de respuestas si es mayor a 0, se guarda variable temporal
            this.paginat = result.data[0].paginat           
            this.path(this.pagina)

            this.identidad.situacion_asegurada = result.data[0].identidad.situacion_asegurada
            this.imagen = result.data[0].imagen
            //Registro acciones usuario
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }

            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped src="../assets/styles/identidad.css">

</style>