<template>
    <div class="container-fluid">
        <div class="row">               
            <div class="col-xs-12 col-sm-12 col-lg-12 align-items-center" >
                <!--Inicia Panel de Busqueda-->
                <h5 class="fw-bold"><strong>ASIGNACIONES Y REVISIONES POR PARTIDO CARGO O PARTIDO POLÍTICO </strong></h5>
            <hr>
            <!--Radio buttons seleccion por Cargo-->
            <div class="buttons-container mb-4" id="busquedas">
                <label class="label-color mb-2" for="busquedaActorPolitico">¿QUE DESEAS CONSULTAR?</label>
                <br>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="todos" value="todos" v-model=valorSeleccionado @change="getFilterByValue">
                    <label class="form-check-label" for="todos">
                        TODOS                  
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="ayuntamiento" value="3" v-model=valorSeleccionado @change="getFilterByValue">
                    <label class="form-check-label" for="ayuntamiento">
                        AYUNTAMIENTOS
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="diputacionmr" value = "1" v-model=valorSeleccionado @change="getFilterByValue" >
                    <label class="form-check-label" for="diputacionmr">
                        DIPUTACIONES MR             
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="diputacionrp" value = "2" v-model=valorSeleccionado @change="getFilterByValue" >
                    <label class="form-check-label" for="diputacionrp">
                        DIPUTACIONES RP             
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="accionesAfirmativas" value=4 v-model=valorSeleccionado @change="getFilterByValue" >
                    <label class="form-check-label" for="accionesAfirmativas">
                        ACCIONES AFIRMATIVAS       
                    </label>
                </div>
            </div> 
            <!--Termina Radio buttons seleccion por Cargo-->
            <!--Inician botones por POSTULANTES-->
            <transition name="fade">
                    <!--Radio button seleccion por Actor Politico-->
                <div v-show="showDiv" >
                    <div class="row buttons-container" >
                        <label class="label-color" for="busquedaActorPolitico">ELIGE UNA OPCIÓN: </label>
                    </div>                    
                    <br>
                    <div class="row buttons-container mb-2" >
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido1" value="PAN" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido1">
                                <img src="../assets/pan-1.png" alt="Logo PAN" class="size-logos">
                                PARTIDO ACCIÓN NACIONAL                                           
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido2" value="PRI" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido2">
                                <img src="../assets/pri-2.png" alt="Logo PAN" class="size-logos">
                                PARTIDO REVOLUCIONARIO INSTITUCIONAL
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido3" value="PRD" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido3">
                                <img src="../assets/prd-3.png" alt="Logo PAN" class="size-logos">
                                PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA
                            </label>
                        </div>
                    </div>

                    <div class="row buttons-container mb-2">                           
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido4" value="PT" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido4">
                                <img src="../assets/pt-4.png" alt="Logo PAN" class="size-logos">
                                PARTIDO DEL TRABAJO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido5" value="PVEM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido5">
                                <img src="../assets/pvem-5.png" alt="Logo PAN" class="size-logos">
                                PARTIDO VERDE ECOLOGISTA DE MÉXICO             
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido6" value="MC" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido6">
                                <img src="../assets/pmc-6.png" alt="Logo PAN" class="size-logos">
                                MOVIMIENTO CIUDADANO               
                            </label>
                        </div>
                    </div>

                    <div class="row buttons-container mb-2">                        
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido7" value="MORENA" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido7">
                                <img src="../assets/morena-7.png" alt="Logo PAN" class="size-logos">
                                MORENA             
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido8" value="PESM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido8" style="font-size: 14px;">
                                <img src="../assets/pes-8.png" alt="Logo PAN" class="size-logos">
                                PARTIDO ENCUENTRO SOLIDARIO MICHOACÁN               
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido9" value="PMM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido9">
                                <img src="../assets/mas-9.png" alt="Logo PAN" class="size-logos">
                                MÁS MICHOACAN             
                            </label>
                        </div>

                    </div>

                    <div class="row buttons-container mb-5">
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido10" value="MICH1" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido10">
                                <img src="../assets/mp-10.png" alt="Logo PAN" class="size-logos">
                                MICHOACÁN PRIMERO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido11" value="TMX" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido11">
                                <img src="../assets/txm-11.png" alt="Logo PAN" class="size-logos">
                                TIEMPO X MÉXICO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido13" value="PT-MORENA-PVEM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido13">
                                <img src="../assets/mpv-13.png" alt="Logo PAN" class="size-logos">
                                SIGAMOS HACIENDO HISTORIA            
                            </label>
                        </div>
                       
                    </div>
                    <div class="row buttons-container mb-5">

                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido12" value=4 v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido12">
                                <img src="../assets/ci-12.png" alt="Logo PAN" class="size-logos">
                                CANDIDATURAS INDEPENDIENTE             
                            </label>
                        </div>
                        
                
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido14" value=3 v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido14">
                                <img src="../assets/ccomun.png" alt="Logo PAN" class="size-logos">
                                CANDIDATURAS COMUNES          
                            </label>
                        </div>
                        <div v-show="showDiv4" class="col-xs-12 col-sm-12 col-lg-4 mt-2">                
                            <label for="municipios " style="margin-right: 10px;" >C. COMUNES P. POSTULANTE</label>                
                                <multiselect
                                    v-model="selectionPPostula"
                                    @select="getByPartidoPostula()"
                                    :options="siglas_p"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    placeholder="Selecciona una opción"
                                    style="font-family: GalanoRegular;">
                                    
                            </multiselect>
                            
                        </div>
                                               
                    </div>
                </div>  

                </transition>
                <!--Terminan botones por POSTULANTES-->
                <div class="row buttons-container align-content-start" v-show="showDiv">                
                    <div class="col-xs-12 col-sm-12 col-lg-6 d-inline-flex">                    
                        <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                        <label class="label-color" for="search" style="margin-right: 10px;">BUSCAR CANDIDATOS:</label>
                        <input type="text" class="form-control" id="search" style="width: 320px; "  v-model="filters.nombre.value" placeholder="Usuario, Nombre, Postulante, distrito" @change="getFilterBySearch">&emsp14;&emsp14;
                        <b-button variant="outline-secondary" style="color:#9F2380; font-weight:bolder; border:2px solid" v-on:click=" limpiarFiltros()">LIMPIAR FILTROS&emsp14;<fa icon="fa-solid fa-trash"/></b-button>                                                                 
                    </div> 
                             
                </div>

                    <v-table  class="custom-table " v-if="activarLista===true" :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event" id="top">
                        <thead slot="head" class="bg-light text-center">                         
                            <v-th sortKey="foto" class="text-center">FOTO</v-th>
                            <v-th sortKey="partido" class="text-center">POSTULANTE</v-th>                 
                            <v-th sortKey="usuario" class="text-center">USUARIO</v-th>
                            <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                            <v-th sortKey="asignaciones" class="text-center">MUNICIPIO</v-th>
                            <v-th sortKey="asignaciones" class="text-center">DISTRITO</v-th>                                                                  
                            <v-th sortKey="observaciones" class="text-center">REVISORES</v-th>
                            <v-th sortKey="activaciones" class="text-center">ESTATUS</v-th>  
                            <v-th sortKey="reasignaciones" class="text-center"><input class ="form-check-input" id="masterCheckbox" v-if="visibleFilter" type="checkbox" v-model="selectAll" >ASIGNAR</v-th> 
                           
                            <v-th sortKey="captura" class="text-center">REVISION DE DATOS {{headerTable}}</v-th>                       
                        </thead>
                    <tbody slot="body" slot-scope="{displayData}">                    
                        <tr v-for="candidato in displayData" :key="candidato.idCandidato">  <!--falta programar evento row-->                         
                            
                                <td><b-avatar v-if="candidato.imagen" :src="dirname+candidato.imagen" size="2.5rem"></b-avatar></td>
                                <td><img :src="dirimagenes+getLogoPartidos(candidato.partido, candidato.tipo_postulante,candidato.postulante)" class="size-logos"></td> 
                                <td > {{ candidato.username}}</td>
                                <td > {{ candidato.nombre}}</td>                                  
                                <td class="text-uppercase">{{ getMunicipios(candidato.cve_mpio) }}</td> 
                                <td>{{ candidato.distrito }}</td>
                                <td>{{ cargarListaValidadores(candidato.asignacionValidador) }}</td>  
                                
                                <td v-if="isOPL1">
                                    <p v-if="!candidato.validacionTransparencia" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL1">  
                                    <input v-if="!candidato.validacionTransparencia" class="form-check-input text-center" type="checkbox" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                    <label class="form-check-label">
                                         ASIGNAR
                                     </label>                                  
                                </td>
                                <td v-if="isOPL2">
                                    <p v-if="!candidato.validacionIgualdad" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL2">  
                                    <input v-if="!candidato.validacionIgualdad" class="form-check-input text-center" type="checkbox" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                    <label class="form-check-label">
                                         ASIGNAR
                                     </label>                                  
                                </td>
                                <td v-if="isOPL3">
                                    <p v-if="!candidato.validacionCPyPP" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL3">  
                                    <input v-if="!candidato.validacionCPyPP" class="form-check-input text-center" type="checkbox" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                    <label class="form-check-label">
                                         ASIGNAR
                                     </label>                                  
                                </td>
                               
                                <td ><router-link :to ="{name:'RevisionCandidato', params: {id:candidato.idCandidato}}" target="_blank"> <p style="color:#9F2380;text-align:center">REVISION DE DATOS</p></router-link></td>
                            </tr> <!--cierre td encabezados-->                              
                    </tbody>                
                </v-table>
                <!--Tabla con filtros acitivados-->
                <v-table  class="custom-table " v-else :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event" id="top">
                        <thead slot="head" class="bg-light text-center">                         
                            <v-th sortKey="foto" class="text-center">FOTO</v-th>
                            <v-th sortKey="partido" class="text-center">POSTULANTE</v-th>                 
                            <v-th sortKey="usuario" class="text-center">USUARIO</v-th>
                            <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                            <v-th sortKey="asignaciones" class="text-center">MUNICIPIO</v-th>
                            <v-th sortKey="asignaciones" class="text-center">DISTRITO</v-th>                                                                  
                            <v-th sortKey="observaciones" class="text-center">REVISORES</v-th>
                            <v-th sortKey="activaciones" class="text-center">ESTATUS</v-th>  
                            <v-th sortKey="reasignaciones" class="text-center"><input class ="form-check-input" id="masterCheckbox" v-if="visibleFilter" type="checkbox-lg" v-model="selectAll" >ASIGNAR</v-th> 
                           
                            <v-th sortKey="captura" class="text-center">REVISION DE DATOS {{headerTable}}</v-th>                       
                        </thead>
                    <tbody slot="body" slot-scope="{displayData}">                    
                        <tr v-for="candidato in displayData" :key="candidato.idCandidato">  <!--falta programar evento row-->                         
                            
                                <td><b-avatar v-if="candidato.imagen" :src="dirname+candidato.imagen" size="2.5rem"></b-avatar></td>
                                <td><img :src="dirimagenes+getLogoPartidos(candidato.partido, candidato.tipo_postulante,candidato.postulante)" class="size-logos"></td> 
                                <td > {{ candidato.username}}</td>
                                <td > {{ candidato.nombre}}</td>                                  
                                <td class="text-uppercase">{{ getMunicipios(candidato.cve_mpio) }}</td> 
                                <td>{{ candidato.distrito }}</td>
                                <td>{{ cargarListaValidadores(candidato.asignacionValidador) }}</td>  
                                
                                <td v-if="isOPL1">
                                    <p v-if="!candidato.validacionTransparencia" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL1">
                                    <label for="check" class="form-check-label" >
                                         ASIGNAR
                                     </label>   
                                    <input v-if="!candidato.validacionTransparencia" class="form-check-input text-center" type="checkbox" id="check" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                                                 
                                </td>
                                <td v-if="isOPL2">
                                    <p v-if="!candidato.validacionIgualdad" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL2">  
                                    <input v-if="!candidato.validacionIgualdad" class="form-check-input text-center" type="checkbox" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                    <label class="form-check-label">
                                         ASIGNAR
                                     </label>                                  
                                </td>
                                <td v-if="isOPL3">
                                    <p v-if="!candidato.validacionCPyPP" style="text-decoration: none; color:#9F2380; text-align:center">SIN VALIDAR</p>
                                    <p v-else disabled style="text-decoration: none; color:#9F2380;text-align:center" >VALIDADO</p>
                                </td>
                                <td v-if="isOPL3">  
                                    <input v-if="!candidato.validacionCPyPP" class="form-check-input text-center" type="checkbox" v-model="listaFoliosCandidatos" :value="candidato.idsc" @change="asignarFolio(candidato.idsc)">
                                    <input v-else type="checkbox" unchecked :disabled="true" class="form-check-input text-center" :value="false">
                                    <label class="form-check-label">
                                         ASIGNAR
                                     </label>                                  
                                </td>
                                
                                <td ><router-link :to ="{name:'RevisionCandidato', params: {id:candidato.idCandidato}}" target="_blank"> <p style="color:#9F2380;text-align:center">REVISION DE DATOS</p></router-link></td>
                            </tr> <!--cierre td encabezados-->                              
                    </tbody>                
                </v-table>
                <smart-pagination
                    :currentPage.sync="currentPage"
                    :totalPages="totalPages"
                    :maxPageLinks="10"
                    class="pagination justify-content-end"
                />
                <div class="row align-items-center" v-show="visibleDiv">
                    <div class="col-xs-12 col-sm-12 col-lg-5 d-inline-flex text-align-center ">
                        <label  for="asignaciones" class="form-label" id="asignaciones">ASIGNAR USUARIOS:</label>                                          
                        <multiselect
                        v-model="selection"
                        @select="choose()"
                        :options="asignaciones"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;">
                        </multiselect>                        
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-7 d-inline-flex text-align-center">
                        <label for="">{{ rol }}</label>&emsp;&emsp;
                        <button type="button" class="btn btn-success" v-on:click="realizarAsignacion()" >REALIZAR ASIGNACIÓN&emsp;</button>&emsp;&emsp;
                        <button type="button"  class="btn btn-danger"  v-on:click=" limpiarFiltros()">LIMPIAR ASIGNACIONES&emsp;<fa icon="fa-solid fa-trash"/></button>  
                    </div>                        
                </div>
            </div><!--Termina Div tabla responsiva-->   
        </div>       
     
    </div><!--Fin container-->
  
</template>

<script>
/**LISTA DE CANDIDATOS PARA REALIZAR ASIGNACIONES A LOS VALIDAROES Y A LOS PARTIDOS */
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import distritos from '../data/distritos.json'
import municipios from '../data/municipios.json'
import lodash from 'lodash'


//import { elements } from 'chart.js';

export default{
    name:'ListaCandidatos',
    data(){
        return{
                      /**
             * INICIAN VARIABLES DEL SICIF
             */
             id_postulacion:0,
             id:0,
             idsc:0,
            
            tipo_eleccion: "",
            
            cve_mpio: "",
            postulante: "",
            cve_distrito: "0",
            nombre: "",
            paterno: "",
            materno: "",
            genero: "",
            sobrenombre: "",
            lugar_nacimiento: "",
            ocupacion: "",
            aa_tipo: 0,
            partido: "",
            suplentes:"",
            municipio:"",
            edad:0,
            tipo_postulante:"",
           
            partido_que_postula:"",
           
            siglas_p:["PAN","PRI","PRD","PT","PVEM","MC","MORENA", "PESM","MM","MICH1","TMX"],
            selection:"",
            selectionPPostula:"",
            partidoPostula:"",
            //Variables de busqueda radiobuttos
            valorSeleccionado:"",
            valorSeleccionado2:"",

            //Elementos del checbok reasignacion de usuarios
            selectAll:false,           
           
            usernamePanel:"", //Nombre de usuario actual con sus permisos 
            //Variables de los usuarios
            //Variables a usar para siganciones
            listaCandidatos:[], //Lista que se pasa
            asignacionValidador:[],

            entradaAsignacionValidador:'',
         

            listaPartidos:[],
            listaPartidosP:[], // lista de todos los aprtidos para unir aquellas coincidencias
            
            listaTmp:[],
            listaUser:[],
            lista:[],
            listaUsuarios:[],
            usuarioValidador:"nombre_completo",
            areas:"areas[0].idArea",
            asignaciones:[],
            //areas:{area:"",idArea:""},
            selected:false,                  
            idUsuario:null,
            idFolioValidacion:"",
            username: "",            
            nombre_completo:"",
            //areas:"areas",
          
            rol:"",
           
            usuario_activado:"",
            email:"",
            //Arreglo para el usuario a modificar/actualizar o eliminar
             // se crea este elemento para almancenar los valores del usuario personalizado
            candidato_idFolioValidacion:"",
            usuario_idFolioValidacion:"",// se necesita este campo para poder mostrarle la tabla de candidatos asignados
            listaFoliosCandidatos:[], // se van almacenando los folios seleccionados del candidado desde el checkbox
            folioUsuarioValidador:"",
            user_name:"",
            user_rol:"",
            user_fullname:"", 
            user_email:"",           
            user_password:"",
            user_munincipio:"",
            user_distrito:"",   
                    
            
           
            filters: {nombre: { value: '', keys: ['nombre','username','postulante','distrito']}},
            roles:["Administrador","Validador","Publicador","Capturista","Invitado"],
            
            selection2:"",
            selection3:"",
            distritos:distritos,
            municipios_mich:municipios,
           

            isLoading: true,
            routes:routes,            
           
            disable:false,
            activarLista:true,
            config:{},
            currentPage:1,
            totalPages:0,          
            pagina:"",
            //Variables de control para mostrar Informacion
            visibleDiv : false,
            visibleDiv2:false,
            showDiv:false,
            showDiv2:false,
            showDiv3:false,
            showDiv4:false,
            visibleFilter:true,
            password:"",
            dirname:this.$assetsDirname,
            dirimagenes:this.$assetsDirname,
            tipoRol:"",
            
            usuario_organizacion:"",
            usuario_area:0,
            headerTable:"",
            isPartido:false,
            isOPL1:false,
            isOPL2:false,
            isOPL3:false,
            disableRow:false // se cambia de disable Button a disableRow


            }     
    },
    watch:{
        //se utiiliza watch como metoo de control de cambios en la tabla
        selectAll: function(value){
            //se pasa la lista completa o filtrada de los folios
            this.listaFoliosCandidatos =[]
            
           this.listaFoliosCandidatos = this.listaFoliosCandidatos.map(()=>value)
            console.log("Lista de folios",this.listaFoliosCandidatos)
            console.log(this.listaFoliosCandidatos)
            if(value !== false){              
                this.asignarAllFolios(this.listaFoliosCandidatos)}
                else{
                    this.limpiarFiltros()
                console.log(this.listaFoliosCandidatos)
            }
         
  
        },
  
    },
    components:{
     
    },
    methods:{
        cargarValidador(e){
            this.entradaAsignacionValidador = e // sustituir por la lista de validadores
        },
        eraseValidador(e){
            console.log(e)
            this.asignacionValidador.splice(e, 1)
        },
        //Se desactiva esta opcion ya que solo se asignata del portal un revisor a muchos candidatos y un supervisor a Muchos validadores
        agregarValidador(){
            if( this.entradaAsignacionValidador != ''){ //cambiar por la lista
                this.asignacionValidador.push(this.entradaAsignacionValidador)
                this.entradaAsignacionValidador=''
            }
        },
        limpiarFiltros(){
              
              this.valorSeleccionado=""
              this.valorSeleccionado2=""            
              this.valorSeleccionado3 = ""
              this.filters.nombre.value=""          
             
              this.showDiv=false
              this.showDiv2=false
              this.showDiv3=false,
              this.showDiv4=false,
              
              
              this.lista2=[]
              this.listaPartidos = []
              this.listaAcciones =[],
              
              this.selectionPPostula=""
             
              this.selected=false
              this.listaFoliosCandidatos=[]
              this.visibleDiv=false
              this.folioUsuarioValidador=""
              this.selection=""
              this.rol=""
              
              this.selectAll=false
              this.visibleFilter=true         
          
          },

         //Busqueda por Index
         /**
                  choose(){
            //this.rol=this.selection
            this.asignacion=this.selection
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.posicion = this.asignaciones.findIndex((asignacion)=>asignacion == this.selection)
            console.log(this.posicion)
            // Se envia  la posicion del  validador en la lista y se envia al metodo asignacion de folio
            this.asignacionFolio(this.posicion)

        },
           */ 

       async choose(){
            //this.rol=this.selection
            this.asignacion=this.selection
            console.log("Datos",this.asignacion)
            // se optiene la lista de usuarios para  realizar asifnaciones
            //nombreValidador = this.asignaciones.find((asignacion)=>asignacion == this.selection)
            //console.log(this.nombreValidador)
            //console.log(this.posicion)
            // Se envia  la posicion del  validador en la lista y se envia al metodo asignacion de folio
            const url =`https://informatica.iem.org.mx/api/conoceles/api/usuarios`
            try{
                const res = await axios.get(url,this.config)
                const listaTemporal = res.data
                listaTemporal.forEach(element =>{
                    if(element.nombre_completo === this.asignacion){                        
                        this.folioUsuarioValidador = element.idFolioValidacion
                        this.rol = element.roles[0].rol

                    }

                })         
                //this.folioUsuarioValidador=res.data[posicion].idFolioValidacion
                //this.rol = res.data[posicion].roles[0].rol
                //this.comprobarFolioUsuario(this.folioUsuarioValidador)                
                console.log("Desde choose",this.folioUsuarioValidador, this.rol)

            }catch (error) {
                console.log(error.code)                
            }
            //this.asignacionFolio(this.nombreCompletoValidador)

        },
   
        //METODOS DE BUSQUEDAS POR FILTOS BOTONES
         //Metodos para busquedas por filtros
         getFilterByValue(){ 
             //Se realiza la consulta a la lista que se encuentra activada  
                  
             if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2==="")){
                this.showDiv=true
                
                this.listatmp=[]
                this.lista.forEach(element =>{
                    this.listatmp.push(element)                   
                    })                                     
                //se crea una Lista2 para la busqueda para el input text filtro
                this.lista2=this.listatmp
                this.listaPartidos=this.listatmp                    
            }
            else if((this.valorSeleccionado !== "todos")&&(this.valorSeleccionado2==="")){            
             
                              
                //se activan radiobuttons de acciones afirmativas                                          
                this.listatmp=[]
                console.log("Lista diferente de todos",this.lista)                                    
                this.lista.forEach(element =>{
                    if(element.tipo_eleccion === this.valorSeleccionado){
                        this.listatmp.push(element)
                    }
                    else if((element.aa_tipo > 0 && element.aa === 1) && (Number(this.valorSeleccionado) === 4) ){
                        this.listatmp.push(element)                            
                    }}) 
                    this.listaAcciones = this.listatmp // se crea e inicializa esta lista para realizar bisqueda por acciones el el grupo de botones de acciones afirmativas
                    this.lista2=this.listatmp// //se crea una Lista2 para la busqueda para el input text filtro
                    this.listaPartidos=this.listatmp                 
                }
            //SE AGREGA POR OBSERVACIONES: Condicional valor seleccionado1 y valor selecionado 3
   
            //Condicional que enlista por actores politicos
            else if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2!=="")){
              
                this.showDiv2=true
                this.showDiv3=true
                this.selectAll=false             
                this.listatmp=[]
                this.lista.forEach(element =>{
                    if(element.postulante === this.valorSeleccionado2){
                        this.listatmp.push(element)
                    }else if(element.tipo_postulante === this.valorSeleccionado2) {
                        this.showDiv4=true
                        this.listatmp.push(element)
                    }
                    //else if para candidaturas comunes 
                })
                this.lista2=this.listatmp
                this.listaPartidos=this.listatmp
            }//Termina condicional que enlista por actores politicos

            //SE AGREGA POR OBSERVACIONES Enlista todas las aciones por accion hace lo mismo que si el radio buton 3         

            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2!=="")){
              
                this.showDiv2=true
                this.showDiv3=true                                            
                this.listatmp=[]                                      
                this.lista.forEach(element =>{
                    if((element.postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado)){
                        this.listatmp.push(element)}
                    else if((element.postulante === this.valorSeleccionado2) && ( Number(this.valorSeleccionado) === 4)&&(element.aa===1)){
                        this.listatmp.push(element)}
                        else if((element.tipo_postulante === this.valorSeleccionado2) && ( Number(this.valorSeleccionado) === 4)&&(element.aa===1)){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado)) {
                        this.listatmp.push(element)
                    }
                    })       
                   
                    //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    
                    this.listaPartidos=this.listatmp               
                 
                }
            /**activar si se ingresan accionea afirmativoas por clasificacion 
                         else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3!=="")){
                                                                   
                    this.listatmp=[]                                      
                    this.lista.forEach(element =>{
                    if((element.postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado) &&(element.aa_tipo===Number(this.valorSeleccionado3))){
                        this.listatmp.push(element)}
                        
                    else if((element.postulante === this.valorSeleccionado2) &&(Number(this.valorSeleccionado) === 4)&&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) &&(Number(this.valorSeleccionado) === 4)&&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado) &&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))) {
                        this.listatmp.push(element)
                    }             
                    })
                    
                                //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    this.listaPartidos=this.listatmp 
                }
             
             
            */
            /*
            else if((this.valorSeleccionado =="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3!=="")){
                                                           
                    this.listatmp=[]                                      
                    this.lista.forEach(element =>{
                    if((element.postulante === this.valorSeleccionado2) &&(element.aa_tipo===Number(this.valorSeleccionado3))){
                        this.listatmp.push(element)}              
                    })
                                //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    this.listaPartidos=this.listatmp 
                } 
                */        

            else{
                console.log("Valor",this.valorSeleccionado2)            
            
            }//Termina condicion else   
        },
        //TERMINA METODO DE BUSQUEDA POR BOTONES
 
        //TERMINA METODO DE BUSQUEDA POR BOTONES


        //Metodo para asignar folio de revisor al candidato( se asignaran solo a capturistas, validadors y suprvidores)
        asignarFolio(candidatoFolio){
            
            
            this.visibleDiv=true
            if(this.selected !==false){
                //se va creando la lista con las selecciones actuales                       
                this.listaFoliosCandidatos.push({"candidatoFolio":candidatoFolio})     
                 console.log(this.listaFoliosCandidatos)            
                                            
            }
            else{
                //opcion si se deselecciona un canidato se quita de la lista actual                           
                this.listaFoliosCandidatos = this.listaFoliosCandidatos.filter(elemento => elemento.candidatoFolio !== candidatoFolio)
                console.log(this.listaFoliosCandidatos)                     
            }
            this.selected=false
        },
        asignarAllFolios(listaCandidatos){
            //this.listaFoliosCandidatos=[]
            this.visibleDiv=true;           
                if(listaCandidatos !== false){
                this.listaPartidos.forEach(element =>{
                    if((element.validacionTransparencia!==true) && (this.usuario_area==2)){
                        this.listaFoliosCandidatos.push(element.idsc)
                    }else if((element.validacionIgualdad!==true)&&((this.usuario_area==3))){
                        this.listaFoliosCandidatos.push(element.idsc)
                    }else if((element.validacionCPyPP!==true)&&(this.usuario_area==4)){
                        this.listaFoliosCandidatos.push(element.idsc)
                    }                
                    console.log(this.listaFoliosCandidatos)
                })}
                
              

        },
        //SE DESACTIVA ESTE METODO POR ASIGNACIONES ERRONEAS DE POSICION
        async asignacionFolio(posicion){ 
            
                     
            const url =`https://informatica.iem.org.mx/api/conoceles/api/usuarios`
            try {
                const res = await axios.get(url,this.config)               
                this.folioUsuarioValidador=res.data[posicion].idFolioValidacion
                this.rol = res.data[posicion].roles[0].rol
                //this.comprobarFolioUsuario(this.folioUsuarioValidador)                
                console.log(this.folioUsuarioValidador, this.rol)
                            
                } catch (error) {
                console.log(error.code)                
            }
        },
  
        async realizarAsignacion(){            
            //let listaAsignacion=[]          
            console.log("Usuario Validador", this.folioUsuarioValidador)
            this.lista.forEach(element =>{                
                if(this.listaFoliosCandidatos.includes(element.idsc)){
                    if(element.asignacionValidador.includes(this.folioUsuarioValidador)){
                        console.log('Ya existe asignacion', element.asignacionValidador)
                        this.asignacionesBD(Number(element.idsc), element.asignacionValidador)                                                                                                 

                    }else{
                        console.log('Se agrega')
                         element.asignacionValidador.push(this.folioUsuarioValidador)
                        console.log('Lista de revisores para este candidato', element.asignacionValidador)                       
                        
                    }
                    console.log("Desde realizar asignacion:",element.idsc,element.asignacionValidador)
                    this.asignacionesBD(element.idsc,element.asignacionValidador)                  
                }
                
                             

            })     
        },
        async asignacionesBD(idsc,listaAsignaciones){
            console.log("desde asignaciones BD",idsc,listaAsignaciones)
           
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato_user'
            const res = await axios.patch(url, { idsc:idsc,asignacionValidador:listaAsignaciones },this.config)
            if(res.data.result = 1){
                this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                this.selectAll=false
                this.selected=false
                this.listaPartidos=this.listatmp
                this.listaFoliosCandidatos=[]
                this.filters.nombre.value=""
                this.valorSeleccionado="todos"

                return
            }
            this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});   

        },

        //Obtener la lista de todos los usuarios del portal administrativo
        async getUsers(){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`)
            this.listaUsers = res.data;
            //Obtencion de la informacion de la API con variables del FronEnd           
        },
        getFilterBySearch(){
                        //Se realiza busqueda con coincidencia en lista
                        this.activarLista=true;     
                this.listatmp=[]
                //const mpio= this.findMunicipio( this.filters.nombre.value) 
                //console.log(mpio)
                
                        
                                              
                this.lista2.forEach(element =>{
                
                              
                    this.visibleFilter=false
                    if(element.nombre.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  
                    }
                    else if(element.username.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  

                    }
                    else if(element.postulante.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  

                    } else if(element.distrito.startsWith(this.filters.nombre.value) === true){
                        this.listatmp.push(element)  

                    }
                    
                    else if(this.filters.nombre.value === ""){
                        this.listatmp=this.lista2
                        
                    }
                    }) 
                    this.listaPartidos=this.listatmp 
                    this.visibleFilter=true

                   
                 
          
      
        },
        //Metodo para consultar al usuario de manera personalizada con el folio pasado en los botones de la tabla
        async getCandidatosInfo(folio)
        {
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidatofrc/${folio}`
            try {                
                 const info = await axios.get(url)
                 this.infoCandidatos= info.data[0].infoCandidatos
                 this.id_postulacion=info.data[0].id_postulacion
                 this.idsc=info.data[0].idsc
                 this.imagen = info.data[0].imagen

                 this.curriculares.contacto.web =info.data[0].curriculares.contacto.web
                 this.curriculares.contacto.email =info.data[0].curriculares.contacto.email
                 this.curriculares.contacto.direccion =info.data[0].curriculares.contacto.direccion
                 //this.curriculares.contacto.telefono =info.data[0].curriculares.contacto.telefono

                 this.curriculares.social.facebook = info.data[0].curriculares.social.facebook
                 this.curriculares.social.twitter = info.data[0].curriculares.social.twitter
                 this.curriculares.social.youtube = info.data[0].curriculares.social.youtube
                 this.curriculares.social.instagram = info.data[0].curriculares.social.instagram
                 this.curriculares.social.tiktok = info.data[0].curriculares.social.tiktok

                 this.curriculares.profesion.razon_cargo_publico = info.data[0].curriculares.profesion.razon_cargo_publico
                this.curriculares.propuesta.propuesta1 = info.data[0].curriculares.propuesta.propuesta1                
                this.curriculares.propuesta.propuesta2 = info.data[0].curriculares.propuesta.propuesta2
                this.curriculares.propuesta.propuesta_grupo_discriminacion = info.data[0].curriculares.propuesta.propuesta_grupo_discriminacion

                //Obtencion de informacion de los cuestionarios de Identidad
                this.identidad.diversidad_sexual = info.data[0].identidad.diversidad_sexual
                this.identidad.tipo_diversidad_sexual = info.data[0].identidad.tipo_diversidad_sexual                                
                //console.log("Desde Coleccion Candidatos: ",this.folioRegistroCandidato)
                this.identidad.indigena = info.data[0].identidad.indigena
                this.identidad.lengua_indigena = info.data[0].identidad.lengua_indigena
                this.identidad.comunidad_indigena = info.data[0].identidad.comunidad_indigena
                //Obtencion de respuestas delos cuestionarios de identidad correspondientes a personas con discapacidad
                this.identidad.discapacidad = info.data[0].identidad.discapacidad
                this.identidad.estatus_discapacidad = info.data[0].identidad.estatus_discapacidad
                this.identidad.tipo_discapacidad = info.data[0].identidad.tipo_discapacidad
                this.identidad.dificultad_discapacidad = info.data[0].identidad.dificultad_discapacidad
                //Obtencion de respuesas de los cuestionarios de identidad correspondientes a si es persona migrante
                this.identidad.migrante = info.data[0].identidad.migrante
                this.identidad.pais_extranjero = info.data[0].identidad.pais_extranjero
                this.identidad.tiempo_extranjero = info.data[0].identidad.tiempo_extranjero
                this.identidad.motivo = info.data[0].identidad.motivo
                this.identidad.situacion_asegurada = info.data[0].identidad.situacion_asegurada
                //Obtencion variable de publicacion de la informacion
                this.identidad.publicacion = info.data[0].identidad.publicacion
                
            } catch (error) {
                console.log(error)
                
            }  
        },    
     
        cancelar(){
            this.visibleDiv=false
            this.password=""
            //this.path("/validacion1")
        },
        generatePassword(){
            this.password='' // limpia el campo cada vez que se clickea

            const letters='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const numbers = '0123456789';
            const symbols = '!@#$%^&*_=+-/';
            const characters = letters + numbers + symbols;
            
            for(let i=0; i< 8;i++){
                const randomIndex = Math.floor(Math.random() * characters.length)
                this.password += characters[randomIndex]
            }
            return this.password;
        },
        async comprobarFolioUsuario(folioUsurio){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`,this.config) 
            this.listUsers = res.data;
            console.log(this.listUsers)         
            this.listUsers.forEach(element =>{
                if(element.idFolioValidacion === folioUsurio)
                {                                  
                    this.idFolioValidacion=""
                    return                  
               }else{
                this.idFolioValidacion
                return
               }              
          
         })        
        },
        
    mergeLista(listaC,listaP){
            return listaC.map( element1 =>{
                const element2 = listaP.find(element => element.id=== element1.idsc)
                return{
                ...element1,
                ...(element2 && {nombre: element2.nombre+" " + element2.paterno +" "+element2.materno , postulante: element2.postulante,tipo_postulante:element2.tipo_postulante,partido: element2.partido,cve_mpio:element2.cve_mpio,distrito:element2.cve_distrito,tipo_eleccion:element2.tipo_eleccion,aa:element2.aa, aa_tipo:element2.aa_tipo, partido_que_postula:element2.partido_que_postula} )
            }
            })
 
        },
    //Metodos de obtencion de descripciones, logos por medio de claves
    getLogoPartidos(partido,tipo_postulante,postulante){
              //inicializamos la lista de logos        
        const jsonString1 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString2 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"mpv-13.png","5":"mpv-13.png","6":"pmc-6.png","7":"mpv-13.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png","12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"paracho5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const jsonString3 = '{"1":"pan-pri-prd.png","2":"pan-pri-prd.png","3":"pan-pri-prd.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString4 ='{"12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"logo.paracho.5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const objt1 = JSON.parse(jsonString1)
        const objt2 = JSON.parse(jsonString2)
        const objt3 = JSON.parse(jsonString3)

        const objt4 = JSON.parse(jsonString4)

        if(tipo_postulante==="1"){
            const logo  = objt1[partido];
        return logo
        }
        if(tipo_postulante==="2"){
            const logo  = objt2[partido];
        return logo
        }
        if(tipo_postulante==="3"){
            if(postulante === "PAN-PRI-PRD"){
                const logo  = objt3[partido];
                return logo   
            }else if(postulante=="PAN-PRI"){
                const logo  = "pan-pri.png";
                return logo  

            }else if(postulante=="PRI-PRD"){
                const logo  = "pri-prd.png";
                return logo
            }else if(postulante=="PAN-PRD"){
                const logo  = "pan-prd.png";
                return logo
            }else if(postulante=="PRD-PESM"){
                const logo  = "prd-pesm.png";
                return logo
            }else if(postulante=="PT-MORENA"){
                const logo  = "pt-morena.png";
                return logo
            }else if(postulante=="PT-PESM"){
                const logo  = "pt-pesm.png";
                return logo
            }else if(postulante=="PVEM-MORENA"){
                const logo  = "pvem-morena.png";
                return logo
            }
        }
        if(tipo_postulante==="4"){
            const logo = objt4[partido]
            return logo
        }


    },
    getNombresPartidos(partidoN){
        const partidop  = this.partidosP[partidoN];
        return partidop

    },
    getMunicipios(cve_mpio){
        //inicializamos la lista de logos             
        const mpio  = this.municipios_mich[cve_mpio];
        return mpio

    },
    getCargo(tipo_eleccion){
        const cargo = this.tipoEleccion[tipo_eleccion]
        return cargo
    },
    getSuplencias(id_postulacion){
        //Busca la suplencia dentro de la lista
        this.listaS.forEach(element =>{
            if(element.id_postulacion === id_postulacion ){
                this.suplentes = element.nombre+" "+ " "+element.paterno+" "+element.materno                           
            }                 
        })
        return this.suplentes   

    },
    findMunicipio( municipio){
        for(let clave in this.municipios_mich){
            if(this.municipios_mich[clave] === municipio){
                return clave;
            }
        }
    },
    cargarListaValidadores(validadores){
        let nombreValidador=""
        console.log(validadores)

        this.listaUsuarios.forEach(element=>{
            validadores.forEach(element2=>{
                if((element.idFolioValidacion === element2)&&(element.areas[0].idArea===this.usuario_area)){                    
                    nombreValidador = nombreValidador+"\n"+element.nombre_completo                
                 }
                })
            })
            return nombreValidador


        }, 
        getByPartidoPostula(){
        this.listatmp=[]
        this.partidoPostula=this.selectionPPostula
        this.lista.forEach(element =>{
            if((element.tipo_postulante === this.valorSeleccionado2)&&(element.partido_que_postula===this.partidoPostula)){
                this.listatmp.push(element)

            }  
        })
        this.lista2=this.listatmp
        this.listaPartidos=this.listatmp 
        console.log(this.listaPartidos)

    },    


    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        const url2 = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const url3= 'https://informatica.iem.org.mx/api/conoceles/api/partidos'
        const url4 ='https://informatica.iem.org.mx/api/conoceles/api/usuarios'
        
       
        try {
            let listaTmp=[]
            //SE INICIALIZAN LOS DATOS DEL USUARIO QUE INICIA SESION, LOS TOKENS Y SE INICIALIZA LA LISTA DE USUARIOS DE LA TABLA
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')
            
            //Datos del usuario que Inicia Sesion en el portal
            const {idUsuario, token, usernamePanel,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario
            this.tipoRol= roles[0].rol
            console.log(this.tipoRol)

            
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }
            //Se obtiene la pagina de aterrizaje del portal
            const resultado = await axios.get(url,this.config)
            //Obtenemos el idFolioValidacion del usuario para poderle mostrar la tabla con sus candidatos asignados para revisar o capturar informacion. 
            this.usuario_idFolioValidacion =resultado.data[0].idFolioValidacion
            this.usuario_area = resultado.data[0].areas[0].idArea
            this.usuario_areas= resultado.data[0].areas[0].area
            console.log(this.usuario_area)
            console.log("Datos areas; ",this.usuario_areas)
           

            //this.pagina = resultado.data[0].pagina

            // Se obtiene la lista de usuarios de la lista de usuarios y se le envia la configuracion del token de autorizacion
            const resultado2 = await axios.get(url2, this.config )
            this.listaCandidatos = resultado2.data;

            // Se obtiene la lista inicial de partidos proveniente del SNR o SRC solo para su consulta GET
            const resultado3 = await axios.get(url3)
             this.listaPartidosP=resultado3.data;

            //Inicializamos la lista de usuario
            const resultado4 = await axios.get(url4,this.config)
            this.listaUsuarios= resultado4.data        
        
         let foliosV
         this.listaUsuarios.forEach(element => {
            foliosV=(element.idFolioValidacion).slice(0, 3)       
            if((foliosV === "VTC") &&(this.usuario_area === 2)){                
                this.asignaciones.push(element[this.usuarioValidador])
                console.log("Lista usuarios v",this.asignaciones)                   
                }
            else if((foliosV === "VIG")&&(this.usuario_area === 3)){                
                this.asignaciones.push(element[this.usuarioValidador])
                console.log("Lista usuarios v",this.asignaciones)                    
                }
            else if((foliosV ==="VCP")&&(this.usuario_area === 4)){                
                this.asignaciones.push(element[this.usuarioValidador])
                console.log("Lista usuarios v",this.asignaciones)                    
                }             
                       
            })
         
        /**
         *        
         * let areaUsuario=0
         *  this.listaUsuarios.forEach(elemento =>{
                //console.log(elemento.areas[0])
                areaUsuario= elemento.areas
                console.log("Area Usuario:",areaUsuario)
                //if(usuarioArea === this.usuario_area)
                if(areaUsuario.areas.idArea.includes(this.usuario_area)){
                    this.asignaciones.push(elemento[this.usuarioValidador])
                    //this.asignaciones.push(elemento[this.usuarioValidador])
                    console.log("Lista usuarios v",this.asignaciones)
                    cont++
                }
            })    
         
        
        */         
            

            //this.nombre_completo_usuario = result.data[0].nombre_completo
            //this.rol = result.data[0].rol   
            //Metodo para unir las Listas de usuarios candidatos y la de partidos( ejemplo que viene del SNR i SCR)
                    //se combinan las listas de acuerdo a una condicion en el metodo
            listaTmp = this.mergeLista(this.listaCandidatos, this.listaPartidosP)
            console.log(listaTmp)
            //Obtenemos los elementos: asignacionValidador de la lista unida para poder mostrar solo aquellos candidatos que tengan al usuario revisor y capturista en su propia tabla
         

            listaTmp.forEach(element =>{
                if(this.tipoRol ==="Administrador"){
                    this.lista.push(element)
                }
                else if((this.tipoRol ==="Supervisor") && (element.validacionCaptura === true)&&(element.validacionPartido === true)&&(this.usuario_area===2)){
                    this.lista.push(element)
                    if( element.validacionTransparencia ===false){
                        this.disableRow= true
                        }
                    element.validacionTransparencia 
                    this.headerTable="TRANSPARENCIA"                                     
                    this.isOPL1 =true

                }else if((this.tipoRol ==="Supervisor") && (element.validacionPartido === true)&&(element.validacionTransparencia === true)&&(this.usuario_area===3)){
                    this.lista.push(element)
                    if( element.validacionIgualdad ===false){
                        this.disableRow= true
                        }
                    element.validacionIgualdad                   
                    this.headerTable="IGUALDAD"                 
                    this.isOPL2 =true

                }
                //se quita validacion igualdad de forma temporal (element.validacionIgualdad === true)&&
                else if((this.tipoRol ==="Supervisor") && (element.validacionPartido === true)&&(element.validacionTransparencia==true)&&(element.validacionIgualdad === true)&&(this.usuario_area===4)){
                    this.lista.push(element)
                    if( element.validacionCPyPP ===false){
                        this.disableRow= true
                        }
                    element.validacionCPyPP                
                    this.headerTable="CPyPP"                    
                    this.isOPL3 =true
                }                          
             })
            
           
            this.isLoading = false

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/404')
            }
        }
        
    },


    mounted(){
        window.scrollTo(0,0);
    }

}
</script>

<style>
h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color:#6c757d;
}

.custom-table {
  font-family: GalanoRegular;
  border-collapse: collapse;
  width: 100%;
}
.custom-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.custom-table tr:nth-child(even){background-color: #f2f2f2;}
.custom-table tr:hover {background-color: #ddd; cursor: pointer;}
.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #9F2380;
  color: white;
  padding-left: 5px;
}
.custom-table th:hover {cursor: pointer;}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #9F2380;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #9F2380;
    --bs-btn-border-color: #9F2380;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6a6ea8;
    --bs-btn-hover-border-color: #6a6ea8;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 2px solid #9F2380;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #9F2380;
      border: 2px solid #9F2380;
      color: white;
      transform: scale(1.111, 1.111);
      .radio-tile-label {
        color: white;
        background-color: #9F2380;
      }
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #9F2380;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
/* Se agregan nuevas clases para mostrar radiobutons de filtrtado**/
.buttons-container{
    text-align: start;
    align-items: start;  
    width: 100%;
    margin-bottom: 1%;
 
}
.label-color{
    color:#9F2380;  

}
.button-separation{
    
    padding-right: 2%;
    margin-bottom: 2%;

}
.btn-align-right{
      
    margin-bottom: 5px;  
    
}
.size-logos{
    height: 45px;
    border-radius: 4px;
}

</style>