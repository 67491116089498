<template>
    <div >
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container" >
            <div>
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=1>
                    <span>1 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div>
            <div class="row questionary-row">
                <div class="questionary-container">
                <p class="question">Medios de contacto público</p>
                <div style="margin-top: 5%; margin-bottom: 10%;">
                    <div class="d-flex flex-column mb-3">
                    <!-- <label for="exampleInputPassword1">Logo&emsp;</label> -->
                        <input style="display: none;" type="file" class="form-control-file" accept="image/jpeg,image/png,image/jpg" ref="fileInput" v-on:change="uploadImage">
                        <button type="button" class="btn btn-custom" v-on:click="$refs.fileInput.click()">Agregar imagen de perfil &emsp;<fa icon="image"/></button>
                        <div v-if="imagen" class="logo-container">
                            <img v-if="imagen.name" style="margin-left: 1%;object-fit: contain;" ref="previewImage" width="100%" height="200px">
                            <img v-else-if="imagen" style="margin-left: 1%;object-fit: contain;" v-bind:src="`${dirname}${imagen}`" width="100%" height="200px">
                            <!-- <img ref="previewImage" width="100%" height="200px" style="object-fit: contain;"> -->
                            <h5 v-if="imagen.name" class="image-name">{{imagen.name}}</h5>
                            <h5 v-else class="image-name">{{imagen}}</h5>
                            <button v-if="imagen" type="button" class="btn btn-danger" v-on:click="deleteImage">Eliminar imagen &emsp;<fa icon="xmark"/></button>
                        </div>
                    </div>
                    <label>FaceBook</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width"><fa icon="fa-brands fa-facebook" class="brand-icon"/></span>
                        <input v-model="social.facebook" type="text" class="form-control" placeholder="https://www.facebook.com/username">
                    </div>
                    <label>Twitter</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width"><fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon"/></span>
                        <input v-model="social.twitter" type="text" class="form-control" placeholder="https://twitter.com/username">
                    </div>
                    <label>YouTube</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-brands fa-youtube" class="brand-icon"/></span>
                        <input v-model="social.youtube" type="text" class="form-control" placeholder="https://www.youtube.com/@username">
                    </div>
                    <label>Instagram</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width"><fa icon="fa-brands fa-instagram" class="brand-icon"/></span>
                        <input v-model="social.instagram" type="text" class="form-control" placeholder="https://www.instagram.com/username">
                    </div>
                    <label>TikTok</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width"><fa icon="fa-brands fa-tiktok" class="brand-icon"/></span>
                        <input v-model="social.tiktok" type="text" class="form-control" placeholder="https://www.tiktok.com/@username">
                    </div>
                    <label>Página web</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text icon-width"><fa icon="fa-solid fa-globe" class="brand-icon"/></span>
                        <input v-model="contacto.web" type="text" class="form-control" placeholder="https://www.misitioweb.com">
                    </div>
                    <label>Correo electrónico público</label>
                    <div class="input-group mb-1">
                        <span class="input-group-text icon-width"><fa icon="fa-solid fa-envelope" class="brand-icon"/></span>
                        <input v-model="contacto.email" v-on:keypress="validate($event, 'email')" class="form-control" placeholder="micorreo@mail.com">
                    </div>
                    <!--Se inhabilita por observaciones
                    <div class="d-flex flex-column mb-3">
                        <table v-for="(email, index) in contacto.email" class="table-custom table-hover mb-1">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="input-group-text-custom" v-on:click="cargar(email, 'email')">{{ email.slice(0,30) }} {{ email.length > 30 ? '...' : '' }}</span>
                                        <button type="button" class="btn btn-danger bc" v-on:click="erase(index, 'email')"><fa icon="trash"/></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                    <div class="d-flex flex-column mb-3">
                        <button :disabled="this.contacto.email.length===9" type="button" class="btn btn-custom" v-on:click="agregar('email')">Agregar&emsp;<fa icon="plus"/>&emsp;</button>
                    </div>

                    -->

                    <label>Teléfono público de contacto</label>
                    <div class="input-group mb-1">
                        <span class="input-group-text icon-width"><fa icon="fa-solid fa-phone" class="brand-icon"/></span>
                        <input v-model="contacto.telefono" v-on:keypress="validate($event, 'telefono')" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" class="form-control" placeholder="123-456-7890">
                    </div>
                    <!--
                        Se inhabilita por observaciones
                    <div class="d-flex flex-column mb-3">
                        <table v-for="(phone, index) in contacto.telefono" class="table-custom table-hover mb-1">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="input-group-text-custom" v-on:click="cargar(phone, 'phone')">{{ phone.slice(0,30) }} {{ phone.length > 30 ? '...' : '' }}</span>
                                        <button type="button" class="btn btn-danger bc" v-on:click="erase(index, 'phone')"><fa icon="trash"/></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                    <div class="d-flex flex-row mb-3">
                        <button :disabled="this.contacto.telefono.length===9" type="button" class="btn btn-custom" v-on:click="agregar('phone')">Agregar&emsp;<fa icon="plus"/>&emsp;</button>
                    </div>
                    -->

                    <label>Domicilio de casa de campaña</label>
                    <div class="input-group mb-1">
                        <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-solid fa-location-dot" class="brand-icon"/></span>
                        <textarea v-model="contacto.direccion" placeholder="Avenida Landana #345 Lote 12, México D.F. CP 01234 México" class="form-control"></textarea>
                    </div>

                    <!-- Se inhabilita por obseraciones
                     <div class="d-flex flex-column mb-3">
                            <table v-for="(address, index) in contacto.direccion" class="table-custom table-hover mb-1">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="input-group-text-custom" v-on:click="cargar(address, 'address')">{{ address.slice(0,30) }} {{ address.length > 30 ? '...' : '' }}</span>
                                            <button type="button" class="btn btn-danger bc" v-on:click="erase(index, 'address')"><fa icon="trash"/></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex flex-column">
                            <button :disabled="this.contacto.direccion.length===9" type="button" class="btn btn-custom" v-on:click="agregar('address')">Agregar&emsp;<fa icon="plus"/>&emsp;</button>
                        </div>
                    -->
                       
                </div>
                
                <div class="d-flex flex-row">
                    <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button>
                </div>

                <div class="container mt-5 text-center">                   
                        <b-modal v-model="showAviso" id="modal-scrollable" scrollable title="Aviso de Privacidad de Datos personales" @click.stop="">                          
                            <template #modal-header>
                                <div class="d-flex flex-row">
                                    <b-button size="sm" variant="outline-danger" @click="cerrarAviso()">Cerrar</b-button>
                                </div>                       
                            </template>
                            
                            <template #default>                                
                                <div class="d-flex flex-row mb-2">
                                    <h6 class="text-center">AVISO DE PRIVACIDAD SIMPLIFICADO CUESTIONARIO CURRICULAR EN EL SISTEMA “CANDIDATAS Y CANDIDATOS, CONÓCELES” PARA EL PROCESO ELECTORAL ORDINARIO LOCAL 2023-2024.</h6>
                                    
                                </div>

                                <div container>
                                    <hr>
                                    <div class="parrafo1 text-justify">
                                        <p>
                                            El Instituto Electoral de Michoacán “IEM”, con domicilio en la calle Bruselas No. 118, Col. Villa
                                            Universidad, C.P. 58060, de la Ciudad de Morelia, Michoacán; será la autoridad responsable de
                                            recabar y tratar sus datos personales, los cuales serán protegidos conforme a lo dispuesto por la
                                            Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de
                                            Michoacán de Ocampo y demás normatividad aplicable en la materia
                                        </p>
                                    </div>
                                    <div class="parrafo2">
                                        <p>
                                        Los datos personales que se recaben para el llenado del <strong>Cuestionario curricular en el sistema
                                        “Candidatas y Candidatos, Conóceles” para el Proceso Electoral Ordinario Local 2023-2024</strong>
                                        se utilizarán con la siguiente finalidad:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Facilitar a la ciudadanía el acceso a la información de las personas candaditas que
                                                    participan a puestos de elección popular dentro del Proceso Electoral Ordinario Local 2023-
                                                    2024.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Maximizar la transparencia en la difusión de las candidaturas, la participación de la
                                                    población y el voto informado y razonado, a efecto de optimizar la toma de decisiones de la ciudadanía.         
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Generar información estadística respecto de los grupos en situación de discriminación o de 
                                                    atención prioritaria en los que se sitúan las personas candidatas.

                                                </p>

                                            </li>
                                        </ul>                                       
                                    </div>
                                    <div class="parrafo3">
                                        <p><strong>¿Qué datos personales se les solicitan?</strong></p>
                                        <p><strong>Los datos personales objeto de tratamiento son:</strong></p>
                                        <ul>
                                            <li><p>Fotografía</p></li>
                                            <li><p>Grado máximo de estudios y su estatus.</p></li>
                                            <li><p>Otra información académica (cursos, diplomados, seminarios)</p></li>
                                            <li><p>Historia profesional y/o laboral que describa la experiencia, los años y las actividades realizadas en esta.</p></li>
                                        </ul>                                       
                                    </div>
                                    <div class="parrafo4">
                                        <p><strong>¿Dónde puede consultar el aviso de privacidad integral? </strong></p>
                                        <p>Para conocer y tener mayor información sobre los términos y condiciones en que serán tratados
                                            sus datos personales, y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en:
                                        </p>
                                        <a href="http://www.iem.org.mx/index.php/publicaciones/28-transparencia-y-acceso/653-aviso-deprivacidad" target="_blank">http://www.iem.org.mx/index.php/publicaciones/28-transparencia-y-acceso/653-aviso-deprivacidad"</a>                                        
                                    </div>
                                </div>
                            </template>
                            <template #modal-footer >                    
                                <button type="button" class="btn btn-custom" @click="enviarAviso()">
                                    Aceptar
                                </button>                   
                            </template>

                        </b-modal>                    
                </div>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'

export default{
    name:"Contacto",
    data(){
        return{
            routes:routes,
            isLoading: true,
            idCandidato:null,
            disable:false,
            config:{},
            currentLogo:null,
            
            username:"", //local storage username

            //Se obtiene los datos del SNR no Modificable

            dirname:this.$assetsDirname, max:24,
            contacto:{                
                email:"",
                //telefono:[],
                web:"",
                telefono:"", // input telefono
                direccion:'', // input direccion pro
            },
            social:{
                facebook:"",
                twitter:"",
                youtube:"",
                instagram:"",
                tiktok:""
            },
            estudio:{
                grado_maximo:'',
                estatus:''                

            },
            imagen:"",
            pagina:false,

            //Se crean variables de control y visibilidad
            
            showAviso: false,
            aviso1:false,
            estadoRoC :"",
            validacionCaptura:false,
            logsAcciones:[],
            dataLogs : logs,
            loggedIn: this.$route.params.loggedIn
            


        }
    },
    components:{
        Header,
        Footer,
        Loading,
        
    },
    props:{
        inicioSesion:Number
    },
  
    methods:{
        validate(e, opt){
            if(opt === 'telefono'){
                const regex = /^[0-9]$/
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(regex.test(char)) return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
            if(opt === 'email'){
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(char !== ' ') return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
        },
        /*
        cargar(e, value){
            const opt = {
                'email':()=>this.email=e,
                'phone':()=>this.phone=e,
                'address':()=>this.address=e,
            }
            opt[value]()
        },
        erase(e, value){
            const opt = {
                'email':()=>this.contacto.email.splice(e, 1),
                'phone':()=>this.contacto.telefono.splice(e, 1),
                'address':()=>this.contacto.direccion.splice(e, 1),
            }
            opt[value]()
        },
        agregar(opt){
            if(opt === 'email' && this.email != ''){
                this.contacto.email.push(this.email)
                this.email=''
                return
            }
            if(opt === 'phone' && this.phone != ''){
                this.contacto.telefono.push(this.phone)
                this.phone=''
                return
            }
            if(opt === 'address' && this.address != ''){
                this.contacto.direccion.push(this.address)
                this.address=''
                return
            }
        },
        */
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        uploadImage(e){
            try {
                this.imagen = this.$refs.fileInput.files[0]
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    this.$refs.previewImage.src = e.target.result;
                }
            } catch (error) {
                console.log(error)
            }
        },
        deleteImage(e){
            this.imagen = ''
            this.$refs.fileInput.value = null;
        },
        async enviarAviso(){      
           
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            //metodo para asignar validacion de partido
            this.aviso1 = true 
            console.log(this.aviso1)           
            const res = await axios.patch(url, { idCandidato:this.idCandidato,aviso1:this.aviso1 }, this.config)
                if(res.data.result === 1){
                    this.$alert('Aceptado','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'center', width: 200, timer: 1000})
                    this.showAviso=false;                  
                    
                    //integrar cierre de ventana y regresar a la lista de candidatos                                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            },
        cerrarAviso(){
            this.showAviso=true;  

        },
        async save(){            
           
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'

            const formData = new FormData() //multi-part

            if (typeof this.imagen === 'object')
                formData.append('imagen',this.imagen)
            // Si el campo logo es un objeto lo limpiamos, esto quiere decir que el logo ha sido actualizado y no debe de mandarse
            
            //Estructura if en caso de que el candidato desee modificar su respuesta
            if(this.paginat > 0){
                this.estadoRoC ="CAPTURADO"
                this.validacionCaptura=true
                if(this.imagen!==""){ //Valida que se haya ingresado una imagen
                formData.append('params', JSON.stringify( { curriculares:{ social:this.social, contacto:this.contacto }, imagen:this.imagen, pagina:this.pagina+(25-this.paginat),paginat:0, idCandidato:this.idCandidato, logoDelete:this.currentLogo,aviso1:this.aviso1,estadoRoC:this.estadoRoC,validacionCaptura:this.validacionCaptura }) )
                const res = await axios.patch(url, formData, this.config)
                if(res.data.result = 1){ // se movio de asignacion a igualdad
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(3)
                    this.disable=false 
                    this.path(this.pagina+(25-this.paginat)) // envia al indice de revision
                    return
                    }      
                    }else{
                        this.$alert('','<label class="alertlabel">Imagen no válida o No Cargada</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                        this.disable=false
                    }
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                    this.disable=false

                }
                //secuencia normal de los cuestionarios
            else{
                if(this.imagen!==""){ //Valida que se haya ingresado una imagen
                formData.append('params', JSON.stringify( { curriculares:{ social:this.social, contacto:this.contacto },imagen:this.imagen, pagina:this.pagina+1, idCandidato:this.idCandidato, logoDelete:this.currentLogo,aviso1:this.aviso1 }) )
                const res = await axios.patch(url, formData, this.config)
                if(res.data.result = 1){ // se movio de asignacion a igualdad
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(2)
                    this.disable=false 
                    this.path(this.pagina+1)
                    return
                    }
                
                    }else{
                        this.$alert('','<label class="alertlabel">Imagen no válida o No Cargada</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                        this.disable=false

                    }
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+1)
                    this.disable=false
            }
            
        },
        //metodo registro acciones
    async logsCandidato(clave){
            let now= new Date();
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            try{
                const log  = this.dataLogs[clave];
                console.log(log)
                this.logsAcciones.push(log+" : "+now)            
                await axios.patch(url, { idCandidato:this.idCandidato,logsAcciones:this.logsAcciones }, this.config)
                

            }catch(error){
                console.error(error)
            }
    },

     
    }, //Termina Metodos

    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        //Se muestra el aviso de privacidad        

        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')

             
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato           

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }               

            const result = await axios.get(url, this.config)           
            this.pagina = result.data[0].pagina
             // Varible bandera para control de modificacion de respuestas si es mayor a 0, se guarda variable temporal
            this.paginat = result.data[0].paginat
            
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }  
            
            this.path(this.pagina)
            
            // Datos requeridos en esta vista
            this.contacto.direccion = result.data[0].curriculares.contacto.direccion
            this.contacto.telefono= result.data[0].curriculares.contacto.telefono
            this.contacto.email = result.data[0].curriculares.contacto.email            
            this.contacto.web = result.data[0].curriculares.contacto.web
            this.social.facebook = result.data[0].curriculares.social.facebook
            this.social.twitter = result.data[0].curriculares.social.twitter
            this.social.youtube = result.data[0].curriculares.social.youtube
            this.social.instagram = result.data[0].curriculares.social.instagram
            this.social.tiktok = result.data[0].curriculares.social.tiktok
            this.imagen = result.data[0].imagen
            this.currentLogo = result.data[0].imagen

           
            //Datos estaticos cargados desde el SNR
            this.estudio.grado_maximo = result.data[0].curriculares.estudio.grado_maximo
            this.estudio.estatus = result.data[0].curriculares.estudio.estatus
            this.aviso1 = result.data[0].aviso1

            if(this.aviso1 === false ){
                this.showAviso= true
            }

            
            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped>
.table-custom {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    caption-side: bottom;
    text-indent: initial;
    border-collapse: separate;
    background-color: #e9ecef;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
}
.table-custom > :not(caption) > * > * {
    cursor: pointer;
    padding: 0.455rem 0.55rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
td {
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
}
.input-group-text-custom {
    width: 100%;
    font-family: GalanoRegular;
}
.bc {
    text-align: right;
    justify-content: right;
    padding: 2px 10px 2px 10px;
}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
textarea {
    font-family: GalanoRegular;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
.icon-width {
    width: 60px;
}
.logo-container {
    display: flex;
    flex-direction: column;
    border: 2px solid #B20066;
    border-radius: 5px;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
    text-align: center;
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    font-family: GalanoRegular;
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #B20066;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #B20066;
    --bs-btn-border-color: #B20066;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b0aeb3;
    --bs-btn-hover-border-color: #b0aeb3;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.image-name {
  font-family: GalanoRegular;
  font-weight: bold;
  color: #B20066;
  overflow-wrap: break-word;
  padding: 0 10px 0px 10px;
}
.brand-icon {
    font-size: 35px;
    color: #B20066;
}
label {
    font-family: GalanoRegular;
    font-weight: bold;
    color: #B20066;
    font-size: 18px;
}
input::placeholder, textarea::placeholder {
  color: #b0aeb3;
}
.question{
    color:#5c636a
  }
</style>