<template>
    <div class="container">
        <Header :nombre_completo_usuario="nombre_completo_usuario" /> 
       
        <div class="mt-4 pt-4 pb-4"><!--div contenido mt-4-->
            <!--Radio button seleccion por Cargo-->
            <div class="buttons-container">
                <label class="label-color" for="busquedaActorPolitico">¿QUE DESEAS CONSULTAR?</label>
                <br>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="todos" value="todos" v-model=valorSeleccionado @change="getFilterByValue">
                    <label class="form-check-label" for="todos">
                        TODOS                  
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="ayuntamiento" value="AYUNTAMIENTO" v-model=valorSeleccionado @change="getFilterByValue">
                    <label class="form-check-label" for="ayuntamiento">
                        AYUNTAMIENTOS
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="diputacion" value="DIPUTACION" v-model=valorSeleccionado @change="getFilterByValue" >
                    <label class="form-check-label" for="diputacion">
                        DIPUTACIONES                  
                    </label>
                </div>

                <div class="form-check form-check-inline button-separation">
                    <input class="form-check-input" type="radio" name="cargo-politico" id="accionesAfirmativas" value=1 v-model=valorSeleccionado @change="getFilterByValue" >
                    <label class="form-check-label" for="accionesAfirmativas">
                        ACCIONES AFIRMATIVAS       
                    </label>
                </div>
            </div>

            <transition name="fade">
                <!--Otro elemento que se desee mostrar -->
            </transition>
            <!--Se mantiene este buscador personalizado-->        
            <transition name="fade">
                    <!--Grupo de radiobuttons por acciones afirmativas-->
                    <div class="buttons-container" v-show="showDiv2">
                        <label class="label-color" for="busquedaAccionesAfirmativas">ELIGE UNA ACCIÓN AFIRMATIVA</label>
                        <br>          

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="personasDiscapacidad" id="acciona1" value=1 v-model=valorSeleccionado2 @change="getFilterByValue">
                            <label class="form-check-label" for="acciona1">
                                PERSONAS CON DISCAPACIDAD               
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="comunidadlgbt" id="acciona2" value=2 v-model=valorSeleccionado2 @change="getFilterByValue">
                            <label class="form-check-label" for="acciona2">
                                PERSONAS DE LA POBLACIÓN LGBTIAQ+
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="poblacionIndigena" id="acciona3" value=3 v-model=valorSeleccionado2 @change="getFilterByValue" >
                            <label class="form-check-label" for="acciona3">
                                PERSONAS INDÍGENAS                  
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="poblacionMIgrante" id="acciona4" value=4 v-model=valorSeleccionado2 @change="getFilterByValue" >
                            <label class="form-check-label" for="acciona4">
                                PERSONAS MIGRANTES     
                            </label>
                        </div>
                    </div>
            </transition>
            
            <transition name="fade">
                <div v-show="showDiv">
                    <div class=" row buttons-container mt-2">                
                        <div class="col-xs-12 col-sm-12 col-lg-3 d-inline-flex text-align-center align-content-center mt-2" >                    
                        <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                            <label class="label-color" for="search">BUSCAR:&nbsp;</label>
                            <input type="text" class="form-control text-uppercase" id="search" v-model="filters.nombre.value" placeholder="NOMBRE, MUNICIPIO,DISTRITO, EDAD" @input="getFilterBySearch">                                                                  
                        </div>
                        <!--Busqueda por sección--> 
                        <div class="col-xs-12 col-sm-12 col-lg-3 d-inline-flex text-align-center align-content-center mt-2" >                    
                        <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                            <label class="label-color" for="seccion">SECCIÓN:&nbsp;</label>
                            <input type="text" class="form-control" id="seccion" v-model=numeroSeccion placeholder="INGRESA LA SECCIÓN">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-lg-6 d-inline-flex text-align-center align-content-center mt-2" >
                            <button type="button" class="btn btn-custom" v-on:click=" buscarSeccion()">Buscar&emsp;<fa icon="fa-solid fa-search"/></button>&emsp;&emsp;&nbsp;
                            <!--Tooltip img-->
                            <b-col>
                                <b-button id="tooltip-ine" variant="outline-secondary"> Ubica tu sección</b-button>
                            </b-col>                                                
                            <b-tooltip target="tooltip-ine" placement="top">                                 
                                <img src="../assets/ine.png" class="tooltip-img" alt="Imagen INE">
                            </b-tooltip>
                        </div>                                                
                    </div><!--clase row de botones-->
                        <div class="row buttons-container mt2">
                            <div class="col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-end btn-align-right">
                                <button type="button" class="btn btn-custom" v-on:click=" limpiarFiltros()">Limpiar<fa icon="fa-solid fa-trash" v-show="showDiv"/></button> &nbsp;&emsp;<button type="button" class="btn btn-custom" v-on:click=" generateReport()" v-show="showDiv">Exportar base de datos&nbsp;<fa icon="fa-solid fa-file-excel"/></button>                                               
                            </div>
                        </div>

                    <!-- :disabled="disable" marca error se quita despues del btn btn-custom-->
                    <div class="table-responsive">
                        <div class="col-xs-12 col-sm-12 col-lg-12" ><p style="color:#B20066">* M = Mujer, H = Hombre, N = No Binaria </p>
                         </div>              
                        <!--Se crean dos tablas con v-table una de inicio consulta de carga de inicio y una para consultas por filtros-->
                        <v-table  class="custom-table" v-if="activarLista===true" :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event">
                            <thead slot="head" class="bg-light">
                                <v-th sortKey="logo" class="text-center">FOTO</v-th>
                                <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                                <v-th sortKey="nombre" class="text-center">SEXO</v-th>
                                <v-th sortKey="siglas" class="text-center">CARGO</v-th>
                                <v-th sortKey="ruta" class="text-center">POSTULANTE</v-th>                                
                                <v-th sortKey="nombre" class="text-center">DISTRITO</v-th>
                                <v-th sortKey="nombre" class="text-center">MUNICIPIO</v-th>
                                <v-th sortKey="nombre" class="text-center">VER</v-th>
                            </thead>
                            <tbody slot="body" slot-scope="{displayData}">                    
                                <tr tr v-for="partido in displayData" :key="partido.idPartido" v-on:click="ver(partido.idPartido)"  >  <!--falta programar evento row-->                          
                                        <td><b-avatar v-if="partido.imagen" :src="dirname+partido.imagen" size="2.5rem"></b-avatar></td>
                                        <td><strong>{{ partido.propietario.nombre }}&nbsp;{{ partido.propietario.primerApellido }}&nbsp;{{ partido.propietario.segundoApellido }}</strong></td>
                                        <td>{{ partido.propietario.sexo }}</td>
                                        <td > {{ partido.candidatura.descTipoCandidatura }}</td>
                                        <td>{{ partido.asociacion.siglasAsociacion}}</td>
                                        <td>{{ partido.entornoGeografico.distrito }}</td>                                        
                                        <td>{{ partido.entornoGeografico.municipio }}</td>                                  
                                        <!--<td> <span style="margin-right: 10px;"><fa icon="magnifying-glass" v-on:click="ver(partido.idCandidato)"/></span></td>-->
                                        <td><router-link :to ="{name: 'Curriculum', params: {id:partido.idPartido}} " ><span style="margin-right: 10px;"><fa icon="magnifying-glass"/></span></router-link></td>                                                            
                                </tr> <!--cierre td encabezados-->                              
                            </tbody>                
                        </v-table>
                        
                        <v-table  class="custom-table" v-else :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event">
                            
                            <thead slot="head" class="bg-light">
                                <v-th sortKey="logo" class="text-center">FOTO</v-th>
                                <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                                <v-th sortKey="nombre" class="text-center">SEXO</v-th>
                                <v-th sortKey="siglas" class="text-center">CARGO</v-th>
                                <v-th sortKey="ruta" class="text-center">POSTULANTE</v-th>
                                <v-th sortKey="nombre" class="text-center">DISTRITO</v-th>                               
                                <v-th sortKey="nombre" class="text-center">MUNICIPIO</v-th>
                                <v-th sortKey="nombre" class="text-center">VER</v-th>
                            </thead>
                            <tbody slot="body" slot-scope="{displayData}">                    
                                <tr v-for="partido in displayData" :key="partido.idPartido" v-on:click="ver(partido.idPartido)" >  <!--falta programar evento row-->                          
                                        <td><b-avatar v-if="partido.imagen" :src="dirname+partido.imagen" size="2.5rem"></b-avatar></td>
                                        <td><strong>{{ partido.propietario.nombre }}&nbsp;{{ partido.propietario.primerApellido }}&nbsp;{{ partido.propietario.segundoApellido }}</strong></td>
                                        <td>{{ partido.propietario.sexo }}</td>
                                        <td > {{ partido.candidatura.descTipoCandidatura }}</td>
                                        <td>{{ partido.asociacion.siglasAsociacion }}</td>
                                        <td>{{ partido.entornoGeografico.distrito }}</td>                                       
                                        <td>{{ partido.entornoGeografico.municipio }}</td>                                  
                                        <!--<td> <span style="margin-right: 10px;"><fa icon="magnifying-glass" v-on:click="ver(partido.idCandidato)"/></span></td>-->
                                        <td><router-link :to ="{name: 'Curriculum', params: {id:partido.idPartido}}"><span style="margin-right: 10px;"><fa icon="magnifying-glass"/></span></router-link></td>                                                            
                                </tr> <!--cierre td encabezados-->                              
                            </tbody>                
                        </v-table>
                        <smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="10" class="pagination justify-content-end"/>           
                    </div><!--Termina clase table respinsiva-->
                </div>
            </transition>    
          </div><!--div contenido mt-4-->       
            
         
        <Footer/>
    </div> <!--Container md-->

</template>
<script>
import Header from '@/components/HeaderPanel.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import municipios from '../data/municipios.json';
import axios from 'axios';
import *  as XLSX from 'xlsx'
//Importacion de los componentes graficas

//import { each } from 'chart.js/dist/helpers/helpers.core';
//import { elements } from 'chart.js';

export default{
    name:"ListaRevisionCandidatos",
    data(){
        return{
            //Variables de inicio de sesion de usuario
            username:"",
            idUsuario:"",
            nombre_completo_usuario:"",
            descRol:"",
            
            secciones:{
                idSeccion:0,
                nroSeccion:0
            },
            accionesAfirmativas:{
                id:0,
                descripcion:"",
                },
            //Variables de uso global del sistema
            idPartido:"",
            imagenes:"", //Ruta de la imagen a guardarse temporal
            numeroSeccion:"",
            max:10, 
            value:6, 
            currentPage: 1, 
            totalPages: 0,
            //baseURL:'192.168.1.14:3000', //cambiar acceso publico ip server, pruebas locales usar localhost 
            showDiv:false,
            showDiv2:false,
            //Variables para realizar busquedas y selecciones
            municipios_mich:municipios,
            selection:"",
            //Lista de objetos para crear reporte en CSV y variables de encabezado
            datosCandidato:[
                {"No.":""},
                {"PROPIETARIO":""},
                {"SUPLENTE":""},
                {"CARGO":""},
                {"EDAD":""},
                {"SEXO/GENERICO":""},
                {"MUNICIPIO":""},
                {"PARTIDO":""},                
                {"DISTRITO":""},
                {"SECCION":""},
                {"CANDIDATURA":""},               
            ],
            headersReport:[],

             //Datos ID Para Crear consultas y formar el CV del Candidato
           
            
            folioRegistroCandidato:"",
            folioRegistro:"",
            activo:"",
            imagen:"",

            propietario:{
                nombre:"",
                primerApellido:"",
                segundoApellido:"",
                sexo:"",
                correo:"",
                fechaNacimiento:"",
                lugarNacimiento:"",
                ocupacion:"",
                sobrenombre:""
            },
            suplente:{
               
                nombre:"",
                primerApellido:"",
                segundoApellido:"",
                sexo:"",
                correo:"",
                              
                fechaNacimiento:"",
                lugarNacimiento:"",                
                ocupacion:"",
                sobrenombre:""
            },
            asociacion:{
                asociacionPostula:"", //PIM
                descTipoAsociacion:"", //LOCAL
                nombreAsociacion:"", //PIM
                siglasAsociacion:""//PIM

            },
            candidatura:{
                descTipoCandidatura:""//AYUNTAMIENTO O DIPUTACION
            },
            entornoGeografico:{
                idEntidad:16, // solu usar si hay mas entidades incluidas de las 31
                idMunicipio:"",//102
                municipio:"",//102 Uruapan
                distrito:""//14
            },
             //Datos publicos para mostrarse en la vista de tabla
            form:{"logo": ""},

            /*
            informacion_personal:{
                nombre:"",
                lugar_nacimiento:""
            },  
            informacion_partido:{
            numero:"",
            propietario:"",
            partido:"",
            cargo:"",
            distrito:"",
            edad:"",
            lugar_nacimiento:""
            },*/
           


           //Declaracion de listas para se mostradas con los datos publicos           
            lista:[], //Lista principal
            lista2:[],
            lista3:[],
            listaC:[],
            listaP:[],
            listaAcciones:[],
            listatmp:[],
            listaPartidos:[],
            activarLista:true,
            dirname:this.$assetsDirname,
                      
           // variables delv-model para almacenar selecciones
            actorPolitico:'',
            valorSeleccionado:'',
            valorSeleccionado2:'',
            valorSeleccionado3:'',
            accionAfirmativa:'',
            filters: {nombre: { value: '', keys: ['propietario.nombre','entornoGeografico.municipio','propietario.sexo','entornoGeografico.distrito']}}, // se coloca el filtro que se quiere buscar
                       
        //Variables del anterior Desarrollo PENDIENTES POR DEPURAR
           
            filtroButtons:[],  
            multipleChoiceValue:null, 
            valuemultiselect:"",
            textoTrayectoria:"", 
            textoCargo:"", 
            textoPropuesta1:"", 
            textoPropuesta2:"", 
            textoPropuestaGrupoDiscr:"",
            options:["Purepechas (P'urhépecha)",'Nahuas','Mazahuas','Otomíes','Matlatzincas','Cuitlatecos','Tzotziles',
            'Chichimecas','Zapotecos','Mixtecos','Hñähñús (Otomíes)','Mestizos (población no indígena)'],
            archivos:[
                {nombre:"Hola", ruta:"hola amigui"},
                {nombre:"bola", ruta:"hola amigui"},
                {nombre:"xola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"}],
           
           
        }
    },
    /*
    props:{
        idCandidato:this.lista.idCandidato // le pasamos el parametro Props para visitar su CV en un nueva vista o componente
    }, */
    components:{
        Header,
        Footer,
        Banner,
        //Uso de los componentes en la aplicacion
       
        

    },
    methods:{
        limpiarFiltros(){
              
            this.valorSeleccionado=""
            this.valorSeleccionado2=""            
            this.valorSeleccionado3 = ""
            this.filters.nombre.value=""
            this.showDiv=false
            this.showDiv2=false
            
            this.lista2=[]
            this.listaPartidos = []
            this.listaAcciones =[]
            //this.showDiv=false
           
          
             
        
        },
        choose(){
            
            //this.informacion_personal.lugar_nacimiento=this.selection
        },
 
        ver(id){            
           
            this.thispath(`Curriculum/${id}`)
          
        },
        siguiente(){
            console.log(this.multipleChoiceValue)
            //this.thispath('/newpartido')
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        consultar(e){
            console.log(e)
        },

        uploadImage(e){
            try {
                this.form.logo = this.$refs.fileInput.files[0]
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);
                    this.$refs.previewImage.src = e.target.result;
                }
            } catch (error) {
                console.log(error)
            }
        },
        deleteImage(e){
            this.form.logo = ''
            this.$refs.fileInput.value = null;
        },
        //Utilizamps axios para obtener los datos con la API
        async getPartidos(){  
           
        },
                                                              
         
    
        //Utilizamos otra API para obtener datos SNR vinculamos ambos campos con folio de registro
        //Importante tomar folio para vincular ambas colecciones de datos
        getFilterByValue(){ 
             //Se realiza la consulta a la lista que se encuentra activada  
                  
             if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2==="")){                
                this.showDiv= true //Bandera control de visibilidad div's               
                this.listatmp=[]
                this.lista.forEach(element =>{
                    this.listatmp.push(element)})                                     
                //se crea una Lista2 para la busqueda para el input text filtro
                this.lista2=this.listatmp
                this.listaPartidos=this.listatmp                     
            }
            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2==="")){
                this.showDiv= true // Se activa en primera instancia el  grupo de botonnes de partidos
                this.showDiv2=true 
                //se activan radiobuttons de acciones afirmativas                                          
                this.listatmp=[]                                      
                this.lista.forEach(element =>{
                    if(element.candidatura.descTipoCandidatura === this.valorSeleccionado){
                        this.listatmp.push(element)
                    }else if(element.accionesAfirmativas.id >= this.valorSeleccionado){
                        this.listatmp.push(element)                            
                    }}) 
                    this.listaAcciones = this.listatmp // se crea e inicializa esta lista para realizar bisqueda por acciones el el grupo de botones de acciones afirmativas
                    this.lista2=this.listatmp// //se crea una Lista2 para la busqueda para el input text filtro
                    this.listaPartidos=this.listatmp                 
                }
 
            //Condicional que enlista por actores politicos
            else if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2!=="")){
                this.showDiv2=true                
                this.listatmp=[]
                this.lista.forEach(element =>{
                    if(element.accionesAfirmativas.id === Number(this.valorSeleccionado2)){
                        this.listatmp.push(element)}              
                        })
                    this.lista2=this.listatmp
                    this.listaPartidos=this.listatmp
                 }//Termina condicional que enlista por actores politicos

            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2 !=="")){
                this.showDiv2=true                            
                this.listatmp=[]                                      
                this.listaAcciones.forEach(element =>{
                    if((element.accionesAfirmativas.id === this.valorSeleccionado2)&&(element.candidatura.descTipoCandidatura === this.valorSeleccionado || element.accionesAfirmativas.id >= this.valorSeleccionado)){
                        this.listatmp.push(element)}              
                        })
                    //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp                    
                    this.listaPartidos=this.listatmp              
                }
                  

            else{
                console.log("Valor",this.valorSeleccionado2)            
            }//Termina condicion else         
        },
        //Metodo para obtener candidatos por acciones afirmativas 
        getUserByActions(){
            this.activarLista=false
       
            this.listatmp=[]       
           
            if(this.selection !==""){
                this.lista.forEach(element =>{ 
                {
                    if(element.entornoGeografico.municipio === this.selection)
                        {
                            this.listatmp.push(element)
                        }
                    this.lista2=this.listatmp
                    this.lista=this.listatmp 
                    
                }
            })
            }   
            else if((this.valorSeleccionado==="todos")&&(this.selection==="")){                
                this.listatmp=[]
                this.lista.forEach(element =>{                 
                        this.listatmp.push(element)})
                                     
                    //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    this.lista=this.listatmp
            }
        }, 
       
        //Método ára obtener candidatos por medio del input de busqueda
        getFilterBySearch(){
            // si estan seleccionadas ambas se realiza busqueda en lista
            //const res = await axios.get(`/api/candidatos`)
            //this.lista=res.data[0].lista;
            this.activarLista=false;           
                //Se realiza busqueda con coincidencia en lista
                this.listatmp=[]
                let filtro = (this.filters.nombre.value).toUpperCase()
                console.log(filtro)            
                this.lista2.forEach(element =>{
                    if(element.entornoGeografico.municipio.startsWith(this.filters.nombre.value.toUpperCase() )=== true){                                                                                                                               
                         this.listatmp.push(element)                        
                        }
                    else if(element.entornoGeografico.distrito.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  
                    }
                    else if(element.propietario.nombre.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  

                    }
                    else if(this.filters.nombre.value === ""){
                        this.listatmp.push(element)
                    }
                    })
                    
                    this.listaPartidos=this.listatmp                                   
                 },         
          
        async generateReport(){
            

            this.datosCandidato = this.listaPartidos.map((datos,contador=0)=>{
                //ingresar otro map con la lista de candidatos registrados que coincidan con la busqueda datos de alta y con informacion             
                   
                return{ 
                        "No.":contador+1,
                        "PROPIETARIO":datos.propietario.nombre+" "+datos.propietario.primerApellido+" "+datos.propietario.segundoApellido,
                        "CARGO":datos.candidatura.descTipoCandidatura,
                        "PARTIDO": datos.asociacion.siglasAsociacion,
                        "DISTRITO": datos.entornoGeografico.distrito ,
                        "MUNICIPIO": datos.entornoGeografico.municipio
                        };
                  
            }) //.map((candidatoElement) => Object.values(candidatoElement).join(",")).join("\n");
            //Object.keys(this.lista2[0]).slice(0,3)
            // const headers = Object.values(this.headersReport)
            //const reporteCandidatos = headers +"\n"+ datosCandidato
            //hacemos el llamado de la funcion
            //console.log(this.headersReport)          
            this.exportToExcel(this.datosCandidato)          
            //this.metodoExcelCreateReport(reporteCandidatos)
            //await fs.writeFile(path.join(__dirname,'candidatos.csv'),reporteCandidatos)            
       },//Termina metodo generateReport

        exportToExcel(datos){
        const ws= XLSX.utils.json_to_sheet(datos); // Repetimos la misma coleccion pendiente por generar la seguns coleccion desde otro metodo
        // Repetimos la misma coleccion pendiente por generar la seguns coleccion desde otro metodo
        const wb= XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'Cuestionario Curricular');
        XLSX.utils.book_append_sheet(wb,ws,'Cuestionario de Identidad');

        XLSX.writeFile(wb,'baseDatosCandidatosCandidatas.xlsx')

    },
    buscarSeccion(){                        
        this.listatmp=[]               
        this.lista2.forEach(element =>{
        if(element.secciones.nroSeccion === Number(this.numeroSeccion)){                                                                                                                               
             this.listatmp.push(element)                        
        }})
        this.listaPartidos=this.listatmp
    },
    mergeLista(listaP, listaC){
            return listaP.map( element1 =>{
                const element2 = listaC.find(element => element.folioRegistroCandidato === element1.folioRegistro)
                return{
                ...element1,
                ...(element2 && {imagen: element2.imagen, Folio: element2.folioRegistroCandidato} )
            }
            }) 
    },
    
    async getAllUsers(){
            this.activarLista=false       
  
    },
    async getImageCandidatos(folio){
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidatofrc/${folio}`
            try {                
                 const info = await axios.get(url)                 
                 //this.folioRegistroCandidato=info.data[0].folioRegistroCandidato
                this. imagenes = info.data[0].imagen
                                     
            } catch (error) {
                console.log('No existe imagen', error)
                
            }  
    },
    
    }, //Terminan los metodos    
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        let listatmp=[]
        //Se inicializan las listas que conforman la tabla se realiza con create para no utilizar promesas
        const res1 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos`)
        this.listaP=res1.data;
        const res2 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/candidatos`)
        this.listaC=res2.data;


        const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')            
            //Datos del usuario que Inicia Sesion en el portal
            const {idUsuario, token, usernamePanel,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario
            this.users_rols=roles       
            
            
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }



        //se combinan las listas de acuerdo a una condicion en el metodo
        listatmp = this.mergeLista(this.listaP, this.listaC)
        listatmp.forEach(element =>{
            this.lista.push(element)            
        })
        console.log(this.lista)       
        // Inicializa la lista ordenada por nombre por medio del metodo create y se utilizan metodos de conversion a a mayusculas
        const orderList = this.lista.sort((a,b)=>{
            const nameA = a.propietario.nombre.toUpperCase()
            const nameB = b.propietario.nombre.toUpperCase()
            if(nameA < nameB){
                return -1
            }
            if(nameA > nameB){
                return 1
            }
            return 0
        })
        this.lista = orderList 
       
        }
    }      
    

</script>

<style scoped>
.custom-table {
  font-family: GalanoRegular;
  border-collapse: collapse;
  width: 100%;
}
.custom-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.custom-table tr:nth-child(even){background-color: #f2f2f2;}
.custom-table tr:hover {background-color: #ddd; cursor: pointer;}
.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #a31a77;
  color: white;
  padding-left: 5px;
}
.custom-table th:hover {cursor: pointer;}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(163,26,119, 1);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #a31a77   ;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #a31a77;
    --bs-btn-border-color: #a31a77; 
    --bs-btn-hover-color: #fff;
    /*--bs-btn-hover-bg: #6a6ea8;*/
    --bs-btn-hover-border-color: #727271;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 2px solid #a31a77;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #a31a77;
      border: 2px solid #a31a77;
      color: white;
      transform: scale(1.111, 1.111);
      .radio-tile-label {
        color: white;
        background-color: #a31a77;
      }
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #a31a77;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
/* Se agregan nuevas clases para mostrar radiobutons de filtrtado**/
.buttons-container{
    text-align: start;    
    width: 100%;
    margin-bottom: 1%;
 
}
.label-color{
    /*color:#6a6ea8;  */
    color:#4d4170

}
.button-separation{
    
    padding-right: 2%;
    margin-bottom: 2%;

}
.btn-align-right{
      
    margin-bottom: 5px;   
}
.fade-enter-active, .fade-leave-active{
    transition:opacity 0.8s
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}
/**Estilos botones side bar menu graficas */
.btn-toggle {
  
  font-weight: bolder;
  color: #686868; 
  font-size: 20px;
  text-align: left;
  
  
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: #990089;
  background-color: #fff;
   font-weight: bolder;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;

}
.graphics{
    border-top: 2px solid #bbbaba ;
    
    
}
.graphics-b{
    border-right: 2px solid #bbbaba ;
    
}
h2 {
    font-family: GalanoRegular;
    color: #717171;
    
    font-weight: bolder;
    font-size:xx-large;
    text-align:center;
    
    
}
.tooltip-img{
    width: 400px;
    border-radius: 10px;
  
}


</style>