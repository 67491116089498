<template>
    <div>
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container">
            <div>
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=2>
                    <span>2 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div>
            <div class="row questionary-row">
                <div class="questionary-container">
                <!-- <h3><strong> ¿Porque quiere ocupar un cargo publico amigue? ahjsbdjbsdsdasdasasdfasdsdasd asd fsdjfj</strong></h3> -->
                <p class="question">Historia Profesional</p>
                <div style="margin-top: 5%; margin-bottom: 5%;">
                    <label>Grado máximo de estudios y su estatus</label>
                    <div class="d-flex flex-column mb-3">
                        <multiselect
                            v-model="estudio.grado_maximo"
                            :options="options"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            placeholder="Selecciona una opción"
                            style="font-family: GalanoRegular;">
                        </multiselect>                      
                    </div>             

                                
                    <label>Otra formación académica: cursos, diplomados, seminarios, etcétera</label>
                    <div class="d-flex flex-column align-items-end">
                        <b-form-textarea
                            v-model="otro"
                            placeholder="Máximo 250 caracteres"
                            rows="3"
                            maxlength="250"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                    <label style="font-size: 14px;">{{otro.length}}/250</label>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <table v-for="(otro, index) in estudio.otro" class="table-custom table-hover mb-1">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="input-group-text-custom" v-on:click="cargar(otro)">{{ otro.slice(0,30) }} {{ otro.length > 30 ? '...' : '' }}</span>
                                        <button type="button" class="btn btn-danger bc" v-on:click="erase(index)"><fa icon="trash"/></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <button type="button" class="btn btn-custom" v-on:click="agregar()">Agregar&emsp;<fa icon="plus"/>&emsp;</button>
                    </div>
                    <label>Historia profesional y/o laboral que describa la experiencia, los años y las actividades realizadas en ésta</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="profesion.historia_laboral"
                            :state="profesion.historia_laboral.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="5000"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{profesion.historia_laboral.length}}/5000</label>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <button style="margin-right: 1%;" type="button" :disabled="disable" class="btn btn-secondary" v-on:click="atras()">Atras&emsp;<fa icon="arrow-left"/>&emsp;</button>
                    <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button>
                </div>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'

export default{
    name:"Profesional",
    data(){
        return{
            routes:routes,
            isLoading: true,
            disable:false,
            idCandidato:null,
            config:{},
            username:"",
            max:24, 
            valuemultiselect:'',
            options:["Ninguno", "Preescolar", "Primaria", "Secundaria", "Carrera técnica con secundaria terminada", "Preparatoria o bachillerato", "Carrera técnica con preparatoria terminada", "Normal", "Profesional", "Maestría o doctorado"],
            otro:"",
            estudio:{
                grado_maximo:"",
                estatus:"",
                otro:[]
            },
            profesion:{
                historia_laboral:""
            },
            pagina:"",
            imagen:"",
            estadoRoC :"",
            validacionCaptura:false,
            //Variables para registro de acciones
            logsAcciones:[],
            dataLogs : logs,
            loggedIn: this.$route.params.loggedIn

        }
    },
    components:{
        Header,
        Footer,
        Loading
    },
    methods:{
        async atras(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-1)
            }
        },
        cargar(e){
            this.otro = e
        },
        erase(e){
            console.log(e)
            this.estudio.otro.splice(e, 1)
        },
        agregar(){
            if( this.otro != ''){
                this.estudio.otro.push(this.otro)
                this.otro=''
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        async save(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'

            if(this.paginat > 0){
                this.estadoRoC ="CAPTURADO"
                this.validacionCaptura=true
                const res = await axios.patch(url, { curriculares:{ estudio:this.estudio, profesion:this.profesion}, idCandidato:this.idCandidato, pagina:this.pagina+(25-this.paginat),paginat:0, estadoRoC:this.estadoRoC,validacionCaptura:this.validacionCaptura}, this.config)
                if(res.data.result = 1){
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(5)
                    this.disable=false
                    this.path(this.pagina+(25-this.paginat)) // envia al indice de revision
                    return
                }

                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path(this.pagina+(25-this.paginat)) // envia al indice de revision
                this.disable=false
             }
             else{
                if(this.profesion.historia_laboral.length >= 280){                    
                    const res = await axios.patch(url, { curriculares:{ estudio:this.estudio, profesion:this.profesion}, idCandidato:this.idCandidato, pagina:this.pagina+1 }, this.config)
                    if(res.data.result = 1){
                        this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                        this.logsCandidato(4)
                        this.disable=false
                        this.path(this.pagina+1) // siguiente pagina
                        return
                    }
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+1) // siguiente pagina
                    this.disable=false
                    }else{
                        this.$alert('','<label class="alertlabel">El campo Historia profesional y/o laboral debe cumplir con mas de 280 caracteres</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                        this.path(this.pagina+1) // siguiente pagina
                        this.disable=false
                    }

             }
        },
        async logsCandidato(clave){
            let now= new Date();
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            try{
                const log  = this.dataLogs[clave];
                console.log(log)
                this.logsAcciones.push(log+" : "+now)            
                await axios.patch(url, { idCandidato:this.idCandidato,logsAcciones:this.logsAcciones }, this.config)
                

            }catch(error){
                console.error(error)
            }
    },



    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato
            this.config = {
                headers:{
                    Authorization: `Bearer ${token}` 
                }
            }
            const result = await axios.get(url, this.config)
            this.pagina = result.data[0].pagina
            this.paginat = result.data[0].paginat   

            this.path(this.pagina)

            this.estudio.grado_maximo = result.data[0].curriculares.estudio.grado_maximo
            this.estudio.estatus = result.data[0].curriculares.estudio.estatus
            this.estudio.otro = result.data[0].curriculares.estudio.otro
            this.profesion.historia_laboral = result.data[0].curriculares.profesion.historia_laboral
            this.imagen = result.data[0].imagen
       
            //Registro acciones usuario
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }  
            

            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped>
.table-custom {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    caption-side: bottom;
    text-indent: initial;
    border-collapse: separate;
    background-color: #e9ecef;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
}
.table-custom > :not(caption) > * > * {
    cursor: pointer;
    padding: 0.455rem 0.55rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
td {
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
}
.input-group-text-custom {
    width: 100%;
    font-family: GalanoRegular;
}
section {
    font-family: GalanoRegular;
}
.bc {
    text-align: right;
    justify-content: right;
    padding: 2px 10px 2px 10px;
}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #B20066;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #B20066;
    --bs-btn-border-color: #B20066;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b0aeb3;
    --bs-btn-hover-border-color: #b0aeb3;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
label {
    font-family: GalanoRegular;
    font-weight: bold;
    color: #B20066;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
textarea::placeholder {
  color: #b0aeb3;
}
section:hover {
  font-weight: bold;
  cursor: pointer;
}
.question{
    color:#5c636a
  }

</style>