<template>
    <div class="container-fluid">
        <HeaderPanel :nombre_completo="nombre_completo" :tipoRol ="tipoRol"/>  
    
    <div class="row bg-iem" v-if="showPanel">
                 
            <div class="col-xs-12 col-sm-12 col-lg-2 align-items-cente" style="margin-top: 2.5%;">
                <SidebarAdmin/>              
            </div>           
            <div class="col-xs-12 col-sm-12 col-lg-10 align-items-center" >
                <router-view></router-view>                  
            </div>       
    </div>
<Footer/>
</div>


</template>
<script>
import HeaderPanel from '@/components/HeaderPanel.vue';
import Footer from '@/components/Footer.vue';
import SidebarAdmin from '@/components/SidebarAdmin.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'


export default{
    name:'PanelAdministrador',
    data(){
        return{
            idUsuario:null,
            username: "",
            nombre_completo:'',
            roles:"", 
            tipoRol:"",
            showPanel:false, // no muestra el panel si no esta autorizado y envia alerta ventana modal que redirecciona al home,

           
            
            idFolioValidacion:"",
            rol:"",
            asignaciones:"",
            usuario_activado:"",
            email:"",
            listaUsers:[],
            filters: {nombre: { value: '', keys: ['nombre_completo','username','rol','email']}},
            isLoading: true,
            routes:routes,            
           
            //disable:false,
            config:{},                   
            pagina:"",
                      
          


        }
    },
    components:{
        HeaderPanel,
        Footer,
        SidebarAdmin,
       
    }, 
    methods:{
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },

    },
    //Declarion de created para carga de sesiones
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        
       
        try {
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/404')
        //Obtencion de Datos del usuario que Inicia Sesion en el portal
                 
            const {idUsuario, token, usernamePanel,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario  
            this.tipoRol=roles[0].rol
            this.showPanel=true
      
        
        if(this.tipoRol ==='Administrador'){ //Agregar mas usuarios mientras sea necesario        
            this.$router.push('/usuarios')
          //Si es admin accede a axios y  toda la configuracion
          this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }           
            const result = await axios.get(url,this.config)           
            this.nombre_completo= result.data[0].nombre_completo           
            this.isLoading = false 
        }      
        else{
          this.$router.push('/adminpanel')        

        }
       

            

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/404')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
<style>
.bg-iem{
    background-image: url("../assets/background-iem.jpg");
}

#panelview{
    display: flex;}
</style>