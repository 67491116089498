<template>
  <div class="container-fluid">
    <div class="row"> 
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>  
        <h4>Registro de candidaturas por grado acádemico</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
      <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie>
   </div>   
</div>
</div>   
  
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'
import { isNull } from 'pdfmake/build/pdfmake'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaGrado',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {       

        labels: ["Ninguno", "Preescolar", "Primaria", "Secundaria", "Carrera técnica con secundaria terminada", "Preparatoria o bachillerato", "Carrera técnica con preparatoria terminada", "Normal", "Profesional", "Maestría o doctorado"],
        datasets: [ 
            {
            label: 'Grado acádemico',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#247BA9','#B20066','#E72174','#E467A3','#247BA9','#B20066','#E72174','#E467A3','#247BA9','#B20066']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        }
        
      },
      //Variables para creacion de los datos
      lista :[],
      cGrado1:0,
      cGrado2:0,
      cGrado3:0,
      cGrado4:0,
      
    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
    const res = await axios.get(url)
    this.lista = res.data
    let array_g=[]
    const contador={}
    

    this.lista.forEach(element =>{
        {
          let index = this.chartData.labels.indexOf(element.curriculares.estudio.grado_maximo)
          array_g.push(index)          
                    
             
        }      
    })

    array_g.forEach(items =>{
      if(contador[items]){
        contador[items]++
      }else{
        contador[items]=1
      }
    })
    
    
    for( let clave in contador)
      if(clave){
        this.chartData.datasets[0].data[clave]=contador[clave]
       
       
    }

    this.loaded = true

    }
    
}
</script>


<style>
</style>