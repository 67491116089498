import { render, staticRenderFns } from "./RevisionCandidato.vue?vue&type=template&id=686cda7b&scoped=true"
import script from "./RevisionCandidato.vue?vue&type=script&lang=js"
export * from "./RevisionCandidato.vue?vue&type=script&lang=js"
import style0 from "../assets/styles/candidato.css?vue&type=style&index=0&id=686cda7b&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686cda7b",
  null
  
)

export default component.exports