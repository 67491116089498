import { render, staticRenderFns } from "./Identidad20.vue?vue&type=template&id=08ee7501&scoped=true"
import script from "./Identidad20.vue?vue&type=script&lang=js"
export * from "./Identidad20.vue?vue&type=script&lang=js"
import style0 from "../assets/styles/identidad.css?vue&type=style&index=0&id=08ee7501&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ee7501",
  null
  
)

export default component.exports