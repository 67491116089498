<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" v-for="(crumb, index) in crumbs" :key="index">
                <a href="https://portal.ine.mx/voto-y-elecciones/" target="_blank">VOTO Y ELECCIONES</a>/&nbsp;
                <a href="https://www.ine.mx/voto-y-elecciones/elecciones-2024/" target="_blank">ELECCIONES 2024</a>/&nbsp;                   
                <router-link :to="crumb.path" style="color:#970064">{{ crumb.label }}</router-link>                
            </li>
        </ol>
    </nav>
</template>
<script>
export default{
    name:'Breadcrumb',
    props:{
        crumbs:{
            type:Array,
            required:true,
        }

    },
}
</script>
<style scoped>




</style>