<template>
  <!-- class="fixed-top" -->
  <div >
    <b-navbar toggleable="lg" type="light" variant="light" class="img-fluid" >
    <b-navbar-toggle target="nav-collapse" style="margin: 0px 10px;"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav style="padding: 0 50px;">
      <!--<b-avatar v-if="imagen" :src="dirname+imagen" alt="Imagen de Perfil" size="6rem" style="margin-right: 20px;"></b-avatar>-->
      <b-avatar src="../assets/logo_iem_2.png" size="6rem" style="margin-right: 20px;"></b-avatar>
      <b-navbar-nav>
        <b-nav-item v-on:click="partido()">
          <p class="item">USUARIO:&emsp;{{ tipoRol }}<br>NOMBRE:&emsp;{{ nombre_completo }}</p>
         
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ms-auto">
        <b-nav-item-dropdown no-caret>
          <template #button-content>
            <p class="item">Menú<fa icon="fa-solid fa-caret-down" style="font-size: 20px; margin-left: 10px; color: #9F2380;"/></p>
          </template>
          <b-dropdown-item><p class="item2">Indice</p></b-dropdown-item> 
          <b-dropdown-item><p class="item2">Aviso de privacidad</p></b-dropdown-item>
          <b-dropdown-item v-on:click="sessionClose()"><p class="item2">Cerrar Sesión</p></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  <br>
  
</div>


</template>
<script>

import routes from '../data/routes.json'
export default {
  data(){
    return{
      dirname:this.$assetsDirname,
      routes:routes,
    }
  },
  props:{
    
    username: String,
    nombre_completo:String,
    imagen: String,
    tipoRol:String,
    paginaTmp:{
      type:Number,
      default:20
    },
    
   }, 
  // Se utilizan los las variables pros que se utilizaran en todo el Header para heredar datos durante toda la navegacion del sitio
  methods:{
    partido(){
    this.thispath('/partido')
  },
    partidocomite(){
    this.thispath('/partidocomite')
  },
    thispath(path){
      if (this.$route.path !== path) this.$router.push(path)
  },  

    sessionClose(){
    localStorage.removeItem('loggedAppUserPanel')
    this.thispath('/adminpanel')
  },

  

  }
 }
</script>
<style scoped>
/* .dropdown-toggle.caret-on::after {
    display: none;
} */
/* .navbar.navbar{
    background-color: #AABB55!important;
 } */
 .navbar{
  background-image: url("../assets/pleca_faltante.png"); 
  background-size:cover;
 
  object-fit:contain;  
  font-size: 22px;
  
}
.item {
  font-family: GalanoBold;
  color: #6a6ea8;
  margin: 0px 40px 0px 0px ;

  
  -webkit-text-stroke-color: #6a6ea8;
  -webkit-text-fill-color: #fff;
}
.item2 {
  font-family: GalanoBold;
  color: #5c636a;
  margin: 0px 40px 0px 0px ;
}

@media (max-width: 991px) {
  .item {
  font-family: GalanoBold;
  color: #FFF;
  margin: 0px 0px 0px 0px ;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #5c636a;
  -webkit-text-fill-color: white; 
}
.item2{
  font-family: GalanoBold;
  color: #5c636a;
  margin: 0px 0px 0px 0px ;
}
}




</style>