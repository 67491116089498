<template ref="contentToConvert">
<div >
    <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
    </loading>
<HeaderClean/>
    <div class="container" >
        <div class="row align-items-center">
            <div class="col-xs-12 col-sm-12 col-lg-3" style="border-radius: 10px;"> 
                <img src="../assets/logoIEM.png" v-on:click="home()" alt="Logo" width="200px" class="img-fluid"/>                  
            </div>
      
            <div class="col-xs-12 col-sm-12 col-lg-8">
                <!-- --><div class="clearfix">&nbsp;</div>
                <p class="heading">La información es proporcionada por los sujetos obligados
                        <strong>bajo su responsabilidad</strong> por lo cual el Instituto Electoral de Michoacán <strong>
                        únicamente apoya para su difusión.</strong></p>                                           
       
                <!--<b-button class="titles2" v-on:click="home()"><fa icon="fa-solid fa-home"/>&nbsp;&nbsp;Pagina Principal</b-button>   -->                  
            </div>
                     
        </div>
    </div>
    <div class="container-xxl"> 
         <div class="row align-items-center mt-4" id="elementpdf">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-4">
                </div>

                <div class="col-xs-12 col-sm-12 col-lg-8">
                    <div class="row center align-items-center">
                        <div class="col-xs-12 col-sm-12 col-lg-8" >
                            <h4 class="name" >{{ nombre }}</h4>
                            <h4 class="last-name">{{ paterno }} {{ materno }}</h4>
                        </div>                       
                        <div class="col-xs-12 col-sm-12 col-lg-4 pt-4">
                            <img :src="dirimagenes+botonConoceles" alt="Proceso image" width="200px" style="margin-bottom: -6%;"/>            
                        </div>
                    </div>
                    <hr>             
                    
                    <div class="row center">
                        <div class="col-xs-12 col-sm-12 col-lg-8 text-center"><!--pendiente catalogo de imagenes almacenadas en local storage-->
                            <h5 v-if='tipo_postulante==="1" ||tipo_postulante==="2" ||tipo_postulante==="3" ' class="light text-center">CANDIDATURA POR:<br>{{ postulante }}</h5>
                            <h5 v-else  class="light text-center">CANDIDATURA INDEPENDIENTE:<br> {{ partido_nombre }}</h5>

                            <img :src="dirimagenes+getLogoPartidos(partido, tipo_postulante,postulante)" class="size-logos mt-1 mb-1">                           
                            <h5 class="light2 text-center">{{ accionAfirmativa }}</h5>
                        </div>                   

                        <div class="col-xs-12 col-sm-12 col-lg-4">
                            <img :src="dirimagenes+logoProceso" alt="Proceso image" width="200px" style="border-radius: 5px;"/>                         
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" >
            <div class="col-xs-12 col-sm-12 col-lg-4">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-12" >
                            <div class="portafolio">                                
                                <div class="portafolio-circle">                                    
                                        <img v-show="activeImage" v-if="this.imagen!=''" :src="dirname+imagen" class="img-fluid"  style="object-fit:cover; border-radius: 20px;">
                                        <img v-else-if="genero==='M'" src="../assets/sin_foto.png" class="img-fluid" style="object-fit:contain; border-radius: 20px;">
                                        <img v-else-if="genero==='H'" src="../assets/sin_foto.png" class="img-fluid" style="object-fit: contain;border-radius: 20px;">
                                        <img v-else  src="../assets/sin_foto.png" class="img-fluid" style="object-fit: contain;border-radius:20px;">
                                        <!---<img src="../assets/profile.png" class="profile-icon"> -->                                                            
                                </div>                               
                            </div><!--Solo div foto-->
                            <div class="pb-2 pt-2" v-show="activeInfo">
                                <a target="_blank" :href="curriculares.social.facebook" @click.prevent="advertencia(curriculares.social.facebook)">
                                        <fa icon="fa-brands fa-facebook" class="brand-icon"/>
                                </a>                                   
                                <a target="_blank" :href="curriculares.social.twitter" @click.prevent="advertencia(curriculares.social.twitter)">
                                        <fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon"/>
                                </a>
                                <a target="_blank" :href="curriculares.social.youtube" @click.prevent="advertencia(curriculares.social.youtube)">
                                        <fa icon="fa-brands fa-youtube" class="brand-icon"/>
                                </a>
                                <a target="_blank" :href="curriculares.social.instagram" @click.prevent="advertencia(curriculares.social.instagram)">
                                        <fa icon="fa-brands fa-instagram" class="brand-icon"/>
                                </a>
                                    <a target="_blank" :href="curriculares.social.tiktok" @click.prevent="advertencia(curriculares.social.tiktok)">
                                        <fa icon="fa-brands fa-tiktok" class="brand-icon"/>
                                </a>  
                                <!--Pendiente obtener direccion del SRC o por medio captura del usuario capturista del partido-->
                                <p class="portafolio-titles">Dirección casa de campaña</p>
                                <p class="portafolio-content">{{ curriculares.contacto.direccion[0]}}<br>{{ curriculares.contacto.direccion[1]  }}</p>                                
                                <p class="portafolio-titles">Teléfono casa de campaña</p>
                               
                                <p class="portafolio-content">{{curriculares.contacto.telefono[0] }} {{curriculares.contacto.telefono[1] }}</p>
                                <p class="portafolio-titles">Correo Electrónico</p>
                                <p class="portafolio-content">{{curriculares.contacto.email[0]}}<br>{{curriculares.contacto.email[1]}}</p>
                                <p class="portafolio-titles">Página web</p>
                                <a target="_blank" :href="curriculares.contacto.web" @click.prevent="advertencia(curriculares.contacto.web)"  style="text-decoration: none;"><p class="portafolio-content">{{curriculares.contacto.web}} </p></a>                                                             
                                                                    
                            </div> 

                        <div class="row">
                            <p class="titles-secundario" >Estudios</p>
                        </div>
                        <div class="center-row" v-show="activeInfo">
                            <p v-if='curriculares.estudio.grado_maximo !==""' class="titles-content">Grado Máximo de Estudios: {{curriculares.estudio.grado_maximo}}</p>
                            <p v-else class="titles-content">Grado Máximo de Estudios: La candidatura no proporcionó información</p>                           
                        </div>
                        <!--
                            <div class="center-row">
                             <p v-if='curriculares.estudio.estatus !==""' class="titles-content">Estatus: {{curriculares.estudio.estatus}}.</p>
                             <p v-else class="titles-content">Estatus: La candidatura no proporcionó información</p>  
                        </div>

                        -->
                  
                       
                        <div class="row">
                            <p class="titles-secundario" >Cursos</p>
                        </div>
                        <div class="center-row" v-for="(otro, index) in curriculares.estudio.otro" v-show="activeInfo">
                            <p v-if='otro !== ""'  class="titles-content"><fa icon="fa-solid fa-chevron-right "/>&nbsp;{{ otro }}</p>
                            <p v-else class="titles-content">""</p>       
                        </div>
                        <!--
                        <div class="row">
                            <p class="titles-secundario" >Información curricular</p>
                        </div>
                        <div class="center-row">
                            <p class="titles-content">Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</p>
                        </div>
                        -->
                        <div class="row">
                            <p class="titles-secundario" >Historia profesional y/o laboral</p>
                        </div>
                        <div class="center-row" v-show="activeInfo">
                            <p v-if='curriculares.profesion.historia_laboral !==""'  class="titles-content">{{ curriculares.profesion.historia_laboral }}</p>
                            <p v-else  class="titles-content">La candidatura no proporcionó información</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-xs-12 col-sm-12 col-lg-8 custom-border ">
                <div class = "center-row">
                    <div class="titles-margin">
                    <p class="titles" >Información General</p>                                        
                    </div>
                    <div class="titles-margin">                 
                        <b-button class="titles2" v-on:click="downloadCV1(80)">Descargar Curriculum <fa icon="fa-solid fa-file-pdf"/></b-button>
                       
                    </div>
                </div>
                <!--div que se muestra a todos los candidatos-->              
                <div class="custom-border2 m-3"><!--Comienza borde izquierdo conectar puntos-->
                    <div class="center-row">
                        <div class="row-flex" >
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2" >PERSONA PROPIETARIA</p>
                        </div>
                        <p class="titles-2-content"> {{ nombre }}&nbsp;{{ paterno }}&nbsp;{{ materno }}</p>
                    </div>
                    <div class="center-row" v-show=" activeRows">
                        <div class="row-flex"  >
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2">PERSONA SUPLENTE</p>
                        </div>
                        <p class="titles-2-content text-uppercase">{{suplente.nombre }} {{suplente.paterno }} {{suplente.materno }}</p>
                    </div>
                    <div class="center-row" v-show="activeRows2">
                        <div class="row-flex"  >
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2">PERSONA SUPLENTE</p>
                        </div>
                        <p class="titles-2-content text-uppercase">{{suplente.nombre }} {{suplente.paterno }} {{suplente.materno }}</p>
                    </div>
                    <!--sobrenombre-->
                    <div class="center-row">
                        <div class="row-flex"  >
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2">SOBRENOMBRE</p>
                        </div>
                        <p class="titles-2-content text-uppercase">{{sobrenombre}}</p>
                    </div>
                    <div class="center-double-row">
                        <div class="col-xs-12 col-sm-12 col-lg-6 marginb marginr">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titles-2-1c">CANDIDATURA</p>
                                <p class="titles-2-body text-uppercase">{{ tipo_partido}}</p>    
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-6">
                            <div class="marginr row-flex">
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titles-2">CARGO</p>
                                <p class="titles-2-body">{{ cargo }}</p>    
                            </div>
                        </div>
                    </div>
                    <div class="center-double-row">
                        <div class="col-xs-12 col-sm-12 col-lg-6 marginb marginr">
                            <div class="row-flex">
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titles-2-1c">EDAD&emsp;&emsp;&emsp;&emsp;&ensp;</p>
                                <p class="titles-2-body">{{ edad }}</p>    
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-6">
                            <div class="marginr row-flex">
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titles-2">SEXO</p>&nbsp;
                                <p class="titles-2-body">{{ genero }}</p>    
                            </div>
                        </div>
                    </div>
                    <div  class="center-row" v-show=" activeRows">
                        <div class="row-flex">
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2">DISTRITO</p>
                        </div>

                        <p class="titles-2-content">{{ cve_distrito }}</p>
                    </div>
                    <div  class="center-row" v-show="activeRows2">
                        <div class="row-flex">
                            <fa icon="circle" class="dot-icon"/>
                            <p class="titles-2">FORMULA</p>
                        </div>
                        <p class="titles-2-content text-uppercase">{{ getFormuladrp(posicion) }}</p>
                    </div>
                    <!--Div 1 personas con discapacidad-->
                    <div v-show="activeDiv1">
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesMedium" >¿TIENE ALGÚN TIPO DE DISCAPACIDAD?</p>
                            </div>
                            <p class="titles-2-body text-uppercase"> {{ identidad.discapacidad  }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesMedium" >EL TIPO DE DISCAPACIDAD CON EL QUE VIVE ES: </p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.estatus_discapacidad }}&nbsp;{{ identidad.tipo_discapacidad }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesMedium" >SU TIPO DE DISCAPACIDAD LE DIFICULTA O IMPIDE: </p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.dificultad_discapacidad }}</p>
                        </div>

                    </div>
                    <!--Div 2 personalizado para personas LGBTIAQ+-->
                    <div v-show="activeDiv2">
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesLarge" >¿ES USTED UNA PERSONA DE LA POBLACION LGBTIAQ+?</p>
                            </div>
                            <p class="titles-2-body text-uppercase"> {{ identidad.diversidad_sexual  }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesSmall" >USTED SE IDENTIFICA COMO:</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.tipo_diversidad_sexual }}</p>
                        </div>
                    </div><!--fin div personas Indigenas+-->
                    <!--Div3 personas de la poblacion indigena-->
                    <div v-show="activeDiv3">
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesLarge" >¿SE IDENTIFICA COMO UNA PERSONA INDÍGENA O COMO PARTE DE ALGÚN PUEBLO O COMUNIDAD INDÍGENA?</p>
                            </div>
                            <p class="titles-2-body text-uppercase"> {{ identidad.indigena  }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesSmall" >¿HABLA O ENTIENDE ALGUNA LENGUA INDÍGENA?</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.lengua_indigena }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesSmall" >¿A QUÉ PUEBLO Y/O COMUNIDAD INDÍGENA PERTENECE?</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.comunidad_indigena }}</p>
                        </div>
                    </div><!--fin div personas Indigenas-->
                    <!--Inicio div Personas Migrantes-->
                    <div v-show="activeDiv4">
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesSmall" >¿ES USTED MIGRANTE?</p>
                            </div>
                            <p class="titles-2-body text-uppercase"> {{ identidad.migrante  }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesSmall" >¿EN QUÉ PAÍS RESIDE?</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.pais_extranjero }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesLarge" >¿CUÁNTO TIEMPO HA VIVIDO EN EL EXTRANJERO?</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.tiempo_extranjero }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesLarge" >CUÁL FUE EL MOTIVO DE LA RESIDENCIA EN EL EXTRANJERO</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.motivo }}</p>
                        </div>
                        <div class="center-row">
                            <div class="row-flex" >
                                <fa icon="circle" class="dot-icon"/>
                                <p class="titlesLarge" >CUÁNDO EMIGRÓ, ¿SE ENCONTRABA CON UNA SITUACIÓN REGULAR DE TRABAJO O CON UN LUGAR ASEGURADO EN ALGUNA INSTITUCIÓN EDUCATIVA DEL PAÍS EXTRANJERO?</p>
                            </div>
                            <p class="titles-2-content text-uppercase"> {{ identidad.situacion_asegurada }}</p>
                        </div>
                    </div>



                </div><!--termina borde izquierdo para conectar los putnos-->

               
                <div class="titles-margin">
                    <p class="titles" >¿Por qué quiero ocupar un cargo público?</p>
                </div>
                <div class="center-row" v-show="activeInfo">
                    <p class="titles-content">{{curriculares.profesion.razon_cargo_publico }}</p>
                </div>
                <div class="titles-margin">
                    <p class="titles" >Propuestas electorales</p>
                </div>
                <div class="row" v-show="activeInfo">
                    <div class="col-xs-12 col-sm-12 col-lg-6 center-column">
                        <p class="titles-2-content-row-heading ">PROPUESTA 1</p>
                        <p class="titles-2-content-row-body">{{ curriculares.propuesta.propuesta1}}</p>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-6 center-column" style="margin-bottom: 20px;">
                        <p class="titles-2-content-row-heading">PROPUESTA 2</p>
                        <p class="titles-2-content-row-body">{{ curriculares.propuesta.propuesta2 }}</p>
                    </div>

                </div>

                <div class="titles-margin">
                    <p class="titles">Propuestas en materia de género o del grupo en situación de discriminación que representa</p>
                </div>
                <div class="center-row" v-show="activeInfo">
                    <p class="titles-content">{{ curriculares.propuesta.propuesta_grupo_discriminacion }}</p>
                </div>
                <div class="titles-margin">
                    <p class="titles">Trayectoria política y/o participación social</p>
                </div>
                <div class="center-row" v-show="activeInfo">
                    <p class="titles-content">{{ curriculares.profesion.trayectoria_politica }}</p>
                </div>
                <div class="titles-margin">
                    <p class="titles">Declaraciones</p>
                </div>
                <div class="center-row" v-show="activeInfo">
                    <p class="titles-content">{{identidad.socioeconomico}}</p>                    
                </div>
                <div class="center-row" v-show="activeInfo">                   
                    <p class="titles-content">{{identidad.ingresos}}</p>
                </div>
            </div>           
          </div>
        </div>
    </div><!--div elementpdf-->
    <div>
        <h5 class="mt-4">La presente sección se actualizará conforme a los plazos establecidos en el acuerdo  <strong><a href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/126830/CGex202201-31-ap-5.pdf" style="color:#B20066" target="_blank">INE/CG46/2022</a></strong></h5>
    </div>     
    
<Footer/>
</div>
</template>

<script>
import HeaderClean from '@/components/HeaderClean.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
//import {PDFDocument, PDFFont} from 'pdf-lib'
import pdfMake from 'pdfmake/build/pdfmake'
//import pdfFonts from 'pdfmake/build/vfs_fonts'
import domToPdf from 'dom-to-pdf'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import formulas from '../data/formulasdrp.json'
import { scale } from 'pdf-lib';

//pdfMake.vfs = pdfFonts.pdfMake.vfs


export default{
    name:"Curriculum",
    data(){
        return{ 
            //Datos del Partido proveniente de Sicif
            id_postulacion:"",
            tipo_postulante:"",
            tipo_eleccion: "",
            cargo:"", // equivalente a cargo conversion
            cve_mpio: "",
            postulante: "",
            cve_distrito: "",
            nombre: "",
            paterno: "",
            materno: "",
            genero: "",
            sobrenombre:"",
            lugar_nacimiento: "",
            ocupacion: "",
            aa_tipo: 0,
            aa:0,
            activacionAfirmativa:0,
            partido: "",
            edad: 0,
            isLoading: false,
            validacionCPyPP:false,
            partido_nombre:"",
            tipo_partido:"",
            formulasdrp:formulas,
            activeRows2:false,
            idsc:0,



            suplente:{
                nombre:"",
                materno:"",
                paterno:"",
            },
            asociacion:{
                asociacionPostula:"",
                descTipoAsociacion:"",// LOCAL PRIMERA VEZ ETC.
                siglasAsociacion:"",

            },
            candidatura:{
                descTipoCandidatura:"",//DIPUTACION O H AYUNTAMIENTO
            },
            entornoGeografico:{
                distrito:"",
                municipio:"" 
           },
           accionesAfirmativas:{
            id:0,
            descripcion:"",
           },
           //Variables de control
            pagina:'',
            activeDiv1:false,
            activeDiv2:false,
            activeDiv3:false,
            activeDiv4:false,
            activeRows:false,
            dirimagenes:this.$assetsDirname,
            logoProceso:"logo_proceso_electoral_curriculum.png",
            botonConoceles:"boton_conoceles.png",
            activeInfo:false,
            activeImage:false,

            varSession2:true ,

            lista:[],
            
            dirname:this.$assetsDirname,
            max:100, 
            value:75, 
            text:"",
            imagen:'',
            folioRegistro:"",
            //Informacion a obtener desde la coleccion de candidatos
            infoCandidatos:[],
            listaS:[],
            //lista para filtrar suplencias y formulas         
            listaP:[],
            //carpeta:"https://informatica.iem.org.mx/iem/uploads/",
            folioRegistroCandidato:"",
            accionAfirmativa:"",
          
            

            curriculares:{
                social:{
                    facebook:'',
                    twitter:'',
                    youtube:'',
                    instagram:'',
                    tiktok:'',
                },
                contacto:{
                    direccion:'',
                    telefono:'',
                    email:'',
                    web:'',
                    },
                estudio:{
                    grado_maximo:"",
                    estatus:"",
                    otro:""

                },
                profesion:{
                historia_laboral:"",
                trayectoria_politica:"",
                razon_cargo_publico:""
                },
                propuesta:{
                    propuesta1:"",
                    propuesta2:"",
                    propuesta_grupo_discriminacion:""
                },


            },//cierre curriculares
            identidad:{
                indigena:"",
                lengua_indigena:"",
                comunidad_indigena:"",
                discapacidad:"",
                estatus_discapacidad:"",
                tipo_discapacidad:"",
                dificultad_discapacidad:"",
                afromexicana:"",
                diversidad_sexual:"",
                tipo_diversidad_sexual:"",
                migrante:"",
                pais_extranjero:"",
                tiempo_extranjero:"",
                motivo:"",
                joven:"",
                publicacion:"",
            }
        }// cierre return
        //variables ventana modal
            
    },
    props:{
        varSession: Boolean
    },

    components:{
        HeaderClean,
        Footer,
        Loading
    },

    methods:{
        showMsgBoxTwo() {
            
            localStorage.setItem('session',false)
            if(localStorage.getItem('session')==='false'){
                    this.varSession2 = false
                    console.log('desde el metodo',typeof this.varSession2)
                    console.log(this.varSession)
                }     
                   
            this.boxTwo = ''
            const h = this.$createElement
            const titleVNode = h('div', { domProps: { innerHTML: '<h3>¡MUCHAS GRACIAS!<>' } })
            this.$bvModal.msgBoxOk(titleVNode, {
            title: '',
            size: 'sm',
            font:'bold',
            
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.boxTwo = value                    

            })
            .catch(err => {
                console.log(err)
            }) 
        },

        home(){
            this.thispath(`/`)
        },
        editar(id){
            console.log(id)
            this.thispath('/editpartido/' + id)
        },
        nuevo(){
            this.thispath('/newpartido')
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
    // metodos ventanas modales
    

        async getPartidoById(id){
            //let url =`/api/candidato/${id}` // cambiamos URL por la simulada del SNR
            let url =`https://informatica.iem.org.mx/api/conoceles/api/partido/${id}`

            
            try {
                
                const res = await axios.get(url)
                this.lista=res.data[0].lista; 
                 //Informacion Personal del candidato proveniente del SNR, pendiente programar EndPoint
                
                this.id_postulacion=res.data[0].id_postulacion // Se deja id_postulacion por si se ocupa
                //console.log("EVLUAR ID POSTULACION", typeof this.id_postulacion)
                this.id=res.data[0].id // Por so es DRP se toma por id en otro caso diptacion DMR se toma id_postulacion
                this.tipo_eleccion = res.data[0].tipo_eleccion
                this.posicion = res.data[0].posicion
                this.getCandidatosInfo(this.id) 
                //condicional if si es tipo de eleccion 1 o 3 se envia el id_postulacion , si es tipo eleccion 2 se envia id
                if(this.tipo_eleccion === "2"){
                     // Se envia ya sea id o id_postulacion dependiendo el caso
                    this.getCandidatoSuplenciasDRP(this.posicion,this.id_postulacion)
                    //console.log("Por ID candidato RP",this.posicion,this.id, this.id_postulacion)                    

                }else{
                    // si es cualquier tipo eleccin se envia el id_postulacion
                   // Se envia ya sea id o id_postulacion dependiendo el caso
                    this.getCandidatoSById(this.id_postulacion) // de deja id_postulacion porque son las suplencias de DMR   
                    //console.log("Por ID candidato AYUNTAMIENTO Y MR", this.id_postulacion)

                }         
               
                this.cve_mpio = res.data[0].cve_mpio
                this.postulante = res.data[0].postulante,
                this.cve_distrito = res.data[0].cve_distrito
                this.nombre = res.data[0].nombre              
                this.paterno = res.data[0].paterno
                this.materno = res.data[0].materno
                this.genero = res.data[0].genero
                this.sobrenombre = res.data[0].sobrenombre
                this.lugar_nacimiento = res.data[0].lugarNacimiento
                this.ocupacion = res.data[0].ocupacion
                this.aa_tipo = res.data[0].aa_tipo
                this.getTipoAccionAfirmativa(this.aa_tipo)  
                this.partido = res.data[0].partido
                this.edad =res.data[0].edad
                this.tipo_partido=res.data[0].tipo_partido
                this.tipo_postulante = res.data[0].tipo_postulante
                this.partido_nombre = res.data[0].partido_nombre
                this.activacionAfirmativa=res.data[0].aa
                
            

              

              //Evalua que tipo es para mostrarse en la vista
              if(this.tipo_eleccion === "3"){
                    this.cargo ="PRESIDENCIA MUNICIPAL"
                    this. activeRows =false
                }else if( this.tipo_eleccion === "1" ){
                    this.cargo = "DIPUTACIÓN MR"
                    this. activeRows =true
                }else if(this.tipo_eleccion==="2"){
                    this. activeRows2=true
                    this.cargo ="DIPUTACION RP"

                }
                

                //Pasamos la accion a un metodo  
               

            } 
            catch (error) 
            {
                console.log(error.code)
                
            }
        },
        async getCandidatoSById(id){
            const url = `https://informatica.iem.org.mx/api/conoceles/api/diputacionesid/${id}`
            try {
                const resCandidatos = await axios.get(url)
                 

                 this.suplente.nombre=resCandidatos.data[0].nombre
                 this.suplente.paterno = resCandidatos.data[0].paterno
                 this.suplente.materno = resCandidatos.data[0].materno

                
            } catch (error) {
                console.log(error.code)
                
            }
        },
        //SE CREA ESTE METODO PARA OBTENER LAS SUPLENCIAS DE DRP
        getCandidatoSuplenciasDRP(posicionp, id) {
            //await this.$nextTick(); // Esperar hasta que Vue termine de renderizar los cambios
            this.listaSuplenciasDRP.forEach(element => {
                if ((element.posicion === posicionp + 1)&&(element.id_postulacion === id)) {
                    this.suplente.nombre = element.nombre
                    this.suplente.paterno = element.paterno
                    this.suplente.materno = element.materno
                }
            })
        },
        //obtener la formula
        getFormuladrp(posicion){
        const formula = this.formulasdrp[posicion]
        return formula
    },

        async getCandidatosInfo(folio)
        {
            //console.log(typeof folio)
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidatofrc/${folio}`
            try {
                
                 const info = await axios.get(url)
                 this.infoCandidatos= info.data[0].infoCandidatos
                 //this.folioRegistroCandidato=info.data[0].folioRegistroCandidato
                 this.infoCandidatos= info.data[0].infoCandidatos
                 this.id_postulacion=info.data[0].id_postulacion
                 this.idsc=info.data[0].idsc
                 this.imagen = info.data[0].imagen

                 this.curriculares.contacto.web =info.data[0].curriculares.contacto.web
                 this.curriculares.contacto.email =info.data[0].curriculares.contacto.email
                 this.curriculares.contacto.direccion =info.data[0].curriculares.contacto.direccion
                 this.curriculares.contacto.telefono =info.data[0].curriculares.contacto.telefono

                 this.curriculares.social.facebook = info.data[0].curriculares.social.facebook
                 this.curriculares.social.twitter = info.data[0].curriculares.social.twitter
                 this.curriculares.social.youtube = info.data[0].curriculares.social.youtube
                 this.curriculares.social.instagram = info.data[0].curriculares.social.instagram
                 this.curriculares.social.tiktok = info.data[0].curriculares.social.tiktok

                 this.curriculares.profesion.razon_cargo_publico = info.data[0].curriculares.profesion.razon_cargo_publico
                 this.curriculares.profesion.historia_laboral= info.data[0].curriculares.profesion.historia_laboral
                this.curriculares.propuesta.propuesta1 = info.data[0].curriculares.propuesta.propuesta1                
                this.curriculares.propuesta.propuesta2 = info.data[0].curriculares.propuesta.propuesta2
                this.curriculares.propuesta.propuesta_grupo_discriminacion = info.data[0].curriculares.propuesta.propuesta_grupo_discriminacion
                this.curriculares.profesion.trayectoria_politica = info.data[0].curriculares.profesion.trayectoria_politica

                this.curriculares.estudio.grado_maximo = info.data[0].curriculares.estudio.grado_maximo
                this.curriculares.estudio.estatus = info.data[0].curriculares.estudio.estatus
                this.curriculares.estudio.otro = info.data[0].curriculares.estudio.otro
                
                //Obtencion de respuestas delos cuestionarios de identidad correspondientes a personas con discapacidad
                this.identidad.discapacidad = info.data[0].identidad.discapacidad
                this.identidad.estatus_discapacidad = info.data[0].identidad.estatus_discapacidad
                this.identidad.tipo_discapacidad = info.data[0].identidad.tipo_discapacidad
                this.identidad.dificultad_discapacidad = info.data[0].identidad.dificultad_discapacidad

                //Obtencion de informacion de los cuestionarios de Identidad
                this.identidad.diversidad_sexual = info.data[0].identidad.diversidad_sexual
                this.identidad.tipo_diversidad_sexual = info.data[0].identidad.tipo_diversidad_sexual

                //console.log("Desde Coleccion Candidatos: ",this.folioRegistroCandidato)
                this.identidad.indigena = info.data[0].identidad.indigena
                this.identidad.lengua_indigena = info.data[0].identidad.lengua_indigena
                this.identidad.comunidad_indigena = info.data[0].identidad.comunidad_indigena
                
                //Obtencion de respuesas de los cuestionarios de identidad correspondientes a si es persona migrante
                this.identidad.migrante = info.data[0].identidad.migrante
                this.identidad.pais_extranjero = info.data[0].identidad.pais_extranjero
                this.identidad.tiempo_extranjero = info.data[0].identidad.tiempo_extranjero
                this.identidad.motivo = info.data[0].identidad.motivo
                this.identidad.situacion_asegurada = info.data[0].identidad.situacion_asegurada
               
                //Declaraciones
                this.identidad.socioeconomico = info.data[0].identidad.socioeconomico
                this.identidad.ingresos = info.data[0].identidad.ingresos
                
                //Variables de validacion
                 //Obtencion variable de publicacion de la informacion
                 this.identidad.publicacion = info.data[0].identidad.publicacion
                 this.validacionCPyPP = info.data[0].validacionCPyPP
                 
                 

                 this.publicarInformacion(this.identidad.publicacion,this.validacionCPyPP )

                
            } catch (error) {
                console.log(error)
                
            }  
        },
        getTipoAccionAfirmativa(tipoAccion){

            if(tipoAccion === 1){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS CON DISCAPACIDAD"
                //this.activeDiv1=true
            }
            else if(tipoAccion === 2){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS LGBTIAQ+"
                //this.activeDiv2=true

            }
            else if(tipoAccion === 3){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS INDÍGENAS"
                //this.activeDiv3=true
            }
            else if(tipoAccion === 4){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS MIGRANTES"
                //this.activeDiv4=true
            }
            else(
                this.activeDiv=false
            )
        },
        
        publicarInformacion(publicarinfo, validacioncpp){
            if((validacioncpp === true)&& (this.activacionAfirmativa === 1)){

                this.activeInfo=true
                this.activeImage=true
                if(this.aa_tipo===1){
                    this.activeDiv1=true

                }else if(this.aa_tipo===2){
                    this.activeDiv2=true

                }else if(this.aa_tipo===3){
                    this.activeDiv3=true
                }
                else if(this.aa_tipo===4){
                    this.activeDiv4=true
                }            
     
            }
            else if(validacioncpp=== true & publicarinfo ===true){
                this.activeInfo=true                
                this.activeImage=true

                if(this.identidad.discapacidad==="Sí"){
                    this.activeDiv1=true

                }else if(this.identidad.diversidad_sexual ==="Sí"){
                    this.activeDiv2=true

                }else if(this.identidad.indigena ==="Sí"){
                    this.activeDiv3=true

                }else if(this.identidad.migrante==="Sí"){
                    this.activeDiv4=true
                }
            }
            else if(validacioncpp ===true && publicarinfo===false){
                this.activeInfo=true
                this.activeImage=true
                this.activeDiv1=false
                this.activeDiv2=false
                this.activeDiv3=false
                this.activeDiv4=false
                
                /*

                this.curriculares.contacto.web = ""
                this.curriculares.contacto.email =[]
                this.curriculares.contacto.direccion =[]
                this.curriculares.contacto.telefono =[]

                this.curriculares.social.facebook = ""
                this.curriculares.social.twitter = ""
                this.curriculares.social.youtube = ""
                this.curriculares.social.instagram = ""
                this.curriculares.social.tiktok = ""
                this.curriculares.estudio.grado_maximo="La candidatura no proporcionó información"
                this.curriculares.estudio.otro =["La candidatura no proporcionó información"]
                this.curriculares.profesion.historia_laboral="La candidatura no proporcionó información"
                this.identidad.socioeconomico = "La candidatura no proporcionó información"
                this.identidad.ingresos = "La candidatura no proporcionó información"
                this.curriculares.profesion.razon_cargo_publico = "La candidatura no proporcionó información"
                 this.curriculares.profesion.historia_laboral= "La candidatura no proporcionó información"
                this.curriculares.propuesta.propuesta1 = "La candidatura no proporcionó información"               
                this.curriculares.propuesta.propuesta2 = "La candidatura no proporcionó información"
                this.curriculares.propuesta.propuesta_grupo_discriminacion = "La candidatura no proporcionó información"
                this.curriculares.profesion.trayectoria_politica = "La candidatura no proporcionó información"

                this.curriculares.estudio.grado_maximo = "La candidatura no proporcionó información"
                this.curriculares.estudio.estatus = "La candidatura no proporcionó información"
                */
                
            }
        },

        downloadCV1(scaleP){
            
           // this.isLoading=true
            const element = document.getElementById('elementpdf')
            //const element =this.$refs.elementpdf;       
            var options ={
                filename: 'CV CANDIDATOS(A).pdf',
                pageOptions:{
                    format:'A6',
                    scale: scaleP / 100
                }
             
            } 
                  
            domToPdf(element,options)
           // this.isLoading = false        
        },
        downloadCV2(){
            const element =this.$refs.elementpdf;

                html2canvas(element).then(canvas=>{
                    const pdf = new jsPDF();

                    const imgData = canvas.toDataURL('image/png');

                    const imgWidth =210;
                    const imgHeight = canvas.height * imgWidth / canvas.width;
                    pdf.addImage(imgData,'PNG',0,0,imgWidth, imgHeight);
                    pdf.save("CV CANDIDATOS(A).pdf")
                })
            },       
        
        
 
  
        advertencia(link){           
            //this.$alert('','Estas apunto de abandonar el Sitio "Conóceles" de Instituto Electoral de Michoacán ¿Deseas Continuar? ','warning', {showConfirmButton: true, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)',position:'center-end', width: 200, timer:500});
            const message = 'Estas apunto de abandonar el Sitio "Conóceles" de Instituto Electoral de Michoacán ¿Deseas Continuar?'
            const response =window.confirm(message)
            if(response)
            {
                if(this.curriculares.social.facebook=== link){
                    window.location.href = this.curriculares.social.facebook
                }else if(this.curriculares.social.twitter=== link){
                    window.location.href = this.curriculares.social.twitter
                }else if(this.curriculares.social.youtube=== link){
                    window.location.href = this.curriculares.social.youtube
                }else if(this.curriculares.social.instagram=== link){
                    window.location.href = this.curriculares.social.instagram
                }else if(this.curriculares.social.tiktok=== link){
                    window.location.href = this.curriculares.social.tiktok
                }else if(this.curriculares.contacto.web=== link){
                    window.location.href = this.curriculares.contacto.web
                }else{
                    window.location.href = `https://conoceles.iem.org.mx/Curriculum/${this.idsc}`
                }                
                
            }


        },
        getLogoPartidos(partido,tipo_postulante,postulante){
              //inicializamos la lista de logos        
        const jsonString1 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString2 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"mpv-13.png","5":"mpv-13.png","6":"pmc-6.png","7":"mpv-13.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png","12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"paracho5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const jsonString3 = '{"1":"pan-pri-prd.png","2":"pan-pri-prd.png","3":"pan-pri-prd.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString4 ='{"12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"logo.paracho.5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const objt1 = JSON.parse(jsonString1)
        const objt2 = JSON.parse(jsonString2)
        const objt3 = JSON.parse(jsonString3)

        const objt4 = JSON.parse(jsonString4)

        if(tipo_postulante==="1"){
            const logo  = objt1[partido];
        return logo
        }
        if(tipo_postulante==="2"){
            const logo  = objt2[partido];
        return logo
        }
        if(tipo_postulante==="3"){
            if(postulante === "PAN-PRI-PRD"){
                const logo  = objt3[partido];
                return logo   
            }else if(postulante=="PAN-PRI"){
                const logo  = "pan-pri.png";
                return logo  

            }else if(postulante=="PRI-PRD"){
                const logo  = "pri-prd.png";
                return logo
            }else if(postulante=="PAN-PRD"){
                const logo  = "pan-prd.png";
                return logo
            }else if(postulante=="PRD-PESM"){
                const logo  = "prd-pesm.png";
                return logo
            }else if(postulante=="PT-MORENA"){
                const logo  = "pt-morena.png";
                return logo
            }else if(postulante=="PT-PESM"){
                const logo  = "pt-pesm.png";
                return logo
            }else if(postulante=="PVEM-MORENA"){
                const logo  = "pvem-morena.png";
                return logo
            }else if(postulante=="PT-PVEM"){
                const logo ="pt-pvem.png";
                return logo
            }
        }
        if(tipo_postulante==="4"){
            const logo = objt4[partido]
            return logo
        }


    },


    },

    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        
        const res1 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos`)
        this.listaP=res1.data;
        this.listaSuplenciasDRP = this.listaP.filter(element => element.posicion % 2 === 0)
         //console.log("LISTA FILTRADA DE LAS SUPLENCIAS",this.listaSuplenciasDRP)

        this.getPartidoById(this.$route.params.id);
        //se inicializa la lista de suplencias       

     
        
        //this.getCandidatosInfo(this.id_postulacion)

        if(!localStorage.getItem('session')){            
            localStorage.setItem('session',true)            
        
        }else{
            if(localStorage.getItem('session')==='false'){
                    this.varSession2= false
                    //console.log('desde la inicializacion',typeof this.varSession2)
                    //console.log(this.varSession)

                }
            }       
        },//termina created
        mounted() {
        
        // Cargar el script de Google Tag Manager
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YM7ZLSHPGR';
            document.head.appendChild(script);
    
            // Configurar el dataLayer
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YM7ZLSHPGR');
            },
    
            
    
}
</script>

<style scoped>
/* Grande */
  .center{
    text-align: left;    
    
  }
  .marginb{
    margin-bottom: 0px;
   
  }
  .marginr{
    margin-right: 20px;
    
  }
  .center-row{
    text-align: left;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    
  }
  .center-column{
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .altura{
    max-height: 380px;
    background-color: #FFFFFF;
    /*width: 100%;*/
  }
/**Contenedor foto de perfil candidato */
  .portafolio{
    background: rgb(192,192,192);
    background: linear-gradient(90deg, rgba(192,192,192,1) 0%, rgba(210,210,210,1) 21%, rgba(227,227,227,1) 60%, rgba(244,244,244,1) 100%);
    border-radius: 50px;
    width: 340px;
    height: 500px;
    justify-content: center;
    text-align: center;
    
    border: 1px solid #f4f4f4   ;
    padding-bottom: 40px;
    margin: -253px auto 30px auto
  }
  .portafolio-circle{
    background: white;  
    border-radius: 30px;
    width: 300px;
    height: 450px;    
    justify-content: center;
    text-align: center;    
    margin: 20px;
    display:flex;
    
    /*border-image: linear-gradient(to right, #b6b8b8 0%, #f9f9fa 100%) 1;*/
   
    
  }
  .portafolio-circle-interno{
    background: white;  
    border-radius: 50px;
    width:272px;
    height: 400px;
    justify-content: center;
    text-align: center;
    margin: 14px;
    
   
    
  }
  .center-double-row{
    text-align: left;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

/* Pequeño */
@media (max-width: 991px) {
  .marginb{
    margin-bottom: 20px;
  }
  .marginr{
    margin-right: 0px;
  }
  .center-double-row{
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .center{
    text-align: center;
    justify-content: center;
  }
  .center-row{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .center-column{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .altura{
    max-height: fit-content;
    background-color: #a2a3c9;
  }
  .portafolio{
    background-color: white;
    width: 340px;
    justify-content: center;
    text-align: center;
    border-radius: 50px;
    border: 3px solid #f4f4f4;
    padding-bottom: 40px;
    margin: 30px auto 30px auto
  }
}
.center-row-p{
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.left{
    text-align: left;
}
.right{
    text-align: right;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #e45c87;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e45c87;
    --bs-btn-border-color: #e45c87;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}
.titles {
  font-family: GalanoRegular;
  font-size: 22px;
  background: rgb(178,0,102);
  background: linear-gradient(0deg, rgba(190 ,0,102,1) 7%, rgba(210,164,185,1) 100%);
  border-radius: 0 50px 50px 0;
  text-align: left;
  padding: 5px 30px 5px 20px;
  width: fit-content;
  border-top: 1px solid  #7e1578;
  border-bottom: 3px solid  #f25fb9  ;
  color: white;
  margin: 20px;
}
.titles2 {
  font-family: GalanoRegular;
  font-size: 22px;
  background: rgb(178,0,102);
  background: linear-gradient(0deg, rgba(190 ,0,102,1) 7%, rgba(210,164,185,1) 100%);
  border-radius: 90px;
  text-align: center;
  
  width: fit-content;
  border-top: 1px solid  #7e1578;
  border-bottom: 3px solid  #f25fb9  ;
  color: white;
  margin: 20px;
  
}
.titles-secundario {
  font-family: GalanoRegular;
  font-size: 22px;
  color: #9b9b9a;
  background: rgb(222,223,227);
  background: linear-gradient(180deg, rgba(222,223,227,1) 1%, rgba(255,255,255,1) 46%, rgba(255,255,255,1) 100%);
  border-radius: 0 50px 50px 0;
  text-align: left;
  padding: 5px 30px 5px 20px;
  width: 380px;
  box-shadow: 13px 11px 15px -5px rgba(183,183,185,1);
-webkit-box-shadow: 13px 11px 15px -5px rgba(183,183,185,1);
-moz-box-shadow: 13px 11px 15px -5px rgba(183,183,185,1);
}
.titles-2 {
  font-family: GalanoRegular;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 5px;
  width:150px;
  color: #9b9b9a;
 
  
}
.titles-2d {
  font-family: GalanoRegular;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 5px;
  width:180px;
  color: #9b9b9a;
 
  
}
.titlesLarge{
font-family: GalanoRegular;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 5px;
  width:600px;
  color: #9b9b9a;

}
.titlesMedium{
font-family: GalanoRegular;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 5px;
  width:400px;
  color: #9b9b9a;
}
.titlesSmall{
font-family: GalanoRegular;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 5px;
  width:300px;
  color: #9b9b9a;

}
.titles-2-content {
    font-family: GalanoRegular;
    font-size: 20px;
    width: 100%;
    margin: 0 0 0 10px;
    padding: 5px;
    background-color: #e1e2ee;
    border-radius: 10px;
}
.titles-2-content-row-heading {
    font-family: GalanoRegular;
    font-size: 20px;
    width: 100%;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    background-color: #e1e2ee;
    border-radius: 10px 10px 0 0;
    text-align: center;
    color: #B20066;
}
.titles-2-content-row-body {
    font-family: GalanoRegular;
    font-size: 20px;
    width: 100%;
    padding: 5px;
    background-color: #e1e2ee;
    border-radius: 0 0 10px 10px;
    text-align: justify;
}
.titles-content {
    font-family: GalanoRegular;
    font-size: 20px;
    width: 100%;
    padding: 5px;
    background-color: #e1e2ee;
    border-radius: 10px;
    text-align: justify;
}
.titles-2-1c {
    font-family: GalanoRegular;
    font-size: 20px;
    font-weight: bold;
    margin: 0 14px 0 5px;
    width:180px;
    color: #9b9b9a;
}
.titles-2-2c {
    font-family: GalanoRegular;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 0 5px;
    width:120px;
    color: #6a6ea8;
}
.titles-2-body {
    font-family: GalanoRegular;
    font-size: 20px;
    width: 100%;
    margin: 0;
    padding: 5px;
    background-color: #e1e2ee;
    border-radius: 10px;
}
.name {
    font-family: GalanoBold;
    font-size: 40px;
    font-weight: bold;
    color: #B20066  ;
    margin-bottom: -10px;
}
.last-name {
    font-family: GalanoRegular;
    font-size: 40px;
    color: #b6b6b6 ;
    margin-bottom: -20px;
}
.light {
    font-family: GalanoRegular;
    font-size: 20px;
    color: #B20066;
    margin-top: -5px;
    font-weight: bolder;
}
.light2 {
    font-family: GalanoRegular;
    font-size: 20px;
    color: #B20066;
    margin-top: -5px;
    font-weight: bolder;   
   
}
.heading {
    padding: 10px 5% 10px 5%;
    font-family: GalanoRegular;
    background: rgb(223,223,223);
    background: linear-gradient(180deg, rgba(223,223,223,1) 0%, rgba(255,255,255,1) 46%, rgba(255,255,255,1) 100%);
    color: #B20066;
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -webkit-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -moz-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
}
.portafolio-content {
  font-family: GalanoRegular;
  font-weight: bold;
  color: #9b9b9a;
  overflow-wrap: break-word;
  padding: 0 10px 0px 10px; 
  font-size: larger;
}
.portafolio-titles {
  font-family: GalanoRegular;
  font-weight: bold;
  color: #B20066;
  overflow-wrap: break-word;
  padding: 0 10px 0px 10px;  
  font-size: larger;
}
.row-flex {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.dot-icon {
    font-size: 8px;
    color: #e55d89;
    margin-left: -5px;
    
}
.brand-icon {
    font-size: 35px;
    margin: 5px;
    color: #B20066;
    padding: 4%;
   
}
.party-icon {
    width: 80px;
    object-fit: contain;
    margin: 10px;
}
.profile-icon {
    width: 300px;
     
}
.custom-border {
    border-left: 3px solid;
    border-color: #B20066;
}
.custom-border2{
    border-left:3px  solid #e55d89;  
}
hr {
    border-top: 3px solid #B20066;
}
titles-margin{
    margin-left:15px;
}
.size-logos{
    height: 100px;
    border-radius: 4px;
}



</style>