import { render, staticRenderFns } from "./Identidad4.vue?vue&type=template&id=b381ac1a&scoped=true"
import script from "./Identidad4.vue?vue&type=script&lang=js"
export * from "./Identidad4.vue?vue&type=script&lang=js"
import style0 from "../assets/styles/identidad.css?vue&type=style&index=0&id=b381ac1a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b381ac1a",
  null
  
)

export default component.exports