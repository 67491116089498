<template>
    <div>
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container">
            <div id="first">
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=4>
                    <span>4 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div>
            <div class="row questionary-row">
                <div class="questionary-container">
                <p class="question ">Identidad indígena</p>
                <div style="margin-top: 5%; margin-bottom: 5%;" >
                    <label class="multiple-choice">¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?</label>
                    <div class="d-flex flex-column align-items-end mb-3" >
                        <div class="radio-tile-group">
                            <div class="input-container">
                                <input class="radio-button" type="radio" value="Sí" v-model="identidad.indigena" />
                                <div class="radio-tile"  >
                                    Sí
                                </div>
                            </div>

                            <div class="input-container">
                                <input class="radio-button" type="radio" value="No" v-model="identidad.indigena" v-if="!accionAfirmativa" :disabled="disable" readonly />
                                <div class="radio-tile"  >
                                    No
                                </div>
                            </div>

                            <div class="input-container">
                                <input class="radio-button" type="radio" value="SR" v-model="identidad.indigena" v-if="!accionAfirmativa" :disabled="disable" readonly>
                                <div class="radio-tile" >
                                    Prefiero no contestar
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <p v-show="accionAfirmativa">*Respuesta predeterminada para accion afirmativa para esta candidatura "{{identidad.indigena}}"</p>
                <div class="d-flex flex-row">
                    <button style="margin-right: 1%;" type="button" :disabled="disable" class="btn btn-secondary" v-on:click="atras()">Atras&emsp;<fa icon="arrow-left"/>&emsp;</button>
                    <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button>
                </div>
                <!--aviso de privacidad-->
                <div class="container mt-5 text-center">                  
                        <b-modal v-model="showAviso" id="modal-scrollable" scrollable title="Aviso de Privacidad de Datos personales" @click.stop="">                          
                            <template #modal-header>
                                <div class="d-flex flex-row">
                                    <b-button size="sm" variant="outline-danger" @click="cerrarAviso()">Cerrar</b-button>
                                </div>                       
                            </template>                            
                            <template #default>                                
                                <div class="d-flex flex-row mb-2">
                                    <h6 class="text-center">AVISO DE PRIVACIDAD SIMPLIFICADO CUESTIONARIO CURRICULAR EN EL SISTEMA “CANDIDATAS Y CANDIDATOS, CONÓCELES” PARA EL PROCESO ELECTORAL ORDINARIO LOCAL 2023-2024.</h6>                                    
                                </div>
                                <div container>
                                    <hr>
                                    <div class="parrafo1 text-justify">
                                        <p>
                                            El Instituto Electoral de Michoacán “IEM”, con domicilio en la calle Bruselas No. 118, Col. Villa
                                            Universidad, C.P. 58060, de la Ciudad de Morelia, Michoacán; será la autoridad responsable de
                                            recabar y tratar sus datos personales, los cuales serán protegidos conforme a lo dispuesto por la
                                            Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de
                                            Michoacán de Ocampo y demás normatividad aplicable en la materia
                                        </p>
                                    </div>
                                    <div class="parrafo2">
                                        <p>
                                            Los datos personales que se recaben para el llenado del <strong> Cuestionario de identidad en el sistema
                                            “Candidatas y Candidatos, Conóceles” para el Proceso Electoral Ordinario Local 2023-2024</strong>
                                            se utilizarán con la siguiente finalidad:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Facilitar a la ciudadanía el acceso a la información de las personas candaditas que
                                                    participan a puestos de elección popular dentro del Proceso Electoral Ordinario Local 2023-
                                                    2024.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Maximizar la transparencia en la difusión de las candidaturas, la participación de la
                                                    población y el voto informado y razonado, a efecto de optimizar la toma de decisiones de la ciudadanía.         
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Generar información estadística respecto de los grupos en situación de discriminación o de 
                                                    atención prioritaria en los que se sitúan las personas candidatas.

                                                </p>

                                            </li>
                                        </ul>                                       
                                    </div>
                                    <div class="parrafo3">
                                        <p><strong>¿Qué datos personales se les solicitan?</strong></p>
                                        <p><strong>Los datos personales objeto de tratamiento son:</strong></p>
                                        <ul>
                                            <li><p>Datos patrimoniales (ingresos y fuente principal de ingresos)</p></li>
                                            <li><p>Rubro socioeconómico (Datos sobre la condición socioeconómica de las y los candidatos)</p></li>                                        
                                        </ul>                                       
                                    </div>
                                    <div class="parrafo4">
                                        <p><strong>¿Qué datos personales sensibles se les solicitan?</strong></p>
                                        <p>Los PP y personas candidatas independientes deberán capturar en el Sistema la autoadscripción con la que se identifican siendo las siguientes: </p>
                                        <ul>
                                            <li><p>Persona indígena (identidad, habla o entiende alguna lengua indígena, pueblo o comunidad indígena a la que pertenece)</p></li>
                                            <li><p>Persona con discapacidad (Si vive con algún tipo de discapacidad)</p></li>
                                            <li><p>Persona afromexicana (Desciende de personas africanas y que tienen nacionalidad mexicana)</p></li>
                                            <li><p>Perteneciente a la comunidad LGBTTTIQ+ </p></li>  
                                            <li><p>Datos patrimoniales (ingresos y fuente principal de ingresos)</p></li>
                                            <li><p>Persona Migrante (país de residencia, tiempo residiendo en el extranjero, motivo para migrar, situación laboral y/o educativa en el extranjero)</p></li>                                       
                                        </ul>
                                        <p>
                                            No obstante, y por tratarse de información que reviste un interés social imperativo, tal y como lo dispuso el INAI mediante la Resolución RRA 10703/21, estos datos se considerarán públicos. 

                                        </p>                                      
                                    </div>
                                    <div class="parrafo5">
                                        <p><strong>¿Dónde puede consultar el aviso de privacidad integral? </strong></p>
                                        <p>Para conocer y tener mayor información sobre los términos y condiciones en que serán tratados sus datos personales, y la forma en que podrá ejercer sus derechos ARCO, puede consultar el
                                            aviso de privacidad integral en: 
                                        </p>
                                        <a href="https://www.iem.org.mx/index.php/publicaciones/28-transparencia-y-acceso/653-aviso-de-privacidad" target="_blank">http://www.iem.org.mx/index.php/publicaciones/28-transparencia-y-acceso/653-aviso-deprivacidad"</a>                                        
                                    </div>
                                </div>
                            </template>
                            <template #modal-footer >                    
                                <button type="button" class="btn btn-custom" @click="enviarAviso()">
                                    Aceptar
                                </button>                   
                            </template>

                        </b-modal>                    
                    </div>
                <!--termina aviso -->
            </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'

export default{
    name:"Identidad1",
    props:{  
    paginaTmp:{
    type:Number,
    default:20
    }
    },
    data(){
        return{
            routes:routes,
            isLoading: true,
            disable:false,
            idCandidato:null,
            config:{},
            username:"",
            dirname:this.$assetsDirname, max:24,
            identidad:{indigena:""},
            pagina:"",
            imagen:"",
             //Se crean variables de control y visibilidad
            aviso2:false,
            showAviso: false,

            
            id_postulacion:"", // proveniente desde candidatos
            //Cambiar variables dependiendo del sisif
            folioRegistro:'', // proveniente desde partidos            
            //se declara una Lista Partidos para obtener datos provenientes de SISIF y utlizarlos en este componente
            listaP:[],
            accionesAfirmativas:0,
            accionAfirmativa:false,
            accionAActivada:0,
            disabled:false,
            estadoRoC :"",
            validacionCaptura:false,
            logsAcciones:[],
            dataLogs : logs,
            loggedIn: this.$route.params.loggedIn

        }
    },
    components:{
        Header,
        Footer,
        Loading
    },
    methods:{
        async atras(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-1)
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        async enviarAviso(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            //metodo para asignar validacion de partido
            this.aviso2 = true               
            const res = await axios.patch(url, { idCandidato:this.idCandidato,aviso2:this.aviso2}, this.config)
                if(res.data.result = 1){
                    this.$alert('Aceptado','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'center', width: 200, timer: 1000})
                    this.showAviso=false;                  
                    this.disable = false;
                    //integrar cierre de ventana y regresar a la lista de candidatos                                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            },
        cerrarAviso(){
           
            this.$alert('','<label class="infolabel">Debe aceptar el aviso de privacidad para continuar</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            this.showAviso=true;

        },
        async save(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            //If de evaluacion si es correccion de respuesta o respuesta de primera vez
            if(this.paginat > 0)
            {
                if(this.identidad.indigena !== ""){
                this.estadoRoC ="CAPTURADO"
                this.validacionCaptura=true
                const res = await axios.patch(url, { identidad:this.identidad , idCandidato:this.idCandidato, pagina:this.pagina+(25-this.paginat),paginat:0,estadoRoC:this.estadoRoC,validacionCaptura:this.validacionCaptura}, this.config)
                if(res.data.result = 1){
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(9)
                    this.disable = false
                    this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                    return
                }

                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                this.disable=false
            }
                else{
                    this.$alert('','<label class="alertlabel">Campo de seleccion obligatorio</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.disable=false
                }

            }else
            {
                if(this.identidad.indigena !== ""){
                const res = await axios.patch(url, { identidad:this.identidad , idCandidato:this.idCandidato, pagina:this.pagina+1 }, this.config)
                if(res.data.result = 1){
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(8)
                    this.disable = false                   
                    this.path(this.pagina+1) // siguiente pagina
                    return
                }

                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path(this.pagina+1) // siguiente pagina
                this.disable=false
                }
                else{
                    this.$alert('','<label class="alertlabel">Campo de seleccion obligatorio</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.disable=false
                }

            }
            


        },
        async logsCandidato(clave){
            let now= new Date();
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            try{
                const log  = this.dataLogs[clave];
                console.log(log)
                this.logsAcciones.push(log+" : "+now)            
                await axios.patch(url, { idCandidato:this.idCandidato,logsAcciones:this.logsAcciones }, this.config)
                

            }catch(error){
                console.error(error)
            }
    }

    }, // termina funcion asincronica save
    
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        const url2='https://informatica.iem.org.mx/api/conoceles/api/partidos'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato
            this.config = {
                headers:{
                    Authorization: `Bearer ${token}` 
                }
            }
            const result = await axios.get(url, this.config)
            this.pagina = result.data[0].pagina
            // Varible bandera para control de modificacion de respuestas si es mayor a 0, se guarda variable temporal
            this.paginat = result.data[0].paginat           
            this.path(this.pagina)

            this.identidad.indigena = result.data[0].identidad.indigena
            this.imagen = result.data[0].imagen
            //Cargamos la avriable aviso2 para proceder a mostrarle el aviso de identidad al candidato
            this.aviso2= result.data[0].aviso2

            //evaluacion para inicializacion de la variable showAviso
            if(this.aviso2=== false ){
                this.showAviso= true
            }
            //obteniendo los folios y comparando sisif vs folio registros de candidato
            this.id_postulacion = result.data[0].id_postulacion        
           
            console.log(this.identidad.indigena)
            //Obtenemos datos desde BASE SISIF para saber si es por accion afirmativa
            const result2 = await axios.get(url2, this.config)
            this.listaP = result2.data
            
            this.listaP.forEach(element =>{
                if(element.id_postulacion === this.id_postulacion ){
                    this.accionesAfirmativas = element.aa_tipo
                    this.accionAActivada= element.aa
                  
                    console.log( this.accionesAfirmativas)
                    console.log(this.accionAActivada)
                }
            })

            if(this.accionesAfirmativas === 3 && this.accionAActivada=== 1){
                // si es por accion la publicacion es automatica si no es decision del candidato que se publique o no
                this.accionAfirmativa = true
                this.identidad.indigena = "Sí"
                console.log(this.identidad.indigena)
            }
            else{
                 this.accionAfirmativa = false                      
            }

            
            //Registro acciones usuario
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }
            

            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped src="../assets/styles/identidad.css">

</style>