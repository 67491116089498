<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>  
        <h4>Del total de las candidaturas que respondieron al cuestionario de identidad, se identifican como personas de la población LGBTIAQ+:</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
      <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie>
    </div>

  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-12 mt-3 pb-2">
        <h4>De las {{ tTipoDiversidad4 }}  (100%) candidaturas que respondieron el cuestionario de identidad, {{tTipoDiversidad2}} ({{ ptipDiv2 }}%) se identificaron como personas de la población LGBTIAQ+,
        {{tTipoDiversidad3}} ({{ ptipDiv3 }}%) prefirió no responder el cuestionario</h4>
    </div>
  </div>    
  </div> 
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaTipoDiversidad',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {       

        labels: ['Hombre gay', 'Mujer lesbiana','Persona bisexual',' Mujer trans','Hombre trans','Persona intersexual','Persona no binaria','Persona queer','Otro','Prefiero no contestar'],
        datasets: [ 
            {
            label: 'Tipo Diversidad Sexual',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#6161A9','#AA0DFC','#5B0DFC','#0D58FC','#F80DFC','#FC180D','#FC0D74','#AA0DFC','#FC420D','#2A7BA9']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        },

        legend:{
          display:true,
          position:'bottom',
          text:"De los candidados.."
        },
        title:{
          display:true,
          text: "Distribucion de datos"
        },
        
      },
      //Variables para creacion de los datos
      lista :[], 

      //Contadores por tipo de diversidad
      ctipDiv1:0, ctipDiv2:0, ctipDiv3:0, ctipDiv4:0, ctipDiv5:0, ctipDiv6:0, ctipDiv7:0, ctipDiv8:0, ctipDiv9:0, ctipDiv10:0, tTipoDiversidad:0, tTipoDiversidad2:0, tTipoDiversidad3:0,tTipoDiversidad4:0,
      ptipDiv1:0,
      ptipDiv2:0,
      ptipDiv3:0,
      validacionCPyPPC:0,

      
    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const res = await axios.get(url)
        this.lista = res.data
      
        //contadores por tipo de diversidad
        let countt1=0,countt2=0, countt3=0, countt4=0, countt5=0,countt6=0,countt7=0,countt8=0,countt9=0, countt10=0
        
      //Clasificacion por tipo de identidad         
     this.lista.forEach(element =>{
      if(element.validacionCPyPP===true){
        this.validacionCPyPPC=this.validacionCPyPPC + 1
        if(element.identidad.tipo_diversidad_sexual === "Hombre gay")
                {
                   countt1=countt1+1
                   //si es afirmativo llama el metodo contar por tipo de diversidad
                }
            else if(element.identidad.tipo_diversidad_sexual === "Mujer lesbiana")
            {
              countt2=countt2+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Persona bisexual")
            {
              countt3=countt3+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Mujer trans")
            {
              countt4=countt4+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Hombre trans")
            {
              countt5=countt5+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Persona intersexual")
            {
              countt6=countt6+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Persona no binaria")
            {
              countt7=countt7+1

            }
            else if(element.identidad.tipo_diversidad_sexual === "Persona queer")
            {
              countt8=countt8+1

            }
            else if(element.identidad.tipo_diversidad_sexual !==undefined)
            {
              countt9=countt9+1
            }
            else{
              countt10 = countt10 +1
            }

      }
        
  
            
      })
      this.tTipoDiversidad4=countt1+countt2+countt3+countt4+countt5+countt6+countt7+countt8+countt9+countt10
      this.tTipoDiversidad = countt1+countt2+countt3+countt4+countt5+countt6+countt7+countt8
      this.tTipoDiversidad2 = countt9+this.tTipoDiversidad
      this.tTipoDiversidad3 = countt10

      this.ptipDiv1 = ((this.tTipoDiversidad * 100)/this.tTipoDiversidad4).toFixed(2)
      this.ptipDiv2 = ((this.tTipoDiversidad2 * 100) / this.tTipoDiversidad4).toFixed(2)
      this.ptipDiv3= ((this.tTipoDiversidad3 * 100) / this.tTipoDiversidad4).toFixed(2)




     this.chartData.datasets[0].data[0] = countt1
     this.chartData.datasets[0].data[1] = countt2
     this.chartData.datasets[0].data[2] = countt3
     this.chartData.datasets[0].data[3] = countt4
     this.chartData.datasets[0].data[4] = countt5
     this.chartData.datasets[0].data[5] = countt6
     this.chartData.datasets[0].data[6] = countt7
     this.chartData.datasets[0].data[7] = countt8
     this.chartData.datasets[0].data[8] = countt9
     this.chartData.datasets[0].data[9] = countt10  

 
   

     this.loaded = true

    }
    
}
</script>


<style>
</style>