<template>
    <div class="container-fluid">
        <HeaderPanel :nombre_completo="nombre_completo" :tipoRol ="tipoRol"/>  <!--nombr de usuario  que realiza la revision-->
        <div class="row bg-iem">
        <div class="container-fluid p-0 d-flex h-100">             
            <div id="bdSidebar" class="d-flex flex-column flex-shrink-0 p-5 pt-4 bg-secondary text-white offcanvas-md offcanvas-start"> 
                <a href="#" class="navbar-brand"><strong>PANEL DE REVISION Y/O CAPTURA DE DATOS</strong></a>
                <hr> 
                <ul class="mynav nav nav-pills flex-column mb-auto"> 
                    <li class="nav-item mb-1"> 
                        <a href="#medios"> 
                            <fa icon="fa-solid fa-user"/>
                            Lista de Personas candidatas
                        </a> 
                    </li>   
                    
                </ul> 
                <hr>
                <ul class="mynav nav nav-pills flex-column mb-auto">                      
                    <li class="nav-item mb-1"> 
                        <a href="#profesional" class="navbar-brand"  v-on:click="sessionClose()" > 
                            <fa icon="fa-solid fa-person-walking-dashed-line-arrow-right"/>&nbsp;&nbsp;                                        
                                Cerrar Sesion
                        </a> 
                    </li>               
                </ul> 
 
            </div>
                <div class="col-xs-12 col-sm-12 col-lg-8 align-items-center" >
                    
                    <div class="container-fluid p-0 d-flex h-100 mt-4">
                        <ListaCandidatosR/>
                         
                    </div>                                
                </div>
        </div>
  
    </div>
        <Footer/> 
    </div>
</template>
<script>
import HeaderPanel from '@/components/HeaderPanel.vue';
import Footer from '@/components/Footer.vue';
import SidebarAdmin from '@/components/SidebarAdmin.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import ListaCandidatosR from './ListaCandidatosR.vue';


export default{
    name:'PanelRevision',
    data(){
        return{
            idUsuario:null,
            idFolioValidacion:"",
            username: "",
            
            rol:"",
            tipoRol:"",
            asignaciones:"",
            usuario_activado:"",
            email:"",
            listaUsers:[],
            filters: {nombre: { value: '', keys: ['nombre_completo','username','rol','email']}},
            isLoading: true,
            routes:routes,            
           
            //disable:false,
            config:{},
            nombre_completo:"",          
            pagina:"",
            showPanel:false,

        }
    },
    components:{
        HeaderPanel,
        Footer,
        ListaCandidatosR
        
    }, 
    methods:{
        sessionClose(){
            localStorage.removeItem('loggedAppUserPanel')
             this.thispath('/adminpanel')
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },

    },
    //Declarion de created para carga de sesiones
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        
       
        try {
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')
            
            //comprobacion de tipo de usurio y acceso a la ruta
            const {idUsuario, token, usernamePanel,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario             
            this.tipoRol=roles[0].rol
            this.showPanel =true

            if(this.tipoRol ==='Validador'){ //Agregar mas usuarios mientras sea necesario
                this.$router.push('/panelrevision')
                this.$router.push('/candidatosr')
                //Si es admin accede a axios y  toda la configuracion
                this.config = {
                    headers:{
                        Authorization: `Bearer ${token}`
                    }
                }           
                const result = await axios.get(url,this.config)           
                this.nombre_completo= result.data[0].nombre_completo
                this.usuario_idFolioValidacion =result.data[0].idFolioValidacion                    
                this.isLoading = false 
            }
        
        else{
          this.$router.push('/404')        

        }
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/404')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
<style scoped src="../assets/styles/candidato.css">
.bg-iem{
    background-image: url("../assets/background-iem.jpg");
}

#panelview{
    display: flex;}
</style>