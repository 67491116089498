<template>
    <div class="container-fluid p-0 d-flex h-100"> 
        <div id="bdSidebar" 
             class="d-flex flex-column  
                    flex-shrink-0  
                    p-3 bg-secondary 
                    text-white offcanvas-md offcanvas-start"> 
            <a href="#" 
               class="navbar-brand"><strong>CAPTURA DE DATOS</strong>
            </a><hr> 
            <ul class="mynav nav nav-pills flex-column mb-auto"> 
                <li class="nav-item mb-1"> 
                    <a href="#imagen"> 
                        <fa icon="fa-solid fa-user"/>
                        Imagen de Perfil
                    </a> 
                </li> 
  
           
                <li class="nav-item mb-1"> 
                    <a href="#redes"> 
                        <fa icon="fa-regular fa-newspaper"/>
                        Redes sociales 
                    </a> 
                </li> 
                <li class="nav-item mb-1"> 
                    <a href="#datos"> 
                        <fa icon="fa-solid fa-archway"/>
                        Datos de contacto 
                    </a> 
                </li>

                <li class="nav-item mb-1"> 
                    <a href="#save"> 
                        <fa icon="fa-solid fa-floppy-disk"/>
                        Guardar y Enviar
                    </a> 
                </li>
        
  
          
            </ul> 
            <hr> 
            <div class="d-flex"> 
  
                <i class="fa-solid fa-book  me-2"></i> 
                <span> 
                    <h6 class="mt-1 mb-0"> 
                          Instituto Electoral de Michoacán
                      </h6> 
                </span> 
            </div> 
        </div> 
  
        <div class="bg-light flex-fill"> 
            <div class="p-2 d-md-none d-flex text-white bg-secondary"> 
                <a href="#" class="text-white" 
                   data-bs-toggle="offcanvas"
                   data-bs-target="#bdSidebar"> 
                    <i class="fa-solid fa-bars"></i> 
                </a> 
                <span class="ms-3">Portal de captura</span> 
            </div> 
            <div class="p-4"> 
                <nav style="--bs-breadcrumb-divider:'>';font-size:14px"> 
                    <ol class="breadcrumb"> 
                        <li class="breadcrumb-item"> 
                            <fa icon="fa-solid fa-house"/>
                        </li> 
                        <li class="breadcrumb-item"><strong>Inicio</strong></li> 
                        <li class="breadcrumb-item"><strong>Captura de datos</strong></li> 
                    </ol> 
                </nav>  
                <hr> 
            <!--inicia contenido de la captura-->           
            
                    <p class="text-header">Captura de información pública de Candidatura</p>                    
                     <!--Section de contenido de revision del formulario con la revision-->
                     <div class="row questionary-row box-border-separator" id="medios">                          
                        <div class="questionary-container">
                            <!--Informacion correspondiente con Medios de contacto-->                                 
                                  <div style="margin-top: 5%; margin-bottom: 10%;">
                                      <div class="d-flex flex-column mb-3" id="imagen">
                                      <!-- <label for="exampleInputPassword1">Logo&emsp;</label> -->
                                          <input style="display: none;" type="file" class="form-control-file" accept="image/jpeg,image/png,image/jpg" ref="fileInput" v-on:change="uploadImage">
                                          <button type="button" class="btn-toggle" v-on:click="$refs.fileInput.click()">Agregar imagen de perfil &emsp;<fa icon="image"/></button>
                                          <div v-if="imagen" class="logo-container">
                                              <img v-if="imagen.name" style="margin-left: 1%;object-fit: contain;" ref="previewImage" width="100%" height="200px">
                                              <img v-else-if="imagen" style="margin-left: 1%;object-fit: contain;" :src="dirname+imagen" width="100%" height="200px">
                                              <!-- <img ref="previewImage" width="100%" height="200px" style="object-fit: contain;"> -->
                                              <h5 v-if="imagen.name" class="image-name">{{imagen.name}}</h5>
                                              <h5 v-else class="image-name">{{imagen}}</h5>
                                              <button v-if="imagen" type="button" class="btn btn-danger" v-on:click="deleteImage">Eliminar imagen &emsp;<fa icon="xmark"/></button>
                                          </div>
                                      </div>
                                      <label>FaceBook</label>
                                      <div class="input-group mb-3" id="redes">
                                          <span class="input-group-text icon-width"><fa icon="fa-brands fa-facebook" class="brand-icon"/></span>
                                          <input v-model="social.facebook" type="text" class="form-control" placeholder="https://www.facebook.com/username">
                                      </div>
                                      <label>Twitter</label>
                                      <div class="input-group mb-3">
                                          <span class="input-group-text icon-width"><fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon"/></span>
                                          <input v-model="social.twitter" type="text" class="form-control" placeholder="https://twitter.com/username">
                                      </div>
                                      <label>YouTube</label>
                                      <div class="input-group mb-3">
                                          <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-brands fa-youtube" class="brand-icon"/></span>
                                          <input v-model="social.youtube" type="text" class="form-control" placeholder="https://www.youtube.com/@username">
                                      </div>
                                      <label>Instagram</label>
                                      <div class="input-group mb-3">
                                          <span class="input-group-text icon-width"><fa icon="fa-brands fa-instagram" class="brand-icon"/></span>
                                          <input v-model="social.instagram" type="text" class="form-control" placeholder="https://www.instagram.com/username">
                                      </div>
                                      <label>TikTok</label>
                                      <div class="input-group mb-3">
                                          <span class="input-group-text icon-width"><fa icon="fa-brands fa-tiktok" class="brand-icon"/></span>
                                          <input v-model="social.tiktok" type="text" class="form-control" placeholder="https://www.tiktok.com/@username">
                                      </div>
                                      <label>Página web</label>
                                      <div class="input-group mb-3" id="datos">
                                          <span class="input-group-text icon-width"><fa icon="fa-solid fa-globe" class="brand-icon"/></span>
                                          <input v-model="contacto.web" type="text" class="form-control" placeholder="https://www.misitioweb.com">
                                      </div>
                                      <label>Correo electrónico público</label>
                                      <div class="input-group mb-1">
                                          <span class="input-group-text icon-width"><fa icon="fa-solid fa-envelope" class="brand-icon"/></span>
                                          <input v-model="contacto.email" v-on:keypress="validate($event, 'email')" class="form-control" placeholder="micorreo@mail.com" >
                                      </div>     
              
                                      <label>Teléfono público de contacto</label>
                                      <div class="input-group mb-1">
                                          <span class="input-group-text icon-width"><fa icon="fa-solid fa-phone" class="brand-icon"/></span>
                                          <input v-model="contacto.telefono" v-on:keypress="validate($event, 'phone')" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" class="form-control" placeholder="123-456-7890">
                                      </div>       
              
                                      <label>Domicilio de casa de campaña</label>
                                      <div class="input-group mb-1">
                                          <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-solid fa-location-dot" class="brand-icon"/></span>
                                          <textarea v-model="contacto.direccion" placeholder="Avenida Landana #345 Lote 12, México D.F. CP 01234 México" class="form-control"></textarea>
                                      </div>               
                                  </div>                 
              
              
                                 
                                    <div class="d-flex flex-row" id="save">
                                        <button type="button" class="btn-toggle" :disabled="disable" v-on:click="capturarDatos()"><fa icon="arrow-left"/>&emsp;Guardar Información&emsp;<fa icon="arrow-right"/>&emsp;</button>
                                    </div>                
                                  </div>
                             
                          </div><!-- -->          

                    
                  
            </div> 
  
        </div> 
    </div>  
<!--Menu lateral de navegacion scrolling autofocus-->

        
           
        
  
</template>
<script>

import axios from 'axios';
import routes from '../data/routes.json'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default{
    name:'CapturaCandidato',
    data(){
        return{         

            propietario:{
                nombre:"",
                primerApellido:"",
                segundoApellido:"",
                sexo:"",
                lugarNacimiento:"",
                fechaNacimiento:"",
                ocupacion:"",
                sobrenombre:"",
                correo:"",
                edad:"30" // falta de donde se obtendra por default 30's a todos
            },
            suplente:{
                nombre:"",
                primerApellido:"",
                segundoApellido:"",
            },
            asociacion:{
                asociacionPostula:"",
                descTipoAsociacion:"",// LOCAL PRIMERA VEZ ETC.
                siglasAsociacion:"",

            },
            candidatura:{
                descTipoCandidatura:"",//DIPUTACION O H AYUNTAMIENTO
            },
            entornoGeografico:{
                distrito:"",
                municipio:"" 
           },
           accionesAfirmativas:{
            id:0,
            descripcion:"",
           },
           //Variables de control
            pagina:'',
            activeDiv1:false,
            activeDiv2:false,
            activeDiv3:false,
            activeDiv4:false,
            activeRows:false,

                      
            dirname:this.$assetsDirname,
            max:100, 
            value:75, 
            text:"",
            imagen:'',
            folioRegistro:"",
            //Informacion a obtener desde la coleccion de candidatos
            lista:[],  
            infoCandidatos:[],
            id_postulacion:0,
            accionAfirmativa:"", //  solo para mostrarse en la vista curricular
            cargo:"", // solo para mostrarse en vista curricular en cargo
        
            //Variables para la revision de ficha de contacto
            routes:routes,
            isLoading: true,
            idCandidato:null,
            disable:false,
            config:{},
            currentLogo:null,
            username:"", //local storage username

            //Se obtiene los datos del SNR no Modificable
            phone:"123 456 7890", // input telefono
            email:"correo@electronico.com", // input email
            address:'', // input direccion pro
            //dirname:this.$assetsDirname, 
            //max:24,
       
            contacto:{                
                email:"",
                
                web:"",
                telefono:"", 
                direccion:'', 
            },
            social:{
                facebook:"",
                twitter:"",
                youtube:"",
                instagram:"",
                tiktok:""
            },
            estudio:{
                grado_maximo:'',
                estatus:''                

            },

            curriculares:{
         
                estudio:{
                    grado_maximo:"",
                    estatus:"",
                    otro:""

                },
                profesion:{
                    historia_laboral:"",
                    trayectoria_politica:"",
                    razon_cargo_publico:""
                },
                propuesta:{
                    propuesta1:"",
                    propuesta2:"",
                    propuesta_grupo_discriminacion:""
                },

            },//cierre curriculares

            //Cuestionarios de Identidad
            identidad:{
                indigena:"",
                lengua_indigena:"",
                comunidad_indigena:"",
                discapacidad:"",
                estatus_discapacidad:"",
                tipo_discapacidad:"",
                dificultad_discapacidad:"",
                afromexicana:"",
                diversidad_sexual:"",
                tipo_diversidad_sexual:"",
                migrante:"",
                pais_extranjero:"",
                tiempo_extranjero:"",
                motivo:"",
                joven:"",
                publicacion:"",
            },
            //Area de variables de validacion

            validacionPartido:false,
            validacionTransparencia:false,
            validacionCPyPP:false,
            asignacionValidador:"", // se listan los validadores que tienen acceso a este candidato.
            validacionObserbaciones:"", //
            validacionCaptura:false, // se activa esta  variable para que el candidato pueda comenzar a capturar los cuestionarios de identidad
            estadoRoC:"EN CAPTURA"


        }// cierre return         
    },
    components:{
        //Como es un Card View o Modal Window no utiliza cabeceras
    
    },
    methods:{
        //Metodos para proteccion de las rutas de las vistas de VueJS
        home(){
            this.thispath(`/conoceles`)
        },
        editar(id){
            console.log(id)
            this.thispath('/editpartido/' + id)
        },
        nuevo(){
            this.thispath('/newpartido')
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
    //Metodos de validacion y carga de imagenes
    validate(e, opt){
            if(opt === 'phone'){
                const regex = /^[0-9]$/
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(regex.test(char)) return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
            if(opt === 'email'){
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(char !== ' ') return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
        },
        cargar(e, value){
            const opt = {
                'email':()=>this.email=e,
                'phone':()=>this.phone=e,
                'address':()=>this.address=e,
            }
            opt[value]()
        },
        erase(e, value){
            const opt = {
                'email':()=>this.contacto.email.splice(e, 1),
                'phone':()=>this.contacto.telefono.splice(e, 1),
                'address':()=>this.contacto.direccion.splice(e, 1),
            }
            opt[value]()
        },
        agregar(opt){
            if(opt === 'email' && this.email != ''){
                this.contacto.email.push(this.email)
                this.email=''
                return
            }
            if(opt === 'phone' && this.phone != ''){
                this.contacto.telefono.push(this.phone)
                this.phone=''
                return
            }
            if(opt === 'address' && this.address != ''){
                this.contacto.direccion.push(this.address)
                this.address=''
                return
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        uploadImage(e){
            try {
                this.imagen = this.$refs.fileInput.files[0]
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    this.$refs.previewImage.src = e.target.result;
                }
            } catch (error) {
                console.log(error)
            }
        },
        deleteImage(e){
            this.imagen = ''
            this.$refs.fileInput.value = null;
        },
        windowClose(){
            const nuevaVentana = window.open([
            window.location.href ="/candidatosr",'Tabla Candidatos Captura'
            ])
            nuevaVentana.onload =()=>{
                nuevaVentana.postMessage('actualizados','*')
            }
            
            window.close()
            //redireccionamiento a la ruta principal
           

        },
        //Metodo captura de datos envia los datos actualizados al candidato
        async capturarDatos(){
            
            this.disable=true
            
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato_userc/`
            const formData = new FormData() //multi-part

            if (typeof this.imagen === 'object')
                formData.append('imagen',this.imagen)
            // Si el campo logo es un objeto lo limpiamos, esto quiere decir que el logo ha sido actualizado y no debe de mandarse
                             
            if(this.imagen!==""){ //Valida que se haya ingresado una imagen
                this.estadoRoC = "CAPTURADO" 
                this.validacionCaptura = true                
                formData.append('params', JSON.stringify( { curriculares:{ social:this.social, contacto:this.contacto }, imagen:this.imagen, idCandidato:this.idCandidato, logoDelete:this.currentLogo, validacionCaptura:this.validacionCaptura, estadoRoC:this.estadoRoC }) )
                console.log(formData)
                const res = await axios.patch(url, formData, this.config)
                if(res.data.result = 1){ // se movio de asignacion a igualdad
                    this.$alert('Datos capturados','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    
                    this.disable=false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                
                this.disable=false
            }else{
                this.$alert('','<label class="alertlabel">Imagen no válida o No Cargada</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false

            }
           


        },
   

        //Meotodo para obtener informacion del candidato proveniente del Sistema de Registro de candidatos o SNR
        async getPartidoById(id){
            //let url =`/api/candidato/${id}` // cambiamos URL por la simulada del SNR  O SISIP
            let url =`https://informatica.iem.org.mx/api/conoceles/api/partido/${id}`            
            try {                
                const res = await axios.get(url)
                this.lista=res.data[0].lista;   

                //Informacion Personal del candidato proveniente del SNR, pendiente programar EndPoint
                this.propietario.nombre = res.data[0].propietario.nombre
                this.folioRegistro=res.data[0].folioRegistro

                this.propietario.primerApellido = res.data[0].propietario.primerApellido
                this.propietario.segundoApellido = res.data[0].propietario.segundoApellido
                //this.informacion_personal.edad = res.data[0].informacion_personal.edad
                this.propietario.sexo = res.data[0].propietario.sexo
                this.propietario.correo = res.data[0].propietario.correo
                this.propietario.sobrenombre = res.data[0].propietario.sobrenombre
                //this.informacion_personal.curp = res.data[0].informacion_personal.curp
                //Informacion partidista proveniente del SNR o del sistema Interno
              
                
                this.suplente.nombre = res.data[0].suplente.nombre
                this.suplente.primerApellido = res.data[0].suplente.primerApellido
                this.suplente.segundoApellido = res.data[0].suplente.segundoApellido

                this.asociacion.asociacionPostula = res.data[0].asociacion.asociacionPostula
                this.asociacion.descTipoAsociacion = res.data[0].asociacion.descTipoAsociacion
                this.asociacion.siglasAsociacion = res.data[0].asociacion.siglasAsociacion
                this.candidatura.descTipoCandidatura = res.data[0].candidatura.descTipoCandidatura

                //Evalua que tipo es para mostrarse en la vista
                if(this.candidatura.descTipoCandidatura==="AYUNTAMIENTO"){
                    this.cargo ="PRESIDENCIA MUNICIPAL"
                    this. activeRows =false
                }else if( this.candidatura.descTipoCandidatura ==="DIPUTACION"){
                    this.cargo = "DIPUTACIÓN"
                    this. activeRows =true
                }
                this.entornoGeografico.distrito = res.data[0].entornoGeografico.distrito
                this.entornoGeografico.municipio = res.data[0].entornoGeografico.municipio
                this.accionesAfirmativas.id = res.data[0].accionesAfirmativas.id //obtenemos el ID para evaluar e insertar el texto personalizado
                //Pasamos la accion a un metodo
                this.getTipoAccionAfirmativa(this.accionesAfirmativas.id)              
                this.getCandidatosInfo(this.folioRegistro)        

            } 
            catch (error) 
            {
                console.log(error.code)
                
            }
        },
        //Metodo para obtener datos desde la base de datos relacionada con los candidatos y preguntas de identidad, medios de contacto e informacion Profesional
        async getCandidatosInfo(id)
        {
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato/${id}`
            try {
                
                 const info = await axios.get(url)
                 this.infoCandidatos= info.data[0].infoCandidatos
                 this.id_postulacion=info.data[0].id_postulacion
                 this.imagen = info.data[0].imagen
                 //Obtenemos el id para proceder a actualizarlo por idCandidato
                 this.idCandidato = info.data[0].idCandidato
                 /**
                  *   this.curriculares.contacto.web =info.data[0].curriculares.contacto.web
                 this.curriculares.contacto.email =info.data[0].curriculares.contacto.email
                 this.curriculares.contacto.direccion =info.data[0].curriculares.contacto.direccion
                 this.curriculares.contacto.telefono =info.data[0].curriculares.contacto.telefono

                 this.curriculares.social.facebook = info.data[0].curriculares.social.facebook
                 this.curriculares.social.twitter = info.data[0].curriculares.social.twitter
                 this.curriculares.social.youtube = info.data[0].curriculares.social.youtube
                 this.curriculares.social.instagram = info.data[0].curriculares.social.instagram
                 this.curriculares.social.tiktok = info.data[0].curriculares.social.tiktok
                  
                  */
                // Datos requeridos en esta vista
                this.contacto.direccion = info.data[0].curriculares.contacto.direccion
                this.contacto.telefono= info.data[0].curriculares.contacto.telefono
                this.contacto.email = info.data[0].curriculares.contacto.email            
                this.contacto.web = info.data[0].curriculares.contacto.web
                this.social.facebook = info.data[0].curriculares.social.facebook
                this.social.twitter = info.data[0].curriculares.social.twitter
                this.social.youtube = info.data[0].curriculares.social.youtube
                this.social.instagram = info.data[0].curriculares.social.instagram
                this.social.tiktok = info.data[0].curriculares.social.tiktok
                this.imagen = info.data[0].imagen
                this.currentLogo = info.data[0].imagen

               

                 this.curriculares.profesion.razon_cargo_publico = info.data[0].curriculares.profesion.razon_cargo_publico
                this.curriculares.propuesta.propuesta1 = info.data[0].curriculares.propuesta.propuesta1                
                this.curriculares.propuesta.propuesta2 = info.data[0].curriculares.propuesta.propuesta2
                this.curriculares.propuesta.propuesta_grupo_discriminacion = info.data[0].curriculares.propuesta.propuesta_grupo_discriminacion

                //Obtencion de informacion de los cuestionarios de Identidad
                this.identidad.diversidad_sexual = info.data[0].identidad.diversidad_sexual
                this.identidad.tipo_diversidad_sexual = info.data[0].identidad.tipo_diversidad_sexual                                
                //console.log("Desde Coleccion Candidatos: ",this.folioRegistroCandidato)
                this.identidad.indigena = info.data[0].identidad.indigena
                this.identidad.lengua_indigena = info.data[0].identidad.lengua_indigena
                this.identidad.comunidad_indigena = info.data[0].identidad.comunidad_indigena
                //Obtencion de respuestas delos cuestionarios de identidad correspondientes a personas con discapacidad
                this.identidad.discapacidad = info.data[0].identidad.discapacidad
                this.identidad.estatus_discapacidad = info.data[0].identidad.estatus_discapacidad
                this.identidad.tipo_discapacidad = info.data[0].identidad.tipo_discapacidad
                this.identidad.dificultad_discapacidad = info.data[0].identidad.dificultad_discapacidad
                //Obtencion de respuesas de los cuestionarios de identidad correspondientes a si es persona migrante
                this.identidad.migrante = info.data[0].identidad.migrante
                this.identidad.pais_extranjero = info.data[0].identidad.pais_extranjero
                this.identidad.tiempo_extranjero = info.data[0].identidad.tiempo_extranjero
                this.identidad.motivo = info.data[0].identidad.motivo
                this.identidad.situacion_asegurada = info.data[0].identidad.situacion_asegurada
                //Obtencion variable de publicacion de la informacion
                this.identidad.publicacion = info.data[0].identidad.publicacion

                
            } catch (error) {
                console.log(error)
                
            }  
        },
        //Metodo para enviar datos a la vista del usuario, no modifica  la informacion solo la muestra
        getTipoAccionAfirmativa(tipoAccion){

            if(tipoAccion === 1){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS CON DISCAPACIDAD"
                this.activeDiv1=true
            }
            else if(tipoAccion === 2){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS LGBTIAQ+"
                this.activeDiv2=true

            }
            else if(tipoAccion === 3){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS INDÍGENAS"
                this.activeDiv3=true
            }
            else if(tipoAccion === 4){
                this.accionAfirmativa ="POSTULACIÓN POR PERSONAS MIGRANTES"
                this.activeDiv4=true
            }
            else(
                this.activeDiv=false
            )
        },
        //Metodo que publica la informacion dell candidato correspondiente a los cuestionarios de identidad
        //Metodo que valida la informacion del candidato por el usuario Validador del partido
        validarInformacion(){
            
        },

        //Metodo para que el usuaro Publicador de informacion correspondiente a CPyPP
        publicarCandidato(){
            this.identidad.publicarInformacion
        },

    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        this.getPartidoById(this.$route.params.id); 
        this.getCandidatosInfo(this.$route.params.id)
         //Metodo para controlar los cambios en tiempo real

        const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')            
            //Datos del usuario que Inicia Sesion en el portal
            const {idUsuario, token, usernamePanel,nombre_completo} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario
            this.nombre_completo_usuario = nombre_completo
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }
        
        //Pasamos los valores de inicio de sesion de usuario y JWT


    },

}

</script>
<style scoped src="../assets/styles/candidato.css">

</style>