<template>
    <div class="container">
        <!-- Modal Captura Informacion -->
        <div class="modal fade" id="resetPassword" tabindex="-1" aria-labelledby="resetPassword" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Cambio de Contraseña</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!--inicia form cambio contraseña-->
                    <div id="home">
                        <div class="wrapper fadeInDown">
                            <div id="formContent" class="d-flex flex-column">
                                <!-- Tabs Titles -->

                                <!-- Icon -->
                                <div class="fadeIn first">
                                <h2>Actualizar contraseña</h2>
                                <img src="../assets/boton_conoceles.png" style="margin-top: 20px;" id="icon" alt="User Icon" />
                                </div>
                                <!-- Login Form -->                                
                                <form v-on:submit.prevent="login">                                    
                                    <div class="mb-3">
                                        <p>Nombre de Usuario</p>
                                        <input type="text" id="login" class="fadeIn second" name="login" placeholder="username" v-model="username">
                                        <p>Contraseña Actual</p><br>
                                        <input type="password" id="currentPassword" class="fadeIn third" name="login" placeholder="Contraseña actual" v-model="currentPassword">
                                        <input type="text" id="login2" class="fadeIn second" name="login" placeholder="username" v-model="username">
                                        <p>Nueva Contraseña</p><br>
                                        <input type="password" id="newPassword" class="fadeIn third" name="login" placeholder="Nueva Contraseña" v-model="newPassword">
                                    </div>
                                    <div class="d-flex flex-column mb-3">              
                                    <button type="submit" class="btn btn-custom fadeIn fourth">Ingresar</button>
                                    </div>
                                </form>
                                <!-- Remind Passowrd -->
                                
                                <a class="underlineHover" href="#">He olvidado la contraseña</a>
                                
                            </div>
                        </div>
                    </div>
                    <!--Termina formulario-->                   
                </div>
                <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary">Cambiar Contraseña</button>                   
                    <div id="formFooter">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'


export default{
    name: 'ResetPassword',

    data(){
        return{
            newPassword:"",
            currentPassword:"",
            sessionVar:"",
            cookie:"",
            pregunta1:"",
            pregunta2:"",
            pregunta3:"",
          
            respuesta1:"",
            respuesta2:"",
            respuesta3:"",

            
        }

    },
    //declaracion e inicio de metodos
    methods:{
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
         }, 
        //Metodo que se llama cuando se actualiza la contraseña obliga al usuario a iniciar sesion de nuevo
        sessionClose(){
            localStorage.removeItem('loggedAppUser')
            this.thispath('/')
  },


    },

    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        //Se muestra el aviso de privacidad    
        //Metodo Created para cargar la informacion de inicio de sesion de usuario en los Tokens    

        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')

             
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato           

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }               

            const result = await axios.get(url, this.config)            
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }  
            
           
            
            // Datos requeridos en esta vista       
            this.imagen = result.data[0].imagen
            this.currentLogo = result.data[0].imagen
            this.username = result.data[0].username

           
            //Datos estaticos cargados desde el SNR
            this.estudio.grado_maximo = result.data[0].curriculares.estudio.grado_maximo
            this.estudio.estatus = result.data[0].curriculares.estudio.estatus
            this.aviso1 = result.data[0].aviso1

            if(this.aviso1 === false ){
                this.showAviso= true
            }

            
            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
  
}





</script>
<style scoped>
.table-custom {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    caption-side: bottom;
    text-indent: initial;
    border-collapse: separate;
    background-color: #e9ecef;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
}
.table-custom > :not(caption) > * > * {
    cursor: pointer;
    padding: 0.455rem 0.55rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
td {
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
}
.input-group-text-custom {
    width: 100%;
    font-family: GalanoRegular;
}
.bc {
    text-align: right;
    justify-content: right;
    padding: 2px 10px 2px 10px;
}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
textarea {
    font-family: GalanoRegular;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
.icon-width {
    width: 60px;
}
.logo-container {
    display: flex;
    flex-direction: column;
    border: 2px solid #B20066;
    border-radius: 5px;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
    text-align: center;
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    font-family: GalanoRegular;
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #B20066;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #B20066;
    --bs-btn-border-color: #B20066;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b0aeb3;
    --bs-btn-hover-border-color: #b0aeb3;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.image-name {
  font-family: GalanoRegular;
  font-weight: bold;
  color: #B20066;
  overflow-wrap: break-word;
  padding: 0 10px 0px 10px;
}
.brand-icon {
    font-size: 35px;
    color: #B20066;
}
label {
    font-family: GalanoRegular;
    font-weight: bold;
    color: #B20066;
    font-size: 18px;
}
input::placeholder, textarea::placeholder {
  color: #b0aeb3;
}
.question{
    color:#5c636a
  }
</style>
