<template>
  <div class="container">
    <div class="row">      
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>  
        <h4>Total de las candidaturas que respondieron al cuestionario de identidad, son personas jóvenes:</h4>
      </div>
  </div>  
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">       
        <Pie v-if="loaded" id="myChart" :options = "chartOptions" :data = "chartData"></Pie> 
      </div>
  </div>  
  <div class="row">    
      <div class="col-xs-12 col-sm-12 col-lg-12 mt-3 pb-2">
          <h4>De las {{ cTotalEdades }}  (100%) candidaturas que respondieron el cuestionario de identidad, {{cEdades1}} ({{ pEdades }}%) se identificaron como personas jóvenes</h4>
      </div>
    </div>
  </div> 
 
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaEdad1',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {  

        labels: ['Si','No'],
        datasets: [ 
            {
            label: 'Población Joven',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#E75395','#6461A9']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        },

      },
      //Variables para creacion de los datos
      lista :[],
      cEdades1:0,
      cEdades2:0,
      cTotalEdades:0,
      pEdades:0,
    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const res = await axios.get(url)
        this.lista = res.data
        let count1=0
        let count2=0

    
       
        this.lista.forEach(element =>{ 
        if(element.validacionCPyPP===true){
            if(element.identidad.joven === "Sí")
                {
                   count1++

                }
            else{
                  count2++
            }           
        }      
     })

     this.chartData.datasets[0].data[0] = count1  
     this.chartData.datasets[0].data[1] = count2  
     this.cEdades1=count1   
     this.cEdades2=count2
     this.cTotalEdades = this.cEdades1 + this.cEdades2
     this.pEdades = ((this.cEdades1 * 100) / this.cTotalEdades).toFixed(2)
     this.loaded = true

    }
}
</script>


<style>
/*rescribimos los estilos heredados*/
h4{
  font-weight:bolder;  
  color: #686868;
}
h5{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
.sizeChart{
  width: 600px;
  height: 400px;

}


</style>