<template>
    <div class="container">
 

        <Banner/>
        <loading :active.sync="isLoading" :can-cancel="false" color="#6a6ea8" transition="fade" :enforce-focus=true :is-full-page=true> 
        </loading>     
        <div class="mt-4 pt-4 pb-4"><!--div contenido mt-4-->
            <h5 class="fw-bold">Podrás hacer búsquedas por tipo de candidatura, actor político y entidad o <strong><a href="#" v-on:click=" generateReport()" style="color:#B20066"> exportar la base de datos</a></strong></h5>
            <hr>
            <!--Radio button seleccion por Cargo--> 
                <div class="buttons-container" id="busquedas">
                    <label class="label-color" for="busquedaActorPolitico">¿QUE DESEAS CONSULTAR?</label>
                    <br>    

                    <div class="form-check form-check-inline button-separation">
                        <input class="form-check-input" type="radio" name="cargo-politico" id="todos" value="todos" v-model=valorSeleccionado @change="getFilterByValue">
                        <label class="form-check-label" for="todos">
                            TODOS                  
                        </label>
                    </div>

                    <div class="form-check form-check-inline button-separation">
                        <input class="form-check-input" type="radio" name="cargo-politico" id="ayuntamiento" value="3" v-model=valorSeleccionado @change="getFilterByValue">
                        <label class="form-check-label" for="ayuntamiento">
                            AYUNTAMIENTOS
                        </label>
                    </div>
                    <div class="form-check form-check-inline button-separation">
                        <input class="form-check-input" type="radio" name="cargo-politico" id="diputacionmr" value = "1" v-model=valorSeleccionado @change="getFilterByValue" >
                        <label class="form-check-label" for="diputacionmr">
                            DIPUTACIONES MR             
                        </label>
                    </div>

                    <div class="form-check form-check-inline button-separation">
                        <input class="form-check-input" type="radio" name="cargo-politico" id="diputacionrp" value = "2" v-model=valorSeleccionado @change="getFilterByValue" >
                        <label class="form-check-label" for="diputacionrp">
                            DIPUTACIONES RP             
                        </label>
                    </div>

                    <div class="form-check form-check-inline button-separation">
                        <input class="form-check-input" type="radio" name="cargo-politico" id="accionesAfirmativas" value=4 v-model=valorSeleccionado @change="getFilterByValue" >
                        <label class="form-check-label" for="accionesAfirmativas">
                            ACCIONES AFIRMATIVAS       
                        </label>
                    </div>
                </div> 
           
        

                <transition name="fade">
                    <!--Radio button seleccion por Actor Politico-->
                <div v-show="showDiv" >
                    <div class="row buttons-container" >
                        <label class="label-color" for="busquedaActorPolitico">ELIGE UNA OPCIÓN: </label>
                    </div>                    
                    <br>
                    <div class="row buttons-container mb-2" >
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido1" value="PAN" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido1">
                                <img src="../assets/pan-1.png" alt="Logo PAN" class="size-logos">
                                PARTIDO ACCIÓN NACIONAL                                           
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido2" value="PRI" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido2">
                                <img src="../assets/pri-2.png" alt="Logo PAN" class="size-logos">
                                PARTIDO REVOLUCIONARIO INSTITUCIONAL
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido3" value="PRD" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido3">
                                <img src="../assets/prd-3.png" alt="Logo PAN" class="size-logos">
                                PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA
                            </label>
                        </div>
                    </div>

                    <div class="row buttons-container mb-2">                           
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido4" value="PT" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido4">
                                <img src="../assets/pt-4.png" alt="Logo PAN" class="size-logos">
                                PARTIDO DEL TRABAJO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido5" value="PVEM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido5">
                                <img src="../assets/pvem-5.png" alt="Logo PAN" class="size-logos">
                                PARTIDO VERDE ECOLOGISTA DE MÉXICO             
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido6" value="MC" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido6">
                                <img src="../assets/pmc-6.png" alt="Logo PAN" class="size-logos">
                                MOVIMIENTO CIUDADANO               
                            </label>
                        </div>
                    </div>

                    <div class="row buttons-container mb-2">                        
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido7" value="MORENA" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido7">
                                <img src="../assets/morena-7.png" alt="Logo PAN" class="size-logos">
                                MORENA             
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido8" value="PESM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido8" style="font-size: 14px;">
                                <img src="../assets/pes-8.png" alt="Logo PAN" class="size-logos">
                                PARTIDO ENCUENTRO SOLIDARIO MICHOACÁN               
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido9" value="PMM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido9">
                                <img src="../assets/mas-9.png" alt="Logo PAN" class="size-logos">
                                MÁS MICHOACAN             
                            </label>
                        </div>

                    </div>

                    <div class="row buttons-container mb-5">
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido10" value="MICH1" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido10">
                                <img src="../assets/mp-10.png" alt="Logo PAN" class="size-logos">
                                MICHOACÁN PRIMERO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido11" value="TMX" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido11">
                                <img src="../assets/txm-11.png" alt="Logo PAN" class="size-logos">
                                TIEMPO X MÉXICO              
                            </label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido13" value="PT-MORENA-PVEM" v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido13">
                                <img src="../assets/mpv-13.png" alt="Logo PAN" class="size-logos">
                                SIGAMOS HACIENDO HISTORIA            
                            </label>
                        </div>
                       
                    </div>
                    <div class="row buttons-container mb-5">

                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido12" value=4 v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido12">
                                <img src="../assets/ci-12.png" alt="Logo PAN" class="size-logos">
                                CANDIDATURAS INDEPENDIENTE             
                            </label>
                        </div>
                        
                
                        <div class="col-xs-12 col-sm-12 col-lg-4 mt-2">
                            <input class="form-check-input" type="radio" name="actorPolitico" id="partido14" value=3 v-model=valorSeleccionado2 @change="getFilterByValue" >&emsp14;
                            <label class="form-check-label" for="partido14">
                                <img src="../assets/ccomun.png" alt="Logo PAN" class="size-logos">
                                CANDIDATURAS COMUNES          
                            </label>
                        </div>

                                               
                    </div>
                </div>  

                </transition>

        
                <transition name="fade">
                                <!--Grupo de radiobuttons por acciones afirmativas-->
                    <div class="buttons-container" v-show="showDiv2">
                        <label class="label-color" for="busquedaAccionesAfirmativas">ELIGE UNA ACCIÓN AFIRMATIVA</label>
                        <br>          

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="personasDiscapacidad" id="acciona1" value=1 v-model=valorSeleccionado3 @change="getFilterByValue">
                            <label class="form-check-label" for="acciona1">
                                PERSONAS CON DISCAPACIDAD               
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="comunidadlgbt" id="acciona2" value=2 v-model=valorSeleccionado3 @change="getFilterByValue">
                            <label class="form-check-label" for="acciona2">
                                PERSONAS DE LA POBLACIÓN LGBTIAQ+
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="poblacionIndigena" id="acciona3" value=3 v-model=valorSeleccionado3 @change="getFilterByValue" >
                            <label class="form-check-label" for="acciona3">
                                PERSONAS INDÍGENAS                  
                            </label>
                        </div>

                        <div class="form-check form-check-inline button-separation">
                            <input class="form-check-input" type="radio" name="poblacionMIgrante" id="acciona4" value=4 v-model=valorSeleccionado3 @change="getFilterByValue" >
                            <label class="form-check-label" for="acciona4">
                                PERSONAS MIGRANTES     
                            </label>
                        </div>
                    </div>
                </transition>
                <hr v-show="showDiv3">
                <p v-show="showDiv3" style="color:#B20066">*La opción de búsqueda por grado académico solamente presenta los datos capturados por las personas candidatas.</p>
                <transition  name="fade">
                    <div class="buttons-container" v-show="showDiv3">
                        <label class="form-check-label">GRADO MÁXIMO DE ESTUDIOS:</label>
                        <div  class="form-check form-check-inline ">
                            <multiselect
                                v-model="estudio.grado_maximo"
                                :options="options_grados"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                @select=" getUserByList()"
                                placeholder="SELECCIONA UNA OPCIÓN"
                                style="font-family: GalanoRegular;">
                            </multiselect>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="buttons-container" v-show="showDiv3">
                        <label class="form-check-label">RANGO DE EDAD:</label>
                        <div class="form-check form-check-inline ">
                            <multiselect
                                v-model="edad"
                                :options="options_edades"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                @select=" getUserByList()"
                                placeholder="SELECCIONA UNA OPCIÓN"
                                style="font-family: GalanoRegular;">
                            </multiselect>
                        </div>
                    </div>
                </transition>
                  
                
                <transition name="fade">
                    <div class="buttons-container" v-show="showDiv3">
                        <label class="form-check-label">SEXO:</label>
                        <div class="form-check form-check-inline ">
                            <multiselect
                                v-model="genero"
                                :options="options_sexo"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                @select=" getUserByList()"
                                placeholder="SELECCIONA UNA OPCIÓN"
                                style="font-family: GalanoRegular;">
                            </multiselect>
                        </div>
                    </div>
                </transition>
            
                <transition name="fade">
                    <div v-show="showDiv3">
                        <div class=" row buttons-container mt-2">                
                            <div class="col-xs-12 col-sm-12 col-lg-3 d-inline-flex text-align-center align-content-center mt-2" >                    
                                <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                                <label class="label-color" for="search">BUSCAR:&nbsp;</label>
                                <input type="text" class="form-control" id="search" v-model="filters.nombre.value" placeholder="NOMBRE, SEXO, EDAD" @input="getFilterBySearch">                                                                  
                            </div>
                                                    <!--Busqueda por sección--> 
                            <div class="col-xs-12 col-sm-12 col-lg-3 d-inline-flex text-align-center align-content-center mt-2" >                    
                            <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                                <label class="label-color" for="seccion">MUNICIPIO Ó SECCIÓN:&nbsp;</label>
                                <input type="text" class="form-control" id="seccion" v-model=numeroSeccion placeholder="MUNICIPIO ó SECCION" @input="filtrarMunicipios(numeroSeccion)">
                            </div>

                            <div class="col-xs-12 col-sm-12 col-lg-6 d-inline-flex mt-2  align-items-start" >
                                <!--<button type="button" class="btn btn-custom">Buscar&emsp;<fa icon="fa-solid fa-search"/></button>&emsp;&emsp;&nbsp; -->
                                <!--Tooltip img-->
                                <b-col>
                                    <b-button id="tooltip-ine" variant="outline-secondary"> Ubica tu sección</b-button>
                                </b-col>                                                
                                <b-tooltip target="tooltip-ine" placement="top">                                 
                                    <img src="../assets/ine.png" class="tooltip-img" alt="Imagen INE">
                                </b-tooltip>
                                <button type="button" class="btn btn-custom pl-5 pr-5" v-on:click=" limpiarFiltros()">Limpiar Filtros&emsp14;<fa icon="fa-solid fa-trash" v-show="showDiv"/></button> 
                            </div>    
                        
                                                                                              
                                                
                        </div><!--clase row de botones-->


                    <!-- :disabled="disable" marca error se quita despues del btn btn-custom-->
                    <div class="table-responsive" v-show="showDiv">
                        <div class="col-xs-12 col-sm-12 col-lg-12" ><p style="color:#B20066">* M = Mujer, H = Hombre, NB = No Binaria </p>
                         </div>              
                        <!--Se crean dos tablas con v-table una de inicio consulta de carga de inicio y una para consultas por filtros-->
                        <v-table  class="custom-table" v-if="activarLista===true" :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event">
                            <thead slot="head" class="bg-light">
                                <v-th sortKey="logo" class="text-center">FOTO</v-th>
                                <v-th sortKey="propietario" class="text-center">PROPIETARIA/O<input type="text" id="input1" class="form-control text-uppercase" v-model="filters2.nombre.value" placeholder="Búsqueda por nombre:" @input="getFilterBySearchTable" ></v-th>
                                <v-th sortKey="suplente" class="text-center">SUPLENTE<input type="text" id="input2" class="form-control text-uppercase" v-model="filters3.nombre.value" placeholder="Búsqueda por nombre:" @input="getFilterBySearchTable"></v-th>
                                <v-th sortKey="genero" class="text-center">SEXO</v-th>
                                <v-th sortKey="cargo" class="text-center">CARGO</v-th>
                                <v-th sortKey="partido" class="text-center">POSTULANTE</v-th>                             
                                <v-th sortKey="municipio" class="text-center">MUNICIPIO</v-th>
                                <v-th sortKey="ver" class="text-center">VER</v-th>
                            </thead>
                            <tbody slot="body" slot-scope="{displayData}">                    
                                <tr tr v-for="partido in displayData" :key="partido.id" v-on:click="ver(partido.id)"  >  <!--falta programar evento row-->                          
                                        <td>
                                            <b-avatar v-if="partido.validacionFotografia" :src="dirname+partido.imagen" size="2.5rem"></b-avatar>
                                            <img v-else src="../assets/sin_foto.png" class="size-logos">
                                        </td>                                       
                                        <td><strong>{{ partido.nombre }}&nbsp;{{ partido.paterno }}&nbsp;{{partido.materno }}</strong></td>
                                        <td v-if="partido.cve_distrito > 0 "><strong> {{ partido.suplente}}</strong></td>
                                        <td v-else-if='(partido.cve_distrito="0") && (partido.cve_mpio="0")'><strong> {{ partido.suplente}}</strong></td>
                                        <td v-else><strong></strong></td>
                                        <td>{{ partido.genero }}</td>
                                        <td class="text-uppercase">{{getCargo(partido.tipo_eleccion)}}</td>
                                        <td><img :src="dirimagenes+getLogoPartidos(partido.partido, partido.tipo_postulante,partido.postulante)" class="size-logos"></td>                                                                                                            
                                        <td class="text-uppercase" v-if='partido.municipio!=="Michoacán"'>{{partido.municipio }}</td>
                                        <td class="text-uppercase" v-else ></td>
                                                                                                                   
                                        <td><router-link :to ="{name: 'Curriculum', params: {id:partido.id}}"><span style="margin-right: 10px;"><fa icon="magnifying-glass"/></span></router-link></td>                                                            
                                </tr> <!--cierre td encabezados-->                              
                            </tbody>                
                        </v-table>
                        
                        <v-table  class="custom-table" v-else :data="listaPartidos" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event">
                            
                            <thead slot="head" class="bg-light">
                                <v-th sortKey="logo" class="text-center">FOTO</v-th>
                                <v-th sortKey="propietario" class="text-center">PROPIETARIA/O<input type="text" id="input1" class="form-control text-uppercase" v-model="filters2.nombre.value" placeholder="Búsqueda por nombre:" @input="getFilterBySearchTable()"></v-th>
                                <v-th sortKey="suplente" class="text-center">SUPLENTE<input type="text" id="input2"  class="form-control text-uppercase"  v-model="filters3.nombre.value" placeholder="Búsqueda por nombre:" @input="getFilterBySearchTable()"></v-th>
                                <v-th sortKey="genero" class="text-center">SEXO</v-th>
                                <v-th sortKey="cargo" class="text-center">CARGO</v-th>
                                <v-th sortKey="partido" class="text-center">POSTULANTE</v-th>                             
                                <v-th sortKey="municipio" class="text-center">MUNICIPIO</v-th>
                                <v-th sortKey="ver" class="text-center">VER</v-th>                            
                            </thead>
                            <tbody slot="body" slot-scope="{displayData}">                    
                                <tr v-for="partido in displayData" :key="partido.id" v-on:click="ver(partido.id)" > 
                                    <td>
                                        <b-avatar v-if="partido.validacionFotografia" :src="dirname+partido.imagen" size="2.5rem"></b-avatar>
                                        <img v-else src="../assets/sin_foto.png" class="size-logos">
                                    </td>                                        
                                        <td><strong>{{ partido.nombre }}&nbsp;{{ partido.paterno }}&nbsp;{{partido.materno }}</strong></td>
                                        <td v-if="partido.cve_distrito > 0 "><strong> {{ partido.suplente}}</strong></td>
                                        <td v-else-if='(partido.cve_distrito="0") && (partido.cve_mpio="0")'><strong> {{ partido.suplente}}</strong></td>
                                        <td v-else><strong></strong></td>                                     
                                        <td>{{ partido.genero }}</td>
                                        <td class="text-uppercase">{{getCargo(partido.tipo_eleccion)}}</td>
                                        <td><img :src="dirimagenes+getLogoPartidos(partido.partido,partido.tipo_postulante,partido.postulante)" class="size-logos"></td>                                                                                                            
                                        <td class="text-uppercase" v-if='partido.municipio!=="Michoacán"'>{{partido.municipio }}</td>
                                        <td class="text-uppercase" v-else ></td>
                                                                                                            

                                        <td><router-link :to ="{name: 'Curriculum', params: {id:partido.id}}"><span style="margin-right: 10px;"><fa icon="magnifying-glass"/></span></router-link></td>                                                            
                                </tr> <!--cierre td encabezados-->                              
                            </tbody>                
                        </v-table>
                        <div class="row buttons-container mt-2">
                            <div class="col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-end btn-align-right">
                                <button type="button" class="btn btn-custom" v-on:click=" generateReport2()" v-show="showDiv">Descarga Consulta&nbsp;<fa icon="fa-solid fa-file-excel"/></button> 
                                                                           
                            </div>
                        </div>                        
                        <smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="10" class="pagination justify-content-end"/>           
                    </div><!--Termina clase table respinsiva-->
                   
                </div>
            </transition>    
          </div><!--div contenido mt-4-->       
            
            <div class ="row graphics pt-3"> <!--Inicio div graficas-->
                <div class="col-xs-12 col-sm-12 col-lg-12" ><p style="color:#B20066">**La siguiente información es proporcionada solo para fines Estadístcos</p>
                </div>             
                <div class="col-xs-12 col-sm-12 col-lg-5">
                    
                    <ul class="nav flex-column">
                        <li class="nav-item p-2 btn-toggle">                                               
                            <router-link to="/graficacurricular1" class="navbar-brand "><fa icon="fa-solid fa-angle-right"/>&nbsp;Registro curricular Ayuntamientos</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">                                               
                            <router-link to="/graficacurricular2" class="navbar-brand "><fa icon="fa-solid fa-angle-right"/>&nbsp;Registro curricular Diputaciones MR</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">                                               
                            <router-link to="/graficacurricular3" class="navbar-brand "><fa icon="fa-solid fa-angle-right"/>&nbsp;Registro curricular Diputaciones RP </router-link> 
                        </li>
                        <br>                        

                        <li class="nav-item p-2 btn-toggle">                                               
                            <router-link to="/graficagrado" class="navbar-brand "><fa icon="fa-solid fa-angle-right"/>&nbsp;Grado acádemico</router-link> 
                        </li>
                        <br>                        
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficaedad" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Rangos de edad</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficanivel" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Nivel de ingresos</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficasexo" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas por sexo</router-link> 
                        </li> 
                        <br>
                        <li class="nav-item p-2 btn-toggle">   
                            <router-link to="/graficain" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas indígenas</router-link> 
                        </li>            
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficadis" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas con discapacidad</router-link> 
                        </li>
                        <br>

                        <li class="nav-item p-2 btn-toggle">                                               
                            <router-link to="/graficaafro" class="navbar-brand "><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas afromexicanas</router-link> 
                        </li>
                        <br>                       
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficatdiversidad" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas de la población LGBTIAQ+</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficamigrante" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas migrantes</router-link> 
                        </li>
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficajoven" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas jóvenes</router-link> 
                        </li>            
                        <br>
                        <li class="nav-item p-2 btn-toggle">              
                            <router-link to="/graficamayor" class="navbar-brand"><fa icon="fa-solid fa-angle-right"/>&nbsp;Candidaturas de personas mayores</router-link> 
                        </li> 
                    </ul>                      
                </div>

                <div class="col-xs-12 col-sm-12 col-lg-7 ">                    
                    <router-view>
                        <GraficaCurricularA/>
                    </router-view>                    
                    <p>Los datos aquí presentados corresponden al número de personas candidatas que respondieron el “Cuestionario de Identidad”, lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa. Para mayor información sobre este último punto, se sugiere consultar el Acuerdo  (INE/CG616/2022)</p>
               </div>           
            </div><!--cierre div graficas-->        
     
            <div>
                <contador></contador>
                <h5>La presente sección se actualizará conforme a los plazos establecidos en el acuerdo  <strong><a href="https://repositoriodocumental.ine.mx/xmlui/bitstream/handle/123456789/126830/CGex202201-31-ap-5.pdf" style="color:#B20066" target="_blank">INE/CG46/2022</a></strong></h5>
            </div>            
      
        <Footer/>
    </div> <!--Container md-->

</template>
<script>
import HeaderClean from '@/components/HeaderClean.vue';
import contador from '@/components/contador.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
//Catalogos JSON a utlizar en esta vista
import municipios from '../data/municipios.json';
import logos from '../data/logos.json';
import tipoE from '../data/tipo_eleccion.json'
import partidosP from '../data/partidos.json'
import secciones from '../data/secciones.json'
import axios from 'axios';
import *  as XLSX from 'xlsx'
import Loading from 'vue-loading-overlay';
//Importacion de los componentes graficas
import GraficaCurricularA from '@/components/GraficaCurricularA.vue'

//import { each } from 'chart.js/dist/helpers/helpers.core';
//import { elements } from 'chart.js';



export default{
    name:"Conoceles",
    data(){
        return{
            //Analitycs
            visitas:0,   
          
            
            
            secciones:{
                idSeccion:0,
                nroSeccion:0
            },
            accionesAfirmativas:{
                id:0,
                descripcion:"",
                },
            //Variables de uso global del sistema
            idPartido:"",
            imagenes:"", //Ruta de la imagen a guardarse temporal
            numeroSeccion:"",
            max:10, 
            value:6, 
            currentPage: 1, 
            totalPages: 0,
            //baseURL:'192.168.1.14:3000', //cambiar acceso publico ip server, pruebas locales usar localhost 
            //baseURL:'http://187.189.192.40:9090',
            showDiv:false,
            showDiv2:false,
            showDiv3:false,
            activarInput:true,
          
            //Variables para ventana modales encuesta
            showModal:false,
            varSession :true ,
            isLoading: true,
            
            cookie:"",
            pregunta1:"",
            pregunta2:"",
            pregunta3:"",
          
            respuesta1:"",
            respuesta2:"",
            respuesta3:"",

            listaOpiones:['Malo','Bueno', 'Excelente'],
            boxTwo: '',
            //Variables para realizar busquedas y selecciones
            municipios_mich:municipios,
            secciones_mich:secciones,
            // se crea un array con los logos desde assets
            logosPartidos:logos,
            //se crear 
            tipoEleccion:tipoE,
            //guardar nombres de partidos
            partidos:partidosP,

            selection:"",
            //Lista de objetos para crear reporte en CSV y variables de encabezado
            datosCandidato:[
                {"No.":""},
                {"PARTIDO":""},
                {"CARGO":""},
                {"POSTULANTE":""},               
                {"MUNICIPIO":""},
                {"DISTRITO":""},
                {"PROPIETARIO":""},
                {"SUPLENTE":""},
                {"EDAD":""},                
                {"SEXO":""}                          
            ],
            headersReport:[],

             //Datos ID Para Crear consultas y formar el CV del Candidato           
            folioRegistroCandidato:"",
            folioRegistro:"",
            activo:"",
            imagen:"",
            /**
             * INICIAN VARIABLES DEL SICIF
             */
             id_postulacion:0,
             id:0,
            
            tipo_eleccion: "",
            cve_mpio: "",
            postulante: "",
            cve_distrito: "0",
           
            nombre: "",
            paterno: "",
            materno: "",
            genero: "",
            sobrenombre: "",
            lugar_nacimiento: "",
            ocupacion: "",
            aa_tipo: 0,
            partido: "",
            suplentes:"",
            municipio:"",
            edad:0,
            tipo_postulante:"",
             //TERMINAN

            form:{"logo": ""},

           


           //Declaracion de listas para se mostradas con los datos publicos           
            lista:[], //Lista principal
            lista2:[],
            lista3:[],
            lista4:[],
            listaC:[],
            listaP:[],
            listaPS:[], //se crea esta lista union con candiatos suplentes
            listaAcciones:[],
            listatmp:[],
            listaPartidos:[],
                 //Variables listas 
            listaSRPMR:[],//para guardar puros propietarios de DRP
            listaCRPMR:[], // para guardar puras suplencias

            listaGrado:["Capturista","Validador","Publicador","Supervisor","Administrador"],
            activarLista:true,
            validacionFotografia:false,
            
            dirname:this.$assetsDirname,
            dirimagenes:this.$assetsDirname ,
                      
           // variables delv-model para almacenar selecciones
            actorPolitico:'',
            valorSeleccionado:'',
            valorSeleccionado2:'',
            valorSeleccionado3:'',
            accionAfirmativa:'',
            filters: {nombre: { value: '', keys: ['nombre','genero','edad','postulante','cve_mpio']}}, // se coloca el filtro que se quiere buscar
            filters2:{nombre: {value:'',keys:['nombre']}},
            
            filters3:{
                nombre: 
                {
                    value:'',keys:['suplente']
                }
            },           
        //Variables del anterior Desarrollo PENDIENTES POR DEPURAR           
            filtroButtons:[],  
            multipleChoiceValue:null, 
            valuemultiselect:"",
            textoTrayectoria:"", 
            textoCargo:"", 
            textoPropuesta1:"", 
            textoPropuesta2:"", 
            textoPropuestaGrupoDiscr:"",
            
            //options:["Purepechas (P'urhépecha)",'Nahuas','Mazahuas','Otomíes','Matlatzincas','Cuitlatecos','Tzotziles',
            //'Chichimecas','Zapotecos','Mixtecos','Hñähñús (Otomíes)','Mestizos (población no indígena)'],
            archivos:[
                {nombre:"Hola", ruta:"hola amigui"},
                {nombre:"bola", ruta:"hola amigui"},
                {nombre:"xola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"},
                {nombre:"hola", ruta:"hola amigui"}], 
            
            options_grados:["Ninguno", "Preescolar", "Primaria", "Secundaria", "Carrera técnica con secundaria terminada", "Preparatoria o bachillerato", "Carrera técnica con preparatoria terminada", "Normal", "Profesional", "Maestría o doctorado"],
            options_edades:['21 a 29 años de edad','30 a 40 años de edad','41 a 50 años de edad','51 a 60 años de edad','60 años en adelante'],
            options_sexo:["Hombre","Mujer","No Binario"],
            otro:"",
            estudio:{
                grado_maximo:"",
                estatus:"",
                otro:[]
            },     
           
        }
        //Nuevas variables de busqueda agregadas por observaciones INE
        
        
    },
    /*
    props:{
        idCandidato:this.lista.idCandidato // le pasamos el parametro Props para visitar su CV en un nueva vista o componente
    }, */
    components:{
        HeaderClean,
        Footer,
        Banner,
        //Uso de los componentes en la aplicacion
        GraficaCurricularA,
        contador,
        Loading
       
        

    },
  
  
    methods:{             
     
           showMsgBoxTwo() {
                                       
                this.boxTwo = ''
                localStorage.setItem('session',false)
                if(localStorage.getItem('session')==='false'){
                    this.varSession = false
                    console.log('desde el metodo',typeof this.varSession)
                    console.log(this.varSession)
                }             

                const h = this.$createElement
                const titleVNode = h('div', { domProps: { innerHTML: '<h3>¡MUCHAS GRACIAS!<h3>' } })
                this.$bvModal.msgBoxOk(titleVNode, {
                title: '',
                size: 'sm',
                font:'bold',
                
                buttonSize: 'sm',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(value => {
                    this.boxTwo = value

          

                })
                .catch(err => {
                    console.log(err)
                }) 
      },
        inputFocus(){
            var input1 = document.getElementById("input1")
            var input2= document.getElementById("input2")
            if(input1.value ===""){
                input2.focus()
            }
            else if(input2.value === ""){
                input1.focus
            }

        },
        limpiarFiltros(){
              
            this.valorSeleccionado=""
            this.valorSeleccionado2=""            
            this.valorSeleccionado3 = ""
            this.filters.nombre.value=""
            this.filters2.nombre.value=""
            this.filters3.nombre.value=""
            this.showDiv=false
            this.showDiv2=false
            this.showDiv3=false,
            
            
            this.lista2=[]
            this.lista3=[]
            this.listaPartidos = []
            this.listaAcciones =[],
            this.estudio.grado_maximo=""
            this.edad=""
            this.sexo=""
            //this.showDiv=false
           
          
             
        
        },
        limpiarFiltros2(){
            this.estudio.grado_maximo=""
            this.edad=""
            this.genero=""
            this.filters.nombre.value=""
            this.filters2.nombre.value=""
            this.filters3.nombre.value=""
            this.numeroSeccion=""
        },
        choose(){
            
            //this.informacion_personal.lugar_nacimiento=this.selection
        },
 
        ver(id){            
           
            this.thispath(`Curriculum/${id}`)
          
        },
        siguiente(){
            this.multipleChoiceValue
            //this.thispath('/newpartido')
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        consultar(e){
            console.log(e)
        },

        uploadImage(e){
            try {
                this.form.logo = this.$refs.fileInput.files[0]
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);
                    this.$refs.previewImage.src = e.target.result;
                }
            } catch (error) {
                console.log(error)
            }
        },
        deleteImage(e){
            this.form.logo = ''
            this.$refs.fileInput.value = null;
        },
        //Utilizamps axios para obtener los datos con la API
        async getPartidos(){
  
            //console.log(this.lista)
        },
                                                              
         
    
        //Utilizamos otra API para obtener datos SNR vinculamos ambos campos con folio de registro
        //Importante tomar folio para vincular ambas colecciones de datos
        getFilterByValue(){ 
             //Se realiza la consulta a la lista que se encuentra activada  
                  
             if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2==="")&&(this.valorSeleccionado3==="")){                
                this.showDiv= true //Bandera control de visibilidad div's
                this.showDiv3=true
                this.valorSeleccionado2 = ""
                this.valorSeleccionado3 = ""
                this.limpiarFiltros2()     

                this.listatmp=[]
                this.lista.forEach(element =>{
                    this.listatmp.push(element)                   
                    })                                     
                //se crea una Lista2 para la busqueda para el input text filtro
                this.listaPartidos=this.listatmp
                this.lista3=this.listatmp
                this.lista2=this.listatmp
                                     
            }
            else if((this.valorSeleccionado !== "todos")&&(this.valorSeleccionado2==="")&&(this.valorSeleccionado3==="")){
                this.showDiv= true // Se activa en primera instancia el  grupo de botonnes de partidos
               
                this.showDiv2=true
                this.showDiv3=true
                this.limpiarFiltros2()
                              
                //se activan radiobuttons de acciones afirmativas                                          
                this.listatmp=[]
                                                
                this.lista.forEach(element =>{
                    if(element.tipo_eleccion === this.valorSeleccionado){
                        this.listatmp.push(element)
                    }
                    else if((element.aa_tipo > 0 && element.aa === 1) && (Number(this.valorSeleccionado) === 4) ){
                        this.listatmp.push(element)                            
                    }}) 
                    this.listaAcciones = this.listatmp // se crea e inicializa esta lista para realizar bisqueda por acciones el el grupo de botones de acciones afirmativas
                    this.listaPartidos=this.listatmp  
                    this.lista3=this.listatmp
                    this.lista2=this.listatmp// //se crea una Lista2 para la busqueda para el input text filtro
                                   
                }
            //SE AGREGA POR OBSERVACIONES: Condicional valor seleccionado1 y valor selecionado 3
            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2==="")&&(this.valorSeleccionado3!=="")){              
                //se activan radiobuttons de acciones afirmativas
                this.limpiarFiltros2()  
                this.showDiv3=true                                                                   
                this.listatmp=[]                                    
                this.lista.forEach(element =>{
                    if((Number(this.valorSeleccionado) === 4) && (element.aa_tipo === Number(this.valorSeleccionado3))&&(element.aa === 1)){
                        this.listatmp.push(element)
                    }else if((element.tipo_eleccion === this.valorSeleccionado)&&(element.aa_tipo === Number(this.valorSeleccionado3)) && (element.aa === 1)){
                        this.listatmp.push(element) 
                    }               
                })
                    this.listaPartidos=this.listatmp   
                    this.listaAcciones = this.listatmp // se crea e inicializa esta lista para realizar bisqueda por acciones el el grupo de botones de acciones afirmativas
                    this.lista3=this.listatmp
                    this.lista2=this.listatmp// //se crea una Lista2 para la busqueda para el input text filtro
                                   
                }
            //Condicional que enlista por actores politicos
            else if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3==="")){
                this.limpiarFiltros2()
                this.showDiv2=true
                this.showDiv3=true                
                this.listatmp=[]
                this.lista.forEach(element =>{
                    if(element.postulante === this.valorSeleccionado2){
                        this.listatmp.push(element)
                    }else if(element.tipo_postulante === this.valorSeleccionado2) {
                        this.listatmp.push(element)
                    }
                    //else if para candidaturas comunes
                         
                })
                this.listaPartidos=this.listatmp
                this.lista2=this.listatmp
                this.lista3=this.listatmp
                
            }//Termina condicional que enlista por actores politicos

            //SE AGREGA POR OBSERVACIONES Enlista todas las aciones por accion hace lo mismo que si el radio buton 3
            else if((this.valorSeleccionado ==="todos")&&(this.valorSeleccionado2==="")&&(this.valorSeleccionado3!=="")){
                this.limpiarFiltros2()  
                this.showDiv2=true
                this.showDiv3=true
                               
                this.listatmp=[]
                this.lista.forEach(element =>{
                    if(element.aa_tipo === Number(this.valorSeleccionado3)&& (element.aa === 1)){
                        this.listatmp.push(element)}              
                        })
                    this.lista2=this.listatmp
                    this.listaPartidos=this.listatmp
                 }//Termina condicional que enlista por actores politicos

            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3==="")){
                this.limpiarFiltros2()  
                this.showDiv2=true
                this.showDiv3=true                                            
                this.listatmp=[]                                      
                this.lista.forEach(element =>{
                    if((element.postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado)){
                        this.listatmp.push(element)}
                    else if((element.postulante === this.valorSeleccionado2) && ( Number(this.valorSeleccionado) === 4)&&(element.aa===1)){
                        this.listatmp.push(element)}
                        else if((element.tipo_postulante === this.valorSeleccionado2) && ( Number(this.valorSeleccionado) === 4)&&(element.aa===1)){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado)) {
                        this.listatmp.push(element)
                    }
                    })
                    this.listaPartidos=this.listatmp                  
                    //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    this.lista3=this.listatmp
                    
                               
                 
                }
            else if((this.valorSeleccionado !=="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3!=="")){
                    this.limpiarFiltros2()                                                  
                    this.listatmp=[]                                      
                    this.lista.forEach(element =>{

                    if((element.postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado) &&(element.aa_tipo===Number(this.valorSeleccionado3))){
                        this.listatmp.push(element)}                        
                    else if((element.postulante === this.valorSeleccionado2) &&(Number(this.valorSeleccionado) === 4)&&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) &&(Number(this.valorSeleccionado) === 4)&&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))){
                        this.listatmp.push(element)}
                    else if((element.tipo_postulante === this.valorSeleccionado2) && (element.tipo_eleccion === this.valorSeleccionado) &&(element.aa_tipo===Number(this.valorSeleccionado3) &&(element.aa === 1))) {
                        this.listatmp.push(element)
                    }             
                    })
                    this.listaPartidos=this.listatmp                              
                    this.lista2=this.listatmp
                    this.lista3=this.listatmp
                    
                }
            else if((this.valorSeleccionado =="todos")&&(this.valorSeleccionado2!=="")&&(this.valorSeleccionado3!=="")){
                     this.limpiarFiltros2()                                         
                    this.listatmp=[]                                      
                    this.lista.forEach(element =>{
                    if((this.valorSeleccionado ==="todos") && (element.tipo_postulante  === this.valorSeleccionado2) &&(element.aa_tipo===Number(this.valorSeleccionado3))){
                        this.listatmp.push(element)}
                    else if((this.valorSeleccionado ==="todos") && (element.postulante  === this.valorSeleccionado2) &&(element.aa_tipo===Number(this.valorSeleccionado3))){
                        this.listatmp.push(element)}
                                
                    })
                    this.listaPartidos=this.listatmp                 
                    //se crea una Lista2 para la busqueda para el input text filtro
                    this.lista2=this.listatmp
                    this.lista3=this.listatmp
                   
                }             

            
          
          
        },
        //Metodo para obtener candidatos por acciones afirmativas 
        getUserByList(){
            //variable temporal par usar con regex               
            
            if((this.estudio.grado_maximo !== "")&&(this.edad === "")&&(this.genero ==="")){
                this.listatmp=[] 
                this.lista2.forEach(element =>{
                if(element.Estudio=== this.estudio.grado_maximo){                                                                                                                               
                    this.listatmp.push(element)
                                        
                }})
                this.lista3 = this.listatmp   
                this.listaPartidos=this.listatmp
            }       
            
            else if(this.estudio.grado_maximo ==="" && this.edad!=="" && this.genero ===""){
                this.listatmp=[] 
                var edades = this.edad            
                var numeros=edades.match(/\d+/g)                
                //console.log(numeros[0], numeros[1])
                //Inicia llenado de la lista
                this.lista2.forEach(element =>{
                    //console.log("Edad", element.propietario.edad) 
                    //console.log(Number(numeros[0]),Number(numeros[1]))                                    
                    if((element.edad >= Number(numeros[0]) && element.edad <= Number(numeros[1])) ){ 
                        this.listatmp.push(element)
                        }
                        else if((element.Estudio === this.estudio.grado_maximo)&&(element.edad >= Number(numeros[0])) && isNaN(Number(numeros[1]))){
                            this.listatmp.push(element)                    
                        }                      
                })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp                            
            }//termina seccion de segunda lista desplegable
            else if(this.estudio.grado_maximo ==="" && this.edad==="" && this.genero !==""){
                this.listatmp=[] 
             
                this.lista2.forEach(element =>{                                                   
                    if(element.genero ===this.genero[0] ){ 
                        this.listatmp.push(element)
                        }                                          
                    })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp                            
            }//termina seccion de tercer lista desplegable

            else if(this.estudio.grado_maximo !=="" && this.edad!=="" && this.genero !=="" ){
                this.listatmp=[] 
                this.lista2.forEach(element =>{
                var edades = this.edad            
                 var numeros=edades.match(/\d+/g)     
                                        
                    if((element.Estudio === this.estudio.grado_maximo)&&(element.edad >= Number(numeros[0]) && element.edad < Number(numeros[1]))&& (element.genero === this.genero[0])){ 
                        this.listatmp.push(element)
                        }
                    else if((element.Estudio === this.estudio.grado_maximo)&&(element.edad >= Number(numeros[0])) && (isNaN(Number(numeros[1])))&&(element.genero ===this.genero[0])){
                            this.listatmp.push(element)                    
                        }                                          
                })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp 
                
            }
            else if(this.estudio.grado_maximo !=="" && this.edad!=="" && this.genero ==="" ){
                this.listatmp=[] 
                this.lista2.forEach(element =>{
                var edades = this.edad            
                 var numeros=edades.match(/\d+/g)     
                                        
                    if((element.Estudio === this.estudio.grado_maximo)&&(element.edad >= Number(numeros[0]) && element.edad <= Number(numeros[1]))){ 
                        this.listatmp.push(element)
                        }
                    else if((element.Estudio === this.estudio.grado_maximo)&&(element.edad >= Number(numeros[0])) && (isNaN(Number(numeros[1])))){
                            this.listatmp.push(element)                    
                        }                                          
                })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp 
                
            }
            //de ABAJO HACIA ARRIBA
            else if(this.estudio.grado_maximo ==="" && this.edad!=="" && this.genero !=="" ){
                this.listatmp=[] 
                this.lista2.forEach(element =>{
                var edades = this.edad            
                 var numeros=edades.match(/\d+/g)     
                                        
                    if((element.edad >= Number(numeros[0]) && element.edad < Number(numeros[1]))&& (element.genero === this.genero[0])){ 
                        this.listatmp.push(element)
                        }
                    else if((element.edad >= Number(numeros[0])) && (isNaN(Number(numeros[1])))&&(element.genero ===this.genero[0])){
                            this.listatmp.push(element)                    
                        }                                          
                })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp 
            }
            else if(this.estudio.grado_maximo !=="" && this.edad==="" && this.genero !=="" ){
                this.listatmp=[] 
                this.lista2.forEach(element =>{
                //var edades = this.edad            
                 //var numeros=edades.match(/\d+/g)     
                                        
                    if((element.Estudio === this.estudio.grado_maximo)&& (element.genero === this.genero[0])){ 
                        this.listatmp.push(element)
                        }
                                                        
                })
                this.lista3 =this.listatmp 
                this.listaPartidos=this.listatmp 
            }
                


        },
             
        //Método ára obtener candidatos por medio del input de busqueda
        getFilterBySearch(){                   
                //Se realiza busqueda con coincidencia en lista
                this.activarLista=true;     
                this.listatmp=[]                  
                                              
                this.lista2.forEach(element =>{              
                    if(element.genero.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  
                    }
                    else if(element.nombre.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)  

                    }
                    else if(element.edad === (Number(this.filters.nombre.value))){
                        this.listatmp.push(element)  

                    } else if(element.postulante.startsWith(this.filters.nombre.value.toUpperCase()) === true){
                        this.listatmp.push(element)
                    }                    
                    else if(this.filters.nombre.value === ""){
                        this.listatmp=this.lista2
                    }
                })
                //se va prellenando la lista 3
                this.lista3=this.listatmp                  
                this.listaPartidos=this.listatmp   
                    
        },
            //Unimos la lista con las suplencias
        filtrarMunicipios(municipio){
            
            this.activarLista=false;           
            //Se realiza busqueda con coincidencia en lista
            /**
            const info = this.buscarSeccion(municipio); // obtiene la seccion si coinicide con el municipio
            this.listatmp=[]
            let mpioOracion = municipio.charAt(0).toUpperCase() + municipio.slice(1).toLowerCase()      
            this.lista2.forEach(element=>{           
                if(element.cve_mpio.startsWith(this.findMunicipio(mpioOracion)) === true){                              
                    this.listatmp.push(element)
                }else if((Number(element.cve_mpio) === info.municipio)||(Number(element.cve_distrito)===info.distrito)){
                    
                    this.listatmp.push(element)

                }else if(mpioOracion ===""){
                    this.listatmp=this.lista2
                }          
                
            })
            this.lista3=this.listatmp
            this.listaPartidos=this.listatmp  
             */
            const info = this.buscarSeccion(municipio); // obtiene la seccion si coinicide con el municipio
            this.listatmp=[]
            //let mpioOracion = municipio.charAt(0).toUpperCase() + municipio.slice(1).toLowerCase()
           
            this.lista2.forEach(element=>{ 
                let municipio1 = this.sustituirAcentos(municipio).toLowerCase()
                let municipio2=this.sustituirAcentos(element.municipio).toLowerCase() 
                  
                if(municipio2.startsWith(municipio1) === true){                              
                    this.listatmp.push(element)
                }else if((Number(element.cve_mpio) === info.municipio)||(Number(element.cve_distrito)===info.distrito)){
                    
                    this.listatmp.push(element)

                }else if(municipio1 ===""){
                    this.listatmp=this.lista2
                }          
                
            })
            this.lista3=this.listatmp
            this.listaPartidos=this.listatmp  
            
        },
        sustituirAcentos(valores){
            return valores.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        },
        getFilterBySearchTable(){
            // si estan seleccionadas ambas se realiza busqueda en lista
            //const res = await axios.get(`/api/candidatos`)
            //this.lista=res.data[0].lista;
            //this.activarLista=false;
            this.activarInput=true         
                //Se realiza busqueda con coincidencia en lista
            this.listatmp=[]
      
                    if(this.filters2.nombre.value!=="" && this.filters3.nombre.value===""){
                    this.lista3.forEach(element =>{              
                        if(element.nombre.startsWith(this.filters2.nombre.value.toUpperCase()) === true){
                            this.listatmp.push(element)
                        }             
                        })                                       
                    }         

                    else if(this.filters3.nombre.value!=="" && this.filters2.nombre.value===""){
                        this.lista3.forEach(element =>{              
                            if(element.suplente.startsWith(this.filters3.nombre.value.toUpperCase()) === true){
                                this.listatmp.push(element)
                            }
                        })                        
                    }
            
                    else if(this.filters2.nombre.value!=="" && this.filters3.nombre.value!==""){
                        this.lista3.forEach(element =>{              
                        if(element.nombre.startsWith(this.filters2.nombre.value.toUpperCase()) === true){
                            if(element.suplente.startsWith(this.filters3.nombre.value.toUpperCase()) === true){
                                this.listatmp.push(element)
                            }                      
                        }
                     }) 
                     
                    }                 
                    else if(this.filters2.nombre.value === "" || this.filters3.nombre.value === ""){
                        this.listatmp=this.lista2
                        this.estudio.grado_maximo=""
                        this.edad=""
                        this.genero=""
                       
                    }
                    this.listaPartidos=this.listatmp   
                         
            },         
        async generateReport(){           

            this.datosCandidato = this.lista.map((datos,contador=0)=>{
                //ingresar otro map con la lista de candidatos registrados que coincidan con la busqueda datos de alta y con informacion
                //Validacion if para mostrar en el reporte a los candidatos validados en caso de que no esten validados se muestra solo la información basica
                let cargoc=this.getCargo(datos.tipo_eleccion)
                //let municipion=this.getMunicipios(datos.cve_mpio)       
                   
                if(datos.ValidacionCPP === true && datos.Publicacion === true){
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS
                        "DIRECCION_CASA_CAMPAÑA":datos.Direccion,
                        "TELEFONO":datos.Telefono,
                        "CORREO_ELECTRONICO":datos.Email,
                        "PAGINA_WEB":datos.Pagina_w,
                        "REDES":datos.Redes,
                        "ESCOLARIDAD":datos.Estudio,
                        "ESTATUS":datos.Estatus,
                        "CURSOS":datos.Otros,
                        "HISTORIA_PROFESIONAL":datos.Historia,
                        "TRAYECTORIA_POLITICA":datos.Trayectoria,
                        "MOTIVO_CARGO_PUBLICO":datos.Razon,
                        "PROPUESTA_1":datos.Propuesta1,
                        "PROPUESTA_2":datos.Propuesta2,
                        "PRPUESTA_MATERIA_GENERO":datos.Propuesta3
                        
                        };
                }else{
                    return{
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //se muestran en vacio si no estan validados
                                       //DATOS PARA REPORTE DE CANDIDATOS
                        "DIRECCION_CASA_CAMPAÑA":"",
                        "TELEFONO":"",
                        "CORREO_ELECTRONICO":"",
                        "PAGINA_WEB":"",
                        "REDES":"",
                        "ESCOLARIDAD":"",
                        "ESTATUS":"",
                        "CURSOS":"",
                        "HISTORIA_PROFESIONAL":"",
                        "TRAYECTORIA_POLITICA":"",
                        "MOTIVO_CARGO_PUBLICO":"",
                        "PROPUESTA_1":"",
                        "PROPUESTA_2":"",
                        "PRPUESTA_MATERIA_GENERO":""
                    }     
                    
                }                
            }) //.map((candidatoElement) => Object.values(candidatoElement).join(",")).join("\n");
            //Object.keys(this.lista2[0]).slice(0,3)
            // const headers = Object.values(this.headersReport)
            //const reporteCandidatos = headers +"\n"+ datosCandidato
            //hacemos el llamado de la funcion
            //console.log(this.headersReport)
            this.datosCandidatoIdentidad = this.lista.map((datos,contador=0)=>{
                //ingresar otro map con la lista de candidatos registrados que coincidan con la busqueda datos de alta y con informacion
                
                let cargoc=this.getCargo(datos.tipo_eleccion)
                let municipion=this.getMunicipios(datos.cve_mpio)
                if(datos.ValidacionCPP === true && datos.Publicacion === true){
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS IDENTIDAD
                        "Pregunta 1: ¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?":datos.Indigena,
                        "Pregunta 2: ¿Habla o entiende alguna lengua indígena?":datos.Lengua,
                        "Pregunta 3: ¿A qué pueblo y/o comunidad indígena pertenece?":datos.Comunidad,
                        "Pregunta 4: ¿Tiene algún tipo de discapacidad?":datos.Discapacidad,
                        "Pregunta 5: El tipo de discapacidad con el que vive es:":datos.Estatusd,
                        "Pregunta 6: El tipo de discapacidad con el que vive es:":datos.Tipo,
                        "Pregunta 7: Su tipo de discapacidad le dificulta o impide:":datos.Dificultad,
                        "Pregunta 8: ¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?":datos.Afromexicana,
                        "Pregunta 9: ¿Es usted una persona de la población LGBTTTIQ+":datos.Diversidad,
                        "Pregunta 10: Usted se identifica como:":datos.Tipod,
                        "Pregunta 11: ¿Es usted migrante?":datos.Migrante,
                        "Pregunta 12: ¿En qué país reside?":datos.Paise,
                        "Pregunta 13: ¿Cuánto tiempo ha vivido en el extranjero?":datos.Tiempoe,
                        "Pregunta 14: ¿Cuál fue el motivo de la residencia en el extranjero?":datos.Motivo,
                        "Pregunta 15: ¿Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?":datos.Situaciona,
                        "Pregunta 16: ¿Es parte de la población joven? (Entre los 21 y 29 años)":datos.Joven,
                        "Pregunta 17: ¿Es parte de la población de personas mayores? (60 años o más)":datos.Mayor,
                        "Pregunta 18: Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.":datos.Socioeconomico,
                        "Pregunta 19: Su fuente principal de ingresos es:":datos.Ingresos,
                        "Pregunta 20: Entidad federativa de nacimiento:":datos.Entidad
                    };
                }else{
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS IDENTIDAD
                        "Pregunta 1: ¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?":"",
                        "Pregunta 2: ¿Habla o entiende alguna lengua indígena?":"",
                        "Pregunta 3: ¿A qué pueblo y/o comunidad indígena pertenece?":"",
                        "Pregunta 4: ¿Tiene algún tipo de discapacidad?":"",
                        "Pregunta 5: El tipo de discapacidad con el que vive es:":"",
                        "Pregunta 6: El tipo de discapacidad con el que vive es:":"",
                        "Pregunta 7: Su tipo de discapacidad le dificulta o impide:":"",
                        "Pregunta 8: ¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?":"",
                        "Pregunta 9: ¿Es usted una persona de la población LGBTTTIQ+":"",
                        "Pregunta 10: Usted se identifica como:":"",
                        "Pregunta 11: ¿Es usted migrante?":"",
                        "Pregunta 12: ¿En qué país reside?":"",
                        "Pregunta 13: ¿Cuánto tiempo ha vivido en el extranjero?":"",
                        "Pregunta 14: ¿Cuál fue el motivo de la residencia en el extranjero?":"",
                        "Pregunta 15: ¿Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?":"",
                        "Pregunta 16: ¿Es parte de la población joven? (Entre los 21 y 29 años)":"",
                        "Pregunta 17: ¿Es parte de la población de personas mayores? (60 años o más)":"",
                        "Pregunta 18: Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.":datos.Socioeconomico,
                        "Pregunta 19: Su fuente principal de ingresos es:":"",
                        "Pregunta 20: Entidad federativa de nacimiento:":"",
                    };
                }     
                   

            })
            this.exportToExcel(this.datosCandidato, this.datosCandidatoIdentidad)          
            //this.metodoExcelCreateReport(reporteCandidatos)
            //await fs.writeFile(path.join(__dirname,'candidatos.csv'),reporteCandidatos)            
       },//Termina metodo generateReport   
    async generateReport2() {           

        this.datosCandidato = this.listaPartidos.map((datos,contador=0)=>{
                //ingresar otro map con la lista de candidatos registrados que coincidan con la busqueda datos de alta y con informacion
                //Validacion if para mostrar en el reporte a los candidatos validados en caso de que no esten validados se muestra solo la información basica
                let cargoc=this.getCargo(datos.tipo_eleccion)
                //let municipion=this.getMunicipios(datos.cve_mpio)
                console.log(datos.nombre+" "+datos.paterno+" "+datos.materno,": "+datos.Historia)      
                   
                if(datos.ValidacionCPP === true && datos.Publicacion === true){
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS
                        "DIRECCION_CASA_CAMPAÑA":datos.Direccion,
                        "TELEFONO":datos.Telefono,
                        "CORREO_ELECTRONICO":datos.Email,
                        "PAGINA_WEB":datos.Pagina_w,
                        "REDES":datos.Redes,
                        "ESCOLARIDAD":datos.Estudio,
                        "ESTATUS":datos.Estatus,
                        "CURSOS":datos.Otros,
                        "HISTORIA_PROFESIONAL":datos.Historia,
                        "TRAYECTORIA_POLITICA":datos.Trayectoria,
                        "MOTIVO_CARGO_PUBLICO":datos.Razon,
                        "PROPUESTA_1":datos.Propuesta1,
                        "PROPUESTA_2":datos.Propuesta2,
                        "PROPUESTA_MATERIA_GENERO":datos.Propuesta3
                        
                        };
                }else{
                    return{
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //se muestran en vacio si no estan validados
                        //DATOS PARA REPORTE DE CANDIDATOS
                        "DIRECCION_CASA_CAMPAÑA":"",
                        "TELEFONO":"",
                        "CORREO_ELECTRONICO":"",
                        "PAGINA_WEB":"",
                        "REDES":"",
                        "ESCOLARIDAD":"",
                        "ESTATUS":"",
                        "CURSOS":"",
                        "HISTORIA_PROFESIONAL":"",
                        "TRAYECTORIA_POLITICA":"",
                        "MOTIVO_CARGO_PUBLICO":"",
                        "PROPUESTA_1":"",
                        "PROPUESTA_2":"",
                        "PROPUESTA_MATERIA_GENERO":""
                    }     
                    
                }                
            }) 
            this.datosCandidatoIdentidad = this.listaPartidos.map((datos,contador=0)=>{
                //ingresar otro map con la lista de candidatos registrados que coincidan con la busqueda datos de alta y con informacion
                
                let cargoc=this.getCargo(datos.tipo_eleccion)
                //let municipion=this.getMunicipios(datos.cve_mpio)
                if(datos.ValidacionCPP === true && datos.Publicacion === true){
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS IDENTIDAD
                        "Pregunta 1: ¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?":datos.Indigena,
                        "Pregunta 2: ¿Habla o entiende alguna lengua indígena?":datos.Lengua,
                        "Pregunta 3: ¿A qué pueblo y/o comunidad indígena pertenece?":datos.Comunidad,
                        "Pregunta 4: ¿Tiene algún tipo de discapacidad?":datos.Discapacidad,
                        "Pregunta 5: El tipo de discapacidad con el que vive es:":datos.Estatusd,
                        "Pregunta 6: El tipo de discapacidad con el que vive es:":datos.Tipo,
                        "Pregunta 7: Su tipo de discapacidad le dificulta o impide:":datos.Dificultad,
                        "Pregunta 8: ¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?":datos.Afromexicana,
                        "Pregunta 9: ¿Es usted una persona de la población LGBTTTIQ+":datos.Diversidad,
                        "Pregunta 10: Usted se identifica como:":datos.Tipod,
                        "Pregunta 11: ¿Es usted migrante?":datos.Migrante,
                        "Pregunta 12: ¿En qué país reside?":datos.Paise,
                        "Pregunta 13: ¿Cuánto tiempo ha vivido en el extranjero?":datos.Tiempoe,
                        "Pregunta 14: ¿Cuál fue el motivo de la residencia en el extranjero?":datos.Motivo,
                        "Pregunta 15: ¿Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?":datos.Situaciona,
                        "Pregunta 16: ¿Es parte de la población joven? (Entre los 21 y 29 años)":datos.Joven,
                        "Pregunta 17: ¿Es parte de la población de personas mayores? (60 años o más)":datos.Mayor,
                        "Pregunta 18: Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.":datos.Socioeconomico,
                        "Pregunta 19: Su fuente principal de ingresos es:":datos.Ingresos,
                        "Pregunta 20: Entidad federativa de nacimiento:":datos.Entidad
                    };
                }else{
                    return{ 
                        "No.":contador+1,
                        "PARTIDO:":datos.partido_nombre,                        
                        "CARGO":cargoc,
                        "TIPO_PARTIDO":datos.tipo_partido,
                        "POSTULANTE": datos.postulante,
                        "MUNICIPIO": datos.municipio,
                        "DISTRITO": datos.cve_distrito,                        
                        "PROPIETARIO":datos.nombre+" "+datos.paterno+" "+datos.materno,
                        "EDAD":datos.edad,
                        "SEXO:":datos.genero,
                        "SUPLENTE":datos.suplente,
                        //DATOS PARA REPORTE DE CANDIDATOS IDENTIDAD
                        "Pregunta 1: ¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?":"",
                        "Pregunta 2: ¿Habla o entiende alguna lengua indígena?":"",
                        "Pregunta 3: ¿A qué pueblo y/o comunidad indígena pertenece?":"",
                        "Pregunta 4: ¿Tiene algún tipo de discapacidad?":"",
                        "Pregunta 5: El tipo de discapacidad con el que vive es:":"",
                        "Pregunta 6: El tipo de discapacidad con el que vive es:":"",
                        "Pregunta 7: Su tipo de discapacidad le dificulta o impide:":"",
                        "Pregunta 8: ¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?":"",
                        "Pregunta 9: ¿Es usted una persona de la población LGBTTTIQ+":"",
                        "Pregunta 10: Usted se identifica como:":"",
                        "Pregunta 11: ¿Es usted migrante?":"",
                        "Pregunta 12: ¿En qué país reside?":"",
                        "Pregunta 13: ¿Cuánto tiempo ha vivido en el extranjero?":"",
                        "Pregunta 14: ¿Cuál fue el motivo de la residencia en el extranjero?":"",
                        "Pregunta 15: ¿Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?":"",
                        "Pregunta 16: ¿Es parte de la población joven? (Entre los 21 y 29 años)":"",
                        "Pregunta 17: ¿Es parte de la población de personas mayores? (60 años o más)":"",
                        "Pregunta 18: Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.":datos.Socioeconomico,
                        "Pregunta 19: Su fuente principal de ingresos es:":"",
                        "Pregunta 20: Entidad federativa de nacimiento:":"",
                    };
                }     
                   

            })
            this.exportToExcel(this.datosCandidato, this.datosCandidatoIdentidad)         
                    
        },//Termina metodo generateReport   
       


    exportToExcel(datos, datosi){
        const ws= XLSX.utils.json_to_sheet(datos); // Repetimos la misma coleccion pendiente por generar la seguns coleccion desde otro metodo
        const ws1= XLSX.utils.json_to_sheet(datosi);
        // Repetimos la misma coleccion pendiente por generar la seguns coleccion desde otro metodo
        const wb= XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'Cuestionario Curricular');
        XLSX.utils.book_append_sheet(wb,ws1,'Cuestionario de Identidad');

        XLSX.writeFile(wb,'baseDatosCandidatosCandidatas.xlsx')        
        //Configurar timer en que se muestra la encuesta        

    },
    

    mergeLista(listaP, listaC){
            return listaP.map( element1 =>{
                 //const element2 = listaC.find(element => Number(element.id_postulacion) === element1.id_postulacion)
                 //SE MODIFICA ESTA LINEA DEBIDO A QUE NO SE MUESTRA EL AVATAR DEL CANDIDATO
                //const element2 = listaC.find((element => Number(element.id_postulacion) === element1.id_postulacion) ||(element => Number(element.id_postulacion) === element1.id) )
                const element2 = listaC.find(element => Number(element.idsc) === element1.id) 
                
                return{
                ...element1,
                ...(element2 && {
                    validacionFotografia: this.validarFoto(element2.imagen,element2.validacionCPyPP),              
                    imagen:element2.imagen, 
                    Folio: element2.id_postulacion,
                    //Obtencion de la informacion curricular del candidato
                    Direccion: element2.curriculares.contacto.direccion[0],
                    Telefono: element2.curriculares.contacto.telefono[0],
                    Email:element2.curriculares.contacto.email[0],
                    Pagina_w:element2.curriculares.contacto.web,
                    Redes:element2.curriculares.social.facebook +" "+element2.curriculares.social.twitter +" "+element2.curriculares.social.youtube +" "+element2.curriculares.social.instagram +" "+element2.curriculares.social.tiktok,
                    Estudio:element2.curriculares.estudio.grado_maximo,
                    Estatus:element2.curriculares.estudio.estatus,
                    Otros: this.getItems(element2.curriculares.estudio.otro),
                    Historia: element2.curriculares.profesion.historia_laboral.replace(/- •/g, ''),
                    Trayectoria: element2.curriculares.profesion.trayectoria_politica,
                    Razon: element2.curriculares.profesion.razon_cargo_publico,
                    Propuesta1:element2.curriculares.propuesta.propuesta1,
                    Propuesta2:element2.curriculares.propuesta.propuesta2,
                    Propuesta3:element2.curriculares.propuesta.propuesta_grupo_discriminacion,
                    // Obtencion de las preguntas de identidad (20 preguntas)
                    Indigena:element2.identidad.indigena,
                    Lengua:element2.identidad.lengua_indigena,
                    Comunidad:element2.identidad.comunidad_indigena,
                    Discapacidad:element2.identidad.discapacidad,
                    Estatusd:element2.identidad.estatus_discapacidad,
                    Tipo:element2.identidad.tipo_discapacidad,
                    Dificultad: element2.identidad.dificultad_discapacidad,
                    Afromexicana: element2.identidad.afromexicana,
                    Diversidad:element2.identidad.diversidad_sexual,
                    Tipod:element2.identidad.tipo_diversidad_sexual,
                    Migrante:element2.identidad.migrante,
                    Paise:element2.identidad.pais_extranjero,
                    Tiempoe:element2.identidad.tiempo_extranjero,
                    Motivo:element2.identidad.motivo,
                    Situaciona:element2.identidad.situacion_asegurada,
                    Joven:element2.identidad.joven,
                    Mayor:element2.identidad.mayor,
                    Socioeconomico:element2.identidad.socioeconomico,
                    Ingresos:element2.identidad.ingresos,
                    Entidad:element2.identidad.entidad_nacimiento,
                    //Obtencion de datos validados 
                    ValidacionCPP:element2.validacionCPyPP,
                    Publicacion: element2.identidad.publicacion

                } )
            }
            }) 
    },
    getItems(otro){
        let estudios=""
        for(let i=0;i < otro.length; i++){
            estudios += i+1+":"+otro[i]+" "        

        }
        return estudios
    
    },
    validarFoto(foto,vcpp){
        if(foto!=="" && vcpp===true){
            return true
        }
        else{
            return false
        }

    },
    
    
    async getAllUsers(){
            this.activarLista=false       
  
    },
    async getImageCandidatos(folio){
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidatos/${folio}`
            try {                
                 const info = await axios.get(url)                 
                 //this.folioRegistroCandidato=info.data[0].folioRegistroCandidato
                this. imagenes = info.data[0].imagen
                                     
            } catch (error) {
                console.log('No existe imagen', error)
                
            }  
    },
    getLogoPartidos(partido,tipo_postulante,postulante){
              //inicializamos la lista de logos        
        const jsonString1 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString2 = '{"1":"pan-1.png","2":"pri-2.png","3":"prd-3.png","4":"mpv-13.png","5":"mpv-13.png","6":"pmc-6.png","7":"mpv-13.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png","12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"paracho5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const jsonString3 = '{"1":"pan-pri-prd.png","2":"pan-pri-prd.png","3":"pan-pri-prd.png","4":"pt-4.png","5":"pvem-5.png","6":"pmc-6.png","7":"morena-7.png","8":"pes-8.png","9":"mas-9.png","10":"mp-10.png","11":"txm-11.png"}'
        const jsonString4 ='{"12":"EPC.png","13":"Chava_Cortes.png","14":"mejoremos_paracho.png","15":"logo.paracho.5X5.png","16":"udp.png","17":"sombrero.png","18":"Sombreriza_Norte.png","19":"Carlos_Manzo.png","20":"CHINICUILA_LOGO.png"}'
        const objt1 = JSON.parse(jsonString1)
        const objt2 = JSON.parse(jsonString2)
        const objt3 = JSON.parse(jsonString3)

        const objt4 = JSON.parse(jsonString4)

        if(tipo_postulante==="1"){
            const logo  = objt1[partido];
        return logo
        }
        if(tipo_postulante==="2"){
            const logo  = objt2[partido];
        return logo
        }
        if(tipo_postulante==="3"){
            if(postulante === "PAN-PRI-PRD"){
                const logo  = objt3[partido];
                return logo   
            }else if(postulante=="PAN-PRI"){
                const logo  = "pan-pri.png";
                return logo  

            }else if(postulante=="PRI-PRD"){
                const logo  = "pri-prd.png";
                return logo
            }else if(postulante=="PAN-PRD"){
                const logo  = "pan-prd.png";
                return logo
            }else if(postulante=="PRD-PESM"){
                const logo  = "prd-pesm.png";
                return logo
            }else if(postulante=="PT-MORENA"){
                const logo  = "pt-morena.png";
                return logo
            }else if(postulante=="PT-PESM"){
                const logo  = "pt-pesm.png";
                return logo
            }else if(postulante=="PVEM-MORENA"){
                const logo  = "pvem-morena.png";
                return logo
            }else if(postulante=="PT-PVEM"){
                const logo ="pt-pvem.png";
                return logo
            }
        }
        if(tipo_postulante==="4"){
            const logo = objt4[partido]
            return logo
        }


    },
    getNombresPartidos(partidoN){
        const partidop  = this.partidosP[partidoN];
        return partidop

    },
    getMunicipios(cve_mpio){
        //inicializamos la lista de logos             
        const mpio  = this.municipios_mich[cve_mpio];
        return mpio

    },
    getCargo(tipo_eleccion){
        const cargo = this.tipoEleccion[tipo_eleccion]
        return cargo
    },
    getSuplencias(id_postulacion){
        //Busca la suplencia dentro de la lista
        this.listaS.forEach(element =>{
            if(element.id_postulacion === id_postulacion ){
                this.suplentes = element.nombre+" "+ " "+element.paterno+" "+element.materno                           
            }                 
        })
        return this.suplentes   

    },
    findMunicipio( municipio){
        for(let clave in this.municipios_mich){
            if(this.municipios_mich[clave] === municipio){
                console.log((typeof clave))
                return clave;
            }
        }
    },

    //se crea este metodo se llama un data json con todas las secciones
    buscarSeccion(seccion){      
        
        const result = this.secciones_mich.find(item => item.SECCIÓN === Number(seccion));
        if (result) {
            return {
                municipio: result["CLAVE MUNICIPIO"],
                distrito: result["DISTRITO"]
                };
            } else {
            return "Seccion no encontrada";
            } 
        
    },
         //Unimos la lista con las suplencias tanto RP y MR
    mergeListaSuplentes(listaP, listaS, listaS2){
            return listaP.map( element1 =>{
                if(element1.cve_distrito > 0){
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else if(element1.cve_distrito === "0" && element1.cve_mpio === "0"){
                    const element2 = listaS2.find(element => (element.posicion === element1.posicion+1)&&(element.id_postulacion===element1.id_postulacion))
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else{
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:""                  
                     } )
                    }
                }   
            })
        }
    
    }, //Terminan los metodos    
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        let listatmp=[]
        //Se inicializan las listas que conforman la tabla se realiza con create para no utilizar promesas
        const res2 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/candidatos`)
        this.listaC=res2.data;
        const res1 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos`)
        this.listaP=res1.data; 
        //console.log(this.listaC)
        //Se crea la lista de diputaciones suplentes para buscar las suplencias dentro de esta lista que coinicidad con el cve_distrito e id_postulante
        const res3 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/diputacioness`)
        this.listaS = res3.data;
       

        //SE INTEGRA PARA DPR Y MR
        //relizamos filtro de la lista con los partidos sin suplencias de drp
        this.listaP.forEach(element=>{
         if((element.posicion%2) !== 0){
                this.listaSRPMR.push(element) // Lista que guarda puras DRP propietarias
            }
         })
        //console.log("LISTA FILTRADA SIN SUPLENCIAS",this.listaSRPMR)
        this.listaP.forEach(element=>{
            if((element.posicion%2)===0){
                this.listaCRPMR.push(element) //Lista que guarda puras DRP suplentes
            }

        })
        //console.log("LISTA FILTRADA DE LAS SUPLENCIAS",this.listaCRPMR)

        //Unimos las listas donde exitan suplentes
        //this.listaPS = this.mergeListaSuplentes(this.listaP, this.listaS)
        // //Creamos una lista con todas los ayuntamientos, DMR Y DRP Filtradas sin suplencias
        this.listaPS = this.mergeListaSuplentes(this.listaSRPMR, this.listaS,this.listaCRPMR) //SE QUITA this.listaP 
        //se combinan las listas de acuerdo a una condicion en el metodo
        listatmp = this.mergeLista(this.listaPS, this.listaC)
        //console.log("LISTA CON IMAGEN",listatmp)
        listatmp.forEach(element =>{
            this.lista.push(element)
            
        })
        this.isLoading=false
        
        //console.log(this.lista)       
        // Inicializa la lista ordenada por nombre por medio del metodo create y se utilizan metodos de conversion a a mayusculas
        const orderList = this.lista.sort((a,b)=>{
            const nameA = a.nombre.toUpperCase()
            const nameB = b.nombre.toUpperCase()
            if(nameA < nameB){
                return -1
            }
            if(nameA > nameB){
                return 1
            }
            return 0
        })       
        this.lista = orderList
       
        
      

  

        
        //Metodos de variables de sesion de la ventana modal
        if(!localStorage.getItem('session')){            
            localStorage.setItem('session',true)            
        
        }else{
            if(localStorage.getItem('session')==='false'){
                    this.varSession = false
                    //console.log('desde la inicializacion',typeof this.varSession)
                    //console.log(this.varSession)

                }
            }
    /*
        //Google Analytics
        // Accede a los datos de Google Analytics
        this.$ga.report('ga:pageviews', {
            'start-date': '30daysAgo',
            'end-date': 'today'
        }).then(response => {
            this.visitas = response.data.totalsForAllResults['ga:pageviews']
        }).catch(error => {
            console.error('Error al obtener datos de Google Analytics:', error)
        })
        */
         
},
mounted() {
        window.scrollTo(0,0);
        
    // Cargar el script de Google Tag Manager
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YM7ZLSHPGR';
        document.head.appendChild(script);

        // Configurar el dataLayer
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-YM7ZLSHPGR');
        },     

          
    }      
    

</script>

<style scoped>
.size-logos{
    height: 45px;
    border-radius: 4px;
}
.custom-table {
  font-family: GalanoRegular;
  border-collapse: collapse;
  width: 100%;
}
.custom-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.custom-table tr:nth-child(even){background-color: #f2f2f2;}
.custom-table tr:hover {background-color: #ddd; cursor: pointer;}
.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #a31a77;
  color: white;
  padding-left: 5px;
}
.custom-table th:hover {cursor: pointer;}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(163,26,119, 1);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #a31a77   ;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #a31a77;
    --bs-btn-border-color: #a31a77; 
    --bs-btn-hover-color: #fff;
    /*--bs-btn-hover-bg: #6a6ea8;*/
    --bs-btn-hover-border-color: #727271;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: inline-flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  margin: 25%;
  width: 50%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      display:inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      border: 2px solid #a31a77;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #a31a77;
      border: 2px solid #a31a77;
      color: white;
      transform: scale(1.111, 1.111);
      .radio-tile-label {
        color: white;
        background-color: #a31a77;
      }
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #a31a77;
    font-size: 15px;
}

button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
/* Se agregan nuevas clases para mostrar radiobutons de filtrtado**/
.buttons-container{
    text-align:start;    
    width: 100%;
    margin-bottom: 1%;
 
}
.label-color{
    /*color:#6a6ea8;  */
    color:#4d4170

}
.button-separation{
    
    padding-right: 2%;
    margin-bottom: 2%;

}
.btn-align-right{
      
    margin-bottom: 5px;   
}
.fade-enter-active, .fade-leave-active{
    transition:opacity 0.8s
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}
/**Estilos botones side bar menu graficas */
.btn-toggle {
  
  font-weight: bolder;
  color: #686868; 
  font-size: 20px;
  text-align: left;
  
  
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: #990089;
  background-color: #fff;
   font-weight: bolder;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;

}
.graphics{
    border-top: 2px solid #bbbaba ;
    
    
}
.graphics-b{
    border-right: 2px solid #bbbaba ;
    
}
h2 {
    font-family: GalanoRegular;
    color: #717171;
    
    font-weight: bolder;
    font-size:xx-large;
    text-align:center;
    
    
}
.tooltip-img{
    width: 400px;
    border-radius: 10px;
  
}

/*stylos cuestionario*/
.style-img{
    width: 50px;
    height: 50px;
}

 


</style>