<template>
 
    <div class="container">
        <div class="row head-size">
            <img
                src="../assets/pleca_faltante.png"
                class="img-fluid rounded-top"
                alt=""                
            />
        </div>
        <span class=" d-flex justify-content-start align-content-start mt-4"><h4><fa icon="fa-solid fa-home" style="text-shadow: text-shadow: 1px 1px 2px #fff;"/>&nbsp;&nbsp;Visita Nuestro Sitio Web: <a href="https://www.iem.org.mx/" target="_blank" style="text-decoration: none; color:#990089;">https://www.iem.org.mx/</a></h4></span>
        <hr>
       
        <div class="row row-size">           
            <div class="col-xs-12 col-sm-12 col-lg-12">
                <h1>Consulta toda la información en: <a href="https://conoceles.iem.org.mx/">https://conoceles.iem.org.mx/</a><br>IEM 2024</h1>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-12">
                
                <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    indicators
                    img-width="960"
                    img-height="520"
                    interval="3000"
                 
                    >
                    <!-- Text slides with image -->
                    <b-carousel-slide>
                        <template #img>
                            <div class="d-flex justify-content-center align-content-center">
                                <img class="d-block img-fluid w-50" width="960" src="../assets/boton_conoceles.png" alt="image slot">                            
                            </div>
                        </template>                                  
                    </b-carousel-slide>

                    <!-- Slides with custom text -->
                    <b-carousel-slide>
                        <template #img>
                            <div class="d-flex justify-content-center align-content-center">
                                <img class="d-block img-fluid w-50" width="960" src="../assets/mapa.png" alt="image slot">
                            </div>         
                        </template> 
                       
                    </b-carousel-slide>

            
                    <b-carousel-slide>
                        <template #img>
                            <div class="d-flex justify-content-center align-content-center">
                                <img class="d-block img-fluid w-50" width="960" src="../assets/logoIEM.png" alt="image slot">
                            </div>                        
                        </template>               
                    </b-carousel-slide>
                        
                </b-carousel>
            </div><!--termina div-carousel-->
            
        </div>
        <div class="row row-size">
            
        </div>
        
    </div>
</template>

<script>
export default{
    name:'LandingPage',
    data(){
        return{
            slide:0,
            sliding:null,

        }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    },
    comments:{

    },
    mounted() {
        
        // Cargar el script de Google Tag Manager
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YM7ZLSHPGR';
            document.head.appendChild(script);
    
            // Configurar el dataLayer
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YM7ZLSHPGR');


            },
    

}

</script>
<style scoped>
.row-size{
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 5%;
    padding-right: 5px;
}
.head-size{
    width: 100%;   
    /*background-image: url("../assets/pleca_faltante.png") ;*/
    height: 100px;
    padding-top:2%;
    padding-bottom: 2%;
    

}
.graphics{
    border-top: 2px solid #bbbaba ;
    
    
}
.graphics-b{
    border-right: 2px solid #bbbaba ;
    
}
h1 {
    font-family: GalanoBold;
    color: #990089;    
    font-weight: bold;
    font-size:60px;
    text-align:center;
    text-shadow: 1px 1px 2px #fff;
    
    
    
}
h2 {
    font-family: GalanoRegular;
    color: #717171;
    
    font-weight: bolder;
    font-size:xx-large;
    text-align:center;
    text-shadow: 1px 1px 2px #fff;
    
    
}
h4 {
    font-family: GalanoRegular;
    color: #41446a;
    
    font-weight: bolder;
    font-size:xx-large;
    text-align:center;
    text-shadow: 1px 1px 2px #fff;

}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #a31a77;
    --bs-btn-border-color: #a31a77; 
    --bs-btn-hover-color: #fff;
    /*--bs-btn-hover-bg: #6a6ea8;*/
    --bs-btn-hover-border-color: #727271;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.spacing-divs{
    margin-top:1%;
    margin-bottom: 1%;

}
.container{
    
    /*background-image: url("../assets/background-iem.jpg");*/
    background: #F9F7F6;
    border-radius: 5px;
    border: 1px 1px 1px 1px solid #990089;
  


}

</style>
