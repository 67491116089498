<template>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-2" >
        </div><!--termina fila izquierda-->           
        <div class="col-xs-12 col-sm-12 col-lg-8" >            
            <h1>Creacion de Usuarios Portal Administrativo</h1>           
            <form class="row g-3" @submit.prevent="submitForm">
                <div class="col-12">
                    <label for="folior" class="form-label" >ASIGNACION FOLIO DE REGISTRO</label>
                    <input type="text" class="form-control text-uppercase" id="folior" placeholder="Folio Registro" v-model="idFolioValidacion" @input="comprobarFolio()" >
                </div>                
                <div class="col-12">
                    <label for="nombre" class="form-label">NOMBRE COMPLETO</label>
                    <input type="text" class="form-control text-uppercase" placeholder="Nombre completo" id="nombre" v-model="nombre_completo">
                </div>
                <div class="col-sm-6">
                    <label for="area">AREA</label>
                    <multiselect
                        v-model="selectionArea"
                        @select="chooseArea()"
                        :options = "listaAreas"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;"
                        id="area">
                    </multiselect>
                </div>          
  
                <div class="col-sm-6">
                    <label for="organizacion">UNIDAD O PARTIDO</label>
                    <multiselect
                        v-model="selectionOrganizacion"
                        @select="chooseOrganismo()"
                        :options="listaOrganizaciones"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;"
                        id="organizacion">
                    </multiselect>
                </div>
                <div class="col-sm-4">
                    <label for="rol">ROL</label>
                    <multiselect
                        v-model="selectionRol"
                        @select="chooseRol()"
                        :options="listaRoles"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;"
                        id="rol">
                    </multiselect>
                </div>               
                <div class="col-sm-4">
                    <label for="correo">USUARIO</label>
                    <input type="email" class="form-control" id="correo" placeholder="USUARIO" v-model="usernamePanel">                  
                </div>
                <div class="col-sm-4">
                    <label for="specificSizeInputGroupUsername">CONTRASEÑA</label>
                    <div class="input-group">                    
                    <input type="text" class="form-control" id="specificSizeInputGroupUsername" placeholder="Contraseña" readonly v-model="password" v-on:click="generatePassword()">
                    </div>
                </div>

                <div class="col-12">
                    <button type="button" class="btn btn-success"  v-on:click="save()" >Registrar Usuario&emsp;</button>   
                </div>
            </form>
        </div> <!--Termina columna central de 6 ancho-->
        <div class="col-xs-12 col-sm-12 col-lg-2" >

        </div> <!--termina columna derecha responsiva--> 
    </div><!--termina clase row-->
</template>

<script>

import axios from 'axios';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import distritos from '../data/distritos.json'
import municipios from '../data/municipios.json'

export default{
    name:'CrearUsuariosPortal',
    data(){
        return{
            idUsuario:null,    
            idFolioValidacion:"",
                  
            nombre_completo:"",               
            usernamePanel:"",
            password:"",
            contadoruser:0,
            //rol:"Validador",
            //area:"",
            distrito:"",
            municipio:"",
            estado:"",           
            observaciones:"",
            usuario_activado:true,
            email:"", // se puede tomar correo electronico como nombre de usuario // se ´rocede a validar que ambos campos username y correo coincidad en caso de tomar                    
            registro_acciones:"log",// Guarda el log o historial de acciones del usuario
            listaRoles:["Capturista","Validador","Publicador","Supervisor","Administrador"],
            listaAreas:["PARTIDO POLÍTICO","COORDINACIÓN DE TRANSPARENCIA","COORDINACIÓN DE IGUALDAD","COORDINACIÓN DE PRERROGATIVAS Y PP","COORDINACIÓN DE INFORMÁTICA"],
            listaOrganizaciones:['Partido Acción Nacional','Partido Revolucionario Institucional','Partido de la Revolución Democrática','Partido del Trabajo','Partido Verde Ecologista de México','Movimiento Ciudadano','Partido Morena','Partido Encuentro Solidario Michoacán','MÁS MICHOACÁN','MICHOACÁN PRIMERO','TIEMPO X MÉXICO','CANDIDATURA INDEPENDIENTE','OPL'],
            areas:{
                idArea:0,
                area:"",
            },
            roles:{
                idRol:0,
                rol:""

            },
            organizacion:{
                idOrganizacion:0,
                descOrganizacion:""
            },
            distritos:distritos,
            municipios:municipios,
            selectionRol:"",
            selectionArea:"",
            selectionOrganizacion:"",
            selection2:"",
            selection3:"",
           
            config:{},
            currentPage: 1, 
            totalPages: 0,
            pagina:"",
            //variables de control de formulario
            inputDisabled:false,
            disable:false
        }
    },
    components:{
      
    },
    methods:{
        chooseRol(){
            let asignacionRol=this.selectionRol
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.roles.idRol = this.listaRoles.findIndex((idRol)=>idRol === asignacionRol)+1          
            this.roles.rol = this.listaRoles.find((rol)=>rol === asignacionRol)
            this.selectionRol=this.roles.rol
            console.log(this.roles)
        },
        choose2(){
            this.distrito=this.selection2
        },
        choose3(){
            this.municipio=this.selection3
        },
        chooseArea(){
            
            let asignacionArea=this.selectionArea
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.areas.idArea = this.listaAreas.findIndex((idArea)=>idArea === asignacionArea)+1
            this.areas.area = this.listaAreas.find((area)=>area === asignacionArea)
            this.selectionArea=this.areas.area
            console.log(this.areas)            
            
            //this.asignacionRol(this.posicion)
        },
        chooseOrganismo(){
            
            let asignacionOrganismo=this.selectionOrganizacion
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.organizacion.idOrganizacion = this.listaOrganizaciones.findIndex((idOrganizacion)=>idOrganizacion === asignacionOrganismo)+1
            this.organizacion.descOrganizacion = this.listaOrganizaciones.find((descOrganizacion)=>descOrganizacion === asignacionOrganismo)
            this.asignacionOrganismo=this.areas.area
            console.log(this.organizacion)            
            
            //this.asignacionRol(this.posicion)
        },
              //Metodo guardar datos
        async asignacionFolio(posicion){           
            let url =`https://informatica.iem.org.mx/api/conoceles/api/usuarios`
            try {
                const res = await axios.get(url,this.config)               
                this.folioUsuarioValidador=res.data[posicion].idFolioValidacion
                console.log(this.folioUsuarioValidador)
            } catch (error) {
                console.log(error.code)
                
            }
        },
        //metodo para generar folios se envia comprobacion a la base de datosl modelo de candidatos de no existir se genera uno nuevo
        generarUsuarios(){
            this.usernamePanel="usuario"+this.splitNombreCompleto()     
          
        },
        async getUsers(){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`,this.config)
            this.listUsers = res.data;           
        },
        async comprobarFolio(){
           
            
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`,this.config) 
            this.listUsers = res.data;
            //console.log(this.listUsers)         
            this.listUsers.forEach(element =>{
                if(element.idFolioValidacion === this.idFolioValidacion)
               {
                    this.$alert('','<label class="alertlabel">Folio ya registrado</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});                   
                    this.path("/crearusuariosportal")
                    //this.idFolioValidacion=""                     
                    return                  
               }                 
          
         })
             
        },
        //Se crea metodo para guardar candidato una vez validado con el metodo create por medio de su numero CIC con la BD del SNR
        async save(){             
            //Enviar datos del usuario registrado desde nuestra vista
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
            //const formData = new FormData() //multi-part // Creamos la instancia para pasar los valores desde el JSON
            //console.log(formData)
            if(this.idFolioValidacion !== "" && this.usernamePanel!=="" &&this.password!==""){ //Valida que el folio no este vacio y se procede  a guardar la informacion
                //formData.append('params', JSON.stringify({idFolioValidacion:this.idFolioValidacion, nombre_completo:this.nombre_completo,usernamePanel:this.usernamePanel,password:this.password, rol:this.rol, distrito:this.distrito, municipio:this.municipio, email:this.email})) // pasamos los parametros a guardar
                const res = await axios.post(url,{ idFolioValidacion:this.idFolioValidacion.toUpperCase(), nombre_completo:this.nombre_completo.toUpperCase(),usernamePanel:this.usernamePanel.toLowerCase(),password:this.password,areas:this.areas, roles:this.roles,organizacion:this.organizacion},this.config)                         
                if(res.data.result = 1){ // se movio de asignacion a igualdad
                    this.$alert('Usuario Registrado','','success', {showConfirmButton: true, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'center', width: 200, timer: 1000});
                    this.disable=false 
                    this.path("/crearusuariosportal")
                    return  
                }
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path("/crearusuariosportal")
               
                
            }else{
                this.$alert('','<label class="alertlabel">Datos Vacios o Invalidos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            }
        },
    
        //Metodo creación de contraseña segura
        generatePassword(){
            this.password='' // limpia el campo cada vez que se clickea
            this.inputDisabled=true

            const letters='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const numbers = '0123456789';
            const symbols = '!@#$%^&*_=+-/';
            const characters = letters + numbers + symbols;
            
            for(let i=0; i< 8;i++){
                const randomIndex = Math.floor(Math.random() * characters.length)
                this.password += characters[randomIndex]
            }
            return this.password;

        },
        splitNombreCompleto(){
            var iniciales = this.nombre_completo.split(" ")
            var primerasIniciales =""
            for(var i=0; i < iniciales.length; i++){
                primerasIniciales+=iniciales[i].charAt(0)

            }
            return primerasIniciales.toLowerCase()


        },


    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        //const url = '/api/usuario'        
       
        try {
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')
            
            const {idUsuario, token, usernamePanel,nombre_completo} = JSON.parse(loggedAppUserPanel)
            this.usernamePanel2 = usernamePanel
            this.idUsuarioPanel = idUsuario  
            this.nombre_completo_usuario = nombre_completo         

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }       
            //Consulta a la ruta con coleccion de datos usuario
           // const result = await axios.get(url,this.config)
                     
            //this.idFolioValidacion = result.data[0].idFolioValidacion         
            this.path("/crearusuariosportal")
            this.isLoading = false

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/404')
            }
        }
        
    },
    mounted(){
        window.scrollTo(0,0);
    }  
}
</script>
<style scoped>
/*Creacion de los estilos CSS importamos desde assets*/
</style>