<template>
    <div>
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container">
            <!-- <div id="first">
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=19>
                    <span>19 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div> -->
            <i class="fa-solid fa-x fa-lg"></i>
            <i class ="fa-solid fa-check fa-sm"></i>
            <i class="fa-solid fa-circle-xmark fa-2xs"></i>
            <i class="fa-solid fa-circle-check fa-2xs"></i>


            <div class="row questionary-row">
                <div class="questionary-container">
                <p style="text-align: center" class="question" >REVISIÓN DE RESPUESTAS DE LAS PERSONAS CANDIDATAS</p>
                    <div style="margin-top: 5%; margin-bottom: 5%;">                                        
                        <div class="d-flex flex-column mb-2">
                                <div v-for="(objeto,index) in preguntas" :key="index" class="radio-tile-group align-items-start containerIn itemIn" > <!--Revision o Chek List de Preguntas-->
                                    <br>
                                        <label >{{ objeto.pregunta}} </label>                            
                                        <div v-if="objeto.seleccionado===true" class="input-group mb-1" >                                 
                                            <input v-model="objeto.respuesta"  placeholder="Revisar y/o Contestar" class="form-control" style="text-align: center; font-weight: bold; color: #b0aeb3" disabled/>&nbsp;&nbsp;                                            
                                            <div v-if="objeto.respuesta === 'SR' || objeto.respuesta === ''" style="justify-content: center;"><button type="button" class="btn btn-custom"  @click="verificacion()" @mouseleave="desactivarSeleccion(index)">&nbsp;Modificar Respuesta&nbsp;<fa icon="fa-solid fa-circle-xmark fa-2xs" class="brand-icon" style="color:#41446a"/></button></div>
                                            <div v-else-if="objeto.respuesta !== 'SR'" style="justify-content: center;"><button type="button" class="btn btn-custom " @click="verificacion()" @mouseleave="desactivarSeleccion(index)">&nbsp;Modificar Respuesta&nbsp;&nbsp;<fa icon="fa-solid fa-circle-check fa-2xs" class="brand-icon" style="color:#A5DC86"/></button></div>                                
                                        </div>
                                        <div v-else class="input-group mb-1">
                                            <input v-model="objeto.respuesta"  placeholder="Revisar y/o Contestar" class="form-control" style="text-align: center; font-weight: bold; color: #b0aeb3" disabled/>&nbsp;&nbsp;                                            
                                            <div v-if="objeto.respuesta === 'SR' || objeto.respuesta === ''" style="justify-content: center;"><button type="button" class="btn btn-custom"  @click="verificacion()" @mouseover="activarSeleccion(index)">Respuesta Guardada&nbsp;<fa icon="fa-solid fa-circle-xmark fa-2xs" class="brand-icon" style="color:#41446a"/></button></div>
                                            <div v-else-if="objeto.respuesta !== 'SR'" style="justify-content: center;"><button type="button" class="btn btn-custom " @click="verificacion()"  @mouseover="activarSeleccion(index)">Respuesta Guardada&nbsp;&nbsp;<fa icon="fa-solid fa-circle-check fa-2xs" class="brand-icon" style="color:#A5DC86"/></button></div>
                                        </div>

                                </div><!--Termina revision-->
                        </div>                    
                    </div>

                    <div class="d-flex flex-row">
                        <button style="margin-right: 1%;" type="button" :disabled="disable" class="btn btn-secondary" v-on:click="atras()">Atras&emsp;<fa icon="arrow-left"/>&emsp;</button>
                        <!-- <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button> -->
                    </div>
                </div>
        </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Identidad17 from '@/views/Identidad17.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import Laboral from './Laboral.vue';
import ResetPassword from '../views/ResetPassword.vue'

export default{
    name:"Indice",
    data(){
        return{
            txtModificar:"",
           
            routes:routes,
            isLoading: true,
            disable:false,
            idCandidato:null,
            config:{},
            username:"",
            dirname:this.$assetsDirname,
            max:10,            
            pagina:"",
            
            paginaTmp:"",
            paginat:"",
            imagen:"",
            identidad:{
                indigena:"",
                lengua_indigena:""
            },
            preguntas:[
                {pregunta:"Revisión y/o Modificación de: Medios de contacto público", respuesta:"",seleccionado:false},
                {pregunta:"Revisión y/o Modificación de: Historia Profesional", respuesta:"",seleccionado:false},
                {pregunta:"Revisión y/o Modificación de: Historia Laboral", respuesta:"",seleccionado:false},                
                {pregunta:"Pregunta 1: ¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 2: ¿Habla o entiende alguna lengua indígena?", respuesta:"", seleccionado:false},
                {pregunta:"Pregunta 3: ¿A qué pueblo y/o comunidad indígena pertenece?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 4: ¿Tiene algún tipo de discapacidad?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 5: El tipo de discapacidad con el que vive es:", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 6: El tipo de discapacidad con el que vive es:", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 7: Su tipo de discapacidad le dificulta o impide:", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 8: ¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 9: ¿Es usted una persona de la población LGBTTTIQ+ (Lesbiana, Gay, Bisexual, Transgénero, Travesti, Transexual, Intersexual, Queer, No Binaria, u otra)?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 10: Usted se identifica como:", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 11: ¿Es usted migrante?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 12: ¿En qué país reside?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 13: ¿Cuánto tiempo ha vivido en el extranjero?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 14: ¿Cuál fue el motivo de la residencia en el extranjero?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 15: ¿Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 16: ¿Es parte de la población joven? (Entre los 21 y 29 años)", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 17: ¿Es parte de la población de personas mayores? (60 años o más)", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 18: Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 19: Su fuente principal de ingresos es:", respuesta:"",seleccionado:false},
                {pregunta:"Pregunta 20: Entidad federativa de nacimiento:", respuesta:"",seleccionado:false},
                //{pregunta:"Pregunta 21: ¿Desea hacer su información personal pública?", respuesta:"",seleccionado:false},              
            ]             
        }
    },
    components:{
        Header,
        Footer,
        Loading,
        Laboral,
        //ResetPassword
       
        
    },
    methods:{
        cambiarTexto(){            
            this.txtModificar="Modificar Respuesta"            
        },
        resetTexto(){
            this.txtModificar="Respuesta Guardada"           
        },
        

        activarSeleccion(index){
            this.preguntas[index].seleccionado=true
            this.cambiarTexto();
        },
        desactivarSeleccion(index){
            this.preguntas[index].seleccionado=false
            this.resetTexto();        
        },
        activarSeleccion2(){
            this.cambiarTexto();
        },
        desactivarSeleccion2(){
        },
        async verificacion(){           
            this.$alert('','Se procede a corregir','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)',position:'center-end', width: 200, timer:500});
            //verificacion de respuesta seleccionada realizamos una redireccion con el path       
            this.paginat = this.preguntas.findIndex(element => element.seleccionado === true);
            console.log(this.paginat)         
            this.paginaTmp = this.pagina - (this.paginat+1)           
            this.disable=true
            console.log(this.paginat, this.paginaTmp)
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-this.paginaTmp,paginat:this.paginat+1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-this.paginaTmp)
            }

        },
        async verificacion2(){           
            this.$alert('','Se procede a corregir','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)',position:'center-end', width: 200, timer:500});
            //verificacion de respuesta seleccionada realizamos una redireccion con el path          
                   
            this.paginaTmp = this.pagina-1        
            this.disable=true
            
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-this.paginaTmp,paginat:this.paginaTmp}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-this.paginaTmp)
            }

        },

        async atras(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-1)
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
    /*
        async save(){
            this.disable=true
            const url = '/api/candidato'

            if(this.identidad.comunidad_indigena !== ""){
                const res = await axios.patch(url, { identidad:this.identidad , idCandidato:this.idCandidato, pagina:this.pagina+1 }, this.config)
                if(res.data.result = 1){
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable=false
                    this.path(this.pagina+1) // siguiente pagina
                    return
                }

                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path(this.pagina+1) // siguiente pagina
                this.disable=false
            }
            else{
                this.$alert('','<label class="alertlabel">Campo de seleccion obligatorio</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
        }*/
    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        //const url = 'https://informatica.iem.org.mx/api/conoceles/api/conoceles/api/candidato'
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        //const url2 = 'https://informatica.iem.org.mx/api/conoceles/api/partidos'

        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            //console.log(loggedAppUser)
            if(!loggedAppUser)
                this.path('/')
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato
            this.config = {
                headers:{
                    Authorization: `Bearer ${token}` 
                }
            }
            const result = await axios.get(url, this.config)
            this.pagina = result.data[0].pagina
            this.paginat=result.data[0].paginat                  
            this.path(this.pagina)
            //console.log(this.pagina)
            //console.log(this.paginat)

            this.imagen = result.data[0].imagen
            // Se realiza la obtencion de los datos  guardados para realizar el checkList
            this.preguntas[0].respuesta= "Medios de contacto Publico"         
            this.preguntas[1].respuesta = "Historia Profesional"
            this.preguntas[2].respuesta= "Historia Laboral"
            this.preguntas[3].respuesta= result.data[0].identidad.indigena          
            this.preguntas[4].respuesta = result.data[0].identidad.lengua_indigena
            this.preguntas[5].respuesta= result.data[0].identidad.comunidad_indigena         
            this.preguntas[6].respuesta = result.data[0].identidad.discapacidad
            this.preguntas[7].respuesta= result.data[0].identidad.estatus_discapacidad          
            this.preguntas[8].respuesta = result.data[0].identidad.tipo_discapacidad  
            this.preguntas[9].respuesta= result.data[0].identidad.dificultad_discapacidad          
            this.preguntas[10].respuesta = result.data[0].identidad.afromexicana
            this.preguntas[11].respuesta= result.data[0].identidad.diversidad_sexual          
            this.preguntas[12].respuesta = result.data[0].identidad.tipo_diversidad_sexual  
            this.preguntas[13].respuesta= result.data[0].identidad.migrante          
            this.preguntas[14].respuesta = result.data[0].identidad.pais_extranjero  
            this.preguntas[15].respuesta= result.data[0].identidad.tiempo_extranjero          
            this.preguntas[16].respuesta = result.data[0].identidad.motivo 
            this.preguntas[17].respuesta= result.data[0].identidad.situacion_asegurada         
            this.preguntas[18].respuesta = result.data[0].identidad.joven 
            this.preguntas[19].respuesta= result.data[0].identidad.mayor        
            this.preguntas[20].respuesta = result.data[0].identidad.socioeconomico  
            this.preguntas[21].respuesta= result.data[0].identidad.ingresos         
            this.preguntas[22].respuesta = result.data[0].identidad.entidad_nacimiento   

            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped>

.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #B20066;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #B20066;
    --bs-btn-border-color: #B20066;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6a6ea8;
    --bs-btn-hover-border-color: #6a6ea8;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      font-family: GalanoBold;
      color: #B20066;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 2px solid #B20066;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #B20066;
      border: 2px solid #B20066;
      color: white;
      transform: scale(1.111, 1.111);
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #B20066;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
.question{
    color:#5c636a
  }
.containerIn{
    display: flex;
    flex-direction: column;
}
.itemIn{
    opacity: 0;
    animation: aparecer 1s forwards;
}
.itemIn:nth-child(1){
    animation-delay: 0.2s;
}
.itemIn:nth-child(2){
    animation-delay: 0.4s;
}
.itemIn:nth-child(3){
    animation-delay: 0.6s;
}
.itemIn:nth-child(4){
    animation-delay: 0.8s;
}
.itemIn:nth-child(5){
    animation-delay: 1s;
}
.itemIn:nth-child(6){
    animation-delay: 1.2s;
}
.itemIn:nth-child(7){
    animation-delay: 1.4s;
}
.itemIn:nth-child(8){
    animation-delay: 1.6s;
}
.itemIn:nth-child(9){
    animation-delay: 1.8s;
}
.itemIn:nth-child(10){
    animation-delay: 2s;
}
.itemIn:nth-child(11){
    animation-delay: 2.2s;
}
.itemIn:nth-child(12){
    animation-delay: 2.4s;
}

.itemIn:nth-child(13){
    animation-delay: 2.6s;
}
.itemIn:nth-child(14){
    animation-delay: 2.8s;
}
.itemIn:nth-child(15){
    animation-delay: 3s;
}
.itemIn:nth-child(16){
    animation-delay: 3.2s;
}
.itemIn:nth-child(17){
    animation-delay: 3.4s;
}
.itemIn:nth-child(18){
    animation-delay: 3.6s;
}
.itemIn:nth-child(19){
    animation-delay: 3.8s;
}
.itemIn:nth-child(20){
    animation-delay: 4s;
}
.itemIn:nth-child(21){
    animation-delay: 4.2s;
}


.containerIn{
    display: flex;
    flex-direction: column;
}
.itemIn{
    opacity: 0;
    animation: aparecer 1s forwards;
}
@keyframes aparecer 
{
    to{
        opacity: 1;
    }    
}
</style>