<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-8 mb-3">
        <h3>Estadística</h3>  
        <h4>Del total de las candidaturas que respondieron al cuestionario de identidad, se identifican personas de la población LGBTIAQ+:</h4>
    </div>
    <div class="col-xs-12 col-sm-12 col-lg-8 p-3 sizeChart">
      <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie>
    </div>
    <div class="col-xs-12 col-sm-12 col-lg-8 mt-3 pb-2">
        <h5>De las {{ tDiversidad }}  (100%) candidaturas que respondieron el cuestionario de identidad, {{cDiv1}} ({{ pDiversidad }}%) se identificaron como personas de la población LGBTIAQ+. </h5>
    </div>





  </div>
         
    
  
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaDiversidad',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {       

        labels: ['Sí', 'No','Sin Responder'],
        datasets: [ 
            {
            label: 'Diversidad Sexual',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#E75395','#6461A9','#70257F']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        },

        legend:{
          display:true,
          position:'bottom',
          text:"De los candidados.."
        },
        title:{
          display:true,
          text: "Distribucion de datos"
        },
        
      },
      //Variables para creacion de los datos
      lista :[],

      cDiv1:0,
      cDiv2:0,
      cDiv3:0,
      tDiversidad:0,
      pDiversidad:0

    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const res = await axios.get(url)
        this.lista = res.data
        let count1=0
        let count2=0
        let count3=0 
        

       
        this.lista.forEach(element =>{ 
        {
            if(element.identidad.diversidad_sexual === "Sí")
                {
                   count1=count1+1
                }
            else if(element.identidad.diversidad_sexual  === "No")
            {
              count2=count2+1

            }
            else if(element.identidad.diversidad_sexual === "SR"){
              count3=count3+1

            }  
        
        }      
      })



     this.chartData.datasets[0].data[0] = count1  
     this.chartData.datasets[0].data[1] = count2
     this.chartData.datasets[0].data[2] = count3  

     this.cDiv1 = count1
     this.cDiv2 = count2
     this.cDiv3 = count3
     this.tDiversidad = this.cDiv1 + this.cDiv2 + this.cDiv3
     this.pDiversidad = ((this.cDiv1 * 100) / this.tDiversidad).toFixed(2)

 
   

     this.loaded = true

    }
    
}
</script>


<style>
</style>