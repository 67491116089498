<template>
    <div class="container-fluid p-0 d-flex h-100"> 
        <div id="bdSidebar" class="d-flex flex-column flex-shrink-0 p-5 pt-4 bg-secondary text-white offcanvas-md offcanvas-start"> 
            <a href="#" class="navbar-brand"><strong>REVISION Y VALIDACION DE DATOS</strong></a>
            <hr> 
            <ul class="mynav nav nav-pills flex-column mb-auto"> 
                <li class="nav-item mb-1"> 
                    <a href="#medios"> 
                        <fa icon="fa-solid fa-user"/>
                        Medios de Contacto
                    </a> 
                </li> 
  
           
                <li class="nav-item mb-1"> 
                    <a href="#profesional"> 
                        <fa icon="fa-regular fa-newspaper"/>
                        Historia Profesional
                    </a> 
                </li> 
                <li class="nav-item mb-1"> 
                    <a href="#laboral"> 
                        <fa icon="fa-solid fa-archway"/>
                        Historia Laboral 
                    </a> 
                </li>

                <li class="nav-item mb-1"> 
                    <a href="#cuestionarios"> 
                        <fa icon="fa-solid fa-floppy-disk"/>
                        Cuestionarios de Identidad
                    </a> 
                </li>
                <li class="nav-item mb-1"> 
                    <a href="#validar"> 
                        <fa icon="fa-solid fa-floppy-disk"/>
                        Validar Informacion
                    </a> 
                </li>           
            </ul> 
            <hr> 
            <div class="d-flex"> 
                <i class="fa-solid fa-book  me-2"></i> 
                <span> 
                    <h6 class="mt-1 mb-0"> 
                          Instituto Electoral de Michoacán
                      </h6> 
                </span> 
            </div> 
        </div> 
  
        <div class="container"> 
            <div class="p-2 d-md-none d-flex text-white bg-secondary"> 
                <a href="#" class="text-white" 
                   data-bs-toggle="offcanvas"
                   data-bs-target="#bdSidebar"> 
                    <i class="fa-solid fa-bars"></i> 
                </a> 
                <span class="ms-3">Portal de Revision y Validación</span> 
            </div> 
            <div class="container" style="margin-left: 2%;"> 
                <nav style="--bs-breadcrumb-divider:'>';font-size:18px"> 
                    <ol class="breadcrumb"> 
                        <li class="breadcrumb-item"> 
                            <a href="#"><strong><fa icon="fa-solid fa-house"/></strong></a>
                        </li> 
                        <li class="breadcrumb-item"> <a href="#"><strong>Inicio</strong></a></li>
                        <li class="breadcrumb-item"><strong>Medios de Contacto Publico</strong></li> 
                    </ol> 
                </nav>  
               
                <!--inicia contenido de la captura-->
               
                                                     
                 <div class="row questionary-row box-border-separator" id="medios">
                        <p class="question">Medios de Contacto Publico</p>                        
                        <div class="questionary-container border-2">
                        <!--Informacion correspondiente con Medios de contacto-->                                                    
                            
                            <div class="d-flex flex-column mb-3" id="imagen">
                                      <!-- <label for="exampleInputPassword1">Logo&emsp;</label> -->
                                        <label style="text-align: left;">Imagen de Perfil Candidatura</label>
                                            <div v-if="imagen" class="logo-container" >
                                              <img v-if="imagen.name" style="margin-left: 1%;object-fit: contain;" ref="previewImage" width="100%" height="200px">
                                              <img v-else-if="imagen" style="margin-left: 1%;object-fit: contain;" :src="dirname+imagen" width="100%" height="200px">
                                              <!-- <img ref="previewImage" width="100%" height="200px" style="object-fit: contain;"> -->
                                              <h5 v-if="imagen.name" class="image-name">{{imagen.name}}</h5>
                                              <h5 v-else class="image-name">{{imagen}}</h5>
                                            </div>
                            </div>
                            <label>FaceBook</label>
                            <div class="input-group mb-3 border">
                                 <span class="input-group-text icon-width"><fa icon="fa-brands fa-facebook" class="brand-icon"/></span>                                    
                                 <textarea v-model="curriculares.social.facebook" class="form-control" style="justify-content: center;" ></textarea>                                      
                            </div>
                            <label>Twitter</label>
                            <div class="input-group mb-3 border">
                                <span class="input-group-text icon-width"><fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon"/></span>                                  
                                <textarea v-model="curriculares.social.twitter" class="form-control" style="justify-content: center;" ></textarea>                                   
                            </div>
                            <label>YouTube</label>
                            <div class="input-group mb-3 border">
                                <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-brands fa-youtube" class="brand-icon"/></span>                              
                                <textarea v-model="curriculares.social.youtube" class="form-control" style="justify-content: center;" ></textarea>    
                            </div>
                                <label>Instagram</label>
                            <div class="input-group mb-3 border">
                                <span class="input-group-text icon-width"><fa icon="fa-brands fa-instagram" class="brand-icon"/></span>
                                <textarea v-model="curriculares.social.instagram" class="form-control" style="justify-content: center;" ></textarea>                           
                            </div>
                                <label>TikTok</label>
                            <div class="input-group mb-3 border">
                                <span class="input-group-text icon-width"><fa icon="fa-brands fa-tiktok" class="brand-icon"/></span>
                                <textarea v-model=" curriculares.social.tiktok" class="form-control" style="justify-content: center;" ></textarea>                               
                            </div>
                                <label>Página web</label>
                            <div class="input-group mb-3 border">
                                <span class="input-group-text icon-width"><fa icon="fa-solid fa-globe" class="brand-icon"/></span>
                                <textarea v-model="curriculares.contacto.web" class="form-control" style="justify-content: center;" ></textarea>                     
                            </div>
                            <label>Correo electrónico público</label>
                            <div class="input-group mb-3 border rounded align-items-center">
                                <span class="input-group-text icon-width"><fa icon="fa-solid fa-envelope" class="brand-icon"/></span>
                                <textarea v-model="curriculares.contacto.email[0]" class="form-control" style="justify-content: center;" ></textarea>                              
                            </div>             
                            <label>Teléfono público de contacto</label>
                            <div class="input-group mb-1">
                                <span class="input-group-text icon-width"><fa icon="fa-solid fa-phone" class="brand-icon"/></span>
                                <textarea v-model="curriculares.contacto.telefono[0]" class="form-control" style="justify-content: center;" ></textarea>                                          
                            </div>             
                            <label>Domicilio de casa de campaña</label>
                            <div class="input-group mb-1">
                                <span class="input-group-text icon-width" style="justify-content: center;"><fa icon="fa-solid fa-location-dot" class="brand-icon"/></span>
                                <textarea v-model="curriculares.contacto.direccion[0]" class="form-control" style="justify-content: center;" ></textarea>                                              
                            </div>              
                        </div>
                    </div><!--termina medios de contacto publico-->                
               
                    <!--Revision informacion profesional-->
                    <div class="row questionary-row box-border-separator" id="profesional">
                        <div class="questionary-container">                           
                            <p class="question">Historia Profesional</p>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                     <label>Grado máximo de estudios y su estatus</label>
                                    <div class="input-group mb-1">                        
                                    <textarea v-model="curriculares.estudio.grado_maximo" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>Otra formación académica: cursos, diplomados, seminarios, etcétera</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                     <textarea v-model="curriculares.estudio.otro" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>                         
                                    <label>Historia profesional y/o laboral que describa la experiencia, los años y las actividades realizadas en ésta</label>
                                    <div class="d-flex flex-column align-items-end mb-3">                                   
                                    <textarea v-model="curriculares.profesion.historia_laboral" class="form-control" style="justify-content: center;"  rows="5" maxlength="500" max-rows="12"></textarea>                                    
                                    </div>
                            </div>
                        </div>
                    </div>
                    <!--Termina div informacion profesional-->                                       <!--Revision informacion profesional-->
                    <div class="container row questionary-row box-border-separator" id="laboral">
                        <div class="questionary-container">                           
                            <p class="question">Historia Laboral</p>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                     <label>Trayectoria política y/o participación social en organizaciones ciudadanas o de la sociedad civil que describa trayectoria, los años y las actividades realizadas en ésta</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="curriculares.profesion.trayectoria_politica" class="form-control" style="justify-content: center;"   rows="5" maxlength="500" max-rows="12"></textarea>
                                    </div>             
                                    <label>¿Por qué quiere ocupar un cargo público?</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="curriculares.profesion.razon_cargo_publico " class="form-control" style="justify-content: center;"  rows="5" maxlength="500" max-rows="12"></textarea>
                                    </div>                         
                                    <div style="display: flex; flex-direction: column;">
                                        <label>¿Cuáles son sus dos principales propuestas?</label>
                                        <label class="secondary-title">Propuesta 1</label>
                                    </div>
                                    <div class="d-flex flex-column align-items-end mb-3">                                                            
                                        <textarea v-model="curriculares.propuesta.propuesta1" class="form-control" style="justify-content: center;"  rows="5" maxlength="500" max-rows="12"></textarea>                                 
                                    </div>
                                    <label class="secondary-title">Propuesta 2</label>
                                    <div class="d-flex flex-column align-items-end mb-3">                                                            
                                        <textarea v-model="curriculares.propuesta.propuesta2" class="form-control" style="justify-content: center;"  rows="5" maxlength="500" max-rows="12"></textarea>                                 
                                    </div>                                  
                                    <label>Propuesta en materia de género o, en su caso, del grupo en situación de discriminación que representa que describa la población objetivo, metas y plazos para su promoción como iniciativa de ley o política pública</label>
                                    <div class="d-flex flex-column align-items-end mb-3">                                   
                                        <textarea v-model="curriculares.propuesta.propuesta_grupo_discriminacion " class="form-control" style="justify-content: center;"  rows="5" maxlength="500" max-rows="12"></textarea>                                    
                                    </div>
                            </div>
                        </div>
                    </div><!--Termina div informacion profesional-->
                    <!--Cuestionarios de Identidad-->
                    <div class="row questionary-row box-border-separator" id="cuestionarios">
                        <div class="questionary-container">                           
                            <p class="question">Cuestionarios de Identidad</p>
                            <label class="question"><strong>Identidad Indígena</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                     <label>¿Se identifica como una persona indígena o como parte de algún pueblo o comunidad indígena?</label>
                                    <div class="input-group mb-1">                        
                                    <textarea v-model="identidad.indigena" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>¿Habla o entiende alguna lengua indígena?</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                     <textarea v-model="identidad.lengua_indigena" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>                         
                                    <label>¿A qué pueblo y/o comunidad indígena pertenece?</label>
                                    <div class="d-flex flex-column align-items-end mb-3">                                   
                                    <textarea v-model="identidad.comunidad_indigena" class="form-control" style="justify-content: center;" ></textarea>                                    
                                    </div>
                            </div>
                            <!--Poblacion con discapacidad-->
                            <label class="question"><strong>Población con discapacidad</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>¿Tiene algún tipo de discapacidad?</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.discapacidad" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>El estado de la  discapacidad con la que vive es:</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.estatus_discapacidad" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                                    <label>El tipo de discapacidad con el que vive es:</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.tipo_discapacidad" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>                        
                                    <label>Su tipo de discapacidad le dificulta o impide:</label>
                                    <div class="d-flex flex-column align-items-end mb-3">                                   
                                        <textarea v-model="identidad.dificultad_discapacidad" class="form-control" style="justify-content: center;" ></textarea>                                    
                                    </div>
                            </div>
                            <!--Poblacion Afromexicana-->
                            <label class="question"><strong>Población afromexicana</strong></label>
                                <div style="margin-top: 5%; margin-bottom: 5%;">
                                     <label>¿Se considera una persona afromexicana o que forma parte de alguna comunidad afrodescendiente?</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.afromexicana" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                                </div>

                            <!--Diversidad sexual-->
                            <label class="question"><strong>Diversidad sexual</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>¿Es usted una persona de la población LGBTTTIQ+ (Lesbiana, Gay, Bisexual, Transgénero, Travesti, Transexual, Intersexual, Queer, No Binaria, u otra)?</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.diversidad_sexual" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>Usted se identifica como:</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.tipo_diversidad_sexual" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                            </div>
                            <!--Personas migrantes-->
                            <label class="question"><strong>Personas mexicanas migrantes</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>¿Es usted migrante?</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.migrante" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>¿En qué país reside?</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.pais_extranjero" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                                    <label>¿Cuánto tiempo ha vivido en el extranjero?</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.tiempo_extranjero" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>             
                                    <label>¿Cuál fue el motivo de la residencia en el extranjero?</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.motivo" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                                    <label> Cuando emigró, ¿se encontraba con una situación regular de trabajo o con un lugar asegurado en alguna institución educativa del país extranjero?</label>
                                    <div class="d-flex flex-column align-items-end">                                    
                                        <textarea v-model="identidad.situacion_asegurada" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>                            
                            </div>
                            <!--identidad poblacion Joven-->                           
                           
                            <label class="question"><strong>Población de personas jóvenes</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>¿Es parte de la población joven? (Entre los 21 y 29 años)</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.joven" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                            <!--identidad poblacion mayor-->
                            </div>
                            <label class="question"><strong>Población de personas Mayores</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>¿Es parte de la población de personas mayores? (60 años o más)</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.mayor" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                            </div>
                            <!--identidad rubro socioeconomico-->

                            <label class="question"><strong>Rubro socioeconómico</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>Pensando en todo lo que ganó el mes pasado, ¿en cuál de los siguientes grupos de ingresos se encuentra? Por favor incluya salario, o alguna otra ganancia que generalmente recibe cada mes.</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.socioeconomico" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                                    <label>Su fuente principal de ingresos es:</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.ingresos" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>                                    
                            </div>
                            <label class="question"><strong>Generales</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">
                                    <label>Entidad federativa de nacimiento:</label>
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="identidad.entidad_nacimiento" class="form-control" style="justify-content: center;" ></textarea>
                                    </div>
                            </div>
                            <label class="question"><strong>¿Desea hacer su información personal pública?</strong></label>
                            <div style="margin-top: 5%; margin-bottom: 5%;">                                    
                                    <div class="input-group mb-1">                        
                                        <textarea v-model="infoPublica" class="form-control" style="justify-content: center;" disabled></textarea>
                                    </div>
                            </div>
                             <!--Validar info-->                              
                             <div class="d-flex">
                                <button type="button" class="btn-toggle" v-on:click="validarInformacion()">&emsp;Validar&emsp;<fa icon="fa-solid fa-check"/>&emsp;</button>&emsp;&emsp;
                                <button type="button" class="btn-toggle1" v-on:click="invalidarInformacion()">&emsp;Invalidar &emsp;<fa icon="fa-solid fa-xmark"/>&emsp;</button>&emsp;&emsp;
                                 
                            </div>
                            <div class="d-flex mt-5">
                                
                                <button type="button" class="btn-toggle"  v-on:click="actualizarInformacion()">&emsp;CORREGIR DATOS&emsp;<fa icon="fa-solid fa-check"/>&emsp;</button> 
                            </div>
                             
                     

                        </div>
                    </div>
                </div>  
        </div> 
    </div>             
        
  
</template>
<script>

import axios from 'axios';
import routes from '../data/routes.json'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default{
    name:'RevisionCandidatoCorreccion',
    data(){
        return{         

      
           accionesAfirmativas:{
            id:0,
            idsc:0,
            descripcion:"",
           },
           //Variables de control
            pagina:'',
            activeDiv1:false,
            activeDiv2:false,
            activeDiv3:false,
            activeDiv4:false,
            activeRows:false,
            editable:false,
            botonQuitarV:false,
            botonValidar:false,
            botonInvalidar:false,
            infoPublica:"",


                      
            dirname:this.$assetsDirname,
            max:100, 
            value:75, 
            text:"",
            imagen:'',
            folioRegistro:"",
            //Informacion a obtener desde la coleccion de candidatos
            lista:[],  
            infoCandidatos:[],
            id_postulacion:"",
            accionAfirmativa:"", //  solo para mostrarse en la vista curricular
            cargo:"", // solo para mostrarse en vista curricular en cargo
            usuario_area:0,
            usuario_idarea:0,        
            //Variables para la revision de ficha de contacto
            routes:routes,
            isLoading: true,
            idCandidato:null,
            disable:false,
            config:{},
            currentLogo:null,
            username:"", //local storage username

            //Se obtiene los datos del SNR no Modificable
            phone:"123 456 7890", // input telefono
            email:"correo@electronico.com", // input email
            address:'', // input direccion pro       
    
         
            

            curriculares:{
                social:{
                    facebook:'',
                    twitter:'',
                    youtube:'',
                    instagram:'',
                    tiktok:'',
                },
                contacto:{
                    direccion:'',
                    telefono:'',
                    email:'',
                    web:'',
                    },
                estudio:{
                    grado_maximo:"",
                    estatus:"",
                    otro:""

                },
                profesion:{
                historia_laboral:"",
                trayectoria_politica:"",
                razon_cargo_publico:""
                },
                propuesta:{
                    propuesta1:"",
                    propuesta2:"",
                    propuesta_grupo_discriminacion:""
                },

            },//cierre curriculares

            //Cuestionarios de Identidad
            identidad:{
                indigena:"",
                lengua_indigena:"",
                comunidad_indigena:"",
                discapacidad:"",
                estatus_discapacidad:"",
                tipo_discapacidad:"",
                dificultad_discapacidad:"",
                afromexicana:"",
                diversidad_sexual:"",
                tipo_diversidad_sexual:"",
                migrante:"",
                pais_extranjero:"",
                tiempo_extranjero:"",
                motivo:"",
                joven:"",
                publicacion:"",
            },
            //Area de variables de validacion

            validacionPartido:false,
            validacionTransparencia:false,
            validacionCPyPP:false,
            asignacionValidador:"", // se listan los validadores que tienen acceso a este candidato.
            validacionObserbaciones:[], //
            estadoRoC:"",
            tipoRol:"",
            usuario_idFolioValidacion:"",
            usuario_organizacion:"",
            
            usuarioActivo:true, //activa o desactiva al usuario candidato y cuando el validador OPL lo activa de nuevo



        }// cierre return         
    },
    components:{
        //Como es un Card View o Modal Window no utiliza cabeceras
    
    },
    methods:{
    
        //Metodos para proteccion de las rutas de las vistas de VueJS
        home(){
            this.thispath(`/conoceles`)
        },
        editar(id){
            console.log(id)
            this.thispath('/editpartido/' + id)
        },
        nuevo(){
            this.thispath('/newpartido')
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
    //Metodos de validacion y carga de imagenes
    validate(e, opt){
            if(opt === 'phone'){
                const regex = /^[0-9]$/
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(regex.test(char)) return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
            if(opt === 'email'){
                const char = String.fromCharCode(e.keyCode) // Obtener caracter
                if(char !== ' ') return true; // Validar mediante regex
                else e.preventDefault(); // Si no es valida entonces no se agrega al input
            }
        },
        cargar(e, value){
            const opt = {
                'email':()=>this.email=e,
                'phone':()=>this.phone=e,
                'address':()=>this.address=e,
            }
            opt[value]()
        },
        erase(e, value){
            const opt = {
                'email':()=>this.contacto.email.splice(e, 1),
                'phone':()=>this.contacto.telefono.splice(e, 1),
                'address':()=>this.contacto.direccion.splice(e, 1),
            }
            opt[value]()
        },
        agregar(opt){
            if(opt === 'email' && this.email != ''){
                this.contacto.email.push(this.email)
                this.email=''
                return
            }
            if(opt === 'phone' && this.phone != ''){
                this.contacto.telefono.push(this.phone)
                this.phone=''
                return
            }
            if(opt === 'address' && this.address != ''){
                this.contacto.direccion.push(this.address)
                this.address=''
                return
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        windowClose(){
            if(this.tipoRol==="Validador" && this.usuario_area === 1){
                window.location.href ="/panelrevision",'Tabla Candidatos Captura'
                const nuevaVentana = window.open([])
                 nuevaVentana.onload =()=>{
                nuevaVentana.postMessage('actualizados','*')
                }            
            window.close()
            //redireccionamiento a la ruta principal

            }else if(this.tipoRol==="Validador" && this.usuario_area >=2){
                window.location.href ="/panelrevision",'Tabla Candidatos Captura'
                const nuevaVentana = window.open([])
                 nuevaVentana.onload =()=>{
                nuevaVentana.postMessage('actualizados','*')
                }            
            window.close()
            //redireccionamiento a la ruta principal

            }
       
           

        }, 


  

        //Metodo para obtener datos desde la base de datos relacionada con los candidatos y preguntas de identidad, medios de contacto e informacion Profesional
        async getCandidatosInfo(id)
        {
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato/${id}`
            try {
                const info = await axios.get(url)
                 this.infoCandidatos= info.data[0].infoCandidatos
                 this.id_postulacion=info.data[0].id_postulacion
                 this.imagen = info.data[0].imagen
                 this.currentLogo = info.data[0].imagen    
                 //Obtenemos el id para proceder a actualizarlo por idCandidato
                 this.idCandidato = info.data[0].idCandidato                
                 this.idsc=info.data[0].idsc
                 console.log("idsc:",this.idsc)
                           
  
                // Datos requeridos en esta vista
                this.curriculares.contacto.direccion = info.data[0].curriculares.contacto.direccion
                this.curriculares.contacto.telefono= info.data[0].curriculares.contacto.telefono
                this.curriculares.contacto.email = info.data[0].curriculares.contacto.email            
                this.curriculares.contacto.web = info.data[0].curriculares.contacto.web
                this.curriculares.social.facebook = info.data[0].curriculares.social.facebook
                this.curriculares.social.twitter = info.data[0].curriculares.social.twitter
                this.curriculares.social.youtube = info.data[0].curriculares.social.youtube
                this.curriculares.social.instagram = info.data[0].curriculares.social.instagram
                this.curriculares.social.tiktok = info.data[0].curriculares.social.tiktok 
                

                 this.curriculares.profesion.razon_cargo_publico = info.data[0].curriculares.profesion.razon_cargo_publico
                 this.curriculares.profesion.historia_laboral= info.data[0].curriculares.profesion.historia_laboral
                this.curriculares.propuesta.propuesta1 = info.data[0].curriculares.propuesta.propuesta1                
                this.curriculares.propuesta.propuesta2 = info.data[0].curriculares.propuesta.propuesta2
                this.curriculares.propuesta.propuesta_grupo_discriminacion = info.data[0].curriculares.propuesta.propuesta_grupo_discriminacion
                this.curriculares.profesion.trayectoria_politica = info.data[0].curriculares.profesion.trayectoria_politica

                this.curriculares.estudio.grado_maximo = info.data[0].curriculares.estudio.grado_maximo
                this.curriculares.estudio.estatus = info.data[0].curriculares.estudio.estatus
                this.curriculares.estudio.otro = info.data[0].curriculares.estudio.otro
                
                //Obtencion de respuestas delos cuestionarios de identidad correspondientes a personas con discapacidad
                this.identidad.discapacidad = info.data[0].identidad.discapacidad
                this.identidad.estatus_discapacidad = info.data[0].identidad.estatus_discapacidad
                this.identidad.tipo_discapacidad = info.data[0].identidad.tipo_discapacidad
                this.identidad.dificultad_discapacidad = info.data[0].identidad.dificultad_discapacidad

                //Obtencion de informacion de los cuestionarios de Identidad
                this.identidad.diversidad_sexual = info.data[0].identidad.diversidad_sexual
                this.identidad.tipo_diversidad_sexual = info.data[0].identidad.tipo_diversidad_sexual

                this.identidad.afromexicana = info.data[0].identidad.afromexicana

                //console.log("Desde Coleccion Candidatos: ",this.folioRegistroCandidato)
                this.identidad.indigena = info.data[0].identidad.indigena
                this.identidad.lengua_indigena = info.data[0].identidad.lengua_indigena
                this.identidad.comunidad_indigena = info.data[0].identidad.comunidad_indigena
                
                //Obtencion de respuesas de los cuestionarios de identidad correspondientes a si es persona migrante
                this.identidad.migrante = info.data[0].identidad.migrante
                this.identidad.pais_extranjero = info.data[0].identidad.pais_extranjero
                this.identidad.tiempo_extranjero = info.data[0].identidad.tiempo_extranjero
                this.identidad.motivo = info.data[0].identidad.motivo
                this.identidad.situacion_asegurada = info.data[0].identidad.situacion_asegurada
                //Identidad Joven o Mayor
                this.identidad.joven = info.data[0].identidad.joven
                this.identidad.mayor = info.data[0].identidad.mayor
               
                //Declaraciones
                this.identidad.socioeconomico = info.data[0].identidad.socioeconomico
                this.identidad.ingresos = info.data[0].identidad.ingresos
                //
                  //Generales
                  this.identidad.entidad_nacimiento = info.data[0].identidad.entidad_nacimiento
                //Variables de validacion
                 //Obtencion variable de publicacion de la informacion
                 //this.identidad.publicacion = info.data[0].identidad.publicacion
                //if(this.identidad.publicacion){
                    //this.infoPublica ="Sí"
               // }else{
                    //this.infoPublica="No"
                //}
                

                
            } catch (error) {
                console.log(error)
                
            }  
        },


        //Metodo que publica la informacion dell candidato correspondiente a los cuestionarios de identidad
        //Metodo que valida la informacion del candidato por el usuario Validador del partido
       //Metodo que publica la informacion dell candidato correspondiente a los cuestionarios de identidad
        //Metodo que valida la informacion del candidato por el usuario Validador del partido
        async validarInformacion(){
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato_user/`
            //metodo para asignar validacion de partido
            if(this.tipoRol==="Validador" && this.usuario_area === 1){
                this.usuarioActivo=false
                this.validacionPartido = true
                const res = await axios.patch(url, { idsc:this.idsc,validacionPartido:this.validacionPartido,validacionObserbaciones:this.validacionObserbaciones,usuarioActivo:this.usuarioActivo}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Validados','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
                     //metodo para asignar validacion de transparencia
            else if(this.tipoRol==="Validador" && this.usuario_area === 2){
                this.validacionTransparencia = true
                const res = await axios.patch(url, { idsc:this.idsc,validacionTransparencia:this.validacionTransparencia,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Validados','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
             //metodo para asignar validacion de igualdad
            else if(this.tipoRol==="Validador" && this.usuario_area === 3){
                this.validacionIgualdad = true
                const res = await axios.patch(url, { idsc:this.idsc,validacionIgualdad:this.validacionIgualdad,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Validados','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
   
            //metodo para asignar validacion de perrogativas
            else if(this.tipoRol==="Validador" && this.usuario_area === 4){
                this.validacionCPyPP = true
                const res = await axios.patch(url, { idsc:this.idsc,validacionCPyPP:this.validacionCPyPP,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Validados','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }

            
            else{
                    this.$alert('','<label class="alertlabel">Usuario no permitido para validar</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return                   
                  
            }        
        },
   
      
        async invalidarInformacion(){
            // si se invalida se le regresa la variable en false al validador del nivel inferior
            //en el caso del partido la variable se coloca en modo "EN CAPTURA"
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato_user`
            if(this.tipoRol==="Validador" && this.usuario_area === 1){
                this.validacionCaptura = false // se le envia false para que se desaparezca de la lista del validador partido
                 this.estadoRoC = "PARA MODIFICAR" 
                const res = await axios.patch(url, { idsc:this.idsc,validacionCaptura:this.validacionCaptura,estadoRoC:this.estadoRoC,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Invalidados','','info', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            this.windowClose()
            return

            }//cierre if validador partido INVALIDAR
            //ESTRUCTURA VALIDACION TRANSPARENCIA
            else if(this.tipoRol==="Validador" && this.usuario_area >= 2){               
                this.usuarioActivo=true // Validador OPL es el unico que activa al usuario para modificar
                this.validacionPartido = false // se coloca en false para que sea mostrado al partido y desaparezca del validador de opl IGUALDAD Y DH
                 this.estadoRoC = "PARA SU REVISION" // se colca nota para que el supervisor sepa del estatus de la revision
                const res = await axios.patch(url, { idsc:this.idsc,validacionCaptura:this.validacionCaptura,estadoRoC:this.estadoRoC,validacionObserbaciones:this.validacionObserbaciones,validacionPartido:this.validacionPartido,usuarioActivo:this.usuarioActivo,}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Invalidados','','info', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
                this.windowClose()
            return

            }
            //ESTRUCTURA VALIDACION TRANSPARENCIA
            else if(this.tipoRol==="Administrador" || this.tipoRol==="Supervisor"){               
                this.usuarioActivo=true // Validador OPL es el unico que activa al usuario para modificar
                this.validacionPartido = false // se coloca en false para que sea mostrado al partido y desaparezca del validador de opl IGUALDAD Y DH
                 this.estadoRoC = "PARA SU REVISION" // se colca nota para que el supervisor sepa del estatus de la revision
                const res = await axios.patch(url, { idsc:this.idsc,validacionCaptura:this.validacionCaptura,estadoRoC:this.estadoRoC,validacionObserbaciones:this.validacionObserbaciones,validacionPartido:this.validacionPartido,usuarioActivo:this.usuarioActivo,}, this.config)
                if(res.data.result === 1){
                    this.$alert('Datos Invalidados','','info', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
                this.windowClose()
            return

            }          
        },
        async quitarValidacion(){
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato_user`
            if(this.tipoRol==="Supervisor" && this.usuario_area === 2){
                this.validacionTransparencia = false
                const res = await axios.patch(url, { idsc:this.idsc,validacionTransparencia:this.validacionTransparencia,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Se quitó la validación del Área','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1500});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
            else if(this.tipoRol==="Supervisor" && this.usuario_area === 3){
                this.validacionIgualdad = false
                const res = await axios.patch(url, { idsc:this.idsc,validacionIgualdad:this.validacionIgualdad,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Se quitó la validación del Área','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
            else if(this.tipoRol==="Supervisor" && this.usuario_area === 4){
                this.validacionCPyPP = false
                const res = await axios.patch(url, { idsc:this.idsc,validacionCPyPP:this.validacionCPyPP,validacionObserbaciones:this.validacionObserbaciones}, this.config)
                if(res.data.result === 1){
                    this.$alert('Se quitó la validación del Área','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.disable = false
                    //integrar cierre de ventana y regresar a la lista de candidatos
                    this.windowClose()                   
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.disable=false
            }
        },
        //METODO PARA ACTUALIZAR
        async actualizarInformacion(){
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato_user`
            if(this.tipoRol==="Validador" || this.tipoRol==="Administrador"){
               
               const res = await axios.patch(url,{idsc:this.idsc,curriculares:this.curriculares},this.config)
               if(res.data.result = 1){
                   this.$alert('SE ACTUALIZO INFORMACION','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1500});
                   this.disable = false
                   //integrar cierre de ventana y regresar a la lista de candidatos
                      //integrar cierre de ventana y regresar a la lista de candidatos
                      this.windowClose()                      
                   return
               }          
               this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
               this.disable=false
           }

        }

    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        //this.getPartidoById(this.$route.params.id);        
        this.getCandidatosInfo(this.$route.params.id)
        const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')            
            //Datos del usuario que Inicia Sesion en el portal
            const {idUsuario,usernamePanel,roles, token} = JSON.parse(loggedAppUserPanel)
            this.idUsuario = idUsuario
            this.username = usernamePanel            
            this.tipoRol= roles[0].rol
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }
            const resultado = await axios.get(url,this.config)
            //Obtenemos el idFolioValidacion del usuario para poderle mostrar la tabla con sus candidatos asignados para revisar o capturar informacion. 
            this.usuario_idFolioValidacion =resultado.data[0].idFolioValidacion
            
            // Para entrar a su validacion de OPL
            this.usuario_organizacion = resultado.data[0].organizacion[0].descOrganizacion
            this.usuario_idarea = resultado.data[0].areas[0].idArea
            this.usuario_organizacion = resultado.data[0].organizacion[0].descOrganizacion
            this.usuario_area = resultado.data[0].areas[0].idArea
        
        
         //Pasamos los valores de inicio de sesion de usuario y JWT
        
            //activacion de botones dependiendo del usuario
            if(this.tipoRol==="Supervisor"){
                this.botonQuitarV=true
                this.botonValidar=false
                this.botonInvalidar=false          
            }
            else if(this.tipoRol==="Validador"){
                this.botonQuitarV=false
                this.botonInvalidar=true
                this.botonValidar=true   

            }   


    },

}

</script>
<style scoped src="../assets/styles/candidato.css">

.editable-div[contenteditable="false"]{
    background-color: #888;
    color:#888
}
.btn-toggleP {
    padding: .6rem .6rem;
    font-weight: 600;
    color: #fff;
    background-color: #686868;;  
    border-radius: 5px;
    font-size: 18px;
    border: 2px solid #54538b;;
    width: 340px;
    text-align: center;
    font-family: GalanoRegular;
      width: 100%;
   
    
    
  }
  .btn-toggleP:hover,
  .btn-toggleP:focus {
    color: #fff;
    background-color:  #12ec12;
  }
  .btn-toggleP-nav a:hover,
  .btn-toggleP-nav a:focus {
  background-color: #d2f4ea;
  
  }
  .btn-toggleP1 {
    padding: .6rem .6rem;
    font-weight: 600;
    color: #fff;
    background-color: #686868;;  
    border-radius: 5px;
    font-size: 18px;
    border: 2px solid #54538b;;
    width: 340px;
    text-align: center;
    font-family: GalanoRegular;
      width: 100%;
   
    
    
  }
  .btn-toggleP1:hover,
  .btn-toggleP1:focus {
    color: #fff;
    background-color:#FF0000;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
  background-color: #d2f4ea;
  
  }


</style>