<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>  
        <h4>Registro de candidaturas por nivel de ingreso mensual</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
        <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie> 
      </div>
    </div>
  </div>



  </div>
         
    
  
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaNivel',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {       

        labels: [ 'Menos de $11,000', 'De $11,001 a $25,000','De $25,001 a $50,000','De $50,001 a $75,000','De $75,001 a $112,000','Más de $112,000','No recibe ingresos','Otros'],
        datasets: [ 
            {
            label: 'Nivel de ingresos',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#B20066','#6461A9','#912283','#3054A1','#E72174','#33398E','#E467A3','#2A7BA9']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    }
                    
                   
                }
            }
        },

        legend:{
          display:true,
          position:'bottom',
          text:"De los candidados.."
        },
        title:{
          display:true,
          text: "Distribucion de datos"
        },
        
      },
      //Variables para creacion de los datos
      lista :[],

      cNivelE1:0,
      cNivelE2:0,
      cNivelE3:0,
      cNivelE4:0,
      cNivelE5:0,
      cNivelE6:0,
      cNivelE7:0,
      cNivelE8:0      
    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const res = await axios.get(url)
        this.lista = res.data
        let count1=0
        let count2=0
        let count3=0
        let count4=0
        let count5=0
        let count6=0
        let count7=0
        let count8=0     
       
        this.lista.forEach(element =>{ 
          if(element.validacionCPyPP===true){
            if(element.identidad.socioeconomico=== "Menos de $11,000")
                {
                   count1=count1+1
                }
            else if(element.identidad.socioeconomico === "De $11,001 a $25,000")
            {
              count2=count2+1

            }
            else if(element.identidad.socioeconomico === "De $25,001 a $50,000"){
              count3=count3+1

            }
            else if(element.identidad.socioeconomico === "De $50,001 a $75,000"){
              count4=count4+1
            }
            else if(element.identidad.socioeconomico === "De $75,001 a $112,000"){
              count5=count5+1
            } 
            else if(element.identidad.socioeconomico === "Más de $112,000"){
              count6=count6+1
            } 
            else if(element.identidad.socioeconomico === "No recibe ingresos"){
              count7=count7+1
            } 
            else{
              count8=count8+1
            } 
        
        }      
      })

     this.chartData.datasets[0].data[0] = count1  
     this.chartData.datasets[0].data[1] = count2
     this.chartData.datasets[0].data[2] = count3
     this.chartData.datasets[0].data[3] = count4
     this.chartData.datasets[0].data[4] = count5 
     this.chartData.datasets[0].data[5] = count6
     this.chartData.datasets[0].data[6] = count7
     this.chartData.datasets[0].data[7] = count8 


     this.loaded = true

    }
    
}
</script>


<style>
</style>