import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import AboutView from '../views/AboutView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Curriculum from '../views/Curriculum.vue'
import Contacto from '../views/Contacto.vue'
import Profesional from '../views/Profesional.vue'
import Laboral from '../views/Laboral.vue'
import Identidad1 from '../views/Identidad1.vue'
import Identidad2 from '../views/Identidad2.vue'
import Identidad3 from '../views/Identidad3.vue'
import Identidad4 from '../views/Identidad4.vue'
import Identidad5 from '../views/Identidad5.vue'
import Identidad6 from '../views/Identidad6.vue'
import Identidad7 from '../views/Identidad7.vue'
import Identidad8 from '../views/Identidad8.vue'
import Identidad9 from '../views/Identidad9.vue'
import Identidad10 from '../views/Identidad10.vue'
import Identidad11 from '../views/Identidad11.vue'
import Identidad12 from '../views/Identidad12.vue'
import Identidad13 from '../views/Identidad13.vue'
import Identidad14 from '../views/Identidad14.vue'
import Identidad15 from '../views/Identidad15.vue'
import Identidad16 from '../views/Identidad16.vue'
import Identidad17 from '../views/Identidad17.vue'
import Identidad18 from '../views/Identidad18.vue'
import Identidad19 from '../views/Identidad19.vue'
import Identidad20 from '../views/Identidad20.vue'
import Indice from '../views/Indice.vue'
import Publicacion from '../views/Publicacion.vue'
import Conoceles from '../views/Conoceles.vue'
import ListaUsuarios from '../views/ListaUsuarios.vue'
import AdminPanel from '@/views/AdminPanel.vue'


import ListaCandidatos from '@/views/ListaCandidatos.vue'
import ListaCandidatosR from '@/views/ListaCandidatosR.vue'
import ListaCandidatosC from '@/views/ListaCandidatosC.vue'
import ListaCandidatosA from '@/views/ListaCandidatosA.vue'
import ListarCrearCandidatos from '@/views/ListarCrearCandidatos.vue'
import ListaInformativaC from '@/views/ListaInformativaC.vue'



import PanelRevision from '@/views/PanelRevision.vue'
import PanelCapturaDatos from '@/views/PanelCapturaDatos.vue'
import PanelSupervisor from '@/views/PanelSupervisor.vue'

//import path from 'path-browserify'
import PanelAdministrador from '../views/PanelAdministrador.vue'
import CrearUsuariosCandidatos from '../views/CrearUsuariosCandidatos.vue'
import CrearUsuariosPortal from '../views/CrearUsuariosPortal.vue'
//import DatosUser from '../views/DatosUser.vue'
import CapturaCandidato from '../views/CapturaCandidato.vue'
import RevisionCandidato from '../views/RevisionCandidato.vue'
import RevisionCandidatoCorreccion from '../views/RevisionCandidatoCorreccion.vue'

import PanelRCPartidos  from '@/views/PanelRCPartidos.vue'
import ListaCandidatosRC from '../views/ListaCandidatosRC.vue'

//import DatosCandidato from '../views/DatosCandidato.vue'
import GraficaEdad from '../components/GraficaEdad.vue'
import GraficaGrado from '../components/GraficaGrado.vue'
import GraficaNivel from '../components/GraficaNivel.vue'
import GraficaIndigena from '../components/GraficaIndigena.vue'
import GraficaDiscapacidad from '../components/GraficaDiscapacidad.vue'
import GraficaAfromexicana from '../components/GraficaAfromexicana.vue'
import GraficaDiversidad from '../components/GraficaDiversidad.vue'
import GraficaMigrante from '../components/GraficaMigrantes.vue'
import GraficaEdad1 from '../components/GraficaEdad1.vue'
import GraficaEdad2 from '../components/GraficaEdad2.vue'
import GraficaTipoDiversidad from '../components/GraficaTipoDiversidad.vue'
import GraficaSexo from '../components/GraficaSexo.vue'
import ListaRevisionCandidatos from '@/views/ListaRevisionCandidatos.vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import GraficaCurricularA from '@/components/GraficaCurricularA.vue'
import GraficaCurricularD from '@/components/GraficaCurricularD.vue'
import GraficaCurricularDRP from '@/components/GraficaCurricularDRP.vue'


//Se crea pagina LandigPage como aterrizje se desactiva el acceso a conoceles
import LandingPage from '@/views/LandingPage.vue'
import ResetPassword from '../views/ResetPassword.vue'



Vue.use(VueRouter)

const routes = [
  {
    path:'/index',
    name:'LandingPage',
    component:LandingPage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Conoceles',
    component: Conoceles,
    //Creacion de los containers hijos graficas
    children: [
      {
        path:'/graficacurricular1',
        component:GraficaCurricularA,

      },
      {
        path:'/graficacurricular2',
        component: GraficaCurricularD
      },
      {
        path:'/graficacurricular3',
        component: GraficaCurricularDRP
      },

      {
        path: '/graficagrado',
        component:GraficaGrado,
      },
      {
        path: '/graficaedad',
        component: GraficaEdad,
      },
      {
        path: '/graficanivel',
        component: GraficaNivel,
      },
      {
        path: '/graficain',      
        component: GraficaIndigena,
      }, 
        
      {
        path: '/graficadis',
        component: GraficaDiscapacidad,     
      },
      {
        path: '/graficaafro',
        component: GraficaAfromexicana,     
      },
      {
        path: '/graficaafro',
        component: GraficaAfromexicana,     
      },
      {
        path: '/graficadiversidad',
        component: GraficaDiversidad,     
      },
      {
        path: '/graficamigrante',
        component:GraficaMigrante,
      },
      {
        path: '/graficajoven',
        component:GraficaEdad1
        
      },
      {
        path: '/graficamayor',
        component:GraficaEdad2        
      },
      {
        path:'/graficatdiversidad',
        component:GraficaTipoDiversidad
      },
      {
        path:'/GraficaSexo',
        component:GraficaSexo

      },
      {
        path: '/graficacurricularA',
        component:GraficaCurricularA,
        
        
      }, 

    ]
  },
  //MIgas de navegacion
  {
    path:'/breadcrumb',
    name: 'Breadcrumb',
    component: Breadcrumb
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto,
    props:true
  },
  {
    path: '/profesional',
    name: 'Profesional',
    component: Profesional
  },
  {
    path: '/laboral',
    name: 'Laboral',
    component: Laboral
  },
  {
    path: '/identidad1',
    name: 'Identidad1',
    component: Identidad1
  },
  {
    path: '/identidad2',
    name: 'Identidad2',
    component: Identidad2
  },
  {
    path: '/identidad3',
    name: 'Identidad3',
    component: Identidad3
  },
  {
    path: '/identidad4',
    name: 'Identidad4',
    component: Identidad4
  },
  {
    path: '/identidad5',
    name: 'Identidad5',
    component: Identidad5
  },
  {
    path: '/identidad6',
    name: 'Identidad6',
    component: Identidad6
  },
  {
    path: '/identidad7',
    name: 'Identidad7',
    component: Identidad7
  },
  {
    path: '/identidad8',
    name: 'Identidad8',
    component: Identidad8
  },
  {
    path: '/identidad9',
    name: 'Identidad9',
    component: Identidad9
  },
  {
    path: '/identidad10',
    name: 'Identidad10',
    component: Identidad10
  },
  {
    path: '/identidad11',
    name: 'Identidad11',
    component: Identidad11
  },
  {
    path: '/identidad12',
    name: 'Identidad12',
    component: Identidad12
  },
  {
    path: '/identidad13',
    name: 'Identidad13',
    component: Identidad13
  },
  {
    path: '/identidad14',
    name: 'Identidad14',
    component: Identidad14
  },
  {
    path: '/identidad15',
    name: 'Identidad15',
    component: Identidad15
  },
  {
    path: '/identidad16',
    name: 'Identidad16',
    component: Identidad16
  },
  {
    path: '/identidad17',
    name: 'Identidad17',
    component: Identidad17
  },
  {
    path: '/identidad18',
    name: 'Identidad18',
    component: Identidad18
  },
  {
    path: '/identidad19',
    name: 'Identidad19',
    component: Identidad19
  },
  {
    path: '/identidad20',
    name: 'Identidad20',
    component: Identidad20
  },
  {
    path: '/indice',
    name: 'Indice',
    component: Indice
  },
  {
    path: '/publicacion',
    name: 'Publicacion',
    component: Publicacion
  },
  {
    path:'/resetpassword',
    name: 'ResetPassword',
    component:ResetPassword,

  },
  {
    path: '/curriculum/:id', // Envia toda la informacion al componente Curriculum por medio de id
    name: 'Curriculum',
    component: Curriculum
  },
  
 
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  },
  { path: "/404",
    name:'PageNotFound',
    component: PageNotFound
  },
  { path: "*", 
  redirect: "/404" },
  //acceso al sistema de captura,  validacion  y publicacion, y administradores dell SCyCC 
  
  
  {
    path: '/adminpanel',
    name: 'AdminPanel',
    component: AdminPanel
  },

  //acceso solo como capturista de SCyCC
  {
    path: '/capturacandidato/:id',
    name: 'CapturaCandidato',     
    component: CapturaCandidato
  },
  {
    path: '/revisioncandidato/:id',
    name: 'RevisionCandidato',     
    component: RevisionCandidato
  },
  {
    path: '/revisionycorreccionc/:id',
    name: 'RevisionCandidatoCorreccion',     
    component: RevisionCandidatoCorreccion
  },
  
  {
    path:'/lista_revision',
    name: 'ListaRevisionCandidatos',
    component:ListaRevisionCandidatos

  },

 
  //Acceso solo validador y supervisor
  {
    path:'/panelrevision',
    name:'PanelRevision',
    component:PanelRevision,
    children:[{
      path:'/candidatosr',   
      component: ListaCandidatosR,
    }]


  },
  {
    path:'/panelcaptura',
    name:'PanelCapturaDatos',
    component:PanelCapturaDatos,
    children:[
    {
      path:'/candidatosc',   
      component: ListaCandidatosC,

    }
    ]

  },
  {
    path:'/panelrcpartidos',
    name:'PanelRCPartidos',
    component:PanelRCPartidos,
    children:[
    {
      path:'/candidatosrc',   
      component: ListaCandidatosRC,

    }
    ]

  },

  {
    //acceso supervisor realizar asignaciones
    path:'/panelsupervisor',
    name:'PanelSupervisor',
    component:PanelSupervisor,
    children:[
      {
        //path:'/candidatosa',
        //component:ListaCandidatosA
        path:'/candidatosa',   
        component: ListaCandidatosA,
      }
    ]    
  },
  {
    //acceso solo via  admininistrador
    path:'/paneladministrador',
    name:PanelAdministrador,
    component:PanelAdministrador,
    children: [
      {
        path: '/usuarios',
        component: ListaUsuarios,
       
      },
      {
        path:'/candidatos',   
        component: ListaCandidatos,
    
      },

      {
        path: '/crearusuariosportal',
        component: CrearUsuariosPortal
      },
      {
        path: '/crearcandidatos',
        component: CrearUsuariosCandidatos
      },
      {
        path: '/crearcandidatoslista',      
        component: ListarCrearCandidatos
      }, 
        
      {
        path: '/listainformativac',
        component: ListaInformativaC      
      }
    ]

  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
