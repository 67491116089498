<template>
  <!-- class="fixed-top" -->
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      
    <b-navbar-toggle target="nav-collapse" style="margin: 0px 10px;"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav style="padding: 0 50px; ">
      <b-avatar v-if="imagen" :src="dirname+imagen" alt="Imagen de Perfil" size="6rem" style="margin-right: 20px;"></b-avatar>
      <b-avatar v-else src="" size="6rem" style="margin-right: 20px;"></b-avatar>
      <b-navbar-nav>
        <b-nav-item v-on:click="partido()">
          <p class="item">{{ username }}  </p>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ms-auto">
        <b-nav-item-dropdown no-caret>
          <template #button-content>
            <p class="item">Menú<fa icon="fa-solid fa-caret-down" style="font-size: 20px; margin-left: 10px; color: #9F2380;"/></p>
          </template>
          <b-dropdown-item><p class="item2">Indice</p></b-dropdown-item>
          <b-dropdown-item><a href="#resetPassword" class="item2" v-b-modal.modal-scoped>Cambiar contraseña</a></b-dropdown-item>
          <b-dropdown-item><p class="item2">Aviso de privacidad</p></b-dropdown-item>
          <b-dropdown-item v-on:click="sessionClose()"><p class="item2">Cerrar Sesión</p></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  <br> 
  <!--Modal View-->
  <div><!--ventana modal-->
    <b-modal id="modal-scoped" >
        <template #modal-header >                  
          <h5><img src="../assets/boton_conoceles.png" alt="Proceso image" width="100px" style="margin:auto"/> </h5>
        </template>
        <template #default="">
            <!--inicia form cambio contraseña-->
            <div id="home">
            <div class="wrapper fadeInDown">
            <div id="formContent" class="d-flex flex-column">                               
              <h2>Actualizar contraseña</h2>
              <div class="m-4 align-content-center">
                <b-avatar v-if="imagen" :src="dirname+imagen" alt="Imagen de Perfil" size="6rem" style="margin-right: 20px;"></b-avatar>
                <b-avatar v-else src="" size="6rem" style="margin-right: 20px;"></b-avatar>      
              </div>

                <form v-on:submit.prevent="login">                                    
                  <div class="m-5 align-content-center">
                      <p style="font-size: 16px">Nombre de Usuario</p><br>                      
                      <p style="font-size: 16px">{{username}}</p><br>
                      <div class="m-2 mb-2">
                        <p style="font-size: 16px">Contraseña actual</p><br> 
                        <input type="password" id="currentPassword" class="fadeIn second" name="login" placeholder=" Contraseña Actual" v-model="currentPassword">
                      </div>
                      <div class="m-2 mb-2">
                        <p style="font-size: 16px">Nueva Contraseña</p><br> 
                        <input type="password" id="newPassword" class="fadeIn third" name="login" placeholder=" Nueva Contraseña" v-model="newPassword">
                      </div>
                      <div class="d-flex flex-column mb-3">              
                        <button type="submit" class="btn btn-custom fadeIn fourth" @click="actualizarPassword()">Actualizar Contraseña</button>
                      </div>
                </div>
              </form>                                                            
          </div>
          </div>                      
          </div>                                     
      </template>
        <template #modal-footer="{cancel}">                    
              <b-button size="sm" variant="danger" @click="cancel()">
                Cerrar Ventana
              </b-button>               
        </template>
    </b-modal>
      </div><!--Fin ventana modal-->     
     
  </div>

</template>
<script>
import axios from 'axios'
import routes from '../data/routes.json'


export default {
  data(){
    return{
      dirname:this.$assetsDirname,
      routes:routes,
      newPassword:"",
      currentPassword:"",
      currentUsername:"",
      config:{},
      username1:"",
      idCandidato:null,
      idCandidato1:null
    }
  },
  props:{ 
    username: String, 
    imagen: String,
 
    
    },
  // Se utilizan los las variables pros que se utilizaran en todo el Header para heredar datos durante toda la navegacion del sitio
  methods:{
    cancel(){
      this.newPassword="",
      this.currentPassword=""
    },
    partido(){
    this.thispath('/partido')
    },
    cambiarPassword(){
      this.thispath('/resetpassword')
    },
    thispath(path){
        if (this.$route.path !== path) this.$router.push(path)
    },  

    sessionClose(){
      localStorage.removeItem('loggedAppUser')
      this.thispath('/login')
    },
    async actualizarPassword(){
      this.disable=true
      const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatoup'
       if((this.newPassword !== this.currentPassword)&&(this.idCandidato===this.idCandidato)&&(this.newPassword!=="")&&(this.currentPassword!=="")){
          const res = await axios.patch(url, { idCandidato:this.idCandidato,password:this.newPassword }, this.config)         
          if(res.data.result = 1){
              this.$alert('','<label class="alertlabel">Se actualizo la contraseña se cerrara la sesión</label>','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'bottom', width: 200, timer: 2000});
              this.sessionClose()
              this.disable = false                   
              //this.path(this.pagina+1) // siguiente pagina
              return
            }
            this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            //this.path(this.pagina+1) // siguiente pagina
            this.disable=false
        }
        else{
            this.$alert('','<label class="alertlabel">Error en contraseñas: es Vacía o Igual a la anterior</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            this.disable=false
        }
      }
    },//Terminan metodos

    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        //Se muestra el aviso de privacidad    
        //Metodo Created para cargar la informacion de inicio de sesion de usuario en los Tokens    

        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/login')

             
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username1 = username
            this.idCandidato = idCandidato           

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }               

            const result = await axios.get(url, this.config)
            this.idCandidato1=result.data[0].idCandidato            
            this.logsAcciones=result.data[0].logsAcciones     
      
            this.currentLogo = result.data[0].imagen  
            this.isLoading = false
        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
<style scoped>
/* .dropdown-toggle.caret-on::after {
    display: none;
} */
/* .navbar.navbar{
    background-color: #AABB55!important;
 } */
 
 .navbar{

  background-image: url("../assets/pleca_faltante.png");
  background-size:auto;
  background-repeat:no-repeat;  

  

  
}
.item {
  font-family: GalanoBold;
  color: #54538b;
  margin: 0px 40px 0px 0px ;
  font-size: 20px;
  text-shadow: #fff;
}
.item2 {
  font-family: GalanoBold;
  color: #9F2380;
  margin: 0px 40px 0px 0px ;
}

@media (max-width: 991px) {
  .item {
  font-family: GalanoBold;
  color: #fff;
  margin: 0px 0px 0px 0px ;
  font-size: 20px;
}
.item2 {
  font-family: GalanoBold;
  color: #9F2380;
  margin: 0px 0px 0px 0px ;
}
}

.custom-border {
  background-image: url("../assets/pleca_faltante.png");
  background-size: contain;
  font-size: 22px;
  /*
    border-bottom: 2px solid;    
    border-color: #B20066;
    background: rgb(181,182,196);
    background: linear-gradient(180deg, rgba(181,182,196,1) 0%, rgba(235,236,255,1) 46%, rgba(255,255,255,1) 99%);
    */
    
}
/** INICIAN ESTILOS DEL MODAL VIEW */
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* BASIC */
html {
  background-color: #56baed;
}

body {
  font-family: GalanoRegular;
  height: 100vh;
}

a {
  font-family: GalanoRegular;
  color: #6a6ea8;
  display:inline-block;
  text-decoration: none;
  font-size: 14px;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #6a6ea8;
  font-family: GalanoRegular;
  padding-left: 2%;
  padding-right: 2%;
}

/* STRUCTURE */
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 10%;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */
h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/
.btn-custom {
    font-family: GalanoRegular;
    margin: 0px 7.5%;
    padding: 15px 0px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #B20066;
    --bs-btn-hover-border-color: #B20066;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #B20066;
}

input[type=text]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */
/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #B20066;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #B20066;
}

.underlineHover:hover:after{
  width: 100%;
}

/* OTHERS */
*:focus {
    outline: none;
} 

#icon {
  width:60%;
} 
input {
  font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}

p{
  font-family: GalanoRegular;
  text-align:left;
  font-size: 12px;
  font-weight: 600;
 
  display:inline-block;  
  color:#6c757d;
}




</style>