import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import VueSimpleAlert from "vue-simple-alert";
import Multiselect from 'vue-multiselect'
import SmartTable from 'vuejs-smart-table'
// se agrega google analytics
import VueAnalytics from 'vue-analytics'

library.add(fas, far, fab) // Agregar los iconos en la librería
Vue.component('fa', FontAwesomeIcon) // Componente para visualizar los iconos en el DOM
Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false
//Vue.prototype.$assetsDirname = 'https://informatica.iem.org.mx/iem/uploads/' //Server (global variable) 3000  parta subir archivos a la nube
//Vue.prototype.$assetsDirname = 'http://localhost:3000/uploads/'
Vue.prototype.$assetsDirname = 'https://informatica.iem.org.mx/api/conoceles/uploads/' 


Vue.use(SmartTable)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSimpleAlert)
Vue.use(VueAxios, axios)
//usamos Vue Analitics
Vue.use(VueAnalytics, {
  id: 'G-YM7ZLSHPGR' // Reemplaza esto con tu ID de Google Analytics
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')