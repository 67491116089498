<template>
    <div class="container">
        <div style="border-color:#970064;">
            <div class="row" style="margin-bottom: -4%; ">
            <b-navbar toggleable="lg">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>             
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="navbar heading-top" style="margin-left: auto; color:#970064">
                        <b-nav-item href="https://directorio.ine.mx/filtroConsultaEmpleado" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-users" class="btn-toggle" />&emsp14;Directorio INE</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/transmisiones-en-vivo/"  target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-video"  class="btn-toggle" />&emsp14;Transmisiones en vivo</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/estructura-ine/consejo-general/gaceta-electoral/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-book" class="btn-toggle" />&emsp14;Gaceta Electoral</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/agenda-consejeros-electorales/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-calendar-days"  class="btn-toggle" />&emsp14;Agenda Consejeros (as)</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/compendio-normativo/" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-scale-balanced"  class="btn-toggle" />&emsp14;NormaINE</p></b-nav-item>
                        <b-nav-item href="https://www.facebook.com/INEMexico/" target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-facebook" class="brand-icon btn-toggle"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://twitter.com/INEMexico"  target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-x-twitter fa-xs" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://www.youtube.com/user/IFETV" target="_blank"><p style="color:#970064"><fa icon="fa-brands fa-youtube" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>
                        <b-nav-item href="https://portal.ine.mx/feed" target="_blank"><p style="color:#970064"><fa icon="fa-solid fa-square-rss" class="brand-icon btn-toggle" style="color:gray"/>&emsp14;</p></b-nav-item>             
                    </b-navbar-nav>&nbsp;&emsp;                               
                </b-collapse>  
            </b-navbar>
            </div>           
            <div class="col-xs-12 col-sm-12 col-lg-12 heading-bg">                         
                    <b-navbar toggleable="lg">
                    <b-navbar-brand href="#" style="margin-right: 13%;"><img src="../assets/ine_logo.png" alt="logo INE" width="90px"/></b-navbar-brand>                    
                        <b-collapse id="nav-collapse" is-nav>
                            <b-navbar-nav >
                                <b-nav-item href="https://portal.ine.mx/sobre-el-ine/" target="_blank" class="">&nbsp;&emsp; SOBRE EL INE</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/credencial/"  target="_blank">&nbsp;&emsp;CREDENCIAL PARA VOTAR</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/voto-y-elecciones/" target="_blank" >&nbsp;&emsp;VOTO Y ELECCIONES</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/cultura-civica/" target="_blank" >&nbsp;&emsp;CULTURA CÍVICA</b-nav-item>
                                <b-nav-item href="https://portal.ine.mx/servicios-ine/" target="_blank">&nbsp;&emsp;SERVICIOS INE</b-nav-item>
                                <b-nav-item href="https://centralelectoral.ine.mx/" target="_blank">&nbsp;&emsp;CENTRAL ELECTORAL</b-nav-item>
                                
                            </b-navbar-nav>
                                   
                        </b-collapse>
                    </b-navbar>                    
            </div>
         

    <div class="d-flex flex-column altura border">
            <div class="row pb-2 mb-2 align-content-center" >
                <div class="col-xs-12 col-sm-12 col-lg-2">
                    <img src="../assets/logoIEM.png" alt="Logo" class="img-fluid" style="width: 200px;object-fit: contain; border-radius: 5px; margin-top: 5%; margin-left: 2%;">                    
                                                  
                </div> <!--Cierre div logo-->
                <!--se desactiva-->
                <div class="col-xs-12 col-sm-12 col-lg-8">                                      
                   <h2 class="texto-w">!Bienvenidos!</h2>
                   <h5 class="text-center mr-2">Consulta la trayectoria política y profesional asi como las propuestas de campaña y la autoidentificación a determinados grupos de atención prioritaria de las candidatas y los candidatos
                    que participan en la contienda <strong> Electoral Local 2024.</strong>
                   </h5>                         
                </div> <!--Cierre div text-->
                <div class="col-xs-12 col-sm-12 col-lg-2 ">                                      
                                                   
                </div> <!--Cierre div text-->
            </div> <!--Termina  fila 1-->
            
            <div class="row div_back ">               
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 mb-5 mt-5">
                        <img src="../assets/logo_proceso_electoral_banner.png" alt="Logo Proceso" class="img-fluid" style="width: 200px; object-fit: contain;" >                         
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 p-2">                    
                        <img src="../assets/boton_conoceles.png" alt="Logo" class="img-fluid" style="width: 700px;object-fit: contain; margin-bottom: -25%;">
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 mt-5">                        
                        <img src="../assets/mapa.png" alt="Logo" class="img-fluid" style="width: 200px;object-fit: contain;" >                       
                    </div>                
            </div><!--Termina div_back; -->
            
            
            <div class="row align_legend"  >                               
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="clearfix">&nbsp;</div>                   
                        <p class="heading mt-3">La información es proporcionada por los sujetos obligados
                        <strong>bajo su responsabilidad</strong> por lo cual el Instituto Electoral de Michoacán <strong>
                        únicamente apoya para su difusión.</strong></p>              
                </div>                          
            </div> <!--Termina fila 3-->
        </div><!--Termina banner principal-->
        <!--Inicia menu migas de navegacion-->
        <!--
            <div>           
            <Breadcrumb :crumbs="breadcrumbs" />          
        </div>
        -->
        <!--segunda miga-->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-6">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="https://portal.ine.mx/voto-y-elecciones/" target="_blank">VOTO Y ELECCIONES</a></li>
                        <li class="breadcrumb-item"><a href="https://www.ine.mx/voto-y-elecciones/elecciones-2024/" target="_blank">ELECCIONES 2024</a></li>
                        <li class="breadcrumb-item active fw-bold" style="color:#970064" aria-current="page">CANDIDATAS Y CANDIDATOS</a>&nbsp;</li>
                                             
                    </ol>
                </nav>                
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-6 justify-content-end btn-align-right mt-4">               
                   <span ><fa icon="fa-solid fa-location-dot"/><strong>Área: </strong><a href="https://portal.ine.mx/estructura-ine/uttpdp/" target="_blank" style="color:#970064;">UTTyPDP</a></span>
                   &emsp;&emsp14;<span><a href="https://candidaturas.ine.mx/preguntasFrecuentes" style="color:#970064" target="_blank" >Preguntas frecuentes</a>  </span>                    
            </div>                                              
                         
        </div>     

    </div>         
          
        <!--            
        <nav style="--bs-breadcrumb-divider: '|';" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><label for="voto"></label><a href="https://portal.ine.mx/voto-y-elecciones/" target="_blank" id="voto">VOTO Y ELECCIONES</a></li>
                <li class="breadcrumb-item"><a href="https://www.ine.mx/voto-y-elecciones/elecciones-2024/">ELECCIONES 2024</a></li>
                <li class="breadcrumb-item active" aria-current="page" >CANDIDARAS Y CANDIDATOS</li>            
            </ol>
        </nav>
        -->    
</div>
</template>


<script>
import Breadcrumb from '@/components/Breadcrumb.vue';

export default{
    name:'Banner',
    components:{
        Breadcrumb
    },
    data(){
        return{
                breadcrumbs:[   
                                      
                {label:'CANDIDATAS Y CANDIDATOS', path:'/conoceles'}

                ]                
            }
        }

    }
</script>
<style scoped>
/* Estilos del menú responsivo */
h1 {
    font-family: GalanoBlack;
    color: #970064;
    /*text-shadow: 1px 2px 1px #e8afd0;*/
}
.texto-w {
    font-family: GalanoBold;
    color: #970064;    
    
    font-size:xx-large;
    text-align:center;
    /**text-shadow: 1px 2px 1px #e8afd0;*/
    text-transform:capitalize;

    
}
h2{
    text-align:center;
}
h3.texto{
    font-family: GalanoBlack;
    color: #4D4170;
    text-shadow: 2px 4px 2px #fff;

}
strong {
    font-family: GalanoBold;
}
.heading {
    padding: 10px 5% 10px 5%;
    font-family: GalanoRegular;
    background: rgb(223,223,223);
    background: linear-gradient(180deg, rgba(223,223,223,1) 0%, rgba(255,255,255,1) 46%, rgba(255,255,255,1) 100%);
    color: #970064;
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -webkit-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
    -moz-box-shadow: 1px 11px 6px -2px rgba(0,0,0,0.19);
   
    
    
}
.heading-bg{
   
    font-family: GalanoRegular;  
    width: 100%;   
    font-size: 14px;
    
    margin-bottom:2%;
  
    
}
.heading-top{
    color:#970064 ;
}
.altura{
    /*background-color: rgb(231, 93, 139, 0.8);*/
    background-color:white;
    width: 100%;
    border-radius: 15px;
    
  
  }
  .div_back{
    background-color:#D9DAEB ; 
    margin-left: -1px;
    margin-right: -1px;
    height: 75%;
    
}
.align_legend{
    margin-left: 2%; 
    margin-right: 2%; 
    margin-top: 5%;
    margin-bottom: 2%;
    padding-top: 6%;
}
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}
.btn-toggle {   
    color: #686868;
    background-color: #fff;   
    font-size:14px ; 
    

}
.btn-toggle-f {   
    color: #686868;    
    background-color: #fff;   
    font-size:14px ;
}
.btn-toggle-f:focus{
    color: #1877f2;
    background-color: #fff;   
    font-size:14px ;

}

.btn-toggle-x {
    font-weight: 16;
    color: #686868;
    background-color: #fff;  
    
    font-size:14px ; 
    

}

.btn-toggle-y {
    font-weight: 16;
    color: #686868;
    background-color: #fff;
    font-size:14px ; 
}


.btn-toggle-rss {
    font-weight: 16;
    color: #686868;
    background-color: #fff;    
    font-size:14px ; 
    

}




</style>