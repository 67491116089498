<template>
  <div>
      <!--<HeaderClean/> -->
      <div class="row head-size">
        <img src="../assets/pleca_faltante.png" class="img-fluid rounded-top" alt=""/>
        </div>

      <div class="container">
          <div class="d-flex flex-column align-items-center" style="margin-top: 5%; margin-bottom: 5%;">
                <img src="../assets/boton_conoceles.png" style="margin-top: 20px;width: 50%;" id="icon" alt="User Icon" />
                <p class="question">NO EXISTE ESTA PÁGINA</p>
          </div>
      </div>
      <Footer/>
  </div>
</template>
  
  <script>
  //import HeaderClean from '@/components/HeaderClean.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    name: 'PageNotFound',
    props: {
      msg: String
    },
    components:{
        //HeaderClean,
        Footer
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }

  .error-404 {
  margin: 0 auto;
  text-align: center;
}
.error-404 .error-code {
  bottom: 60%;
  color: #4686CC;
  font-size: 96px;
  line-height: 100px;
  font-weight: bold;
}
.error-404 .error-desc {
  font-size: 12px;
  color: #647788;
}
.error-404 .m-b-10 {
  margin-bottom: 10px!important;
}
.error-404 .m-b-20 {
  margin-bottom: 20px!important;
}
.error-404 .m-t-20 {
  margin-top: 20px!important;
}
.head-size{
    width: 100%;   
    /*background-image: url("../assets/pleca_faltante.png") ;*/
 
}

  </style>
  