<template>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-2" >

        </div><!--termina fila izquierda-->           
        <div class="col-xs-12 col-sm-12 col-lg-8" >                
            <h1>Creacion de usuarios Candidatos</h1>               
   
            <!-- Habilitar dependiendo del campo con que se validara la informacion
            <div class="col-auto">
                <label for="cic">Ingresa No. CIC</label>
                <input type="text" class="form-control" id="cic" placeholder="Ingresa CIC" v-model="cicCandidato" @input="comprobarFolio">
            </div>        
            <div class="col-auto">
                <button type="submit" class="btn btn-secondary m-3">Validar datos</button>
            </div>

            -->               
            <form class="row g-3" id="formulario"> 
                <div class="col-md-12">
                    <label for="folior" class="form-label">Ingresa ID del Postulante</label>
                    <input type="text" class="form-control" id="folior" placeholder="Folio Registro" v-model="id_postulacion" >
                </div>
                <div class="col-md-12">
                    <button type="button" class="btn btn-secondary"  v-on:click="comprobarFolio()" >Validar Folio&emsp;</button>   
                </div>
                <div class="col-md-3">
                    <label for="inputAddress" class="form-label">Partido Político</label>                                
                    <input type="text" class="form-control" id="inputAddress" placeholder="Emblema" readonly disabled v-model="postulante">
                </div>
                <div class="col-md-2">
                    <label for="inputAddress2" class="form-label">Distrito</label>
                    <input type="text" class="form-control" id="inputAddress2" placeholder="Distrito" readonly disabled v-model="cve_distrito">
                </div>
                <div class="col-md-4">
                    <label for="inputCity" class="form-label" >Nombre Partido</label>
                    <input type="text" class="form-control" id="inputCity" readonly disabled v-model="partido_nombre">
                </div>
                <div class="col-md-3">
                    <label for="inputState" class="form-label">Municipio</label>
                    <input type="text" class="form-control" id="municipio" readonly disabled v-model="cve_mpio">
                </div>

                <div class="col-md-3">
                    <label for="folior" class="form-label">Nombre(s)</label>
                    <input type="text" class="form-control" id="nombre" placeholder="Nombre" readonly disabled v-model="nombre">
                </div>
                <div class="col-md-3">
                    <label for="inputAddress" class="form-label">Primer Apellido</label>                                
                    <input type="text" class="form-control" id="primerapellido" placeholder="Primer Apellido" readonly disabled v-model="paterno">
                </div>
                <div class="col-md-3">
                    <label for="inputAddress2" class="form-label">Segundo Apellido</label>
                    <input type="text" class="form-control" id="segundoapellido" placeholder="Segundo Apellido" readonly disabled v-model="materno">
                </div>

                <div class="col-sm-3"  >
                    <label for="inputEmail4" class="form-label">Correo</label>
                    <input type="email" class="form-control" id="correo" readonly disabled v-model="email">                  
                </div>
                <div v-show = "activarFormulario === true" class="row g-3">
                    <div class="col-md-4">
                    <label for="username" class="form-label">Usuario</label>
                        <div class="input-group">
                        <div class="input-group-text">@</div>
                        <input type="text" class="form-control" id="username" readonly disabled  placeholder="Username" v-model="username" style="font-weight: bolder;">
                        </div>
                    </div>
                    <div class="col-md-4"  >
                        <label for="password" class="form-label">Password</label>
                        <input type="text" class="form-control" id="text" v-model="password" :disabled="inputDisabled" v-on:click="generatePassword()" style="font-weight: bolder;">                  
                    </div>
                    <div class="col-md-4">
                        <label  for="inlineFormSelectPref" class="form-label" id="asignaciones">Asignaciones</label>
                        <multiselect
                        v-model="selection"
                        @select="choose()"
                        :options="asignaciones"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;">
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="btn btn-secondary" :disabled="disable" v-on:click="crearPdfAcuse()" >CREAR PDF ACUSE DE RECIBO&emsp;</button>   
                    </div>           
                    <div class="col-md-4" v-show = "activarBoton === true">
                        <button type="button" class="btn btn-secondary" :disabled="disable" v-on:click="save()" >REGISTRAR USUARIO&emsp;</button>   
                    </div>
                    <div class="col-md-4" v-show = "activarBoton === true">
                        <button type="button" class="btn btn-secondary" v-on:click="limpiar()" >LIMPIAR&emsp;</button>   
                    </div>
                     
                </div>

                <div v-show="activarFormulario===false" class="row g-3">
                    
                    <!--
                                            <div class="col-md-3">
                    <label for="specificSizeInputGroupUsername" class="form-label">Usuario</label>
                        <div class="input-group">
                        <div class="input-group-text">@</div>
                        <input type="text" class="form-control" id="username" placeholder="Username" v-model="username">
                        </div>
                    </div>
                    <div class="col-md-3"  >
                        <label for="inputEmail4" class="form-label">Password</label>
                        <input type="password" class="form-control" id="contraseña" v-model="password">                  
                    </div>
                    <div class="col-md-3">
                        <label  for="inlineFormSelectPref" class="form-label" id="asignaciones">Asignaciones</label>
                        <multiselect
                        v-model="selection"
                        @select="choose()"
                        :options="asignaciones"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Selecciona una opción"
                        style="font-family: GalanoRegular;">
                        </multiselect>
                    </div>          
                    <div class="col-md-12">
                        <button type="button" class="btn btn-secondary" :disabled="disable" v-on:click="save()" >Registrar Usuario&emsp;</button>   
                    </div> 

                    -->
                       
                </div>                               
                        
            </form>                   
        </div> <!--Termina columna central de 6 ancho-->
        <div class="col-xs-12 col-sm-12 col-lg-2" >
        </div> <!--termina columna derecha responsiva--> 
    </div><!--termina clase row-->
</template>
<script>
//importtacion de las librerias 
import axios from 'axios'
import { BIconCheckLg } from 'bootstrap-vue'

export default{
name:'CrearUsuariosCandidatos',
data(){
    return{

        //variables  globales
        listaPartidos:[],
        listaUsuarios:[],
        listaCandidatos:[],// lista para revision de folios 
        listaTmp:[],
        selection:"",
        asignaciones:[],
        asignacionValidador:"",
        usuarioValidador:"nombre_completo",
        posicion:"", // se obtiene la posicion para obtener  folio de acuerdo al index
        folioUsuarioValidador:"", // se obtiene una vez que se elige el nombre del validador
        folioRegistroCandidato:"", // importante para vincular ambas bases de datos
        config:"",


        

        //variables que se llenan del SISIP y se envian a la coleccion de datos candidatos
        cicCandidato: "",//"183657717", Ya sea que se registre el candidato con el CIC o
        idregistro: "",
        folioRegistro: "",//"src-01" o con este folio de registro
        fechaRegistro: "",
        formulaRegistro: "",
        idEstatus: "",
        descEstatus: "",
        imagen: "",
        asociacionPostula:"",
        distrito:"",
        municipio:"",
        idRegiduria:"",
        correo:"",


        //Variables de control de formulario
        activarFormulario:false, // si la validacion es exitosa se activan celdas de creacion de usuario
        activarBoton:false, // Si el acues es generado se activa el boton para tener asegurado los datos en el pdf
        inputDisabled:false,
        message:"Usuario Candidato Registrado", //Mensajes de acciones a los usuarios
        message2:"",
        message3:"",

        //INICIAN VARIABLES DEL SICIF
         
        id_postulacion:0,
        
        tipo_eleccion: "",
        cve_mpio: "",
        postulante: "",
        cve_distrito: "0",
        nombre: "",
        paterno: "",
        materno: "",
        genero: "",
        sobrenombre: "",
        lugar_nacimiento: "",
        ocupacion: "",
        aa_tipo: 0,
        partido: "",
        partido_nombre:"",
        suplentes:"",          
        edad:0,
        tipo_postulante:"",
        email:"",
         //TERMINAN

        //Datos creacion del usuario candidato
        rol: "Candidato", // por default se asigna rol capturista al candidado
        username: "",
        password:"",
        pagina:"1",//inicializamos la pagina en 1
        paginat:"0",
        validacionPartido:false,
        validacionTransparencia:false,
        validacionCPyPP:false,
        validacionObservaciones:[],
        estadoRoC:"EN CAPTURA", // se envia por default como NO CAPTURADO
        usuarioActivo:true,
        usuarioCreado: true, // el valor true indica en la tabla que ya se a creado el usuario y se coloca en false,
        logsAcciones:"",

   
        curriculares:{
            social:{
                    facebook:"https://www.facebook.com/personal",
                    twitter:"https://www.twitter/personal",
                    youtube:"https://www.youtube.com/personal",
                    instagram:"https://www.instagram.com/personal",
                    tiktok:"https://www.tiktok.com/personal"
                    },
            contacto:{
                direccion:["direccion","direccion"],
                 email:["@gmail.com","@hotmail.com"],
                telefono:["4431700719","4431700721"],
                web:"www.pagina.com.mx",
                    },
            estudio:{
                grado_maximo:"LICENCIATURA",
                estatus:"TERMINADA",
                otro:"N/A"
                    },
            profesion:{
                historia_laboral:"HISTORIA",
                trayectoria_politica:"TRAYECTORIA",
                razon_cargo_publico:"RAZON"
                },
            propuesta:{
                 propuesta1:"PROPUESTA 1",
                propuesta2:"PROPUESTA 2",
                propuesta_grupo_discriminacion:"PROPUESTA GRUPO"
                }	 
            }, 
            identidad:""
        
 

     }
},

components:{

  
}, 
//Creacion de los metodos para esta vista
methods:{
    //Metodos para mamejo de las rutas
    path(opt){
        const routes = this.routes
        this.thispath(routes[opt])
    },
    thispath(path){
        if (this.$route.path !== path) this.$router.push(path)
    },
//Metodos de esta vista
    async getPartidos(){
        const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos`,this.config)
        this.listaPartidos = res.data;

    },
    async choose(){
        this.asignacion=this.selection
        // se optiene la lista de usuarios para  realizar asifnaciones
        this.posicion = this.asignaciones.findIndex((asignacion)=>asignacion == this.selection)
        console.log(this.posicion)
        this.asignacionFolio(this.posicion)        

    },
          //Metodo guardar datos
    async asignacionFolio(posicion){           
        let url =`https://informatica.iem.org.mx/api/conoceles/api/usuarios`
        try {
            const res = await axios.get(url,this.config)               
            this.folioUsuarioValidador=res.data[posicion].idFolioValidacion
            console.log(this.folioUsuarioValidador)
        } catch (error) {
            console.log(error.code)
            
        }
    },
    //Metodo para comprobar folio de registro o CIC y llena las celdas de solo de lectura para conformar los datos del candidato BASE DE DATOS A CONECTARSE SISIP
    async comprobarFolio(){
        const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos_users`,this.config) //AQUI SE REALIZA LA CONEXION CAMBIAR EL MODELO DEL BACKEND HECHO EN MONGO A MYSQL
        console.log(res)

        this.listaPartidos = res.data;
        //console.log(this.listaPartidos)
        //console.log(this.listaPartidos)
        console.log(typeof this.id_postulacion)       
        this.listaPartidos.forEach(element =>{

            // se realiza validacion conforme a folio o cic, esta por definirse el modo de validacion
        if((element.id_postulacion === Number(this.id_postulacion)))
           {              
            
            this.$alert('VALIDACION EXITOSA','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'center-end', width: 300, timer: 1000});
            // datos solo de Muestra para revision
            this.postulante = element.postulante
            this.distrito = element.cve_distrito
            this.cve_mpio = element.cve_mpio
            this.partido_nombre = element.partido_nombre              
            this.email = element.email
            this.nombre = element.nombre
            this.paterno = element.paterno
            this.materno = element.materno
            this.username =element.correo
            this.comprobarfolioRegistroCandidato() //comprueba el folio
            this.generatePassword()// genera el folio en el campo de texto     
            return                  
           }             
                  
     })        
    },
    // Se realiza la comprobacion que el folioRegistroCandidato no este dado de alta en la base de datos de candidatos
    async comprobarfolioRegistroCandidato(){ 
       
        this.listaCandidatos.forEach(element =>{
            //realizamos la validacion dentro de la lista de candiatos
            if(element.id_postulacion === this.id_postulacion){
                this.$alert('','<label class="alertlabel">Usuario ya registrado</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#B20066'});                   
                this.activarFormulario = false
                this.path("/crearcandidatos")                  
            }  
            //this.folioRegistroCandidato = element.folioRegistroCandidato                   
        })
        this.activarFormulario=true
    },

    async save(){             
        //Enviar datos del usuario registrado desde nuestra vista            
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        console.log(this.username, this.folioRegistroCandidato, this.folioRegistro)
        this.estadoRoC="NO CAPTURADO"

        if(this.folioRegistro!== "" && this.username !=="" && this.password!==''){ //Valida que el folio no este vacio y se procede  a guardar la informacion
            const res = await axios.post(url,{asignacionValidador:this.folioUsuarioValidador, username:this.username, password:this.password,imagen:this.imagen,rol:this.rol,pagina:this.pagina,folioRegistroCandidato:this.folioRegistro, curriculares:this.curriculares,informacion_personal:this.informacion_personal,informacion_partido:this.informacion_partido,identidad:this.identidad,validacionObservaciones:this.validacionObservaciones,estadoRoC:this.estadoRoC},this.config)    

            if(res.data.result === 1){ // se movio de asignacion a igualdad
                this.$alert('Usuario Registrado','','success', {showConfirmButton: true, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'center-end', width: 200, timer: 1000});
                this.disable=false 
                this.path("/crearcandidatos")
                return
            }
            else{
            this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
            this.path("/crearcandidatos")

            }               
            
        }else{
            this.$alert('','<label class="alertlabel">Usuario y/o Contraseña Vacíos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#B20066'});
        }
    },
    generatePassword(){
        this.password='' // limpia el campo cada vez que se clickea
        this.inputDisabled=true

        const letters='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const numbers = '0123456789';
        const symbols = '!@#$%&*_=+-/';
        const characters = letters + numbers + symbols;
        
        for(let i=0; i< 8;i++){
            const randomIndex = Math.floor(Math.random() * characters.length)
            this.password += characters[randomIndex]
        }
        return this.password;
        
        
        
    },
    crearPdfAcuse(){
        this.activarBoton = true
        // Inicia la logica de desarrollo

    },
    limpiar(){
        var formulario = document.getElementById("formulario")
        //limpiar formulario
        formulario.reset()
        formulario.getElementsByTagName('input')[0].focus()
        /*
        this.selection=""
        this.password=""
        this.folioRegistro=""
        this.asociacionPostula = ""
        this.distrito = ""
        this.municipio = ""
        this.idRegiduria = ""
        this.correo = ""
        this.nombre = ""
        this.primerApellido = ""
        this.segundoApellido = ""
        this.username =""
        this.enfoque=true
        */

        }     
   

},
//Creacion e inicializacion de lista mediante Axios para obtencion de la ruta de api partidos
async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
    //const url = '/api/usuario'        
   
    try {
        const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
        if(!loggedAppUserPanel)
            this.path('/')
        
        const {idUsuario, token, usernamePanel,nombre_completo} = JSON.parse(loggedAppUserPanel)
        this.usernamePanel2 = usernamePanel
        this.idUsuarioPanel = idUsuario  
        this.nombre_completo_usuario = nombre_completo         

        this.config = {
            headers:{
                Authorization: `Bearer ${token}`
            }
        }
        //ejecutamos la obtencion de folios cuando la pagina se cargue
        
        //inicializamos la lista de usuario con sus roles
        const res = await axios.get(`http://187.189.192.40:9090/api/usuarios`,this.config)
        this.listaUsuarios= res.data
        this.listaUsuarios.forEach(elemento =>{
            this.asignaciones.push(elemento[this.usuarioValidador])
            
        })
        //inicializamos la lista candidatos
        const res2 = await axios.get(`http://187.189.192.40:9090/api/candidatos`,this.config)
        this.listaCandidatos= res2.data          

         
        //Consulta a la ruta con coleccion de datos usuario
       // const result = await axios.get(url,this.config)
                 
        //this.idFolioValidacion = result.data[0].idFolioValidacion         
        this.path("/crearusuariosportal")
        this.isLoading = false

    } catch (error) {
        if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
            alert("error")
            localStorage.removeItem('loggedAppUserPanel')
            this.thispath('/adminpanel')
        }
    }
},

}

</script>
<style>
/*Creacion de los estilos CSS importamos desde assets*/
.btn-custom {
font-family: GalanoRegular;
padding-right: 30px;
padding-left: 30px;
--bs-btn-color: #fff;
--bs-btn-bg: #a31a77;
--bs-btn-border-color: #a31a77; 
--bs-btn-hover-color: #fff;
/*--bs-btn-hover-bg: #6a6ea8;*/
--bs-btn-hover-border-color: #727271;
--bs-btn-focus-shadow-rgb: 49, 132, 253;
--bs-btn-active-color: #fff;
--bs-btn-active-bg: #41446a;
--bs-btn-active-border-color: #41446a;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
--bs-btn-disabled-color: #fff;
--bs-btn-disabled-bg: #6c757d;
--bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
font-family: GalanoRegular;
--bs-btn-color: #fff;
--bs-btn-bg: #686868;
--bs-btn-border-color: #686868;
--bs-btn-hover-color: #fff;
--bs-btn-hover-bg: #5c636a;
--bs-btn-hover-border-color: #565e64;
--bs-btn-focus-shadow-rgb: 130, 138, 145;
--bs-btn-active-color: #fff;
--bs-btn-active-bg: #565e64;
--bs-btn-active-border-color: #51585e;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
--bs-btn-disabled-color: #fff;
--bs-btn-disabled-bg: #6c757d;
--bs-btn-disabled-border-color: #6c757d;
}
button {
font-family: GalanoRegular;
}</style>