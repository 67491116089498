<template>
    <div>
        <loading
        :active.sync="isLoading" 
        :can-cancel="false" 
        color="#6a6ea8"
        transition="fade"
        :enforce-focus=true
        :is-full-page=true>
        </loading>
        <Header :username="username" :imagen="imagen"/>
        <div class="container">
            <div>
                <b-progress :max="max" height="1.2rem" class="progress-bar">
                <b-progress-bar :value=3>
                    <span>3 / {{ max }}</span>
                </b-progress-bar>
                </b-progress>
            </div>
            <div class="row questionary-row">
                <div class="questionary-container">
                <!-- <h3><strong> ¿Porque quiere ocupar un cargo publico amigue? ahjsbdjbsdsdasdasasdfasdsdasd asd fsdjfj</strong></h3> -->
                <p class="question">Historia Laboral</p>
                <div style="margin-top: 5%; margin-bottom: 5%;">
                    <label>Trayectoria política y/o participación social en organizaciones ciudadanas o de la sociedad civil que describa trayectoria, los años y las actividades realizadas en ésta</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="profesion.trayectoria_politica"
                            :state="profesion.trayectoria_politica.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="5000"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{profesion.trayectoria_politica.length}}/5000</label>
                    </div>
                    <label>¿Por qué quiere ocupar un cargo público?</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="profesion.razon_cargo_publico"
                            :state="profesion.razon_cargo_publico.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="5000"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{profesion.razon_cargo_publico.length}}/5000</label>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                    <label>¿Cuáles son sus dos principales propuestas?</label>
                    <label class="secondary-title">Propuesta 1</label>
                    </div>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="propuesta.propuesta1"
                            :state="propuesta.propuesta1.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="1600"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{propuesta.propuesta1.length}}/1600</label>
                    </div>
                    <label class="secondary-title">Propuesta 2</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="propuesta.propuesta2"
                            :state="propuesta.propuesta2.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="1600"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{propuesta.propuesta2.length}}/1600</label>
                    </div>
                    <label>Propuesta en materia de género o, en su caso, del grupo en situación de discriminación que representa que describa la población objetivo, metas y plazos para su promoción como iniciativa de ley o política pública</label>
                    <div class="d-flex flex-column align-items-end mb-3">
                        <b-form-textarea
                            v-model="propuesta.propuesta_grupo_discriminacion"
                            :state="propuesta.propuesta_grupo_discriminacion.length >= 280"
                            placeholder="Ingrese al menos 280 caracteres"
                            rows="3"
                            maxlength="1600"
                            max-rows="8"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            style="font-family: GalanoRegular; font-size: 16px; width: 100%;"
                        ></b-form-textarea>
                        <label style="font-size: 14px;">{{propuesta.propuesta_grupo_discriminacion.length}}/1600</label>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <button style="margin-right: 1%;" type="button" :disabled="disable" class="btn btn-secondary" v-on:click="atras()">Atras&emsp;<fa icon="arrow-left"/>&emsp;</button>
                    <button type="button" class="btn btn-custom" :disabled="disable" v-on:click="save()">Continuar&emsp;<fa icon="arrow-right"/>&emsp;</button>
                </div>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import logs from '../data/logs.json'

export default{
    name:"Laboral",
    data(){
        return {
            routes:routes,
            isLoading: true,
            disable:false,
            idCandidato:null,
            config:{},
            username:"",
            dirname:this.$assetsDirname, max:24,
            profesion:{
                trayectoria_politica:"",
                razon_cargo_publico:""
            },
            propuesta:{
                propuesta1:"",
                propuesta2:"",
                propuesta_grupo_discriminacion:""
            },
            identidad:{

                indigena:'',
                lengua_indigena:'',
                comunidad_indigena:'',
                discapacidad:'',
                diversidad_sexual:''
            },
            validadorIdentidad:'',
            pagina:"",
            imagen:"",
            estadoRoC :"",
            validacionCaptura:false,

            logsAcciones:[],
            dataLogs : logs,
            loggedIn: this.$route.params.loggedIn

        }
    },
    components:{
        Header,
        Footer,
        Loading
    },
    methods:{
        async atras(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            const res = await axios.patch(url, {idCandidato:this.idCandidato, pagina:this.pagina-1}, this.config)
            if(res.data.result = 1){
                this.disable=false
                this.path(this.pagina-1)
            }
        },
        path(opt){
            const routes = this.routes
            this.thispath(routes[opt])
        },
        thispath(path){
            if (this.$route.path !== path) this.$router.push(path)
        },
        async save(){
            this.disable=true
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            if(this.paginat > 0){
                this.estadoRoC ="CAPTURADO"
                this.validacionCaptura=true

                const res = await axios.patch(url, { curriculares:{ profesion:this.profesion, propuesta:this.propuesta }, idCandidato:this.idCandidato, pagina:this.pagina+(25-this.paginat),paginat:0,estadoRoC:this.estadoRoC,validacionCaptura:this.validacionCaptura }, this.config)
                if(res.data.result = 1){
                    this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                    this.logsCandidato(7)
                    this.disable=false
                    this.path(this.pagina+(25-this.paginat))
                    return
                }          
                this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                this.path(this.pagina+(25-this.paginat)) // siguiente pagina
                this.disable=false
            }else{
                if(this.profesion.trayectoria_politica.length >= 280 && this.profesion.razon_cargo_publico.length>=280 && this.propuesta.propuesta1.length>=280 && this.propuesta.propuesta2.length>=280 && this.propuesta.propuesta_grupo_discriminacion.length>=280  ){
                    const res = await axios.patch(url, { curriculares:{ profesion:this.profesion, propuesta:this.propuesta }, idCandidato:this.idCandidato, pagina:this.pagina+1 }, this.config)
                    if(res.data.result = 1){
                        this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                        this.logsCandidato(6)
                        this.disable=false
                        this.path(this.pagina+1)
                        return
                    }          
                    this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                    this.path(this.pagina+1) // siguiente pagina
                    this.disable=false                    
                }else{
                        this.$alert('','<label class="alertlabel">Alguno de los campos no cumple con los caracteres solicitados de 280 caracteres</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});
                        this.path(this.pagina+1) // siguiente pagina
                        this.disable=false
                    }
    

            }  
        },
         /*se realiza una consulta a la base de datos de registro para identificar a que grupo tiene pertenencia de accion afirmatica
           async validarIdentidad(idCandidato){
            let url =`/api/candidato/${idCandidato}`
            try {
                const res = await axios.get(url)
                this.lista=res.data[0].lista;
                this.identidad.indigena = res.data[0].identidad.indigena
                this.identidad.discapacidad = res.data[0].identidad.discapacidad
                this.identidad.diversidad_sexual = res.data[0].this.identidad.diversidad_sexual
                console.log(res)
                
            } catch (error) {
                console.log(error.code)
                
            }
        }*/
        async logsCandidato(clave){
            let now= new Date();
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
            try{
                const log  = this.dataLogs[clave];
                console.log(log)
                this.logsAcciones.push(log+" : "+now)            
                await axios.patch(url, { idCandidato:this.idCandidato,logsAcciones:this.logsAcciones }, this.config)
                

            }catch(error){
                console.error(error)
            }
    }

    },
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato'
        try {
            const loggedAppUser = localStorage.getItem('loggedAppUser')
            if(!loggedAppUser)
                this.path('/')
            
            const {token, username, idCandidato} = JSON.parse(loggedAppUser)
            this.username = username
            this.idCandidato = idCandidato
            this.config = {
                headers:{
                    Authorization: `Bearer ${token}` 
                }
            }
            const result = await axios.get(url, this.config)
            this.pagina = result.data[0].pagina
            // Varible bandera para control de modificacion de respuestas si es mayor a 0, se guarda variable temporal
            this.paginat = result.data[0].paginat   
            this.path(this.pagina)

            this.profesion.trayectoria_politica = result.data[0].curriculares.profesion.trayectoria_politica
            this.profesion.razon_cargo_publico = result.data[0].curriculares.profesion.razon_cargo_publico
            this.propuesta.propuesta1 = result.data[0].curriculares.propuesta.propuesta1
            this.propuesta.propuesta2 = result.data[0].curriculares.propuesta.propuesta2
            this.propuesta.propuesta_grupo_discriminacion = result.data[0].curriculares.propuesta.propuesta_grupo_discriminacion
            this.imagen = result.data[0].imagen


            // Para validar identidad por
            this.identidad.indigena = result.data[0].identidad.indigena
            this.identidad.discapacidad = result.data[0].identidad.discapacidad
            this.identidad.diversidad_sexual = result.data[0].identidad.diversidad_sexual

            //Registro acciones usuario
            this.logsAcciones=result.data[0].logsAcciones
            if(this.loggedIn===1){
                this.logsCandidato(this.loggedIn)
            }

            this.isLoading = false

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                localStorage.removeItem('loggedAppUser')
                this.thispath('/')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}

</script>

<style scoped>
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.secondary-title {
    color:#5c636a;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #B20066;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #B20066;
    --bs-btn-border-color: #B20066;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b0aeb3;
    --bs-btn-hover-border-color: #b0aeb3;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    width: 100%;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
label {
    font-family: GalanoRegular;
    font-weight: bold;
    color: #B20066;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
textarea::placeholder {
  color: #b0aeb3;
}
.question{
    color:#5c636a
  }
</style>