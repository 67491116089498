<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
          <h3>Estadística</h3>  
          <h4>Del total de las candidaturas que respondieron al cuestionario de identidad, se identifican como personas indígenas:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
        <Pie v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></Pie>
      </div>
    </div>
    <div class="row">      
      <div class="col-xs-12 col-sm-12 col-lg-12 mt-3">
          <h4>De las {{ cTotalIndigenas }}  (100%) candidaturas que respondieron el cuestionario de identidad, {{cIndigena1}} ({{ porcentajeIndigenas }})% se identificaron como personas indígenas. </h4>
      </div>
    </div>    
  </div>

         

  
</template>

<script>
import axios from 'axios'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)


export default {
  name: 'GraficaIndigena',
  components: { Pie },
  data() {
    return {
    loaded:false,
      chartData: {       

        labels: [ 'Sí', 'No','Sin Responder'],
        datasets: [ 
            {
            label: 'Indígena',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#E75395','#6461A9','#70257F']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        },

        legend:{
          display:true,
          position:'bottom',
          text:"De los candidados.."
        },
        title:{
          display:true,
          text: "Distribucion de datos"
        },
        
      },
      //Variables para creacion de los datos
      lista :[],

      cIndigena1:0,
      cIndigena2:0,
      cIndigena3:0,
      cTotalIndigenas:0,
      porcentajeIndigenas:0
      
    }
  },

  async mounted(){

    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const res = await axios.get(url)
        this.lista = res.data
        let count1=0
        let count2=0
        let count3=0
          
       
        this.lista.forEach(element =>{ 
          if(element.validacionCPyPP===true){
              if(element.identidad.indigena === "Sí")
                  {
                    count1=count1+1
                  }
              else if(element.identidad.indigena  === "No")
              {
                count2=count2+1

              }
              else if(element.identidad.indigena === "SR"){
                count3=count3+1

              }      
          
          }      
      })

     this.chartData.datasets[0].data[0] = count1  
     this.chartData.datasets[0].data[1] = count2
     this.chartData.datasets[0].data[2] = count3

     this.cIndigena1 = count1
     this.cIndigena2 = count2
     this.cIndigena3 = count3
    this.cTotalIndigenas= this.cIndigena1 + this.cIndigena2 + this.cIndigena3
    this.porcentajeIndigenas = ((this.cIndigena1 * 100) / this.cTotalIndigenas).toFixed(2)
   
 

   

     this.loaded = true

    }
    
}
</script>


<style>
</style>