<template>
    <div class="container">
        <div class="row">               
            <div class="col-xs-12 col-sm-12 col-lg-12 align-items-center" >
                    <!---<label class="label-color">Bienvenido {{ nombre_completo }}</label>-->
                <div class="container  buttons-container align-content-lg-start">                
                    <div class="d-inline-flex align-items-center ">                    
                        <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                        <label class="label-color" for="search" style="margin-right: 10px;">BUSCAR CANDIDATOS:</label>
                        <input type="text" class="form-control" id="search" style="width: 320px; "  v-model="filters.nombre.value" placeholder="Usuario, Nombre, Postulante, Municipio" @change="getFilterBySearch">                                                                  
                    </div>                   
                </div>                
                    <v-table  class="custom-table " :data="lista" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event" id="top">
                    <thead slot="head" class="bg-light text-center">                         
                        <v-th sortKey="foto" class="text-center">FOTO</v-th>
                        <v-th sortKey="partido" class="text-center">POSTULANTE</v-th>                 
                        <v-th sortKey="usuario" class="text-center">USUARIO</v-th>
                        <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                        <v-th sortKey="asignaciones" class="text-center">MUNICIPIO</v-th>
                        <v-th sortKey="asignaciones" class="text-center">DISTRITO</v-th>                                                                  
                        
                        <v-th sortKey="activaciones" class="text-center">ESTADO</v-th>                       
                        <v-th sortKey="captura" class="text-center">CAPTURAR DATOS</v-th>                                            
                    </thead>
                    <tbody slot="body" slot-scope="{displayData}">                    
                        <tr v-for="candidato in displayData" :key="candidato.idCandidato">  <!--falta programar evento row-->                         
                            
                                <td><b-avatar v-if="candidato.imagen" :src="dirname+candidato.imagen" size="2.5rem"></b-avatar></td>
                                <td>{{ candidato.partido }}</td>
                                <td > {{ candidato.username}}</td>
                                <td > {{ candidato.nombre}}</td>                                  
                                <td>{{ candidato.municipio }}</td>
                                <td>{{ candidato.distrito }}</td>                                 
                                <td>{{candidato.estadoRoC}}</td>     
                                                     
                                <td >
                                    <b-button v-if="candidato.estadoRoC==='CAPTURADO' || candidato.estadoRoC==='EN CAPTURA'" disabled variant="outline-secondary" target="_self"><router-link style="text-decoration: none; color:#9F2380"   :to ="{name:'CapturaCandidato', params: {id:candidato.idCandidato}}" >CAPTURAR DATOS</router-link></b-button>
                                    <b-button v-else-if="candidato.estadoRoC==='NO CAPTURADO'" variant="outline-secondary" target="_self"><router-link style="text-decoration: none; color:#9F2380"   :to ="{name:'CapturaCandidato', params: {id:candidato.idCandidato}}" >CAPTURAR DATOS</router-link></b-button>                                                           
                                </td>

                            </tr> <!--cierre td encabezados-->                              
                    </tbody>                
                </v-table>
                <smart-pagination
                    :currentPage.sync="currentPage"
                    :totalPages="totalPages"
                    :maxPageLinks="10"
                    class="pagination justify-content-end"
                />                
            </div><!--Termina Div tabla responsiva-->   
        </div>
        <hr>
  
    </div><!--Fin container-->
  
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import distritos from '../data/distritos.json'
import municipios from '../data/municipios.json'
//import { elements } from 'chart.js';

export default{
    name:'ListaCandidatosC',
    data(){
        return{
            state:'disabled',
           
            usernamePanel:"", //Nombre de usuario actual con sus permisos 
            //Variables de los usuarios
            //Variables a usar para siganciones
            listaCandidatos:[], //Lista que se pasa
            asignacionValidador:[],
            entradaAsignacionValidador:'',

            listaPartidos:[],
            listaTmp:[],
            listaUser:[],
            lista:[],
            items:[],
            listaUsuarios:[],
            usuarioValidador:"nombre_completo",
            asignaciones:[],
            selected:false,
            id:0,           
            idUsuario:null,
            idFolioValidacion:"",
            username: "",
            
            nombre_completo:"",
            nombre:"",
            rol:"",
           
            usuario_activado:"",
            email:"",
            //Arreglo para el usuario a modificar/actualizar o eliminar
             // se crea este elemento para almancenar los valores del usuario personalizado
            candidato_idFolioValidacion:"",
            usuario_idFolioValidacion:"",// se necesita este campo para poder mostrarle la tabla de candidatos asignados
            listaFoliosCandidatos:[], // se van almacenando los folios seleccionados del candidado desde el checkbox
            user_name:"",
            user_rol:"",
            user_fullname:"", 
            user_email:"",           
            user_password:"",
            user_munincipio:"",
            user_distrito:"",   
                    
            
           
            filters: {nombre: { value: '', keys: ['nombre','username','partido', 'municipio','distrito']}},
            roles:["Administrador","Validador","Publicador","Capturista","Invitado"],
            selection:"",
            selection2:"",
            selection3:"",
            distritos:distritos,
            municipios:municipios,

            isLoading: true,
            routes:routes,            
           
            disable:false,
            config:{},
            currentPage:1,
            totalPages:0,          
            pagina:"",
            //Variables de control para mostrar Informacion
            visibleDiv : false,
            visibleDiv2:false,
            disableRow:true,

            password:"",
            dirname:this.$assetsDirname,
            tipoRol:"",
            activo:"",
           

            }     
    },
    components:{
     
    },
    computed: {
        disabled() {
        return this.state === 'disabled'
      },
      readonly() {
        return this.state === 'readonly'
      }

    },
    methods:{
        isActive(){


        },

        
       
        cargarValidador(e){
            this.entradaAsignacionValidador = e // sustituir por la lista de validadores
        },
        eraseValidador(e){
            console.log(e)
            this.asignacionValidador.splice(e, 1)
        },
        //Se desactiva esta opcion ya que solo se asignata del portal un revisor a muchos candidatos y un supervisor a Muchos validadores
        agregarValidador(){
            if( this.entradaAsignacionValidador != ''){ //cambiar por la lista
                this.asignacionValidador.push(this.entradaAsignacionValidador)
                this.entradaAsignacionValidador=''
            }
        },
        limpiarFiltros(){
              
              this.selected=false
              this.listaFoliosCandidatos=[]
              this.visibleDiv=false
              this.folioUsuarioValidador=""
              this.selection=""
              this.rol=""
              this.filters.nombre.value="" 
                  
          },

        choose(){
            //this.rol=this.selection
            this.asignacion=this.selection
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.posicion = this.asignaciones.findIndex((asignacion)=>asignacion == this.selection)
            console.log(this.posicion)
            // Se envia  la posicion del  validador en la lista y se envia al metodo asignacion de folio
            this.asignacionFolio(this.posicion)

        },
        choose2(){
            this.distrito=this.selection2

        },
        choose3(){
            this.municipio=this.selection3

        },
        choose4(){

        },

        //Metodo para asignar folio de revisor al candidato( se asignaran solo a capturistas, validadors y suprvidores)
        asignarFolio(candidatoFolio){
            this.visibleDiv=true
            if(this.selected !==false){
                //se va creando la lista con las selecciones actuales                       
                this.listaFoliosCandidatos.push({"id":this.id, "candidatoFolio":candidatoFolio})     
                 console.log(this.listaFoliosCandidatos)                                
            }
            else{
                //opcion si se deselecciona un canidato se quita de la lista actual                           
                this.listaFoliosCandidatos = this.listaFoliosCandidatos.filter(elemento => elemento.candidatoFolio !== candidatoFolio)
                console.log(this.listaFoliosCandidatos)                     
            }
            this.selected=false
        },
        //Metodo para obtener el idFolioValidacion y establecerselo al candidato
        async asignacionFolio(posicion){ 
                     
            let url =`https://informatica.iem.org.mx/api/conoceles/api/usuarios`
            try {
                const res = await axios.get(url,this.config)               
                this.folioUsuarioValidador=res.data[posicion].idFolioValidacion
                this.rol = res.data[posicion].roles[0].rol
                //this.comprobarFolioUsuario(this.folioUsuarioValidador)                
                console.log(this.folioUsuarioValidador, this.rol)
                            
            } catch (error) {
                console.log(error.code)                
            }
        },
        async realizarAsignacion(){            
            //let listaAsignacion=[]          
            //console.log("Usuario Validador", this.folioUsuarioValidador)
            this.lista.forEach(element =>{                
                if(this.listaFoliosCandidatos.includes(element.folioRegistroCandidato)){
                    if(element.asignacionValidador.includes(this.folioUsuarioValidador)){
                        console.log('Ya existe asignacion', element.asignacionValidador)
                        this.asignacionesBD(element.folioRegistroCandidato, element.asignacionValidador)                                                                                                 

                    }else{
                        console.log('Se agrega')
                         element.asignacionValidador.push(this.folioUsuarioValidador)
                        console.log('Lista de revisores para este candidato', element.asignacionValidador)
                        this.asignacionesBD(element.folioRegistroCandidato, element.asignacionValidador)
                    }
                }
                

            })     
        },
        async asignacionesBD(idFolioRegistroC, listaAsignaciones){
           
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/candidato_user'
            const res = await axios.patch(url, {folioRegistroCandidato:idFolioRegistroC, asignacionValidador:listaAsignaciones}, this.config)
            if(res.data.result = 1){
                this.$alert('','','success', {showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                           
                return
            }
            this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});   

        },

        //Obtener la lista de todos los usuarios del portal administrativo
        async getUsers(){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`)
            this.listaUsers = res.data;
            //Obtencion de la informacion de la API con variables del FronEnd           
        },
        getFilterBySearch(){
                    //Se realiza busqueda con coincidencia en lista
            this.listaTmp=[]
            console.log(this.filters.nombre.value)
            this.lista.forEach(element =>{
                if(element.nombre.startsWith(this.filters.nombre.value) == true){                                                                                                                              
                    this.listatmp.push(element)
                    }
                else if(element.username.startsWith(this.filters.username.value)== true){
                    this.listatmp.push(element)
                }
                else if(element.partido.startsWith(this.filters.partido.value)== true){
                    this.listatmp.push(element)
                }
                else if(element.municipio.startsWith(this.filters.municipio.value)== true){
                    this.listatmp.push(element)
                }
                else if(element.distrito.startsWith(this.filters.distrito.value)== true){
                    this.listatmp.push(element)
                }
                else if(this.filters.nombre.value === ""){
                    this.lista.forEach(element =>{
                        if(element.folioRegistroCandidato === this.listaFoliosCandidatos.candidatoFolio){
                            this.selected=true
                        }
                    })
                }                 
            //this.lista=this.listatmp
                                     
            })     
        },
        //Metodo para consultar al usuario de manera personalizada con el folio pasado en los botones de la tabla
        async getCandidatosInfo(folio)
        
        {
            
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidatofrc/${folio}`
            try {                
                 const info = await axios.get(url)
                 this.infoCandidatos= info.data[0].infoCandidatos
                 this.folioRegistroCandidato=info.data[0].folioRegistroCandidato
                 this.imagen = info.data[0].imagen
                 this.estadoRoC = info.data[0].estadoRoC

                 this.curriculares.contacto.web =info.data[0].curriculares.contacto.web
                 this.curriculares.contacto.email =info.data[0].curriculares.contacto.email
                 this.curriculares.contacto.direccion =info.data[0].curriculares.contacto.direccion
                 //this.curriculares.contacto.telefono =info.data[0].curriculares.contacto.telefono

                 this.curriculares.social.facebook = info.data[0].curriculares.social.facebook
                 this.curriculares.social.twitter = info.data[0].curriculares.social.twitter
                 this.curriculares.social.youtube = info.data[0].curriculares.social.youtube
                 this.curriculares.social.instagram = info.data[0].curriculares.social.instagram
                 this.curriculares.social.tiktok = info.data[0].curriculares.social.tiktok

                 this.curriculares.profesion.razon_cargo_publico = info.data[0].curriculares.profesion.razon_cargo_publico
                this.curriculares.propuesta.propuesta1 = info.data[0].curriculares.propuesta.propuesta1                
                this.curriculares.propuesta.propuesta2 = info.data[0].curriculares.propuesta.propuesta2
                this.curriculares.propuesta.propuesta_grupo_discriminacion = info.data[0].curriculares.propuesta.propuesta_grupo_discriminacion

                //Obtencion de informacion de los cuestionarios de Identidad
                this.identidad.diversidad_sexual = info.data[0].identidad.diversidad_sexual
                this.identidad.tipo_diversidad_sexual = info.data[0].identidad.tipo_diversidad_sexual                                
                //console.log("Desde Coleccion Candidatos: ",this.folioRegistroCandidato)
                this.identidad.indigena = info.data[0].identidad.indigena
                this.identidad.lengua_indigena = info.data[0].identidad.lengua_indigena
                this.identidad.comunidad_indigena = info.data[0].identidad.comunidad_indigena
                //Obtencion de respuestas delos cuestionarios de identidad correspondientes a personas con discapacidad
                this.identidad.discapacidad = info.data[0].identidad.discapacidad
                this.identidad.estatus_discapacidad = info.data[0].identidad.estatus_discapacidad
                this.identidad.tipo_discapacidad = info.data[0].identidad.tipo_discapacidad
                this.identidad.dificultad_discapacidad = info.data[0].identidad.dificultad_discapacidad
                //Obtencion de respuesas de los cuestionarios de identidad correspondientes a si es persona migrante
                this.identidad.migrante = info.data[0].identidad.migrante
                this.identidad.pais_extranjero = info.data[0].identidad.pais_extranjero
                this.identidad.tiempo_extranjero = info.data[0].identidad.tiempo_extranjero
                this.identidad.motivo = info.data[0].identidad.motivo
                this.identidad.situacion_asegurada = info.data[0].identidad.situacion_asegurada
                //Obtencion variable de publicacion de la informacion
                this.identidad.publicacion = info.data[0].identidad.publicacion

                
            } catch (error) {
                console.log(error)
                
            }  
        },    
     
        cancelar(){
            this.visibleDiv=false
            this.password=""
            //this.path("/validacion1")
        },
        generatePassword(){
            this.password='' // limpia el campo cada vez que se clickea

            const letters='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const numbers = '0123456789';
            const symbols = '!@#$%^&*_=+-/';
            const characters = letters + numbers + symbols;
            
            for(let i=0; i< 8;i++){
                const randomIndex = Math.floor(Math.random() * characters.length)
                this.password += characters[randomIndex]
            }
            return this.password;
        },
        async comprobarFolioUsuario(folioUsurio){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`,this.config) 
            this.listUsers = res.data;
            console.log(this.listUsers)         
            this.listUsers.forEach(element =>{
                if(element.idFolioValidacion === folioUsurio)
                {                                  
                    this.idFolioValidacion=""
                    return                  
               }else{
                this.idFolioValidacion
                return
               }              
          
         })        
        },
        mergeLista(listaC,listaP){
            return listaC.map( element1 =>{
                const element2 = listaP.find(element => element.folioRegistro=== element1.folioRegistroCandidato)
                return{
                ...element1,
                ...(element2 && {nombre: element2.propietario.nombre+" " + element2.propietario.primerApellido +" "+element2.propietario.segundoApellido , partido: element2.asociacion.siglasAsociacion,municipio:element2.entornoGeografico.municipio,distrito:element2.entornoGeografico.distrito} )
            }
            }) 
        },
        async obtenerDatosActualizados(){
            try{
                const response = await axios.get('https://informatica.iem.org.mx/api/conoceles/api/candidatos')
                this.items = response.data
                console.log('items', this.items)
            }catch(error){
                console.log("Error al obtener datos actualizados", error)

            }
        },
        controlUpdates(){
            const socket = new WebSocket('ws:/https://informatica.iem.org.mx/api/conoceles/api/candidatos')
            socket.onmessage = (event) =>{
                const changeItem = JSON.parse(event.data)
                const index =this.lista.findIndex(elemento => elemento.idCandidato === changeItem.idCandidato);
                if(index !== -1){
                    this.$set(this.lista, index, changeItem)
                    console.log('sin cambios Cambio imagen')
                }else{
                    this.lista.push(changeItem)
                    console.log('Nuevo Cambio imagen')
                }
            }
           

        }, 
        async establecerEstado(id){
            
            const url =`https://informatica.iem.org.mx/api/conoceles/api/candidato/${id}`
            try {                
                 const info = await axios.get(url)
                 this.folioRegistroCandidato=info.data[0].folioRegistroCandidato               
                 this.estadoRoC = info.data[0].estadoRoC
                 console.log("Para este id:",id, 'ESTADO: ', this.estadoRoC)        
                

                console.log(this.estadoRoC)
                if((this.estadoRoC === "EN CAPTURA") || (this.estadoRoC === "CAPTURADO")){
                    this.disableRow=true
                    console.log(this.estadoRoC)
                    console.log(this.disableRow)
                    
                    }
                else if(this.estadoRoC === "NO CAPTURADO"){                        
                        this.disableRow=true
                        console.log(this.estadoRoC)
                        console.log(this.disableRow)
                    }
             
                
            } catch (error) {
                console.log(error)
            }             

  
        },
    },
  

    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        const url2 = 'https://informatica.iem.org.mx/api/conoceles/api/candidatos'
        const url3= 'https://informatica.iem.org.mx/api/conoceles/api/partidos'
        const url4 ='https://informatica.iem.org.mx/api/conoceles/api/usuarios'

        //this.obtenerDatosActualizados()
        this.controlUpdates()
        
        
       
        try {
            let listaTmp=[]
            //SE INICIALIZAN LOS DATOS DEL USUARIO QUE INICIA SESION, LOS TOKENS Y SE INICIALIZA LA LISTA DE USUARIOS DE LA TABLA
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')


            
            //Datos del usuario que Inicia Sesion en el portal
            const {idUsuario, token, usernamePanel,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario
            this.tipoRol= roles[0].rol
            
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }
            //Se obtiene la pagina de aterrizaje del portal
            const resultado = await axios.get(url,this.config)
            //Obtenemos el idFolioValidacion del usuario para poderle mostrar la tabla con sus candidatos asignados para revisar o capturar informacion. 
            this.usuario_idFolioValidacion =resultado.data[0].idFolioValidacion

            //this.pagina = resultado.data[0].pagina

            // Se obtiene la lista de usuarios de la lista de usuarios y se le envia la configuracion del token de autorizacion
            const resultado2 = await axios.get(url2, this.config )
            this.listaCandidatos = resultado2.data;
            

            // Se obtiene la lista inicial de partidos proveniente del SNR o SRC solo para su consulta GET
            const resultado3 = await axios.get(url3)
             this.listaPartidos=resultado3.data;

            //Inicializamos la lista de usuario
            const resultado4 = await axios.get(url4,this.config)
            this.listaUsuarios= resultado4.data
            this.listaUsuarios.forEach(elemento =>{
            this.asignaciones.push(elemento[this.usuarioValidador])
                
            })

            //this.nombre_completo_usuario = result.data[0].nombre_completo
            //this.rol = result.data[0].rol   
            //Metodo para unir las Listas de usuarios candidatos y la de partidos( ejemplo que viene del SNR i SCR)
                    //se combinan las listas de acuerdo a una condicion en el metodo
            listaTmp = this.mergeLista(this.listaCandidatos, this.listaPartidos)
            console.log(listaTmp)
            //Obtenemos los elementos: asignacionValidador de la lista unida para poder mostrar solo aquellos candidatos que tengan al usuario revisor y capturista en su propia tabla
         
            //&&(element.estadoRoC==="NO CAPTURADO")

            listaTmp.forEach(element =>{
                if((this.tipoRol ==="Capturista")&&(element.asignacionValidador.includes(this.usuario_idFolioValidacion))){
                    this.lista.push(element)
                    //this.disableRow=true
                    //this.establecerEstado(element.idCandidato)
                }                                      
             })          
           
           

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/adminpanel')
            }
        }
        
    },
    mounted(){
        
        this.$root.$on('datos actualizados'),() =>{
            this.lista
        }
   
        window.scrollTo(0,0);
    }

}
</script>

<style>
h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color:#6c757d;
}

.custom-table {
  font-family: GalanoRegular;
  border-collapse: collapse;
  width: 100%;
}
.custom-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.custom-table tr:nth-child(even){background-color: #f2f2f2;}
.custom-table tr:hover {background-color: #ddd; cursor: pointer;}
.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #9F2380;
  color: white;
  padding-left: 5px;
}
.custom-table th:hover {cursor: pointer;}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #9F2380;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #9F2380;
    --bs-btn-border-color: #9F2380;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6a6ea8;
    --bs-btn-hover-border-color: #6a6ea8;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 2px solid #9F2380;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #9F2380;
      border: 2px solid #9F2380;
      color: white;
      transform: scale(1.111, 1.111);
      .radio-tile-label {
        color: white;
        background-color: #9F2380;
      }
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #9F2380;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
/* Se agregan nuevas clases para mostrar radiobutons de filtrtado**/
.buttons-container{
    text-align: start;
    align-items: start;  
    width: 100%;
    margin-bottom: 1%;
 
}
.label-color{
    color:#9F2380;  

}
.button-separation{
    
    padding-right: 2%;
    margin-bottom: 2%;

}
.btn-align-right{
      
    margin-bottom: 5px;  
    
}

</style>