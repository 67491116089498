<template>
    <div class="container">
        <div class="row">               
            <div class="col-xs-12 col-sm-12 col-lg-12 align-items-center" >
                    <!---<label class="label-color">Bienvenido {{ nombre_completo }}</label>-->
                <div class="container  buttons-container align-content-lg-start">                
                    <div class="d-inline-flex align-items-center ">                    
                        <!---<label for="search" class="label-color">FILTRAR BÚSQUEDA</label>--><!--<span style="margin-right: 10px;"></span><fa icon="magnifying-glass"/>-->
                        <label class="label-color" for="search" style="margin-right: 10px;">BUSCAR USUARIOS:</label>
                        <input type="text" class="form-control" id="search" style="width: 320px; "  v-model="filters.nombre.value" placeholder="usuario, nombre, partido, distrito, seccion" @change="getFilterBySearch">                                                                  
                    </div>                   
                </div>
                <v-table  class="custom-table " :data="listaUsers" :filters="filters" :currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages=$event" id="top">
                    <thead slot="head" class="bg-light text-center">
                        <v-th sortKey="nombre" class="text-center">FOLIO</v-th>                   
                        <v-th sortKey="folio" class="text-center">USUARIO</v-th>
                        <v-th sortKey="seccion" class="text-center">ROL</v-th>
                        <v-th sortKey="nombre" class="text-center">NOMBRE</v-th>
                        <v-th sortKey="correo" class="text-center">CORREO-E</v-th>
                        <v-th sortKey="partido" class="text-center">MUNICIPIO</v-th>
                        <v-th sortKey="distriro" class="text-center">DISTRITO</v-th>                        
                        <v-th sortKey="acciones" class="text-center">ACCIONES</v-th>                        
                    </thead>
                    <tbody slot="body" slot-scope="{displayData}">                    
                        <tr v-for="user in displayData" :key="user.idUsuario">  <!--falta programar evento row-->                          
                            
                                <td><strong>{{ user.idFolioValidacion}}</strong></td>
                                <td > {{ user.usernamePanel}}</td>
                                <td>{{ user.rol }}</td>
                                <td>{{ user.nombre_completo }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.municipio}}</td>
                                <td>{{ user.distrito }}</td>                                
                                <td>
                                    <b-button-group>
                                        <router-link to="/datosuser"><b-button variant="secondary">Consultar</b-button></router-link>
                                        <b-button variant="info" v-on:click="getUsuariosInfo(user.idFolioValidacion)" href="#updateUser">Modificar</b-button>
                                        <b-button variant="danger">Eliminar</b-button>
                                    </b-button-group>                                    
                                </td>                                                                                  
                        </tr> <!--cierre td encabezados-->                              
                    </tbody>                
                </v-table>
                <smart-pagination
                    :currentPage.sync="currentPage"
                    :totalPages="totalPages"
                    :maxPageLinks="10"
                    class="pagination justify-content-end"
                /> 
            </div><!--Termina Div tabla responsiva-->   
        </div>
        <hr>
        <div class="row mb-2" v-show="visibleDiv" id="updateUser">
                <div class="col-xs-12 col-sm-12 col-lg-2" >
                </div><!--termina fila izquierda-->           
                <div class="col-xs-12 col-sm-12 col-lg-8" >            
                    <h1>Actualización de Usuarios Portal Administrativo</h1>           
                    <form class="row g-3" @submit.prevent="submitForm">
                        <div class="col-12">
                            <label for="folior" class="form-label" >FOLIO ASIGNADO:</label>
                            <input type="text" class="form-control text-uppercase" id="folior" placeholder="Folio Registro" v-model="idFolioValidacion" readonly="readonly" disabled>
                        </div>
                        <div class="col-12">
                            <label for="nombre" class="form-label">NOMBRE COMPLETO</label>
                            <input type="text" class="form-control text-uppercase" placeholder="Nombre completo" id="nombre" v-model="user_fullname">
                        </div>
                        <div class="col-sm-6">
                            <label for="area">AREA</label>
                            <multiselect
                                v-model="selectionArea"
                                @select="chooseArea()"
                                :options = "listaAreas"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                placeholder="Selecciona una opción"
                                style="font-family: GalanoRegular;"
                                id="area">
                            </multiselect>
                        </div>          
        
                        <div class="col-sm-6">
                            <label for="organizacion">UNIDAD O PARTIDO</label>
                            <multiselect
                                v-model="selectionOrganizacion"
                                @select="chooseOrganismo()"
                                :options="listaOrganizaciones"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                placeholder="Selecciona una opción"
                                style="font-family: GalanoRegular;"
                                id="organizacion">
                            </multiselect>
                        </div>
                        <div class="col-sm-4">
                            <label for="rol">ROL</label>
                            <multiselect
                                v-model="selectionRol"
                                @select="chooseRol()"
                                :options="listaRoles"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                placeholder="Selecciona una opción"
                                style="font-family: GalanoRegular;"
                                id="rol">
                            </multiselect>
                        </div>               
                        <div class="col-sm-4">
                            <label for="correo">USUARIO</label>
                            <input type="email" class="form-control" placeholder="USUARIO" v-model="user_name">                  
                        </div>
                        <div class="col-sm-4">
                            <label for="specificSizeInputGroupUsername">CONTRASEÑA</label>
                            <div class="input-group">                    
                            <input type="text" class="form-control" id="specificSizeInputGroupUsername" placeholder="Contraseña" readonly v-model="user_password" v-on:click="generatePassword()" :disabled="inputDisabled">
                            </div>
                        </div>            
     
                        <div class="col-12">
                            <button type="button" class="btn btn-info mr-4" :disabled="disable" v-on:click="updateUser()" >ACTUALIZAR USUARIO&emsp;</button>
                            <button type="button" class="btn btn-danger" :disabled="disable" v-on:click="cancelar()" href="#top">CANCELAR ACCIÓN&emsp;</button> 
                        </div>
                    </form>
                </div> <!--Termina columna central de 6 ancho-->
                <div class="col-xs-12 col-sm-12 col-lg-2" >

                </div> <!--termina columna derecha responsiva--> 
            </div><!--termina clase row-->
    </div><!--Fin container-->
  
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import routes from '../data/routes.json'
import distritos from '../data/distritos.json'
import municipios from '../data/municipios.json'

export default{
    name:'ListaUsuarios',
    data(){
        return{
            idUsuario:null,    
            idFolioValidacion:"",
                  
            nombre_completo:"",               
           
            password:"",
            contadoruser:0,
            //rol:"Validador",
            //area:"",
            distrito:"",
            municipio:"",
            estado:"",           
            observaciones:"",
            usuario_activado:true,
            email:"", // se puede tomar correo electronico como nombre de usuario // se ´rocede a validar que ambos campos username y correo coincidad en caso de tomar                    
            registro_acciones:"log",// Guarda el log o historial de acciones del usuario
            listaRoles:["Capturista","Validador","Publicador","Supervisor","Administrador"],
            listaAreas:["PARTIDO POLÍTICO","COORDINACIÓN DE IGUALDAD","COORDINACIÓN DE TRANSPARENCIA","COORDINACIÓN DE PRERROGATIVAS Y PP","COORDINACIÓN DE INFORMÁTICA"],
            listaOrganizaciones:['Partido Acción Nacional','Partido Revolucionario Institucional','Partido de la Revolución Democrática','Partido del Trabajo','Partido Verde Ecologista de México','Movimiento Ciudadano','Partido Morena','Partido Encuentro Solidario Michoacán','MÁS MICHOACÁN','MICHOACÁN PRIMERO','TIEMPO X MÉXICO','CANDIDATURA INDEPENDIENTE','OPL'],
            areas:{
                idArea:0,
                area:"",
            },
            roles:{
                idRol:0,
                rol:""

            },
            organizacion:{
                idOrganizacion:0,
                descOrganizacion:""
            },
            distritos:distritos,
            municipios:municipios,
            selectionRol:"",
            selectionArea:"",
            selectionOrganizacion:"",
            selection2:"",
            selection3:"",
           
            config:{},
            currentPage: 1, 
            totalPages: 0,
            pagina:"",
            //variables de control de formulario
            inputDisabled:false,
            disable:false,
            usernamePanel:"", //Nombre de usuario actual con sus permisos 
            //Variables de los usuarios
            listaUsers:[],
           
            username: "",
            
           
            rol:"",
            asignaciones:"",
            
           
            //Arreglo para el usuario a modificar/actualizar o eliminar
            listaUser:[], // se crea este elemento para almancenar los valores del usuario personalizado
            user_idFolioValidacion:"",
            user_name:"",
            user_rol:"",
            user_fullname:"", 
            user_email:"",           
            user_password:"",
            user_munincipio:"",
            user_distrito:"",
            user:{
                role:'admin'

            },       
                    
            
           
            filters: {nombre: { value: '', keys: ['nombre_completo','username','rol','email']}},
            
           

            isLoading: true,
            routes:routes,            
           
           
            //Variables de control para mostrar Informacion
            visibleDiv : false,
          

            }     
    },
    components:{
     
    },
    computed:{
    isLoggedIn() {
      // Verificar si el usuario está autenticado
      // por ejemplo, desde el estado de Vuex
      return this.$store.state.auth.isLoggedIn;
    },
    isAdmin() {
      // Verificar si el usuario es un administrador
      // por ejemplo, desde el estado de Vuex
      return this.$store.state.auth.user.role === 'admin';
    },
    isUser() {
      // Verificar si el usuario es un usuario regular
      // por ejemplo, desde el estado de Vuex
      return this.$store.state.auth.user.role === 'user';
    }

    },
    methods:{
        chooseRol(){
            let asignacionRol=this.selectionRol
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.roles.idRol = this.listaRoles.findIndex((idRol)=>idRol === asignacionRol)+1          
            this.roles.rol = this.listaRoles.find((rol)=>rol === asignacionRol)
            this.selectionRol=this.roles.rol
            console.log(this.roles)
        },
 
        chooseArea(){
            
            let asignacionArea=this.selectionArea
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.areas.idArea = this.listaAreas.findIndex((idArea)=>idArea === asignacionArea)+1
            this.areas.area = this.listaAreas.find((area)=>area === asignacionArea)
            this.selectionArea=this.areas.area
            console.log(this.areas)            
            
            //this.asignacionRol(this.posicion)
        },
        chooseOrganismo(){
            
            let asignacionOrganismo=this.selectionOrganizacion
            // se optiene la lista de usuarios para  realizar asifnaciones
            this.organizacion.idOrganizacion = this.listaOrganizaciones.findIndex((idOrganizacion)=>idOrganizacion === asignacionOrganismo)+1
            this.organizacion.descOrganizacion = this.listaOrganizaciones.find((descOrganizacion)=>descOrganizacion === asignacionOrganismo)
            this.asignacionOrganismo=this.areas.area
            console.log(this.organizacion)            
            
            //this.asignacionRol(this.posicion)
        },

       
     

        //Obtener la lista de todos los usuarios del portal administrativo
        async getUsers(){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`)
            this.listaUsers = res.data;
            //Obtencion de la informacion de la API con variables del FronEnd


            //console.log(res);
        },
        getFilterBySearch(){
                    //Se realiza busqueda con coincidencia en lista
            this.listatmp=[]
            console.log(this.filters.nombre.value)
            this.lista.forEach(element =>{
                if(element.informacion_partido.propietario.startsWith(this.filters.nombre.value) == true){                                                                                                                              
                    this.listatmp.push(element)
                    }                       
            this.listaPartidos=this.listatmp
                                     
            })     
        },
        //Metodo para consultar al usuario de manera personalizada con el folio pasado en los botones de la tabla

        async getUsuariosInfo(folio)
        {
            const url =`https://informatica.iem.org.mx/api/conoceles/api/usuariofrc/${folio}`
            try {
                this.idFolioValidacion = folio
                console.log(this.idFolioValidacion)

                const res = await axios.get(url)
                this.listaUser = res.data
                console.log(this.listaUser)                
                this.visibleDiv=true // se activa el div              


                 this.user_idFolioValidacion=res.data[0].idFolioValidacion
                 this.user_name = res.data[0].usernamePanel                 
                 this.user_fullname = res.data[0].nombre_completo
                 this.user_email = res.data[0].email
                 this.selectionArea = res.data[0].areas[0].area
                 this.selectionOrganizacion =res.data[0].organizacion[0].descOrganizacion
                 this.selectionRol = res.data[0].roles[0].rol                 
              

               
            } catch (error) {
                console.log(error)
                
            }  
        },      
     
        cancelar(){
            this.visibleDiv=false
            
            //this.path("/validacion1")
        },
        generatePassword(){
            this.user_password=""
          

            const letters='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const numbers = '0123456789';
            const symbols = '!@#$%^&*_=+-/';
            const characters = letters + numbers + symbols;
            
            for(let i=0; i< 8;i++){
                const randomIndex = Math.floor(Math.random() * characters.length)
                this.user_password += characters[randomIndex]
            }
            return this.user_password;
        },
        async comprobarFolio(){
            const res = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/usuarios`,this.config) 
            this.listUsers = res.data;
            console.log(this.listUsers)         
            this.listUsers.forEach(element =>{
                if(element.idFolioValidacion === this.idFolioValidacion)
               {
                    this.$alert('','<label class="alertlabel">Folio ya registrado</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});                   
                    this.path("/crearusuariosportal")
                    return                  
               }                 
          
         })        
        },
        async updateUser(){
            const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario_user'
            const res = await axios.patch(url,{ idFolioValidacion:this.idFolioValidacion.toUpperCase(), nombre_completo:this.user_fullname.toUpperCase(),usernamePanel:this.user_name.toLowerCase(),password:this.user_password },this.config)
            if(res.data.result = 1){
                this.$alert('Usuario Actualizado','','success',{showConfirmButton: false, background: 'rgba(0, 0, 0, 0)', backdrop: 'rgba(0, 0, 0, 0)', position: 'top-end', width: 200, timer: 1000});
                           
                return
            }
            this.$alert('','<label class="alertlabel">Ocurrió un problema al guardar los datos</label>','error', {confirmButtonText:'<p class="ok">OK!</p>', confirmButtonColor: '#6a6ea8'});   

        



        },
    },
  
    async created(){  //se utiliza created porque funciona de mejor manera que mounted para llamar APIS, ya que mounted se espera a que el DOM cargue
        const url = 'https://informatica.iem.org.mx/api/conoceles/api/usuario'
        const url2 = 'https://informatica.iem.org.mx/api/conoceles/api/usuarios'
       
        try {
            //SE INICIALIZAN LOS DATOS DEL USUARIO QUE INICIA SESION, LOS TOKENS Y SE INICIALIZA LA LISTA DE USUARIOS DE LA TABLA
            const loggedAppUserPanel = localStorage.getItem('loggedAppUserPanel')
            if(!loggedAppUserPanel)
                this.path('/')
            
            //Obtencion de Datos del usuario que Inicia Sesion en el portal
            const {idUsuario, token, usernamePanel,nombre_completo,roles} = JSON.parse(loggedAppUserPanel)
            this.username = usernamePanel
            this.idUsuario = idUsuario
            this.nombre_completo_usuario = nombre_completo
            this.user_rol=roles
            //this.nombre_completo_panel = nombre_completo 
            //Token de autorización del usuario que inicia la sesion        

            this.config = {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }
            // Se obtiene la lista de usuarios de la lista de usuarios y se le envia la configuracion del token de autorizacion
            const res = await axios.get(url2, this.config )
            this.listaUsers = res.data;
            //Se obtiene la pagina de aterrizaje del portal
            const result = await axios.get(url,this.config)
            this.pagina = result.data[0].pagina

            //this.nombre_completo_usuario = result.data[0].nombre_completo
            //this.rol = result.data[0].rol          
            
            this.path(this.pagina)
            this.isLoading = false

        } catch (error) {
            if(error.request && error.request.status && (error.request.status === 401 || error.request.status === 404)){
                alert("error")
                localStorage.removeItem('loggedAppUserPanel')
                this.thispath('/adminpanel')
            }
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }

}
</script>

<style>
h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color:#6c757d;
}

.custom-table {
  font-family: GalanoRegular;
  border-collapse: collapse;
  width: 100%;
}
.custom-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.custom-table tr:nth-child(even){background-color: #f2f2f2;}
.custom-table tr:hover {background-color: #ddd; cursor: pointer;}
.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #9F2380;
  color: white;
  padding-left: 5px;
}
.custom-table th:hover {cursor: pointer;}
.form-control:focus {
  border-color: rgba(228,92,135, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(228,92,135, 0.6);
}
.progress-bar {
    font-family: GalanoRegular; font-size: 16px;
}
.questionary-container {
  text-align: left; margin: 10px 10px; max-width: 624px;
}
.questionary-row {
  justify-content: center; margin-top: 3%; margin-bottom: 3%;
}
.progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #9F2380;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}
.btn-custom {
    font-family: GalanoRegular;
    padding-right: 30px;
    padding-left: 30px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #9F2380;
    --bs-btn-border-color: #9F2380;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6a6ea8;
    --bs-btn-hover-border-color: #6a6ea8;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41446a;
    --bs-btn-active-border-color: #41446a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-secondary {
    font-family: GalanoRegular;
    --bs-btn-color: #fff;
    --bs-btn-bg: #686868;
    --bs-btn-border-color: #686868;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.radio-tile-group {
  font-family: GalanoRegular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-container {
    position: relative;
    height:  fit-content;
    width:  90%;
}
.radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background-color: #686868;
}
.radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 2px solid #9F2380;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      transition: transform 300ms ease;
}
.radio-tile-label {
      text-align: center;
      font-weight: bold;
}
.radio-button:checked + .radio-tile {
      background-color: #9F2380;
      border: 2px solid #9F2380;
      color: white;
      transform: scale(1.111, 1.111);
      .radio-tile-label {
        color: white;
        background-color: #9F2380;
      }
}
.multiple-choice {
    font-weight: bold;
    font-family: GalanoRegular;
}
label {
    font-family: GalanoBold;
    color: #9F2380;
    font-size: 18px;
}
button {
    font-family: GalanoRegular;
}
input {
    font-family: GalanoRegular;
}
input::placeholder {
  color: #b0aeb3;
}
/* Se agregan nuevas clases para mostrar radiobutons de filtrtado**/
.buttons-container{
    text-align: start;
    align-items: start;  
    width: 100%;
    margin-bottom: 1%;
 
}
.label-color{
    color:#9F2380;  

}
.button-separation{
    
    padding-right: 2%;
    margin-bottom: 2%;

}
.btn-align-right{
      
    margin-bottom: 5px;  
    
}

</style>