<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
        <h3>Estadística</h3>   
        <h4>Registro de candidaturas por rango de edad</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
        <bar v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></bar>
      </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 mt-3">
        <h4>Rangos de Edad</h4>
        
      </div>
    </div>

    </div>
  </div> 
</template>

<script>
import axios from 'axios'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: 'GraficaEdad',
  components: { Bar },
  data() {
    return {
    loaded:false,
      chartData: {  

        labels: [ '18-20','21-29','30-40','41-50','51-60','Mayor a 60'],
        datasets: [ 
            {
            label: 'Rangos de Edad',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#2A7BA9','#E75395','#6161A9','#AA0DFC','#5B0DFC','#0D58FC']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        }
      },
      //Variables para creacion de los datos
      lista :[],
      listaP:[],
      listaPS:[],
      listaS:[],
      listaC:[],
      listaSRPMR:[],
      listaCRPMR:[],

      cTotalEdades:0,  
      cTotalEdades1:0,
      cTotalEdades2:0,
      cTotalEdades3:0,
      cTotalEdades4:0,
      cTotalEdades5:0,

      porcentajeEdad1:0,
      porcentajeEdad2:0
    }
  },
  methods:{
    mergeListaSuplentes(listaP, listaS, listaS2){
            return listaP.map( element1 =>{
                if(element1.cve_distrito > 0){
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else if(element1.cve_distrito === "0" && element1.cve_mpio === "0"){
                    const element2 = listaS2.find(element => (element.posicion === element1.posicion+1)&&(element.id_postulacion===element1.id_postulacion))
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:element2.nombre+" "+element2.paterno+" "+element2.materno                   
                     } )
                    }
                }
                else{
                    const element2 = listaS.find(element => element.id_postulacion === element1.id_postulacion)
                    return{
                    ...element1,
                    ...(element2 && {
                        suplente:""                  
                     } )
                    }
                }   
            })
        }

      
    },

  async mounted(){
    const res2 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/candidatos`)
    this.listaC=res2.data;
    const res3 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/diputacioness`)
    this.listaS = res3.data;


    this.loaded = false
    const url = 'https://informatica.iem.org.mx/api/conoceles/api/partidos'
    const res = await axios.get(url)

    this.listaP = res.data

    this.listaP.forEach(element=>{
         if((element.posicion%2) !== 0){
                this.listaSRPMR.push(element) // Lista que guarda puras DRP propietarias
            }
         })
        //console.log("LISTA FILTRADA SIN SUPLENCIAS",this.listaSRPMR)
        this.listaP.forEach(element=>{
            if((element.posicion%2)===0){
                this.listaCRPMR.push(element) //Lista que guarda puras DRP suplentes
            }
        })
        this.listaPS = this.mergeListaSuplentes(this.listaSRPMR, this.listaS,this.listaCRPMR) //SE QUITA this.listaP 
        //se combinan las listas de acuerdo a una condicion en el metodo
        
        //console.log("LISTA CON IMAGEN",listatmp)
        this.listaPS.forEach(element =>{
            this.lista.push(element)
            
        })
        this.total=this.lista.length


    //this.lista = res.data

    let array_g=[]
    const contador={}
    

    this.lista.forEach(element =>{
        {         
          array_g.push(element.edad)     
                    
             
        }      
    })

    array_g.forEach(items =>{
      if(contador[items]){
        contador[items]++
      }else{
        contador[items]=1
      }
    })
   
    
    for( let clave in contador){
      if(clave >=18 && clave <=20){
        this.cTotalEdades = this.cTotalEdades+contador[clave]       
      }
      else if(clave >=21 && clave <=29){
        this.cTotalEdades1 = this.cTotalEdades1+contador[clave]       
      }else if(clave >=30 && clave <=40){
        this.cTotalEdades2 = this.cTotalEdades2+contador[clave]     
        
      }else if(clave >=41 && clave <=50){
        this.cTotalEdades3 = this.cTotalEdades3+contador[clave]      
      }
      else if(clave >=51 && clave <=60){
        this.cTotalEdades4 = this.cTotalEdades4+contador[clave]        
      }
      else if(clave >=61 && clave <=100){
        this.cTotalEdades5 = this.cTotalEdades5+contador[clave]        
      }
    }
    //console.log("Edades:18-21",this.cTotalEdades)
    this.chartData.datasets[0].data[0]=this.cTotalEdades
    this.chartData.datasets[0].data[1]=this.cTotalEdades1
    this.chartData.datasets[0].data[2]=this.cTotalEdades2
    this.chartData.datasets[0].data[3]=this.cTotalEdades3
    this.chartData.datasets[0].data[4]=this.cTotalEdades4
    this.chartData.datasets[0].data[5]=this.cTotalEdades5

    this.loaded = true
    }
}
</script>


<style>
/*rescribimos los estilos heredados*/
h3{
  font-weight:bolder;  
  color: #686868;
}
p{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
/*rescribimos los estilos heredados*/
h4{
  font-weight:bolder;  
  color: #686868;
}
h5{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
.sizeChart{
  width: 600px;
  height: 600px;

}

</style>