<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-12 mb-3">
          <h3>Estadística</h3>   
          <h4>Registro curricular de Diputaciones de Representación Proporcional</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-12 p-3 sizeChart">
          <bar v-if="loaded" id="my-chart-id" :options = "chartOptions" :data = "chartData"></bar>
        </div> 
      </div>
      <!--
              <div class="row border-top border-bottom">
        <div class="col-xs-12 col-sm-12 col-lg-12">
          TOTALES
          <hr>
          <table class="table">
          <tr >            
            <td class="p-2" scope="col-xs-1">{{chartData.datasets[0].data[0]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[1]}} de {{total}}</td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[2]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[3]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[4]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[5]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[6]}} de {{total}}</td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[7]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[8]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[9]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[10]}} de {{total}} </td>
            <td scope="col-xs-1">{{chartData.datasets[0].data[12]}} de {{total}} </td>
           
          </tr>
        </table>

        </div>       
      </div>

      -->

    </div> 
  </template>

<script>
import axios from 'axios'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: 'GraficaCurricularDRP',
  components: { Bar },
  data() {
    return {
    lista :[],
    total:112,
    idsc:0,

    loaded:false,
      chartData: {  

        labels: ['PAN','PRI','PRD','PT','PVEM','MC','MORENA','PESM','PMM','MICH1','TMX'],
        datasets: [ 
            {
            label: 'Registro curricular de Diputaciones',             
            data:[],
            borderWidth:1,
            borderColor:'#686868',
            backgroundColor:['#B20066','#E72174','#6461A9','#33398E','#912283','#33398E','#B20066','#E72174','#6461A9','#33398E','#912283']
            },           
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                labels: {
                    
                    font: {
                        size: 20
                    },
                   
                }
            }
        }
      },
      //Variables para creacion de los datos
      
    }
  },
  methods:{
    mergeLista(listaP, listaC){
            return listaP.map( element1 =>{
                const element2 = listaC.find(element => element.idsc === element1.id)
                return{
                ...element1,
                ...(element2 && {imagen: element2.imagen, Folio: element2.idsc, pagina:element2.pagina,validacionCpyPP:element2.validacionCPyPP,validacionCaptura:element2.validacionCaptura} )
            }
        }) 
    }
 },

  async mounted(){
    this.loaded = false
    //contadores temporales
    let listatmp=[]
    let count=1
    //let pos=0
    

    
    const res1 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/partidos`)
    this.listaP=res1.data;
    const res2 = await axios.get(`https://informatica.iem.org.mx/api/conoceles/api/candidatos`)
    this.listaC=res2.data;

    listatmp = this.mergeLista(this.listaP, this.listaC)
    listatmp.forEach(element =>{
            if(element.tipo_eleccion==="2" && element.tipo_postulante==="1"){
              this.lista.push(element)
            }
                       
        })
        
    //console.log('Desde Grafica Curricular DRP',this.lista)
    for(let i=0; i <=10; i++){
        this.lista.forEach(element =>{
            if(element.validacionCpyPP===true && element.postulante===this.chartData.labels[i] && element.tipo_eleccion==="2"&&element.tipo_postulante==="1"){
                this.chartData.datasets[0].data[i] = count++
            }        
          }
          )
          
        count=1      
    }
     this.loaded = true
    },
    //Union de listas
   



}
</script>


<style>
/*rescribimos los estilos heredados*/
h3{
  font-weight:bolder;  
  color: #686868;
}
p{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
/*rescribimos los estilos heredados*/
h4{
  font-weight:bolder;  
  color: #686868;
}
h5{
  font-weight:bolder;  
  color: #686868; 
  font-size: 14px;
  text-align: justify;
}
.sizeChart{
  width: 600px;
  height: 600px;

}

</style>